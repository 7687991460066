<script setup>
import AccountListing from "@src/modules/integration/components/platforms/social/AccountListing.vue";
import {computed, inject, onBeforeMount, reactive} from "vue";
import {cookieDomain, plannerBaseUrl} from "@src/config/api-utils";
import { useRoute } from 'vue-router';
import {easyConnectProxy} from '@common/lib/http-common'
import vueCookie from "vue-cookie";
import {useStore} from "vuex";
import {platforms} from "@src/modules/integration/store/states/mutation-types";
import { EventBus } from '@common/lib/event-bus'
import SaveSocialAccounts from "@src/modules/integration/components/dialogs/SaveSocialAccounts";
import {SAVE_PLATFORMS_ERROR, SAVE_PLATFORMS_SUCCESS, UNKNOWN_ERROR} from "@common/constants/messages";
import BulkReconnectedAccounts from "@src/modules/integration/components/dialogs/BulkReconnectedAccounts";
import VerifyExternalLinkPassword from "@src/modules/integration/components/dialogs/VerifyExternalLinkPassword";
import AccountsNotAllowedModal from "@src/modules/integration/components/modals/AccountsNotAllowedModal";
import {userMaven} from "@src/tracking/userMavenMixin";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import {sanitizeUrl} from "@braintree/sanitize-url";

const root = inject('root')
const { trackUserMaven, $bvModal } = root
const route = useRoute()
const {appData, isWhiteLabelDomain} = useWhiteLabelApplication()
const { dispatch, commit, getters } = useStore()

const location = window.location?.origin

const linkUrlSlug = route.path.split('/')[2]
const data = reactive({
  facebookAccounts: [],
  instagramAccounts: [],
  twitterAccounts: [],
  linkedinAccounts: [],
  gmbAccounts: [],
  youtubeAccounts: [],
  tiktokAccounts: [],
  tumblrAccounts: [],
  pinterestAccounts: [],
  threadsAccounts: [],
  blueskyAccounts: [],
  token: '',
  externalLinkId: '',
  workspaceId: '',
  secureLink: false,
  verifyLoading: false,
  disableError: false,
  isTwitterAllowed: false,
  type: '',
  platform: '',
})

const platformName = computed(() => {
  return (
      getters.getPlatformsConnectionState?.process
          ? getters.getPlatformsConnectionState.process
          : ''
  ).toLowerCase()
})

const firstSectionItems = computed(() => {
  switch (platformName.value) {
    case 'instagram':
      return getters.getPlatformsConnectionState?.profiles
    case 'facebook':
      return getters.getPlatformsConnectionState?.pages
    case 'pinterest':
      return getters.getPlatformsConnectionState?.boards
    case 'linkedin':
      return [getters.getPlatformsConnectionState?.profile]
    case 'gmb':
      return getters.getPlatformsConnectionState?.locations
    case 'youtube':
      return getters.getPlatformsConnectionState?.channels
    case 'tumblr':
      return getters.getPlatformsConnectionState?.blogs
  }
  return []
})

const firstSectionMessage = computed(() => {
  switch (platformName.value) {
    case 'instagram':
      return 'No instagram business profile found.'
    case 'facebook':
      return 'No facebook page found.'
    case 'pinterest':
      return 'No pinterest boards found.'
    case 'linkedin':
      return 'No linkedin profiles found.'
    case 'gmb':
      return 'No location found.'
    case 'youtube':
      return 'No youtube channels found.'
    case 'tumblr':
      return 'No tumblr blogs found.'
  }
  return 'No account found!'
})

const firstSectionHeader = computed(() => {
  switch (platformName.value) {
    case 'instagram':
      return 'Business Profiles'
    case 'facebook':
      return 'Pages'
    case 'pinterest':
      return 'Boards'
    case 'linkedin':
      return 'Profiles'
    case 'gmb':
      return 'Locations'
    case 'youtube':
      return 'Channels'
    case 'tumblr':
      return 'Blogs'
  }
  return ''
})

const secondSectionHeader = computed(() => {
  switch (platformName.value) {
    case 'facebook':
      return 'Groups'
    case 'linkedin':
      return 'Pages'
    case 'tumblr':
      return 'Profiles'
  }
  return ''
})


const secondSectionMessage = computed(() => {
  switch (platformName.value) {
    case 'facebook':
      return 'No facebook groups found.'
    case 'linkedin':
      return 'No linkedin pages found.'
    case 'tumblr':
      return 'No tumblr profile found'
  }
  return 'No account found!'
})

const secondSectionItems = computed(() => {
  switch (platformName.value) {
    case 'facebook':
      return getters.getPlatformsConnectionState?.groups
    case 'linkedin':
      return getters.getPlatformsConnectionState?.pages
    case 'tumblr':
      return getters.getPlatformsConnectionState?.profiles
  }
  return []
})


const getSecureLink = async () => {
  await easyConnectProxy.post(`${plannerBaseUrl}getLinkSecureStatus`, {'link_url_slug': linkUrlSlug}).then((response) => {
    if (response.data.status) {
      if (response.data.is_secure) {
        data.secureLink = true
        $bvModal.show('public-password-protected-external-link')
      } else {
        data.facebookAccounts = response.data.data.facebook_accounts
        data.instagramAccounts = response.data.data.instagram_accounts
        data.twitterAccounts = response.data.data.twitter_accounts
        data.linkedinAccounts = response.data.data.linkedin_accounts
        data.gmbAccounts = response.data.data.gmb_accounts
        data.youtubeAccounts = response.data.data.youtube_accounts
        data.tiktokAccounts = response.data.data.tiktok_accounts
        data.tumblrAccounts = response.data.data.tumblr_accounts
        data.pinterestAccounts = response.data.data.pinterest_accounts
        data.threadsAccounts = response.data.data.threads_accounts
        data.blueskyAccounts = response.data.data.bluesky_accounts
        data.token = response.data.data.token
        data.externalLinkId = response.data.data._id
        data.workspaceId = response.data.data.workspace_id
        data.isTwitterAllowed = response.data.data.is_twitter_allowed
        sessionStorage.setItem('external_link_token', JSON.stringify({token: data.token, externalLinkId: data.externalLinkId, workspaceId: data.workspaceId}))
      }
    }
  }).catch( ({ response } )=> {
    if(response.status === 403 || response.status === 422){
      data.disableError = true
    } else {
      dispatch('toastNotification', {
        message: response.data?.message || UNKNOWN_ERROR,
        type: 'error',
      })
    }
  })
}

onBeforeMount(async () => {

  if(!linkUrlSlug) {
    data.disableError = true
    return ''
  }

  const verified = JSON.parse(sessionStorage.getItem('external_link_token'))
  data.token = verified?.token
  data.externalLinkId = verified?.externalLinkId
  data.workspaceId = verified?.workspaceId

  if(data.token) {
    await updateExternalLinkData()
  } else {
    await getSecureLink()
  }

  await initializeSection()
})


const initializeSection = async () => {
  console.debug('Social::externalLink::initializeSection')
  const response = await dispatch(
      'checkConnectorState',
      {hash: route.hash, eternalLinkId: data?.externalLinkId, externalLinkToken: data?.token}
  )
  if (response?.data?.processType === 'bulk_reconnect') {
    await processBulkReconnectResponse(response)
  } else {
    await processReconnectResponse(response)
  }
  vueCookie.delete('external_link_token', {
    domain: cookieDomain,
  })
}

const processReconnectResponse = async (response) => {
  if (response) {
    if (response?.data?.limitExceed) {
      dispatch('toastNotification', {
        message: 'You have reached your plan\'s limit. Please reach out to your administrator for assistance.',
        type: 'error',
      })
      return
    }
    if (response?.data?.status === true) {
      trackUserMaven('connected_social_accounts')
      switch (response.data?.process) {
        case 'Facebook':
        case 'Instagram':
        case 'Linkedin':
        case 'Pinterest':
        case 'tumblr':
        case 'Gmb':
        case 'Youtube':
          if (
              response.data?.pages ||
              response.data?.groups ||
              response.data?.profile ||
              response.data?.boards ||
              response.data?.profiles ||
              response.data?.blogs ||
              response.data?.locations ||
              response.data?.channels
          ) {
            commit(
                platforms.SET_CONNECTION_STATE,
                response.data
            )
            $bvModal.show('save-social-accounts')
            return
          }
          break
      }
      if (response.data?.not_allowed?.length) {
        EventBus.$emit('showNotAllowedModal', {
          notAllowedIds: response?.data?.not_allowed,
          firstSectionItems: [response?.data?.profile || []],
          firstSectionHeader: 'Social Account',
        })
        return
      }
      await dispatch('toastNotification', {
        message: response?.data?.message,
        type: 'success',
      })
      await updateExternalLinkData()
    }else if (response?.data?.status === false) {
      dispatch('toastNotification', {
        message: response?.data?.message,
        type: 'error',
      })
    }
  }
}


const SaveSocialSocialAccounts = async ({Platforms, platformName}) => {
  if (
      Platforms.firstItems.length === 0 &&
      Platforms.secondItems.length === 0
  ) {
    dispatch('toastNotification', {
      message: 'Please select at least one platform to continue.',
      type: 'error',
    })
    return false
  }
  const connectionState = JSON.parse(
      JSON.stringify(getters.getPlatformsConnectionState)
  )
  if( platformName === 'instagram' ){
    const selectedAccounts = connectionState.profiles.filter((value) => {
      return Platforms.firstItems.includes(value.instagram_id)
    })

    const response = await dispatch('processSaveIGBusinessProfile',{ connectionState: {
        social_accounts: selectedAccounts,
        workspace_id: data.workspaceId,
        connection_via_link: true,
        connection_link_id: data.externalLinkId
      }, externalLinkToken: data.token })

    // hiding the connection modal
    $bvModal.hide('save-social-accounts')

    if(response.data) {
      if (response.data.status === true) {
        if (response.data.limitExceed) {
          // showing the upgrade plan modal
          dispatch('toastNotification', {
            message: 'You have reached your plan\'s limit. Please reach out to your administrator for assistance.',
            type: 'error',
          })
        }else {
          if (response.data?.not_allowed.length) {
            EventBus.$emit('showNotAllowedModal', {
              notAllowedIds: response.data.not_allowed,
              firstSectionItems: firstSectionItems.value,
              firstSectionHeader: firstSectionHeader.value,
              firstSectionMessage: firstSectionMessage.value,
              secondSectionItems: secondSectionItems.value,
              secondSectionHeader: secondSectionHeader.value,
              secondSectionMessage: secondSectionMessage.value,
            })
          } else {
            dispatch('toastNotification', {
              message: SAVE_PLATFORMS_SUCCESS,
              type: 'success',
            })
            await updateExternalLinkData()
          }
        }
      } else {
        // if the user's social accounts limit exceeded
        if (response.data.limitExceed) {
          dispatch('toastNotification', {
            message: 'You have reached your plan\'s limit. Please reach out to your administrator for assistance.',
            type: 'error',
          })
        } else if (response.data.message) {
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
        } else {
          dispatch('toastNotification', {
            message: SAVE_PLATFORMS_ERROR,
            type: 'error',
          })
        }
      }
    }

  }
  else{
    connectionState.selectedPlatform = Platforms

    // saving the selected accounts (api call)
    const response = await dispatch('processSavePlatforms', {connectionState: {
        ...connectionState,
        workspace_id: data.workspaceId,
        connection_via_link: true,
        connection_link_id: data.externalLinkId

      }, externalLinkToken: data.token})
    $bvModal.hide('save-social-accounts')

    if(response?.data){
      if (response.data?.status === true) {
        if (response.data?.limitExceed) {
          dispatch('toastNotification', {
            message: 'You have reached your plan\'s limit. Please reach out to your administrator for assistance.',
            type: 'error',
          })
        } else {
          if (response.data?.not_allowed.length) {
            EventBus.$emit('showNotAllowedModal', {
              notAllowedIds: response.data?.not_allowed,
              firstSectionItems: firstSectionItems.value,
              firstSectionHeader: firstSectionHeader.value,
              firstSectionMessage: firstSectionMessage.value,
              secondSectionItems: secondSectionItems.value,
              secondSectionHeader: secondSectionHeader.value,
              secondSectionMessage: secondSectionMessage.value,
            })
          } else {
            dispatch('toastNotification', {
              message: SAVE_PLATFORMS_SUCCESS,
              type: 'success',
            })
            await updateExternalLinkData()
          }
        }
      }else {
        if (response.data?.limitExceed) {
          dispatch('toastNotification', {
            message: 'You have reached your plan\'s limit. Please reach out to your administrator for assistance.',
            type: 'error',
          })
          return false
        }

        if (response.data?.message) {
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
          return false
        }

        dispatch('toastNotification', {
          message: SAVE_PLATFORMS_ERROR,
          type: 'error',
        })
      }
    }
  }

  // usermaven tracking
  userMaven.track('easy_connect_accounts_connected', {
    platform: platformName,
    no_of_accounts: Platforms.firstItems.length + Platforms.secondItems.length,
    workspace_id: data.workspaceId,
    connection_link_id: data.externalLinkId
  })
}

const  processBulkReconnectResponse = (response) => {
  const {
    data: { connectionData },
  } = response
  if (connectionData) {
    trackUserMaven('connected_social_accounts')
    EventBus.$emit('toggleBulkReconnectedAccounts', {
      toggleValue: true,
      connectionData,
    })
    $bvModal.show('bulkReconnectedAccounts')
  }
}

const updateExternalLinkData = async () => {
  await easyConnectProxy.post(`${plannerBaseUrl}getLinkDetails`, {'link_url_slug': linkUrlSlug}).then(async (response) => {
        data.facebookAccounts = response.data.data.facebook_accounts
        data.instagramAccounts = response.data.data.instagram_accounts
        data.twitterAccounts = response.data.data.twitter_accounts
        data.linkedinAccounts = response.data.data.linkedin_accounts
        data.gmbAccounts = response.data.data.gmb_accounts
        data.youtubeAccounts = response.data.data.youtube_accounts
        data.tiktokAccounts = response.data.data.tiktok_accounts
        data.tumblrAccounts = response.data.data.tumblr_accounts
        data.pinterestAccounts = response.data.data.pinterest_accounts
        data.threadsAccounts = response.data.data.threads_accounts
        data.blueskyAccounts = response.data.data.bluesky_accounts
        data.token = response.data.data.token
        data.externalLinkId = response.data.data._id
        data.workspaceId = response.data.data.workspace_id
        data.isTwitterAllowed = response.data.data.is_twitter_allowed
        sessionStorage.setItem('external_link_token', JSON.stringify({token: data.token, externalLinkId: data.externalLinkId, workspaceId: data.workspaceId}))
      }
  ).catch( ({ response } )=> {
    if(response.status === 403 || response.status === 422){
      data.disableError = true
    }else{
      getSecureLink()
    }
  })
}

const verifyPassword = async (password) =>{
  data.verifyLoading = true
  await easyConnectProxy.post(`${plannerBaseUrl}verifyLinkPassword`,{'link_url_slug': linkUrlSlug, password }).then(async (response)  => {
    if (response.data.status) {
      data.facebookAccounts = response.data.data.facebook_accounts
      data.instagramAccounts = response.data.data.instagram_accounts
      data.twitterAccounts = response.data.data.twitter_accounts
      data.linkedinAccounts = response.data.data.linkedin_accounts
      data.gmbAccounts = response.data.data.gmb_accounts
      data.youtubeAccounts = response.data.data.youtube_accounts
      data.tiktokAccounts = response.data.data.tiktok_accounts
      data.tumblrAccounts = response.data.data.tumblr_accounts
      data.pinterestAccounts = response.data.data.pinterest_accounts
      data.threadsAccounts = response.data.data.threads_accounts
      data.blueskyAccounts = response.data.data.bluesky_accounts
      data.token = response.data.data.token
      data.externalLinkId = response.data.data._id
      data.workspaceId = response.data.data.workspace_id
      data.isTwitterAllowed = response.data.data.is_twitter_allowed
      sessionStorage.setItem('external_link_token', JSON.stringify({token: data.token, externalLinkId: data.externalLinkId, workspaceId: data.workspaceId}))

      $bvModal.hide('public-password-protected-external-link')
    }
  }).catch( ({ response } )=> {
    if(response.status === 403){
      data.disableError = true
      $bvModal.hide('public-password-protected-external-link')
      return ''
    }
    dispatch('toastNotification', {
      message: response.data?.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }).finally(() => {
    data.verifyLoading = false
  })
}

</script>

<template>
  <div>
    <header class="h-[60px] bg-[#005fd0] flex justify-content-center px-4 py-2">
      <a :href="sanitizeUrl(isWhiteLabelDomain ? location : 'https://app.contentstudio.io/')" target="_blank" rel="noopener"  class="flex items-center justify-center gap-2">
        <img
            :src="isWhiteLabelDomain ? appData.brandLogo : 'https://contentstudio.io/images/Logo%20White.svg'"
            :alt="appData.businessName"
            height="30"
        />
      </a>
    </header>
    <template v-if="data.disableError">
      <div
          class="rounded-xl bg-white shadow-sm p-12 my-20 w-128 mx-auto text-center text-lg"
      >
        The link is invalid or disabled by the user.
      </div>
    </template>
    <template v-else-if="!data.disableError && data.token">
      <p class="text-[#757A8A] text-base mt-6 text-center">EasyConnect - Securely connect your social accounts with {{appData.businessName}}.</p>
      <div class="flex flex-col items-center">
        <div class="p-5 w-full md:w-[80%] lg:w-[60%] xl:w-[50%]">
          <AccountListing type="facebook" :is-bulk-reconnect="true" :is-easy-connect-view="true" :external-link="true" :external-link-account="data.facebookAccounts" ></AccountListing>
          <AccountListing type="instagram" :is-bulk-reconnect="true" :is-easy-connect-view="true" :external-link="true" :external-link-account="data.instagramAccounts" ></AccountListing>
          <AccountListing type="threads" :external-link="true" :external-link-account="data.threadsAccounts" :is-easy-connect-view="true" ></AccountListing>
          <AccountListing type="twitter" :connect-twitter-content-studio="data.isTwitterAllowed" :is-easy-connect-view="true" :external-link="true" :external-link-account="data.twitterAccounts" ></AccountListing>
          <AccountListing type="linkedin" :is-bulk-reconnect="true" :is-easy-connect-view="true" :external-link="true" :external-link-account="data.linkedinAccounts" ></AccountListing>
          <AccountListing type="pinterest" :external-link="true" :is-easy-connect-view="true" :external-link-account="data.pinterestAccounts" ></AccountListing>
          <AccountListing type="gmb" :is-bulk-reconnect="true" :is-easy-connect-view="true" :external-link="true" :external-link-account="data.gmbAccounts" ></AccountListing>
          <AccountListing type="youtube" :external-link="true" :is-easy-connect-view="true" :external-link-account="data.youtubeAccounts" ></AccountListing>
          <AccountListing type="tiktok" :external-link="true" :is-easy-connect-view="true" :external-link-account="data.tiktokAccounts" ></AccountListing>
          <AccountListing type="tumblr" :external-link="true" :is-easy-connect-view="true" :external-link-account="data.tumblrAccounts"></AccountListing>
          <AccountListing type="bluesky" :external-link="true" :is-easy-connect-view="true" :external-link-account="data.blueskyAccounts" :easy-connect-token="data.token"></AccountListing>
        </div>
      </div>
      <SaveSocialAccounts is-external-link @save-social-accounts="SaveSocialSocialAccounts"/>
      <BulkReconnectedAccounts
          :external-link="true"
          :external-platform="[
              ...data.facebookAccounts,
              ...data.instagramAccounts,
              ...data.twitterAccounts,
              ...data.linkedinAccounts,
              ...data.gmbAccounts,
              ...data.youtubeAccounts,
              ...data.tiktokAccounts,
              ...data.tumblrAccounts,
              ...data.pinterestAccounts
          ]"
      ></BulkReconnectedAccounts>
    </template>
    <VerifyExternalLinkPassword :verify-loading="data.verifyLoading" @verify-password="verifyPassword"></VerifyExternalLinkPassword>
    <AccountsNotAllowedModal />
  </div>
</template>

<style scoped>



</style>
