<script>
import {
  ACCOUNT_NOT_ENOUGH_DATA_ERROR,
  SLOTS_EMPTY_DAYS_SELECTION_ERROR,
  SLOTS_EMPTY_TIME_SELECTION_ERROR,
  SLOTS_INVALID_TIME_SELECTION_ERROR,
} from '@common/constants/messages'
import useDateFormat from '@common/composables/useDateFormat'
import { mapGetters } from 'vuex'
import { social } from '../../store/states/mutation-types'
import CstButton from '@ui/Button/CstButton.vue'
import DropdownTransition from "@src/components/common/DropdownTransition.vue";
import {setting} from "@modules/setting/store/states/mutation-types";
import {nextTick} from "vue";

export default {
  components: {
    DropdownTransition,
    CstButton,
  },
  setup() {
    const { getUserTimePreference } = useDateFormat()
    return {
      getUserTimePreference,
    }
  },
  data() {
    return {
      update_slots_loader: false,
      selectedHour: 9,
      selectedMinute: 0,
      selectedPeriod: 'AM',
      openPickerIndex: null,
      openTimeIndex: null,
      editingTime: null,
      hours: this.getUserTimePreference === '12h' ? 12 : 24,
    }
  },
  computed: {
    ...mapGetters(['getAccountQueueTimes', 'getWorkspaces', 'getSocialLoader']),

    getQueueSlots() {
      return this.getAccountQueueTimes.type === 'prime'
        ? this.getAccountQueueTimes.prime_queue
        : this.getAccountQueueTimes.custom_queue
    },
    showPlansRequeueWarning() {
      return this.getAccountQueueTimes.plans_count
    },
    showQueueSlots() {
      return this.getQueueSlots.length > 0
    },
    isCustomQueue() {
      return this.getAccountQueueTimes.type === 'custom'
    },
    allowQueueReschedule() {
      return (
        this.getProfile._id ===
          this.getAccountQueueTimes.selected_item.user_id ||
        this.hasPermission('can_reschedule_plan')
      )
    },
    fetchSlotsLoaderStatus() {
      return this.getSocialLoader.fetch_slots_loader
    },
    updateSlotsLoaderStatus() {
      return this.update_slots_loader
    },
    formattedTime() {
      return this.formatTime(this.getDefaultTime())
    },
  },

  methods: {
    hideModal() {
      this.$bvModal.hide('account_queue_slot_modal')
    },
    clearAllAccountQueueSlots() {
      const slots = this.getAccountQueueTimes.custom_queue
      for (let i = 0; i < slots.length; i++) {
        slots[i].status = false
        slots[i].times = []
      }
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    addAccountQueueSlot(slotIndex) {
      const slots = this.getAccountQueueTimes.custom_queue
      slots[slotIndex].times.push('12:00')
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    removeAccountQueueSlot(slotIndex, timeIndex) {
      const slots = this.getAccountQueueTimes.custom_queue
      slots[slotIndex].times.splice(timeIndex, 1)
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },
    async updateQueueSlots() {
      console.debug('Method:updateQueueSlots')
      const currentObject = this
      const account_queue_times = this.getAccountQueueTimes

      const data = {
        queueType: account_queue_times.type,
        type: account_queue_times.selected_item.type,
        platform: account_queue_times.selected_item.platform,
        workspace_id: account_queue_times.selected_item.workspace_id,
      }

      if (account_queue_times.selected_item.type === 'Facebook') {
        if (
          account_queue_times.queue_type === 'prime' &&
          !account_queue_times.prime_status
        ) {
          this.alertMessage(ACCOUNT_NOT_ENOUGH_DATA_ERROR, 'error')
          return false
        }
      }

      if (account_queue_times.queue_type !== 'prime') {
        if (
          account_queue_times.custom_queue[0].status === false &&
          account_queue_times.custom_queue[1].status === false &&
          account_queue_times.custom_queue[2].status === false &&
          account_queue_times.custom_queue[3].status === false &&
          account_queue_times.custom_queue[4].status === false &&
          account_queue_times.custom_queue[5].status === false &&
          account_queue_times.custom_queue[6].status === false
        ) {
          this.alertMessage(SLOTS_EMPTY_DAYS_SELECTION_ERROR, 'error')
          return false
        }
        if (account_queue_times.custom_queue[0].status === true) {
          if (account_queue_times.custom_queue[0].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'sunday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[0].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'sunday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[1].status === true) {
          if (account_queue_times.custom_queue[1].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'monday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[1].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'monday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[2].status === true) {
          if (account_queue_times.custom_queue[2].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'tuesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[2].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'tuesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[3].status === true) {
          if (account_queue_times.custom_queue[3].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'wednesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[3].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'wednesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[4].status === true) {
          if (account_queue_times.custom_queue[4].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'thursday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[4].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'thursday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[5].status === true) {
          if (account_queue_times.custom_queue[5].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'friday.',
              'error'
            )
            return false
          }
          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[5].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'friday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (account_queue_times.custom_queue[6].status === true) {
          if (account_queue_times.custom_queue[6].times.length === 0) {
            this.alertMessage(
              SLOTS_EMPTY_TIME_SELECTION_ERROR + 'saturday.',
              'error'
            )
            return false
          }
          // loop on times for validation
          let status = true

          account_queue_times.custom_queue[6].times.forEach(function (
            el,
            index
          ) {
            if (!currentObject.maskingValidationStatus(el)) {
              currentObject.alertMessage(
                SLOTS_INVALID_TIME_SELECTION_ERROR +
                  'saturday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }

        data.slots = account_queue_times.custom_queue
      }
      this.update_slots_loader = true
      const response = await this.$store.dispatch(
        'updateAccountQueueSlots',
        data
      )
      if (response) {
        this.$bvModal.hide('account_queue_slot_modal')
      }
      this.update_slots_loader = false
    },
    togglePicker(dayIndex, timeIndex = null) {
      if (
          this.openPickerIndex === dayIndex &&
          this.openTimeIndex === timeIndex
      ) {
        this.closeTimePicker()
      } else {
        if (timeIndex !== null) {
          const time = this.getQueueSlots[dayIndex].times[timeIndex]
          this.editingTime = time
          const [hour, minute] = time.split(':')
          this.selectedHour = parseInt(hour, 10)
          this.selectedMinute = parseInt(minute, 10)
          if (this.getUserTimePreference === '12h') {
            this.selectedPeriod = this.selectedHour >= 12 ? 'PM' : 'AM'
            this.selectedHour = this.selectedHour % 12 || 12
          }
        } else {
          this.resetTimePicker()
          this.editingTime = null
        }
        this.openPickerIndex = dayIndex
        this.openTimeIndex = timeIndex
      }
     setTimeout(() => {
        this.scrollToSelectedTime();
      }, 300);
    },

    setHour(hour) {
      this.selectedHour = hour
      this.updateEditingTime()
    },

    setMinute(minute) {
      this.selectedMinute = minute
      this.updateEditingTime()
    },

    setPeriod(period) {
      this.selectedPeriod = period
      this.updateEditingTime()
    },

    updateEditingTime() {
      if (this.editingTime !== null) {
        this.editingTime = this.getDefaultTime()
        this.updateTimeSlot(this.openPickerIndex, this.openTimeIndex, this.editingTime, false)
      }
    },

    addTime(dayIndex) {
      const time = this.getDefaultTime()
      const slots = [...this.getAccountQueueTimes.custom_queue]
      const existingTimeIndex = slots[dayIndex].times.indexOf(time)

      if (existingTimeIndex === -1) {
        slots[dayIndex].times.push(time)
        this.updateTimeSlot(dayIndex, slots[dayIndex].times.length - 1, time, true)
      }
    },

    updateTimeSlot(dayIndex, timeIndex, time, shouldSort = true) {
      const slots = [...this.getAccountQueueTimes.custom_queue]
      slots[dayIndex].times[timeIndex] = time
      if (shouldSort) {
        slots[dayIndex].times.sort()
      }
      this.$store.commit(social.SET_QUEUE_CUSTOM_SLOTS, slots)
    },

    closeTimePicker() {
      if (this.openTimeIndex !== null) {
        this.updateTimeSlot(this.openPickerIndex, this.openTimeIndex, this.getDefaultTime(), true)
      } else {
        this.addTime(this.openPickerIndex)
      }
      this.openPickerIndex = null
      this.openTimeIndex = null
      this.editingTime = null
      this.resetTimePicker()
    },

    resetTimePicker() {
      this.selectedHour = 9
      this.selectedMinute = 0
      this.selectedPeriod = 'AM'
    },

    formatTime(time) {
      if (this.getUserTimePreference !== '12h') {
        return time
      }
      // convert the time to 12h format
      const [hour, minute] = time.split(':')
      let hourNum = parseInt(hour, 10)
      const period = hourNum >= 12 ? 'PM' : 'AM'
      hourNum = hourNum % 12 || 12
      return `${hourNum}:${minute.padStart(2, '0')} ${period}`
    },

    getDefaultTime() {
      let hour = this.selectedHour
      if (this.getUserTimePreference === '12h') {
        if (this.selectedPeriod === 'PM' && hour !== 12) {
          hour += 12
        } else if (this.selectedPeriod === 'AM' && hour === 12) {
          hour = 0
        }
      }
      const minute = this.selectedMinute.toString().padStart(2, '0')
      return `${hour.toString().padStart(2, '0')}:${minute}`
    },
    scrollToSelectedTime() {
      const hourColumn = document.querySelector('.column.hour > .hour-option.active');
      const minuteColumn = document.querySelector('.column.minute > .minute-option.active');
      setTimeout(() => {
        hourColumn?.scrollIntoView({ behavior: 'smooth', block: 'center'});
      }, 0);
      setTimeout(() => {
        minuteColumn?.scrollIntoView({ behavior: 'smooth' , block: 'center'});
      }, 500);
    },
  },
}
</script>

<template>
  <b-modal
    id="account_queue_slot_modal"
    modal-class="time_queue_modal normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h4
        >Queue time<a
          v-tooltip.top="'Learn more about queue slots'"
          href="https://docs.contentstudio.io/article/691-what-are-social-queues"
          target="_blank" rel="noopener"
          ><i class="far fa-question-circle ml-1"></i></a
      ></h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="hideModal"
        >&times;</button
      >
    </div>

    <div v-if="fetchSlotsLoaderStatus" class="modal_body">
      <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
    </div>

    <div v-if="!fetchSlotsLoaderStatus" class="modal_body basic_form m_t_15">
      <div class="custom_block">
        <div
          v-if="showPlansRequeueWarning"
          class="warning_box mb-3 d-inline-block"
        >
          <p
            >Note: Posts already in the queue will be re-queue according to the
            new schedule.</p
          >
        </div>

        <div
          class="time_table_main_top"
          :class="{ disabled: !allowQueueReschedule }"
        >
          <div
            v-if="showQueueSlots"
            class="time_table d-flex align-items-start"
          >
            <div
              v-for="(day, dayIndex) in getQueueSlots"
              :key="dayIndex"
              class="time_box_main"
            >
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="dayIndex"
                    v-model="getQueueSlots[dayIndex].status"
                    type="checkbox"
                  />
                  <label :for="dayIndex" data-cy="day-select">{{
                    day.name
                  }}</label>
                </div>

                <div
                  v-for="(time, timeIndex) in day.times"
                  :key="timeIndex"
                  class="time_list"
                >
                  <div
                    class="group time_slot relative"
                    :class="{ disabled: !getQueueSlots[dayIndex].status }"
                  >
                    <span
                      class="flex items-center text-sm"
                      @click="togglePicker(dayIndex, timeIndex)"
                    >
                      {{ formatTime(time) }}
                      <template>
                        <i
                          v-if="
                            openPickerIndex === dayIndex &&
                            openTimeIndex === timeIndex
                          "
                          class="
                            ml-auto
                            text-xs
                            fas
                            fa-chevron-up
                            text-[#C2C8CD]
                          "
                        >
                        </i>
                        <i
                          v-else
                          class="
                            ml-auto
                            text-xs
                            fas
                            fa-chevron-down
                            text-[#C2C8CD]
                          "
                        >
                        </i>
                      </template>
                    </span>
                    <span
                      class="
                        rounded-full
                        bg-red-500
                        absolute
                        -right-2
                        -top-2
                        cursor-pointer
                        invisible
                        group-hover:!visible
                        w-[16px]
                        h-[16px]
                        flex
                        items-center
                        justify-center
                      "
                      @click="removeAccountQueueSlot(dayIndex, timeIndex)"
                    >
                      <i class="cs-cross text-[10px] text-white"></i>
                    </span>
                    <DropdownTransition>
                    <div
                      v-if="
                        openPickerIndex === dayIndex &&
                        openTimeIndex === timeIndex
                      "
                      class="picker-dropdown p-1 absolute left-0 top-full"
                      v-click-away="closeTimePicker"
                    >
                      <div class="column-container my-2 py-1">
                        <div class="column hour" ref="hourColumn">
                          <div
                            v-for="hour in hours"
                            :key="hour"
                            @click.stop="setHour(hour)"
                            :class="{ active: selectedHour === hour }"
                            class="hour-option"
                            :data-hour="hour"
                          >
                            {{ hour }}
                          </div>
                        </div>
                        <div class="column minute">
                          <div
                              ref="minuteColumn"
                            v-for="minute in 60"
                            :key="minute"
                            @click.stop="setMinute(minute - 1)"
                            :class="{ active: selectedMinute === minute - 1 }"
                            class="minute-option"
                            :data-minute="minute - 1"
                          >
                            {{ (minute - 1).toString().padStart(2, '0') }}
                          </div>
                        </div>
                        <div
                          v-if="getUserTimePreference === '12h'"
                          class="column period my-auto"
                          ref="periodColumn"
                        >
                          <div
                            @click.stop="setPeriod('AM')"
                            :class="{ active: selectedPeriod === 'AM' }"
                            class="period-option"
                            data-period="AM"
                          >
                            AM
                          </div>
                          <div
                            @click.stop="setPeriod('PM')"
                            :class="{ active: selectedPeriod === 'PM' }"
                            class="period-option"
                            data-period="PM"
                          >
                            PM
                          </div>
                        </div>
                      </div>
                    </div>
                    </DropdownTransition>
                  </div>
                </div>

                <div
                  class="
                    add_time_box add_icon
                    d-flex
                    align-items-center
                    justify-content-center
                    !px-0
                    !py-0.5
                  "
                  @click.stop="togglePicker(dayIndex)"
                >
                  <div class="time-picker">
                    <template
                      v-if="
                        openPickerIndex !== dayIndex || openTimeIndex !== null
                      "
                    >
                      <i
                        class="cs-plus plus-icon"
                        data-cy="add-time-slot"
                        @click.stop="togglePicker(dayIndex)"
                      ></i>
                    </template>
                    <template v-else>
                      <span
                        class="time_slot"
                        @click.stop="togglePicker(dayIndex)"
                      >
                        {{ formattedTime }}
                      </span>
                    </template>
                    <DropdownTransition>
                    <div
                      v-if="
                        openPickerIndex === dayIndex && openTimeIndex === null
                      "
                      class="picker-dropdown p-1"
                      v-click-away="closeTimePicker"
                    >
                      <div class="column-container my-2 py-1">
                        <div class="column hour" ref="hourColumn">
                          <div
                            v-for="hour in hours"
                            :key="hour"
                            @click.stop="setHour(hour)"
                            :class="{ active: selectedHour === hour }"
                            class="hour-option"
                            :data-hour="hour"
                          >
                            {{ hour }}
                          </div>
                        </div>
                        <div class="column minute" ref="minuteColumn">
                          <div
                            v-for="minute in 60"
                            :key="minute"
                            @click.stop="setMinute(minute - 1)"
                            :class="{ active: selectedMinute === minute - 1 }"
                            class="minute-option"
                            :data-minute="minute - 1"
                          >
                            {{ (minute - 1).toString().padStart(2, '0') }}
                          </div>
                        </div>
                        <div
                          v-if="getUserTimePreference === '12h'"
                          class="column period my-auto"
                          ref="periodColumn"
                        >
                          <div
                            @click.stop="setPeriod('AM')"
                            :class="{ active: selectedPeriod === 'AM' }"
                            class="period-option"
                            data-period="AM"
                          >
                            AM
                          </div>
                          <div
                            @click.stop="setPeriod('PM')"
                            :class="{ active: selectedPeriod === 'PM' }"
                            class="period-option"
                            data-period="PM"
                          >
                            PM
                          </div>
                        </div>
                      </div>
                    </div>
                    </DropdownTransition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center time_bottom">
            <span
              v-if="isCustomQueue"
              class="clear_all_btn"
              @click="clearAllAccountQueueSlots"
              >Clear all</span
            >
            <span class="timezone_box ml-auto">
              Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="input_field float-right mr-8 mb-4">
      <button
        v-if="allowQueueReschedule"
        class="btn btn-studio-theme-space"
        :disabled="updateSlotsLoaderStatus"
        data-cy="update-queue"
        @click.prevent="updateQueueSlots"
      >
        <span>Update</span>
        <clip-loader
          v-if="updateSlotsLoaderStatus"
          :color="'#ffffff'"
          :size="'12px'"
        ></clip-loader>
      </button>
    </div>
  </b-modal>
</template>

<style scoped>
.time_slot {
  position: relative;
  padding: 4px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  margin: 1rem 2px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #3C4549;
}
.time-picker {
  position: relative;
}

.picker-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 180px;
  background: #FFFFFF;
  border: 1px solid #eceef5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
}

.column-container {
  display: flex;
  height: 200px;
}

.column {
  flex: 1;
  overflow-y: auto;
  border-right: 1px solid #eee;
  color: #3C4549;
}

.column:last-child {
  border-right: none;
}

.column > div {
  padding: 5px;
  text-align: center;
  cursor: pointer;
  color: #333;
}

.column > div:hover {
  background-color: #f0f0f0;
}

.column > div.active {
  font-weight: bold;
  color: #2973e5;
}

.hour,
.minute,
.period {
  flex: 2;
}

/* Hide scrollbar for WebKit browsers */
.column::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.column {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
