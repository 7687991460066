<template>
  <button
    type="button"
    :class="`transition ease-out delay-150 focus:outline-none font-medium ${sizeClasses()} ${disabledClasses()} ${variantClasses()}`"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <span class="flex justify-center items-center leading-none">
      <template v-if="text">{{ text }}</template>
      <slot v-else></slot>

      <clip-loader
        v-if="loading"
        :color="'#9da6ac'"
        :size="'13px'"
        class="spinner ml-2"
        variant="info"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'CstButton',
  props: {
    text: {
      type: String,
      default: '',
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'primary',
            'primary-light',
            'secondary',
            'outlined',
            'success',
            'danger',
          ].indexOf(value) !== -1
        )
      },
    },
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customClasses: {
      type: String,
      default: '',
    },
    customDisabledClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    sizeClasses() {
      if (this.size === 'small') return 'text-xs py-1.5 px-3 rounded-md'
      else if (this.size === 'large') return 'text-base py-3 px-5 rounded-lg'
      else return 'text-sm px-4 py-2.5 rounded'
    },
    variantClasses() {
      const disabledClasses = this.disabledClasses()
      const variantConfig = {
        primary: {
          defaultClasses: 'border !border-primary-cs',
          enabledClasses:
            'bg-primary-cs hover:bg-primary-cs-800 text-white cursor-pointer border !border-primary-cs',
        },
        'primary-light': {
          defaultClasses: 'border-0',
          enabledClasses:
            'bg-primary-cs hover:bg-opacity-80 !border-primary-cs',
        },
        secondary: {
          defaultClasses: 'border !border-secondary-cs',
          enabledClasses:
            'bg-secondary-cs hover:bg-opacity-80 text-[#F7F7FC] border !border-secondary-cs',
        },
        outlined: {
          defaultClasses: 'border !border-gray-300',
          enabledClasses: 'border bg-white text-primary-cs hover:!bg-blue-50',
        },
        success: {
          defaultClasses: 'border-0 text-white bg-[#37B54A] hover:bg-[#2D9B3E]',
          enabledClasses: 'border-0 text-white bg-[#37B54A] hover:bg-[#2D9B3E]',
        },
        danger: {
          defaultClasses: 'border-0 text-white bg-[#DB5353] hover:bg-[#B54242]',
          enabledClasses: 'border-0 text-white bg-[#DB5353] hover:bg-[#B54242]',
        },
        text: {
          defaultClasses: 'border-0 text-gray-900 !bg-transparent',
          enabledClasses: 'border-0 text-gray-900 !bg-transparent',
        },
        default: {
          defaultClasses:
            'border-0 text-gray-900 bg-gray-100 hover:bg-opacity-80',
          enabledClasses:
            'border-0 text-gray-900 bg-gray-100 hover:bg-opacity-80',
        },
      }

      const variant = variantConfig[this.variant] || variantConfig.default
      const showCustomClasses = this.customClasses !== ''
      const showCustomDisabledClasses = this.customDisabledClasses !== ''

      if (showCustomClasses) {
        return `${this.customClasses} ${variant.defaultClasses}`
      }

      if (showCustomDisabledClasses) {
        return `${this.customDisabledClasses} ${variant.defaultClasses}`
      }

      if (this.disabled) {
        return `${disabledClasses} ${variant.defaultClasses}`
      } else {
        return `${variant.enabledClasses} ${disabledClasses}`
      }
    },
    disabledClasses() {
      if (this.disabled) {
        if (this.variant === 'primary') {
          return 'cursor-not-allowed bg-primary-cs opacity-50 hover:shadow-none text-white'
        }
        if (this.variant === 'primary-light') {
          return 'cursor-not-allowed bg-blue-100 opacity-50 hover:shadow-none text-primary-cs-variant-1'
        }
        if (this.variant === 'secondary') {
          return 'cursor-not-allowed bg-secondary-cs opacity-50 hover:shadow-none text-[#F7F7FC]'
        }
        if (this.variant === 'outlined') {
          return 'cursor-not-allowed bg-white opacity-50 hover:shadow-none text-gray-900'
        }
      } else return 'cursor-pointer'
    },
  },
}
</script>
