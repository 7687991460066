<script setup>
import { computed, defineEmits, inject } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import PlatformAccountSelect from '@src/modules/analytics/views/common/PlatformAccountSelect.vue'
import useYoutubeAnalytics from '@modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import CstButton from '@ui/Button/CstButton.vue'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import useWhiteLabelApplication from '@/src/modules/setting/composables/whitelabel/useWhiteLabelApplication.js'

const { appData } = useWhiteLabelApplication()

const emit = defineEmits(['scroll-to-top'])

const root = inject('root')
const { $bvModal } = root
const {
  dateRange,
  selectedAccount,
  toggleYoutubeAccountData,
  showConsentPopup,
  isLoading,
} = useYoutubeAnalytics()

const disabled = computed(() => {
  return (
    !selectedAccount.value?.platform_identifier ||
    showConsentPopup.value ||
    selectedAccount.value?.preferences?.analytics_data_deleted
  )
})

const handleDateChange = ({ dateRange }) => {
  setDateRange(dateRange)
}
const setDateRange = (newDate) => {
  dateRange.value = newDate.split(' - ')
}
const onchange = (account) => {
  console.log(account)
  selectedAccount.value = account
  if (
    selectedAccount.value &&
    !selectedAccount.value.preferences?.analytics_data_deleted
  ) {
    selectedAccount.value.preferences = {
      ...selectedAccount.value.preferences,
      analytics_data_deleted: false,
    }
  }

  emit('scroll-to-top')
}

const handleDelete = () => {
  $bvModal.show('youtube-confirmation-modal')
}

const confirmDelete = async () => {
  await toggleYoutubeAccountData()
  $bvModal.hide('youtube-confirmation-modal')
}
</script>

<template>
  <AnalyticsFilterBarWrapper
    :disabled="disabled"
    :selected-accounts="selectedAccount"
    type="youtube"
    @date-change="handleDateChange"
  >
    <template v-slot:left>
      <PlatformAccountSelect type="youtube" @on-account-change="onchange" />
    </template>
    <template
      v-if="!selectedAccount?.preferences?.analytics_data_deleted"
      v-slot:pre-right
    >
      <CstButton
        :class="{
          disabled: disabled,
        }"
        class="flex !px-5 !w-max !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A] mr-2 !shadow-none"
        variant="outlined"
        @click="handleDelete"
      >
        <i
          class="far fa-trash-alt fa-lg text-red-500 !shadow-none mr-1 text-sm leading-none"
        ></i>
        <span class="text-sm font-normal leading-none">Delete Data</span>
      </CstButton>
    </template>
  </AnalyticsFilterBarWrapper>
  <CstConfirmationPopup modal-name="youtube" @confirmed="confirmDelete">
    <template v-slot:body>
      <div class="bg-[#E677001A]">
        <div class="flex items-start my-2 p-2 bg-[#E677001A] rounded-md">
          <i
            aria-hidden="true"
            class="far fa-exclamation-triangle h-5 w-5 pt-1 text-black mr-1.5"
            style="color: #e67700"
          ></i>
          <div class="px-1 font-normal text-gray-900">
            <span class="font-medium text-[#E67700]"
              >Are you sure you want to delete all your stored data?</span
            >
            <span class="block"
              >This will delete all analytics data, for the selected channel
              from this workspace within 7 calender days.</span
            >
            <span
              ><b>Note: </b>
              <ul>
                <li class="list-disc list-inside"
                  >This won't delete data from your social media accounts. To
                  delete data from social platforms, use their official
                  tools.</li
                >
                <li class="list-disc list-inside"
                  >Deleting data through this request still allows you to
                  retrieve data later. However, if you revoke account access
                  through
                  <a
                    href="https://myaccount.google.com/connections?filters=3,4&hl=en"
                    target="_blank" rel="noopener"
                    >Google Security Settings</a
                  >, your data will be permanently deleted, and you'll need to
                  reconnect your account and reauthorize {{ appData.businessName }} for full
                  access</li
                >
              </ul>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer="slotProps">
      <a
        class="text-blue-400 text-sm"
        href="https://developers.google.com/youtube/terms/developer-policies#e.-handling-youtube-data-and-content"
        target="_blank" rel="noopener"
      >
        Learn More
      </a>

      <div class="flex gap-2">
        <CstButton
          type="button"
          size="small"
          variant="outlined"
          @click="slotProps.onCancel"
          >Cancel
        </CstButton>

        <!-- Delete Button -->
        <CstButton
          class="!bg-red-600"
          variant="primary"
          size="small"
          :disabled="isLoading"
          :loading="isLoading"
          @click="slotProps.onConfirm"
        >
          Yes, Delete My Data
        </CstButton>
      </div>
    </template>
  </CstConfirmationPopup>
</template>
