<script setup>
import { vInfiniteScroll } from '@vueuse/components'
import {
  parseDescriptionHtml,
} from '@common/lib/helper'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
import HtmlRenderer from "@common/components/htmlRenderer.vue";

defineProps({
  notificationLoader: {
    type: Boolean,
    required: true,
  },
})

const {
  allNotificationDates,
  allNotifications,
  fetchAllNotifications,
  onNotificationClick,
  checkNotificationDateInclusion,
  getNotificationIconColor,
  formatNotificationDate,
} = useDashboard()
</script>

<template>
  <div
    v-infinite-scroll="[fetchAllNotifications, { distance: 30 }]"
    class="flex-grow-1 flex flex-col items-center overflow-y-auto no-scrollbar scroll-smooth"
  >
    <template v-if="allNotifications?.length">
      <template
        v-for="(date, dateIndex) in allNotificationDates"
        :key="`date-key-${dateIndex}`"
      >
        <div
          class="text-sm text-secondary-cs-700 text-center font-light py-3 px-6 w-full border !border-t-0 !border-l-0 !border-r-0 !border-gray-cs-200 select-none"
        >
          {{ formatNotificationDate(date) }}
        </div>
        <template
          v-for="(notification, notificationIndex) in allNotifications"
          :key="`notification-key-${notificationIndex}`"
        >
          <div
            v-if="checkNotificationDateInclusion(date, notification)"
            class="px-6 py-4 w-full flex gap-2 border !border-t-0 !border-l-0 !border-r-0 cursor-pointer"
            :class="
              notification.is_read
                ? '!border-gray-cs-200 hover:bg-gray-cs-50'
                : 'bg-primary-cs-200 !border-primary-cs-50 hover:bg-gray-cs-200'
            "
            @click="onNotificationClick(notification)"
          >
            <p
            :class="['font-medium text-lg', getNotificationIconColor(notification)]"
            >
              &#9679;</p
            >
            <div class="w-full py-1.5">
              <div class="flex w-full justify-between items-center">
                <p
                  class="text-sm mb-1"
                  :class="
                    notification.is_read
                      ? 'font-medium text-secondary-cs'
                      : 'font-semibold text-gray-cs-900'
                  "
                >
                  {{ notification.title }}
                </p>
                <p
                  class="text-xs mb-0.5 font-light"
                  :class="
                    notification.is_read
                      ? 'text-secondary-cs-700'
                      : 'text-secondary-cs'
                  "
                >
                  {{
                    getUtcTimeAgoFromNow(
                      notification.created_at
                        ? notification.created_at
                        : notification.created_time
                    )
                  }}
                </p>
              </div>
              <HtmlRenderer
                tag="p"
                class="text-sm"
                :class="
                  notification.is_read
                    ? 'text-secondary-cs-700'
                    : 'text-secondary-cs'
                "
                :html-content="parseDescriptionHtml(notification.description, 320, false)"
              />
            </div>
          </div>
        </template>
      </template>
      <template v-if="notificationLoader">
        <clip-loader class="p-3" :color="'#436aff'" :size="'1rem'" />
      </template>
    </template>

    <template v-if="!allNotifications?.length">
      <div class="h-full flex items-center justify-center flex-col gap-4">
        <template v-if="notificationLoader">
          <clip-loader class="p-3" :color="'#436aff'" :size="'2rem'" />
        </template>
        <template v-else>
          <img
            src="@assets/img/dashboard/images/no-notifications.svg"
            alt=""
            :draggable="false"
          />
          <p class="text-secondary-cs-700">No Notifications Yet</p>
        </template>
      </div>
    </template>
  </div>
</template>
