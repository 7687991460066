<template>
  <div class="scroll_content">
    <template v-if="getInfluencerLoaders.top_mentioned">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="top_mentioned && top_mentioned.length > 0">
      <div
        v-for="(mention, index) in top_mentioned"
        class="twitter_influencer_box"
      >
        <div class="box_inner">
          <div class="d-flex align-items-center">
            <div class="t_left">
              <div class="d-flex item_row">
                <div class="row_left">
                  <div class="picture_box">
                    <div
                      :style="`background-image: url(${mention.profile_image_url_https})`"
                      class="img"
                    ></div>
                    <!--<img :src="mention.profile_image_url_https" alt="">-->
                  </div>
                </div>
                <div class="row_right border_bottom">
                  <div class="top_content">
                    <h3
                      :data-cy="'top_mention_name_' + index"
                      v-html="twitterUsernameHtml(mention, index)"
                    ></h3>
                    <p
                      v-if="mention.description"
                      v-html="mention.description"
                    ></p>
                  </div>
                </div>
              </div>
              <div class="d-flex item_row">
                <div class="row_left">
                  <template v-if="mention.created_at">
                    <p>{{ $filters.relative(mention.created_at) }}</p>
                  </template>
                </div>
                <div
                  v-if="mention.status && mention.status.text"
                  class="row_right"
                >
                  <div class="bottom_content">
                    <p
                      v-html="
                        twitterText(
                          mention.status.text,
                          mention.status.entities.hashtags,
                          mention.status.entities.urls,
                          mention.status.entities.user_mentions
                        )
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_right">
              <p>Followers</p>
              <h3
                v-tooltip.top="stringToInt(mention.followers_count)"
                :data-cy="'top_mention_followers_' + index"
                >{{ intToString(mention.followers_count) }}</h3
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <!--<h2>No version history found</h2>-->
            <p data-cy="top_mention_no_data"> No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    top_mentioned: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders']),
  },
  methods: {
    twitterUsernameHtml(mention, index) {
      return (
        mention.name +
        '<a href="' +
        this.handleLink(mention.screen_name) +
        '" target="_blank" rel="noopener"  data-cy="top_mention_username_' +
        index +
        '">@' +
        mention.screen_name +
        '</a>'
      )
    },
  },
}
</script>
