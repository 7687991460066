<script setup>
import CstPopup from '@ui/Popup/CstPopup.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
import usePermissions from '@common/composables/usePermissions'
import usePlatform from '@common/composables/usePlatform'
import useDateFormat from '@common/composables/useDateFormat'

const { momentWrapper } = useDateFormat()

const { setDashboardBannerStatus } = useDashboard()
const { isTrialPlan } = usePermissions()
const { topBannersHeight } = usePlatform()

const dashboardNotifBanner = ref(null)
const stickyBannersHeight = ref(0)
const remainingTime = ref({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
})

const updateCountdown = () => {
  const endDate = momentWrapper('2025-01-27 00:00:00')?.formatTimezone() // End of Black Friday

  const now = momentWrapper()?.formatTimezone()
  const diff = endDate - now

  if (diff > 0) {
    remainingTime.value = {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((diff % (1000 * 60)) / 1000),
    }
  } else {
    remainingTime.value = { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }
}

let countdownInterval

onMounted(() => {
  stickyBannersHeight.value = dashboardNotifBanner.value.clientHeight
  topBannersHeight.value += stickyBannersHeight.value
  document.documentElement.style.setProperty(
    '--calender-height',
    `${270 + topBannersHeight.value}px`
  )

  // Start countdown
  updateCountdown()
  countdownInterval = setInterval(updateCountdown, 1000)
})

onBeforeUnmount(() => {
  topBannersHeight.value -= stickyBannersHeight.value
  document.documentElement.style.setProperty(
    '--calender-height',
    `${270 + topBannersHeight.value}px`
  )

  // Clear countdown interval
  if (countdownInterval) clearInterval(countdownInterval)
})

const formatNumber = (num) => num.toString().padStart(2, '0')

const showPopup = ref(false)

const closePopup = () => {
  showPopup.value = false
}

const openInNewTab = () => {
  const url = isTrialPlan()
    ? 'https://contentstudio.io/new-year-2025'
    : 'https://docs.contentstudio.io/article/1054-white-label'

  window.open(url, '_blank', 'noopener, noreferrer')
}
</script>

<template>
  <div ref="dashboardNotifBanner">
    <div
      class="z-[1039] bg-no-repeat bg-right-bottom flex min-h-10 py-2 w-full pl-8 pr-6 items-center bg-gray-cs-800 relative"
    >
      <div class="flex flex-grow my-auto gap-4 items-center justify-center">
        <span class="relative flex h-3 w-3 items-center justify-center">
          <span
            class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-primary-cs-200 opacity-75"
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3 bg-primary-cs-600"
          ></span>
        </span>

        <p
          v-if="isTrialPlan()"
          class="text-white leading-none flex items-center gap-2"
        >
          <span class="font-semibold">
            🎉 Kick off 2025 with a bang—save 40% on your first annual plan!
          </span>
          <span
            class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 cursor-pointer"
            @click="openInNewTab"
          >
            Claim this deal today!
          </span>
          <span class="mx-3"> | </span>
          <span class="flex items-center gap-1 font-mono">
            <div class="flex flex-col items-center">
              <span class="rounded text-xl font-bold">{{
                formatNumber(remainingTime.days)
              }}</span>
              <span class="text-[10px] text-white mt-0.5 uppercase">Days</span>
            </div>
            <span class="text-white/80 -mt-4 font-bold text-xl">:</span>
            <div class="flex flex-col items-center">
              <span class="rounded text-xl font-bold">{{
                formatNumber(remainingTime.hours)
              }}</span>
              <span class="text-[10px] text-white mt-0.5 uppercase">Hours</span>
            </div>
            <span class="text-white/80 -mt-4 font-bold text-xl">:</span>
            <div class="flex flex-col items-center">
              <span class="rounded text-xl font-bold">{{
                formatNumber(remainingTime.minutes)
              }}</span>
              <span class="text-[10px] text-white mt-0.5 uppercase"
                >Minutes</span
              >
            </div>
            <span class="text-white/80 -mt-4 font-bold text-xl">:</span>
            <div class="flex flex-col items-center">
              <span class="rounded text-xl font-bold">{{
                formatNumber(remainingTime.seconds)
              }}</span>
              <span class="text-[10px] text-white mt-0.5 uppercase"
                >Seconds</span
              >
            </div>
          </span>
        </p>
        <p v-else class="text-white leading-none">
          <span class="font-semibold">
            Exciting News: White Label feature is now live! 🎉 Rebrand the
            platform with your business name, domain, and logo.
          </span>
          <span
            class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 ml-1 cursor-pointer"
            @click="openInNewTab"
          >
            {{ 'Learn more!' }}
          </span>
        </p>
      </div>
      <i
        class="fas fa-times text-white text-xl font-light cursor-pointer ml-auto"
        @click="showPopup = !showPopup"
      />
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <CstPopup
          v-if="showPopup"
          v-click-away="closePopup"
          parent-class="absolute top-[110%] right-0 w-[27.875rem] h-[7.25rem] cursor-default rounded-b-lg rounded-tl-lg"
          confirm-text="Yes, Hide"
          @close-popup="closePopup"
          @confirm-popup="setDashboardBannerStatus"
        >
          <template v-slot:popup-text>
            <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4"
              >Are you sure you want to hide this banner?</p
            >
          </template>
        </CstPopup>
      </transition>
    </div>
  </div>
</template>
