<template>
  <div class="h-full" :class="customClass">
    <CstIconSwitch :left-tooltip="`${previewToggleObject[instagramPostType].title} Preview`" :right-tooltip="`Story Preview`" class="mt-5 mb-2 flex justify-end items-center" v-if="isShowPreviewToggle" v-model="selectCarouselOrStory">
      <template v-slot:left-icon>
        <img v-if="!selectCarouselOrStory" class="mr-2 w-[0.9rem] h-[0.9rem]" :src="previewToggleObject[instagramPostType].active_image" />
        <img v-else class="mr-2 w-[0.9rem] h-[0.9rem]" :src="previewToggleObject[instagramPostType].inactive_image" />
        {{ previewToggleObject[instagramPostType].title }}
      </template>
      <template v-slot:right-icon>
        <img v-if="selectCarouselOrStory" class="mr-2 w-[0.9rem] h-[0.9rem]" src="/img/instagram-stories-icon-active-blue.svg" />
        <img v-else class="mr-2 w-[0.9rem] h-[0.9rem]" src="/img/instagram-stories-icon.svg" /> Story
      </template>
    </CstIconSwitch>

    <InstagramMultimediaStoryPreview
        v-if="shouldRenderInstagramMultimediaStoryPreview"
        :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
        :detail="computeMediaList"
        :account-name="account.name"
        :account-image="account.image"
        :description="getDescription"
        :toggle-preview="togglePreview"
        :white-bg="whiteBg"
        :show-overlay="showOverlay"
        :instagram-post-type="instagramPostType"
    />
    <InstagramReelPreview
      v-else-if="shouldRenderInstagramReelPreview"
      :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
      :account-name="getDisplayNameWithCollaborators"
      :account-image="account.image"
      :video="detail?.multimedia?.length ? detail.multimedia[0] : detail.video"
      :description="detail.message"
      :toggle-preview="togglePreview"
      :thumbnail="getVideoThumbnail"
      :is-location-added="isLocationAdded"
    />
    <InstagramMultimediaPreview
        v-else-if="shouldRenderInstagramCarouselPreview"
        :account="account"
        :account-name="getDisplayNameWithCollaborators"
        :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
        :is-location-added="isLocationAdded"
        :user-tags="userTags"
        :get-description="getDescription"
        :comment-details="commentDetails"
        :comment="comment"
        :detail="detail"
        :hide-footer="hideFooter"
        :load-more="loadMore"
        :char-limit="charLimit"
        @displayFile="displayFile"
        @changeLoadMore="changeLoadMore"
    />

  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="detail.image"
    :index="selectedImageIndex"
    @hide="visible = false"
  ></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import InstagramReelPreview from '@src/modules/composer_v2/components/SocialPreviews/InstagramReelPreview'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'
import InstagramMultimediaPreview from "@modules/composer_v2/components/SocialPreviews/InstagramMultimediaPreview.vue";
import InstagramMultimediaStoryPreview from "@modules/composer_v2/components/SocialPreviews/InstagramMultimediaStoryPreview.vue";
import CstIconSwitch from '@ui/Switch/CstIconSwitch'
export default {
  name: 'InstagramPreview',
  components: {
    InstagramReelPreview,
    VueEasyLightbox,
    InstagramMultimediaPreview,
    InstagramMultimediaStoryPreview,
    CstIconSwitch
  },
  props: {
    customClass: {
      type: String,
      default: '',
    },
    commonBoxStatus: {
      type: Boolean,
      default: false,
      required: true,
    },
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    togglePreview: {
      type: Boolean,
      default: false,
    },
    userTags: {
      type: [Object, Array],
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
    whiteBg: {
      type: Boolean,
      default: true,
    },
    commentDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
    instagramCollaborators: {
      type: Array,
      default: null,
    },
    instagramShareToStory: {
      type: Boolean,
      default: false,
    },
    locations:{
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      loadMore: true,
      charLimit: 500,
      visible: false,
      selectedImageIndex: 0,
      selectCarouselOrStory: false,
      previewToggleObject: {
        feed: {
          inactive_image: '/img/instagram-feed-icon-active.svg',
          active_image: '/img/instagram-feed-icon-active-blue.svg',
          title: 'Feed'
        },
        reel: {
          inactive_image: '/img/instagram-reels-icon-active.svg',
          active_image: '/img/instagram-reels-icon-active-blue.svg',
          title: 'Reel'
        },
        feed_reel: {
          inactive_image: '/img/instagram-reels-plus-feed-icon-active.svg',
          active_image: '/img/instagram-reels-plus-feed-icon-active-blue.svg',
          title: 'Feed + Reel'
        },
        carousel: {
          inactive_image: '/img/instagram-carousel-icon-active.svg',
          active_image: '/img/instagram-carousel-icon-active-blue.svg',
          title: 'Carousel'
        }
      }
    }
  },
  computed: {
    /**
     * Check if the preview toggle should be shown.
     * @returns {boolean}
     */
    isShowPreviewToggle () {
      return ['feed', 'reel', 'feed_reel', 'carousel'].includes(this.instagramPostType) &&
          this.instagramShareToStory &&
          (
              this.shouldRenderInstagramMultimediaStoryPreview ||
              this.shouldRenderInstagramReelPreview ||
              this.shouldRenderInstagramCarouselPreview
          )
    },
    computeMediaList() {
      return {
        multimedia: this.detail?.multimedia?.length ? this.detail.multimedia : ( this.detail?.image?.length ? this.detail.image : [this.detail.video])
      }
    },
    getDisplayNameWithCollaborators() {
      const length = this.instagramCollaborators?.length
      switch (length) {
        case 1:
          return `${this.account.name} and ${this.instagramCollaborators[0]}`
        case 2:
          return `${this.account.name}, ${this.instagramCollaborators[0]} and ${this.instagramCollaborators[1]}`
        case 3:
          return `${this.account.name}, ${this.instagramCollaborators
            .slice(0, -1)
            .join(', ')} and ${this.instagramCollaborators[length - 1]}`
        default:
          return this.account.name
      }
    },
    isLocationAdded() {
      if (!this.locations || !this.account?._id) return false;

      const matchingLocation = Object.entries(this.locations)
        .find(([key, value]) => key === this.account._id && value?.name);

      return matchingLocation?.[1]?.name || false;
    },
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg && msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    shouldRenderInstagramReelPreview() {
      return (
        ['reel', 'feed_reel'].includes(this.instagramPostType) && ((
        this.detail.video &&
        this.detail.video.link) || this.detail?.multimedia?.length)
      )
    },
    shouldRenderInstagramMultimediaStoryPreview() {
      return (
          this.instagramPostType === 'story' &&
          (this.detail?.multimedia?.length || this.detail.image?.length || this.detail.video?.link)
      ) || this.selectCarouselOrStory
    },
    shouldRenderInstagramCarouselPreview() {
      return (
          ['feed', 'carousel'].includes(this.instagramPostType) &&
          (this.detail?.multimedia?.length || this.detail.image?.length || this.detail.message.length)
      )
    },
    /**
     * Get the video thumbnail.
     * @returns {string}
     */
    getVideoThumbnail() {
      const { multimedia, video } = this.detail ?? {};
      let thumbnail = multimedia?.[0]?.thumbnail ?? video?.thumbnail;

      if (this.instagramPostType !== 'feed') {
        return thumbnail;
      }

      const isCustomThumbnail = thumbnail?.includes('/media_library/') && !thumbnail?.includes('?offset=');

      if (isCustomThumbnail) {
        thumbnail = video?.thumbnails_suggestions?.[0] ?? thumbnail;
      }

      return thumbnail;
    }
  },
  methods: {
    changeLoadMore() {
      this.loadMore = !this.loadMore
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
  },
}
</script>
