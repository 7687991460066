<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import _debounce from 'lodash.debounce'
import { searchLocationsURL } from '@src/modules/publish/config/api-utils.js'
import {
  commonTypes,
  facebookSharingTypes,
  instagramTypes,
  twitterSharingTypes
} from '@src/modules/publish/store/states/mutation-types.js'

export default {
  props: ['type'],
  data () {
    return {
      active_tab: 'Twitter',
      // active_tab: 'Facebook',
      keyword: '',
      search_locations_loader: false,
      locations: [],
      search_locations_requests: []
    }
  },
  computed: {
    ...mapGetters([
      'getJWTToken',
      'getAccountSelection',
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getInstagramSharingDetails'
    ]),
    tabsVisibilityStatus () {
      return this.type === 'Common'
    },
    isFacebookTabActive () {
      return this.active_tab === 'Facebook'
    },
    isInstagramTabActive () {
      return this.active_tab === 'Instagram'
    },
    facebookTabVIsibilityStatus () {
      return this.getAccountSelection.facebook.length > 0
    },
    instagramTabVIsibilityStatus () {
      return this.getAccountSelection.instagram.length > 0
    },
    isTwitterTabActive () {
      return this.active_tab === 'Twitter'
    },
    twitterTabVIsibilityStatus () {
      return this.getAccountSelection.twitter.length > 0
    },
    searchLocationsLoaderStatus () {
      return this.search_locations_loader
    },
    getLocations () {
      return this.locations
    },
    showLocationAddedSection () {
      // this.$nextTick(() => {
      let details = null
      if (this.getCommonBoxOptions.status) {
        details = this.getCommonSharingDetails
        if (details.location) {
          if (details.location.facebook && details.location.facebook.name) {
            return true
          }
          if (details.location.twitter && details.location.twitter.name) {
            return true
          }
          if (details.location.instagram && details.location.instagram.name) {
            return true
          }
        }
        return false
      }
      if (this.type === 'Facebook') details = this.getFacebookSharingDetails
      if (this.type === 'Twitter') details = this.getTwitterSharingDetails
      if (this.type === 'Instagram') details = this.getInstagramSharingDetails
      return !!(
          (this.type === 'Facebook' ||
              this.type === 'Twitter' ||
              this.type === 'Instagram') &&
          details.location &&
          details.location.name
      )
      // });
    }
  },
  mounted () {
    this.checkActiveTab()
  },
  methods: {
    checkActiveTab () {
      console.debug('Method::checkActiveTab')
      this.locations = []
      this.keyword = ''
      if (this.type === 'Common') {
        if (this.facebookTabVIsibilityStatus) {
          this.active_tab = 'Facebook'
        } else if (this.instagramTabVIsibilityStatus) {
          this.active_tab = 'Instagram'
        } else {
          this.active_tab = 'Twitter'
        }
      } else {
        this.active_tab = this.type
      }
    },
    keywordChanged: _debounce(function (ev) {
      console.log('Method::keywordChanged _debounce', this.keyword)
      this.searchLocations()
    }, 1000),
    changeTab (type) {
      console.log('Method::changeTab', type)
      this.active_tab = type
      this.searchLocations()
    },
    searchLocations () {
      console.log('Method::searchLocations', this.active_tab, this.keyword)
      if (this.keyword) {
        const stateObject = this

        // handling request
        const CancelToken = axios.CancelToken
        for (let i = 0; i < stateObject.search_locations_requests.length; i++) {
          const remove = stateObject.search_locations_requests[i]
          remove()
        }

        // making headers
        axios.defaults.headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stateObject.getJWTToken
        }

        let cancel

        this.search_locations_loader = true

        // request for the locations data
        axios
          .post(
            searchLocationsURL,
            {
              keyword: stateObject.keyword,
              type: stateObject.active_tab
            },
            {
              cancelToken: new CancelToken(function executor (c) {
                // An executor function receives a cancel function as a parameter
                cancel = c
              })
            }
          )
          .then(
            (response) => {
              // setting response
              this.locations = response.data.locations
                ? response.data.locations
                : []
              this.search_locations_loader = false
            },
            (response) => {
              console.debug('Exception in searchLocations ', response)
              this.locations = []
            }
          )

        stateObject.search_locations_requests.push(cancel)

        return true
      }
      this.search_locations_loader = false
    },
    addLocation (location) {
      console.log('Method::addLocation', this.type, location)
      switch (this.type) {
        case 'Common':
          switch (this.active_tab) {
            case 'Facebook':
              return this.$store.commit(
                commonTypes.SET_COMMON_FACEBOOK_LOCATION,
                location
              )

            case 'Twitter':
              return this.$store.commit(
                commonTypes.SET_COMMON_TWITTER_LOCATION,
                location
              )
            case 'Instagram':
              this.$store.commit(
                commonTypes.SET_COMMON_INSTAGRAM_LOCATION,
                location
              )
            // this.processSharingPreviewDesciption("message is", "Instagram");
          }
          break
        case 'Facebook':
          return this.$store.commit(facebookSharingTypes.SET_LOCATION, location)
        case 'Twitter':
          return this.$store.commit(twitterSharingTypes.SET_LOCATION, location)
        case 'Instagram':
          return this.$store.commit(instagramTypes.SET_LOCATION, location)
      }
    },
    removeLocation () {
      console.log('Method::removeLocation', this.type)
      switch (this.type) {
        case 'Common':
          this.$store.commit(commonTypes.SET_COMMON_FACEBOOK_LOCATION, {
            id: '',
            name: ''
          })
          this.$store.commit(commonTypes.SET_COMMON_TWITTER_LOCATION, {
            id: '',
            name: ''
          })
          this.$store.commit(commonTypes.SET_COMMON_INSTAGRAM_LOCATION, {
            id: '',
            name: ''
          })
          break
        case 'Facebook':
          return this.$store.commit(facebookSharingTypes.SET_LOCATION, {
            id: '',
            name: ''
          })
        case 'Twitter':
          return this.$store.commit(twitterSharingTypes.SET_LOCATION, {
            id: '',
            name: ''
          })
        case 'Instagram':
          return this.$store.commit(instagramTypes.SET_LOCATION, {
            id: '',
            name: ''
          })
      }
    },
    showLocationIcon (type) {
      const details = this.getCommonSharingDetails
      if (type === 'Facebook' && this.facebookTabVIsibilityStatus) {
        return !!(
          this.type === 'Facebook' ||
          (this.getCommonBoxOptions.status &&
            details.location.facebook &&
            details.location.facebook.name)
        )
      }
      if (type === 'Twitter' && this.twitterTabVIsibilityStatus) {
        return !!(
          this.type === 'Twitter' ||
          (this.getCommonBoxOptions.status &&
            details.location.twitter &&
            details.location.twitter.name)
        )
      }
      if (type === 'Instagram' && this.instagramTabVIsibilityStatus) {
        return !!(
          this.type === 'Instagram' ||
          (this.getCommonBoxOptions.status &&
            details.location.instagram &&
            details.location.instagram.name)
        )
      }
      return false
    },

    showLocationName (type) {
      if (this.showLocationIcon(type)) {
        switch (this.type) {
          case 'Common':
            const details = this.getCommonSharingDetails
            const boxType = type.toLowerCase()
            return details.location[boxType].name
          case 'Facebook':
            return this.getFacebookSharingDetails.location.name
          case 'Twitter':
            return this.getTwitterSharingDetails.location.name

          case 'Instagram':
            return this.getInstagramSharingDetails.location.name
        }
      }
      return false
    }
  }
}
</script>

<template>
  <div
    id="location_dropdown_id"
    class="dropdown data_boundary_dd prevent_close_dropdown ml-1 dropdown default_style_dropdown form_dropdown location_dropdown sharing_location_dropdown d-flex"
  >
    <div
      data-display="static"
      class="min_width dropdown_header"
      data-toggle="dropdown"
      @click="checkActiveTab"
    >
      <i
        v-tooltip.top="'Add Location'"
        class="d-block locate_icon"
        data-cy="add-location"
      ></i>
    </div>

    <div v-if="showLocationAddedSection" class="location_added">
      <i
        v-if="showLocationIcon('Facebook')"
        v-tooltip="showLocationName('Facebook')"
        class="icon-facebook_icon h-100"
      ></i>
      <i
        v-if="showLocationIcon('Twitter')"
        v-tooltip="showLocationName('Twitter')"
        class="icon-twitter_icon h-100"
      ></i>
      <i
        v-if="showLocationIcon('Instagram')"
        v-tooltip="showLocationName('Instagram')"
        class="icon-instagram_icon h-100"
      ></i>
      <a
        v-if="
          showLocationIcon('Facebook') ||
          showLocationIcon('Twitter') ||
          showLocationIcon('Instagram')
        "
        href=""
        class="mr-auto"
        @click.prevent="removeLocation"
        >×</a
      >
    </div>

    <div class="dropdown-menu dropdown-menu-left">
      <ul class="">
        <div class="social_mention_dropdown">
          <div
            v-if="tabsVisibilityStatus"
            class="close_this top_heading d-flex align-items-center"
          >
            <div
              v-if="facebookTabVIsibilityStatus"
              class="head_left"
              :class="{ active: isFacebookTabActive }"
              @click="changeTab('Facebook')"
            >
              <p>Facebook</p>
            </div>
            <div
              v-if="instagramTabVIsibilityStatus"
              class="head_left"
              :class="{ instagramActive: isInstagramTabActive }"
              @click="changeTab('Instagram')"
            >
              <p>Instagram</p>
            </div>
            <div
              v-if="twitterTabVIsibilityStatus"
              class="head_right ml-auto"
              :class="{ active: isTwitterTabActive }"
              @click="changeTab('Twitter')"
            >
              <p>X (Twitter)</p>
            </div>
          </div>

          <div class="input_field">
            <div
              class="radio_input_image search_field d-block"
              data-cy="hashtag-append"
            >
              <input
                id="search_locations_keyword"
                v-model="keyword"
                name="search_locations_keyword"
                type="text"
                placeholder="Search for a location..."
                @input="keywordChanged"
              />
            </div>
          </div>

          <ul v-if="searchLocationsLoaderStatus" class="inner">
            <li class="no_text">
              <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
            </li>
          </ul>

          <template v-if="!searchLocationsLoaderStatus">
            <ul class="inner">
              <template v-for="(location, index) in getLocations" :key="`${index}_location`">
                <li data-toggle="dropdown" @click="addLocation(location)">
                  <div class="profile_picture">
                    <div class="text_block">
                      <div class="text">
                        <p class="text_left">{{ location.name }}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>

            <div v-if="isTwitterTabActive" class="bottom_message">
              <p>
                <i class="fa fa-info-circle"></i>
                Enable Tweet Locations on X (Twitter).
                <a href="https://twitter.com/settings/safety" target="_blank" rel="noopener" >
                  Learn How
                </a>
              </p>
            </div>
          </template>
        </div>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.instagramActive {
  background: #9b36b7;
}
</style>
