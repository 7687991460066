<template>
  <div
    class="message"
    :class="[commonMethods.isIncomingMessage(temp, message) ? 'm_in' : 'm_out']"
  >
    <div
      class="profile_picture d-flex align-items-start"
      :class="[
        commonMethods.isIncomingMessage(temp, message)
          ? ''
          : 'justify-content-end',
      ]"
    >
      <div
        v-if="commonMethods.isIncomingMessage(temp, message)"
        class="picture_block picture_block_background"
      >
        <div
          class="img"
          :style="
            'background: url(' +
            commonMethods.getImageForUser(temp, message) +
            ')'
          "
        >
        </div>
      </div>

      <clip-loader
        v-if="isLoading && !commonMethods.isIncomingMessage(temp, message)"
        style="padding-right: 10px"
        color="rgba(152, 158, 181, 0.8)"
        :size="'20px'"
      ></clip-loader>

      <div
        class="text_block"
        style="display: flex; flex-direction: column; max-width: 80%"
        :style="
          !commonMethods.isIncomingMessage(temp, message)
            ? { alignItems: 'end' }
            : { alignItems: 'flex-start' }
        "
      >
        <template
          v-if="
            message.event_type === 'message' || message.event_type === 'note'
          "
        >
          <span
            v-if="!commonMethods.isIncomingMessage(temp, message)"
            class="bookmarked top"
            @click="updateBookmark"
          >
            <template v-if="message.is_starred">
              <i class="fas fa-star marked"></i>
            </template>
            <template v-else>
              <i class="far fa-star"></i>
            </template>
          </span>
        </template>

        <template v-if="message.event_type === 'note'">
          <div
            class="__text"
            style="
              background-color: rgb(251, 252, 225);
              white-space: pre-line;
              width: 100%;
            "
            :class="[
              commonMethods.isIncomingMessage(temp, message)
                ? '_user_chat'
                : '',
            ]"
          >
            {{ message.message }}
          </div>
          <p class="sub_text">{{
            commonMethods.timesAgo(message.created_time)
          }}</p>
        </template>

        <template v-else-if="message.event_type === 'message'">
          <template
            v-if="
              typeof message.attachment !== 'undefined' &&
              message.attachment != null &&
              message.attachment.length > 0
            "
          >
            <div v-for="attachment in message.attachment" :key="attachment.id">
              <template v-if="attachment.type === 'text/plain'">
                <div
                  class="__text"
                  style="width: 100%"
                  :class="[
                    commonMethods.isIncomingMessage(temp, message)
                      ? '_user_chat'
                      : '',
                  ]"
                >
                  <div class="_file d-flex align-items-start">
                    <div class="file_icon">
                      <i class="fal fa-file-pdf"></i>
                    </div>
                    <div class="file_detail">
                      <p class="name">{{ attachment.name }}</p>
                    </div>
                  </div>
                </div>
                <p class="sub_text">
                  {{ commonMethods.timesAgo(message.created_time) }}</p
                >
              </template>

              <template v-else>
                <template v-if="attachment.type.indexOf('image') >= 0">
                  <div
                    class="__text"
                    style="width: 100%"
                    :class="[
                      commonMethods.isIncomingMessage(temp, message)
                        ? '_user_chat'
                        : '',
                    ]"
                  >
                    <div class="_images">
                      <!-- TODO: Use vue-easy-lightbox package here.-->
                      <img :src="attachment.url" height="200"
                           @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      "
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="attachment.type.indexOf('video') >= 0">
                  <div
                    class="__text"
                    style="width: 100%"
                    :class="[
                      commonMethods.isIncomingMessage(temp, message)
                        ? '_user_chat'
                        : '',
                    ]"
                  >
                    <div class="_video">
                      <video controls height="100">
                        <source
                          height="100"
                          :src="attachment.url"
                          type="video/mp4"
                        />
                        <source
                          height="100"
                          :src="attachment.url"
                          type="video/ogg"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  </div>
                </template>
                <template v-else-if="attachment.type.indexOf('audio') >= 0">
                  <div
                    class="__text"
                    style="width: 100%"
                    :class="[
                      commonMethods.isIncomingMessage(temp, message)
                        ? '_user_chat'
                        : '',
                    ]"
                  >
                    <div class="">
                      <audio id="myAudio" controls>
                        <source :src="attachment.url" type="audio/ogg" />
                        <source :src="attachment.url" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                </template>
                <p class="sub_text">{{
                  commonMethods.timesAgo(message.created_time)
                }}</p>
              </template>
            </div>
          </template>
          <template
            v-else-if="
              typeof message.sticker !== 'undefined' && message.sticker != null
            "
          >
            <div
              class="__text"
              :class="[
                commonMethods.isIncomingMessage(temp, message)
                  ? '_user_chat'
                  : '',
              ]"
            >
              <div class="sticker">
                <img :src="message.sticker"
                     @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      "
                />
              </div>
            </div>
            <p class="sub_text">{{
              commonMethods.timesAgo(message.created_time)
            }}</p>
          </template>
          <template
            v-else-if="
              message?.story?.is_story && message?.story?.link !== ''
            "
          >
            <div
              class="__text"
              :class="[
                commonMethods.isIncomingMessage(temp, message)
                  ? '_user_chat'
                  : '',
              ]"
            >
              <div class="sticker">
                <img class='!max-h-[85%] !h-[85%]' :src="message?.story?.link"
                     @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      "
                />
                <p class='mt-2'>{{message?.message}}</p>
              </div>
            </div>
            <p class="sub_text">{{
                commonMethods.timesAgo(message.created_time)
              }}</p>
          </template>
          <template v-else>
            <template
              v-if="
                typeof message.link !== 'undefined' &&
                message.link != null &&
                message.link.title
              "
            >
              <div
                class="__text"
                style="width: 100%"
                :class="[
                  commonMethods.isIncomingMessage(temp, message)
                    ? '_user_chat'
                    : '',
                ]"
              >
                <div v-html="getLink(message.message)"></div>

                <div
                  v-if="
                    !checkFor('email', message.message) &&
                    checkFor('url', message.message)
                  "
                  class="_link d-flex align-items-start"
                >
                  <div class="content"
                    ><a :href="sanitizeUrl(message.link.url)">{{ message.link.title }}</a>
                    <p>{{ message.link.description }}</p></div
                  >
                  <div v-if="message.link.image !== null" class="img">
                    <!-- TODO: use vue-easy-lightbox here-->
                    <img
                      :src="message.link.image"
                      :alt="message.link.website"
                      @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                typeof message.private_message !== 'undefined' &&
                message.private_message != null &&
                message.private_message.name
              "
            >
              <div
                class="__text"
                style="width: 100%"
                :class="[
                  commonMethods.isIncomingMessage(temp, message)
                    ? '_user_chat'
                    : '',
                ]"
                >{{ message.message }}
                <div class="_link d-flex align-items-start">
                  <div class="content"
                    ><a @click="goToPost(message.private_message.post_id)">{{
                      message.private_message.name
                    }}</a>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="__text"
                style="width: 100%"
                :class="[
                  commonMethods.isIncomingMessage(temp, message)
                    ? '_user_chat'
                    : '',
                ]"
                v-html="getMentionedUsersNames(message.message)"
              ></div>
            </template>
            <p class="sub_text">{{
              commonMethods.timesAgo(message.created_time)
            }}</p>
          </template>
        </template>

        <template v-if="message.event_type === 'message'">
          <span
            v-if="commonMethods.isIncomingMessage(temp, message)"
            class="bookmarked bottom"
            @click="updateBookmark"
          >
            <template v-if="message.is_starred">
              <i class="fas fa-star marked"></i>
            </template>
            <template v-else>
              <i class="far fa-star"></i>
            </template>
          </span>
        </template>
      </div>

      <clip-loader
        v-if="isLoading && commonMethods.isIncomingMessage(temp, message)"
        style="padding-left: 10px"
        color="rgba(152, 158, 181, 0.8)"
        :size="'20px'"
      ></clip-loader>

      <div
        v-if="
          !commonMethods.isIncomingMessage(temp, message) &&
          message.event_type !== 'assigned' &&
          message.event_type !== 'marked_done' &&
          message.event_type !== 'archived'
        "
        class="picture_block picture_block_background"
      >
        <div
          v-if="message.event_type === 'note'"
          class="img"
          :style="
            'background: url(' +
            commonMethods.getUserImageForNote(temp, message) +
            ')'
          "
        >
        </div>
        <div
          v-else
          class="img"
          :style="
            'background: url(' +
            commonMethods.getImageForUser(temp, message) +
            ')'
          "
        >
        </div>
      </div>
      <div class='ml-2 mt-auto mb-5'>
        <div v-if="message?.is_unsupported" v-tooltip="{
          content: `<div class='text-sm text-gray-900' >This message may contain content types such as voice notes,
                    stickers, GIFs, etc., which are currently unsupported due to API limitations.</div>`,
          theme: 'light',
          allowHTML: true,
          }">
            <i class="fa fa-exclamation-triangle p-0 cursor-pointer !text-[#FFC555]"></i>
        </div>
        <div v-if="message?.story?.is_story && message?.story?.type === 'mention'"
           v-tooltip="{
          content: `<div class='text-sm text-gray-900' >Unfortunately, this message contains story mentions,
                    which are not supported due to API limitations.</div>`,
          theme: 'light',
          allowHTML: true,
          }">
          <i class="fa fa-exclamation-triangle p-0 cursor-pointer !text-[#FFC555]"></i>
        </div>
        <div v-if="message?.story?.is_story && message?.story?.type === 'reply_to'"
           v-tooltip="{
          content: `<div class='text-sm text-gray-900' >This message is a response to a story.</div>`,
          theme: 'light',
          allowHTML: true,
          }">
          <i class="far fa-question-circle p-0 cursor-pointer !text-[#2f8ae0]"></i>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import * as linkify from 'linkifyjs'
import { sanitizeUrl } from '@braintree/sanitize-url'

export default {
  components: {},
  props: ['message', 'temp', 'index', 'messageIndex', 'updateBookmarkStatus'],
  data() {
    return {
      commonMethods,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    ...mapActions(['updateBookmarkedMessages']),
    sanitizeUrl,
    async updateBookmark() {
      if (!this.isLoading) {
        this.isLoading = true
        const payload = {
          workspace_id: this.message.workspace_id,
          message_id:
            this.message.event_type === 'note'
              ? this.message.uuid
              : this.message.message_id,
          is_starred: !this.message.is_starred,
          is_note: this.message.event_type === 'note',
        }
        const bookmarkResponse = await this.updateBookmarkedMessages(payload)
        if (bookmarkResponse.isValid) {
          this.isLoading = false
          this.updateBookmarkStatus(
            bookmarkResponse.response,
            this.index,
            this.messageIndex
          )
        } else {
          this.isLoading = false
        }
      }
    },
    checkFor(content, text) {
      const links = linkify.find(text, content)
      return links.length > 0
    },
    getLink(text) {
      if (text != null) {
        const emailLinks = linkify.find(text, 'email')
        const urlLinks = linkify.find(text, 'url')
        if (emailLinks.length > 0) {
          for (let x = 0; x < emailLinks.length; x++) {
            const link = emailLinks[x]
            const replaceText =
              "<a href='" + link.href + "'>" + link.value + '</a>'
            text = text.replace(new RegExp(link.value, 'g'), replaceText)
          }
        } else if (urlLinks.length > 0) {
          for (let y = 0; y < urlLinks.length; y++) {
            const link = urlLinks[y]
            const replaceText =
              '<a class="link" target="_blank" rel="noopener"  href=\'' +
              link.href +
              "'>" +
              link.value +
              '</a>'
            text = text.replace(new RegExp(link.value, 'g'), replaceText)
          }
        }

        return text
      }
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.sticker {
  width: 200px;
  height: 200px;
  text-align: center;

  img {
    max-width: 100%;
    margin: 0 auto;
    max-height: 100%;
  }
}
</style>
