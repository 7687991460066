<template>
  <b-modal
    id="image_generator_modal"
    centered
    header-class="cs-modal-header"
    size="lg"
    hide-footer
    title-class="cs-modal-title"
    modal-class="overflow-y-hidden side_slide_right modal-non-blocking"
    dialog-class="h-full m-0 ml-auto max-w-max"
    content-class="h-full !rounded-none"
    body-class="!p-0"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    @hidden="handleModalClose"
    @shown="modalShown"
  >
    <template v-slot:modal-header>
      <div class="w-full flex items-center justify-between">
        <!-- modal title -->
        <div class="flex items-center justify-center gap-x-4">
          <img
            src="@assets/img/common/ai-image-generation.svg"
            alt=""
            class="w-8 h-8"
          />
          <span class="text-base font-semibold text-[#26303E] mt-1">{{ modal_heading }}</span>
        </div>

        <!-- captions limit -->
        <div class="flex items-center justify-center gap-x-4">
         <div class="flex items-center">
           <!-- History  -->
          <button
            v-tooltip="'Image Generation History'"
            class="border-none bg-transparent p-1.5 rounded"
            :class="{ '!bg-[#E3F2FF]': showHistory }"
            @click="toggleHistory"
        >
          <img
              src="@src/assets/img/chat_bot/chat-history.svg"
              alt="chat-history"
          />
        </button>
          <!-- Chat Credit -->
          <button
            v-if="!isLimitExceeded"
            v-tooltip="'AI Image Generation Credits'"
            class="ml-2 border-none bg-transparent p-1.5 rounded"
            :class="{ '!bg-[#E3F2FF]': showCredits }"
            @click="toggleCredits"
        >
          <img
              src="@src/assets/img/chat_bot/credit-icon.svg"
              alt="chat-credit"
          />
        </button>
         </div>

          <button
            id="close"
            type="button"
            class="modal_head__close ml-5"
            @click="handleModalClose"
            >&times;
          </button>
        </div>
      </div>
    </template>
    <div class="flex flex-row h-full" style="max-height: calc(100vh - 70px)">
      <slot />
    </div>
  </b-modal>
</template>

<script>
// libraries
import { defineComponent, computed, inject } from 'vue'

// constants
import { useStore } from '@state/base'
import useImageGeneration from '@/src/modules/composer_v2/composables/useImageGeneration'

export default defineComponent({
  name: 'ModalHeader',
  components: {},
  props: {
    modal_heading: {
      type: String,
      default: '',
    },
    modalClose: {
      type: Function,
      default: () => {},
    },
    modalShown: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const root = inject('root')
    const store = useStore()
    const { showHistory, toggleHistory, showCredits, toggleCredits  } = useImageGeneration()

    // computed properties
    /**
     * check if user credits limits exceeds
     * @type {ComputedRef<unknown>}
     */
    const isLimitExceeded = computed(() => {
      if (store.getters.getPlan.subscription.limits?.image_generation_credit)
        return (
          store.getters.getPlan.used_limits.image_generation_credit >=
          store.getters.getPlan.subscription.limits.image_generation_credit
        )
      return true
    })

    /**
     * fetch admin full name
     * @type {ComputedRef<unknown>}
     */
    const getSuperAdminDetails = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      return teamMembers
        ? teamMembers.find((member) => member.role === 'super_admin').user
        : { full_name: '' }
    })

    /**
     * validate user is super admin or not
     * @type {ComputedRef<unknown>}
     */
    const isActiveUserSuperAdmin = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      return teamMembers
        ? teamMembers.find(
            (member) =>
              member.role === 'super_admin' &&
              store.getters.getProfile.email === member.user.email
          )
        : false
    })
    /**
     * fetch user credits limit
     * @type {ComputedRef<number|*>}
     */
    const getCreditSubscribeLimit = computed(() => {
      return store.getters.getPlan?.subscription?.limits
        ?.image_generation_credit
    })

    /**
     * fetch credits used by user
     * @type {ComputedRef<number>}
     */
    const getCreditUsedLimit = computed(() => {
      return store.getters.getPlan?.used_limits?.image_generation_credit
    })

    // methods
    /**
     * hide modal
     */
    const handleModalClose = () => {
      root.$bvModal.hide('image_generator_modal')
      props.modalClose()
    }
    /**
     * Format number like 10k,10M e.g
     * @param number
     * @returns {*}
     */
    const formatNumber = (number) => {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      return formatter.format(number)
    }

    const captionLimitsTooltip = () => {
      const formattedCreditUsedLimit = formatNumber(getCreditUsedLimit.value);
      const formattedCreditSubscribeLimit = formatNumber(getCreditSubscribeLimit.value);

      return `
              <div class="text-left">
      <span class="font-medium block">AI text Credits (words)</span>
      <span class="block my-2">${formattedCreditUsedLimit} of ${formattedCreditSubscribeLimit} used</span>
      <span class="block">
        This limit is subscribed by ${getSuperAdminDetails.value.full_name} and being consumed by all team members.
        And the credits get reset on the start of the month.
      </span>
    </div>
                `;
    };

    return {
      handleModalClose,
      isLimitExceeded,
      getSuperAdminDetails,
      store,
      isActiveUserSuperAdmin,
      getCreditSubscribeLimit,
      getCreditUsedLimit,
      formatNumber,
      captionLimitsTooltip,
      showHistory,
      toggleHistory,
      showCredits,
      toggleCredits
    }
  },
})
</script>

<style lang="scss">
.modal-non-blocking {
  pointer-events: none;

  .modal-dialog {
    pointer-events: auto;
    box-shadow: 0 2px 50px 0 rgba(0, 39, 114, 0.15);
    border: 1px solid rgba(210, 213, 223, 0.50);
  }
}

#image_generator_modal {
  + .modal-backdrop {
    display: none;
  }
}
</style>
