<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl py-2">
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        {{ account.name }}
        <span
          v-if="account.headline"
          class="block font-normal text-xs text-gray-800"
        >
          {{ account.headline }}
        </span>
        <span class="block font-normal text-xs text-gray-800">
          <span v-if="executionTime.date">{{
              getWorkspaceTimeZoneTime(
                  executionTime.date,
                  getAccountDateTimeFormat
              )
          }}</span>
          <span v-else
            >{{ getWorkspaceTimezonCurrentTime(getAccountDateTimeFormat) }}.</span
          >
        </span>
      </div>
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
          src="@assets/img/integration/linkedin-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="getDescription" class="items-center px-3 py-2">
      <span class="whitespace-pre-wrap" v-html="getDescription"></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        Load More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <!-- used inline style where tailwind base classes are missing -->
    <div class="w-100 h-100">
      <div v-if="detail.url && detail.image.length > 0" class="">
        <div
          class="flex-img bg-contain bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
          :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
        >
          <img
            class="max-w-full max-h-full opacity-0 w-full"
            :src="detail.image[0]"
            alt=""
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
        <div
          v-if="detail.website || detail.title"
          class="bg-[#EEF3F8] w-full h-max py-2 px-3 overflow-hidden"
        >
          <div
            v-if="detail.title"
            class="font-medium text-[0.9rem] leading-5 truncate"
            >{{ detail.title }}
          </div>
          <div v-if="detail.website" class="block text-gray-700 text-sm mt-0.5"
            >{{ getHostOnly(detail.website) }}
          </div>
        </div>
      </div>
      <div
        v-else-if="linkedinOptions && linkedinOptions.is_carousel"
        class="w-full relative show-details-parent"
      >
        <div
          class="absolute top-0 w-full h-11 z-10 flex items-center justify-start pl-[10px] text-[18px] show-details !bg-opacity-90"
          :class="
            isMobileView
              ? 'bg-[#EDF1F7] text-[#404040]'
              : 'bg-[#202324]  text-white'
          "
        >
          <p class="font-bold"
            >{{ linkedinOptions.title ? linkedinOptions.title : 'Title' }} •
            <span class="!font-normal">{{ noOfPages }} pages</span></p
          >
        </div>
        <Carousel
          id="linkedin-preview-carousel"
          :navigation-next-label="navigationNext"
          :navigation-prev-label="navigationPrev"
          :navigation-enabled="true"
          :pagination-enabled="false"
          :scroll-per-page="true"
          :per-page="1"
          :mouse-drag="true"
        >
          <template v-for="(image, index) in carouselSlides" :key="index">
            <Slide>
              <div class="flex justify-center items-center w-full h-full relative">
                <div
                  v-if="linkedinOptions?.document_added"

                  class="absolute top-0 w-full h-full z-10 flex items-center justify-start pl-[10px] text-[18px] show-details !bg-opacity-50"
                  :class="'bg-[#202324] text-white'"
                >
                <div
                    v-tooltip="{
                      content: 'View PDF in new tab',
                      placement: 'auto',
                    }"
                    class="text-white border rounded-md p-2 flex items-center justify-center bg-[#0000007a] cursor-pointer absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                    @click="openPDF"
                  >
                  <i :class="'fas fa-file-pdf'"></i>
                </div>
              </div>
                <img
                  v-tooltip="{
                    content: 'Carousel Preview',
                    placement: 'auto',
                  }"
                  :src="image"
                  alt=""
                  class="min-h-[12rem] max-h-[30rem] h-full w-full object-contain"
                  :class="{ 'cursor-pointer': linkedinOptions.document_added }"
                  @click="openImageLightBox(carouselSlides)"
                />
              </div>
            </Slide>
          </template>
        </Carousel>
      </div>
      <div
        v-else-if="detail.image && detail.image.length > 0"
        class="flex flex-wrap relative overflow-hidden"
      >
        <template
          v-for="(image, index) in detail.image"
          :key="`image-index-${index}`"
        >
          <div
            v-if="index <= 4"
            class="flex-img bg-contain bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
            :class="{ 'img-five': detail.image.length >= 5 && index > 1 }"
            :style="{ 'background-image': 'url(' + image + ')' }"
          >
            <span
              v-if="detail.image.length > 5 && index === 4"
              class="block w-full h-full bg-[##00000066]"
            ></span>
            <img
              v-tooltip="{
                content: 'Image Preview',
                placement: 'auto',
              }"
              class="max-w-full max-h-full w-full opacity-0 cursor-pointer"
              :src="image"
              alt=""
              @click="openImageLightBox(detail.image, index)"
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
          <div
            v-else
            class="absolute p-2 text-2xl text-bold text-white"
            style="transform: translate(-50%, -50%); left: 83%; top: 75%"
            >+{{ detail.image.length - 5 }}
          </div>
        </template>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white text-center"
      >
        <img
          class="w-full min-h-48 max-h-[500px]"
          :src="detail.video.thumbnail"
          alt=""
        />
        <!-- playback icon -->
        <div
          class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
          @click.prevent="
            displayFile(
              'video',
              detail.video.converted_video || detail.video.link,
              0,
            )
          "
        >
          <i
            class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
          ></i>
        </div>
      </div>
    </div>

    <div
      v-if="commentDetails?.first_comment !== undefined || comment"
      class="flex items-center justify-center px-2 py-3"
      style="border-top: 1px solid #dee2e6"
    >
      <div>
        <img
          class="rounded-full object-cover border border-solid border-black"
          width="30"
          height="30"
          :src="account.image"
          alt=""
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </div>
      <div class="ml-2 w-full rounded-lg bg-cs-slate py-2 px-2">
        {{ comment }}
      </div>
    </div>
    <div
      v-if="commentDetails?.first_comment !== undefined"
      class="comments-status-box"
    >
      <p v-if="commentDetails?.first_comment?.status === true" class="ml-2">
        <span class="status published"></span>
        <span class="text">Published</span>
        <span> | [First comment has been posted successfully.]</span>
      </p>
      <p v-else-if="!commentDetails?.first_comment?.status" class="ml-2">
        <span class="status failed"></span>
        <span class="text">Failed</span>
        <span> | {{ commentDetails?.first_comment?.message }}</span>
      </p>
    </div>
  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="imgs"
    :index="selectedImageIndex"
    @hide="handleHide"
  ></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import useDateFormat from "@common/composables/useDateFormat";

export default {
  name: 'LinkedinPreview',
  components: {
    Carousel,
    Slide,
    VueEasyLightbox,
  },
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    executionTime: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
    linkedinOptions: {
      type: Object,
      default: () => {},
    },
    isMobileView: {
      type: Boolean,
      default: false,
    },
    commentDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(){
    const {momentWrapper,getAccountDateTimeFormat} = useDateFormat()
    return{
      momentWrapper,
      getAccountDateTimeFormat
    }
  },
  data() {
    return {
      loadMore: true,
      charLimit: 210,
      visible: false,
      imgs: [],
      selectedImageIndex: 0,
    }
  },
  computed: {
    navigationNext: function () {
      return `<i class="fas fa-chevron-right"></i>`
    },
    navigationPrev: function () {
      return `<i class="fas fa-chevron-left"></i>`
    },
    noOfPages() {
      return this.linkedinOptions.document_added
        ? this.linkedinOptions.document?.no_of_pages
        : this.detail.image.length
    },
    carouselSlides() {
      if (this.linkedinOptions.document_added)
        return [this.linkedinOptions.document.thumbnail]
      return this.detail.image
    },
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
  },
  methods: {
    getHostOnly(url) {
      const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
      return urlParts[1] ? urlParts[1] : ''
    },
    changeLoadMore(val) {
      this.loadMore = val
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    openImageLightBox(item, index = 0) {
      this.selectedImageIndex = index
      this.imgs = Array.isArray(item) ? item : [item]
      this.visible = true
    },
    openPDF(){
      window.open(this.linkedinOptions?.document?.link, '_blank', 'noopener, noreferrer')
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}

.img-five {
  flex: 1 0 calc(18.33% - 10px);
  border: 1px solid white;
}

/* Initially hide the details */
.show-details {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1%);
  transition: opacity 0.4s, transform 0.4s;
}

/* Show the details when the parent is hovered */
.show-details-parent:hover .show-details {
  opacity: 1;
  pointer-events: initial;
  transform: translateY(0);
}

.VueCarousel-slide {
  visibility: visible;
  flex-basis: 100%;
  width: 100%;
}
</style>
