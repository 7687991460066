<script>
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import { directive as onClickaway } from 'vue3-click-away'
export default {
  data() {
    return {
      type: null,
      media: null,
      index: 0,
      close: true,
      multimediaItemType: ''
    }
  },
  mounted() {
    EventBus.$on('displayFile', (data) => {
      this.type = data.type
      this.media = data.media
      this.index = data.index
    })
  },
  beforeUnmount() {
    EventBus.$off('displayFile')
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters([]),
    showImageFile() {
      return this.type === 'image' && this.media
    },
    showVideoFile() {
      return this.type === 'video' && this.media
    },
    showMultipleFiles() {
      return this.type === 'multimedia' && this.media.length
    },
    getImage() {
      return this.media[this.index]
    },
    getVideo() {
      return this.media
    },
    getMultimedia() {
      this.multimediaItemType = typeof this.media[this.index] === 'string' ? 'image' : 'video'
      return this.media[this.index]
    },
    showPreviousArrow() {
      return this.index > 0
    },
    showNextArrow() {
      return this.index + 1 < this.media.length
    },
  },

  methods: {
    ...mapActions([]),
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        this.getResizedImageURL(this.getImage, 1080, 0),
        `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`,
      ]
      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },
    onhide(event) {
      this.$bvModal.hide('display-file-modal')
    },
    showNextImage() {
      this.index = this.index + 1
      this.$bvModal.show('display-file-modal')
    },
    showPreviousImage() {
      this.index = this.index - 1
      this.$bvModal.show('display-file-modal')
    },
    away: function () {
      this.$bvModal.hide('display-file-modal')
    },
    CloseModal: function () {
      this.$bvModal.hide('display-file-modal')
    },
  },

  // eslint-disable-next-line vue/order-in-components
  directives: {
    onClickaway,
  },
}
</script>

<template>
  <b-modal
    id="display-file-modal"
    modal-class="social_share_modal display-file-modal top_left_design_box  no-enforce-focus hide-footer hide-header"
    @hide="onhide"
  >
    <div class="modal_head">
      <button
        v-tooltip.top="'Close'"
        type="button"
        class="close slide_close"
        data-dismiss="modal"
        @click="CloseModal"
        >&times;
      </button>
    </div>

    <div v-if="showImageFile" class="CSSgal">
      <div class="slider">
        <div class="slide_contain">
          <img data-fallback="0" :src="getImage" @error="onError($event)" />

          <div class="prevNext">
            <div>
              <a
                v-if="showPreviousArrow"
                class="left_arrow"
                @click="showPreviousImage"
                ><i
                  v-tooltip.top="'Previous'"
                  class="fa fa-angle-left"
                ></i
              ></a>
              <a v-if="showNextArrow" class="right_arrow" @click="showNextImage"
                ><i v-tooltip.top="'Next'" class="fa fa-angle-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showVideoFile" class="modal_body" style="min-height: 300px">
      <div
        class="ratio ratio-16x9 rounded-md overflow-hidden max-w-5xl mx-auto mt-12"
      >
        <video
          v-click-away="away"
          controls="controls"
        >
          <source :src="getVideo" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <div v-if="showMultipleFiles" class="CSSgal">
      <div class="slider">
        <div class="slide_contain">
          <img v-if="multimediaItemType === 'image'" data-fallback="0" :src="getMultimedia" @error="onError($event)" />
          <div v-else class="modal_body" style="min-height: 300px">
            <div
                class="ratio ratio-16x9 rounded-md overflow-hidden max-w-5xl mx-auto mt-12"
            >
              <video
                  controls="controls"
                  :poster="getMultimedia.thumbnail"
              >
                <source :src="getMultimedia.link" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="prevNext">
            <div>
              <a
                  v-if="showPreviousArrow"
                  class="left_arrow"
                  @click="showPreviousImage"
              ><i
                  v-tooltip.top="'Previous'"
                  class="fa fa-angle-left"
              ></i
              ></a>
              <a v-if="showNextArrow" class="right_arrow" @click="showNextImage"
              ><i v-tooltip.top="'Next'" class="fa fa-angle-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
