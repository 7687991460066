<template>
  <div
    v-if="post._source"
    class="article-grid-view-container__inner"
    :class="{ shared: post._source.shared }"
  >
    <div class="article-grid-view-container__inner__image_block">
      <!--<transition name="fade-list-item">
                <div class="tags_block top_right action_top_right_gridview" v-show="post._source.actions">
                    <i class="far fa-archive gridview_icon_action" v-tooltip="'Archive'"></i>
                </div>
            </transition>-->

      <div
        class="article-grid-view-container__inner__image_block__article_type"
      >
        <span v-if="post._source.read_time && post._source.read_time == 1">
          Less than 1 Min. Read
        </span>
        <span v-if="post._source.read_time && post._source.read_time > 1">
          {{ post._source.read_time }} Min. Read -
        </span>
        <span v-if="post._source.has_abstract">Abstract Content</span>
        <span v-else>Full Content</span>
      </div>

      <div
        v-if="post._source.categories"
        class="article-grid-view-container__inner__image_block__tags"
      >
        <span
          v-for="(category, i) in post._source.categories"
          :key="i"
          class="
            article-grid-view-container__inner__image_block__tags__item
            capitalize
          "
          >{{ category }}</span
        >
      </div>

      <div
        :style="{
          backgroundImage: `url('${getResizedImageURL(
            backgroundImageURL(post._source),
            381,
            200
          )}')`,
        }"
        class="article-grid-view-container__inner__image"
      ></div>

      <div
        v-if="
          post._source.shared &&
          post._source.plan_status &&
          post._source.plan_status === 'scheduled'
        "
        class="article-grid-view-container__inner__shared"
      >
        <span>
          Scheduled On
          <b>
            {{
              getWorkspaceTimeZoneTime(
                post._source['execution_time']['date'],
                'MMM DD YYYY, hh:mm A'
              )
            }}
          </b>
        </span>
      </div>

      <div
        v-else-if="post._source.shared && post._source.shared_count"
        class="article-grid-view-container__inner__shared"
      >
        <span v-if="post._source.plan_status === 'draft'">
          Drafted on
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>

        <span v-else-if="post._source.plan_status === 'queued'">
          Queued on
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>
        <span v-else>
          Shared to
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>
      </div>
      <!--<img class="lazy _img" :src="backgroundImageURL(post._source)" alt="">-->
    </div>
    <div class="article-grid-view-container__inner__content_block">
      <div class="article-grid-view-container__inner__content_block__height">
        <HtmlRenderer
            tag="h2"
            :title="limitTextLength(post._source.title, 0)"
            :html-content="limitTextLength(post._source.title, 60)"
            @click="searchWebPreview"
        />

        <template v-if="post._source.author">
          <p class="article-grid-view-container__inner__content_block__author">
            By
            <span
              class="mr-1"
              v-tooltip="'View articles from this author'"
              style="color: #4e92ff"
              @click="
                changeRouteForAuthorGridView(
                  post._source.author,
                  trimURL(post._source.domain_url)
                )
              "
              v-html="post._source.author"
            ></span>
            <template
              v-if="
                post._source.twitter_accounts &&
                post._source.twitter_accounts.length > 0
              "
            >
              <a
                v-for="(account, index) in getTwitterAccountsForView(
                  post._source.twitter_accounts
                )"
                style="cursor: pointer"
                @click.prevent="
                  callGetInfluencerPreviewDiscoveryRedirect(account)
                "
              >
                <span v-if="index < 1">{{ account }}</span></a
              >
            </template>

            <a
              v-else-if="post._source.twitter_handler"
              target="_blank" rel="noopener"
              :href="sanitizeUrl(getTwitterLink(post._source.twitter_handler))"
            >
              {{ post._source.twitter_handler }}</a
            >
            <clip-loader
              v-if="loader"
              class="d-inline-block ml-1"
              :color="'#436aff'"
              :size="'12px'"
            ></clip-loader>
          </p>
        </template>
        <template
          v-else-if="
            post._source.twitter_accounts &&
            post._source.twitter_accounts.length > 0
          "
        >
          <p class="article-grid-view-container__inner__content_block__author">
            By
            <a
              v-for="(account, index) in getTwitterAccountsForView(
                post._source.twitter_accounts
              )"
              style="cursor: pointer"
              @click.prevent="
                callGetInfluencerPreviewDiscoveryRedirect(account)
              "
              ><span v-if="index < 1">{{ account }}</span></a
            >
            <clip-loader
              v-if="loader"
              class="d-inline-block ml-1"
              :color="'#436aff'"
              :size="'12px'"
            ></clip-loader>
          </p>
        </template>

        <template v-if="post._source.twitter_handler">
          <p class="article-grid-view-container__inner__content_block__author">
            By
            <a
              target="_blank" rel="noopener"
              :href="sanitizeUrl(getTwitterLink(post._source.twitter_handler))"
              >{{ post._source.twitter_handler }}</a
            >
          </p>
        </template>
        <div
          class="
            clear
            article-grid-view-container__inner__content_block__information
          "
        >
          <div class="d-flex flex-row align-items-center">
            <div
              class="
                article-grid-view-container__inner__content_block__information__author
              "
            >
              <a
                v-if="post._source.domain_url"
                v-tooltip="'View articles from this domain'"
                class="domain_url_detail"
                href="javascript:;"
                @click.prevent="
                  changeRouteByDomainGridView(trimURL(post._source.domain_url))
                "
                >{{ trimURL(post._source.domain_url) }}</a
              > </div
            >&nbsp;
            <div
              class="
                article-grid-view-container__inner__content_block__information__author
              "
            >
              <p class="full_width_author"
                >{{ $filters.relative(post._source.post_date) }}
                <span
                  v-if="post._source.related_ids_count > 1"
                  style="float: right"
                  class="similar_stories"
                  @click.prevent="
                    retrieveSimilarArticles(post._source.id, post._index)
                  "
                  >{{ post._source.related_ids_count }} similar articles</span
                ></p
              >
              <!--v-if="post._source.related_ids_count"-->
            </div>
          </div>

          <div
            v-if="isTopicsDisplayAllowed(post)"
            class="
              article-grid-view-container__inner__content_block__information__categories
            "
          >
            <template v-for="item in post._source.topics">
              <template v-if="item.label">
                <span
                  v-for="finalized_topic in checkIfMultipleTopics(item.label)"
                  v-tooltip="'View results from this topic'"
                  class="tag"
                  @click.prevent="changeTopicRouteLabel(item, finalized_topic)"
                >
                  <span v-if="item && item.label">{{ finalized_topic }}</span>
                </span>
              </template>
            </template>
          </div>

          <div class="more_detail">
            <!-- <template v-if="post._source.top_twitter_influencers && post._source.top_twitter_influencers.length > 0">
                             <div class="similar_content">{{post._source.top_twitter_influencers.length}} influencers
                                 shared
                             </div>
                         </template>-->

            <!--<div class="article_type">-->
            <!--<span v-if="post._source.read_time && post._source.read_time ==1 ">-->
            <!--Less than 1 Min. Read - -->
            <!--</span>-->
            <!--<span v-if="post._source.read_time && post._source.read_time >1 ">{{post._source.read_time}} Min. Read -</span>-->
            <!--<span v-if="post._source.has_abstract">Abstract Content</span>-->
            <!--<span v-else>Full Content</span>-->
            <!--</div>-->
            <!--<div class="similar_content">16 similar articles</div>-->
          </div>
          <div
            v-if="post._source.top_twitter_influencers"
            class="article-grid-view-top-influencers"
          >
            <div
              v-if="post._source.total_influencers_shares"
              class="article-grid-view-top-influencers__left"
            >
              <p
                ><i class="icon-profile-cs pr-1"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(post._source.total_influencers_shares)
                }}</strong>
                influencers</p
              >
            </div>
            <!-- showSharers(post._source.id)-->
            <!--                      @click.prevent="influencersWebPreview()"-->
            <!--                      v-if="influencer.follower_count > 1000"-->
            <!--                      v-if="index <= 3"-->
            <div
              v-if="post._source.total_influencers_shares > 0"
              class="article-grid-view-top-influencers__right ml-auto"
            >
              <template
                v-for="(influencer, index) in post._source
                  .top_twitter_influencers"
              >
                <a
                  v-tooltip="{
                    content: channelTooltipHTML('influencer', influencer),
                    classes: 'team_tooltip',
                    allowHTML: true,
                    theme: 'light',
                  }"
                  target="_blank" rel="noopener"
                  :href="sanitizeUrl('https://twitter.com/' + influencer.screen_name)"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      'background-image': getBackgroundURLWithDefault(
                        influencer.profile_image
                      ),
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
              <!--                            <div class="rounded_more"-->
              <!--                                 v-if="post._source.top_twitter_influencers.length > 3  && post._source.total_influencers_shares - 4 > 0">-->
              <!--                                +{{post._source.total_influencers_shares - 4}}-->
              <!--                            </div>-->
            </div>
          </div>
        </div>
        <!--<div class="tag_section">-->

        <!--<template v-for="item in post._source.topics" v-if="item.probability > 0.20">-->
        <!--<span v-for="finalized_topic in checkIfMultipleTopics(item.label)">-->
        <!--{{finalized_topic}}-->
        <!--</span>-->
        <!--</template>-->
        <!--</div>-->
      </div>

      <div
        class="
          article-grid-view-container__inner__content_block__social_statistics
        "
      >
        <div
          class="
            article-grid-view-container__inner__content_block__social_statistics__inner
            d-flex
          "
        >
          <div
            class="
              popover-social-statistics
              article-grid-view-container__inner__content_block__social_statistics__inner__item
            "
          >
            <SharedCountTooltip :post="post" />
            <div class="value">
              <h3>{{ intToString(calculateEngagements(post._source)) }}</h3>
            </div>
          </div>
          <div
            v-if="post._source.velocity && post._source.velocity > 5"
            class="
              article-grid-view-container__inner__content_block__social_statistics__inner__item
            "
          >
            <div class="name">
              <p>Trending Score</p>
            </div>
            <div class="value">
              <h3>{{ intToString(post._source.velocity) }}</h3>
            </div>
          </div>
          <SentimentIcon
            :post="post"
            :class="'article-grid-view-container__inner__content_block__social_statistics__inner__item'"
          />
        </div>
      </div>
      <div class="article-grid-view-container__inner__action_buttons">
        <button
          id="dropdown-archive-articles"
          class="btn btn-studio-icon-theme post_archi_btn"
          @click.prevent="
            initializeArticlePostCreation(post._source.id, 'social_share_modal')
          "
        >
          <i v-tooltip="'Social Media Share'" class="far fa-share-alt icon"></i>
        </button>

        <button
          id="dropdown-archive-articles"
          class="btn btn-studio-icon-theme post_archi_btn"
          @click.prevent="
            initializeArticlePostCreation(post._source.id, 'blog_share_modal')
          "
        >
          <i v-tooltip="'Blog Post share'" class="far fa-rss"></i>
        </button>

        <!--&lt;!&ndash; share buttons &ndash;&gt;-->
        <!--<b-dropdown variant="studio-icon-theme" id="dropdown-share-articles" class="studio-icon-theme-dropdown mr-3" no-caret>-->

        <!--<template v-slot:button-content>-->
        <!--<i v-tooltip="'Share this post'" class="far fa-share-alt icon"></i>-->
        <!--</template>-->

        <!--<b-dropdown-item-->
        <!--variant="studio-icon-theme"-->
        <!--@click.prevent="initializeArticlePostCreation(post._source.id,'social_share_modal')"-->
        <!--&gt;<i class="far fa-share share-ico-align"></i> Social Media-->
        <!--</b-dropdown-item>-->

        <!--<b-dropdown-item-->
        <!--variant="studio-icon-theme"-->
        <!--@click.prevent="initializeArticlePostCreation(post._source.id,'blog_share_modal')"><i class="far fa-rss"></i> Blog Post-->
        <!--</b-dropdown-item>-->

        <!--</b-dropdown>-->

        <!-- favorite article button -->
        <!--<template>-->
        <!--<i @click="unfavoritePostItemEventFire(post._source.id)" v-tooltip="'Unfavorite'" class="far fa-heart icon is-favorite" ></i>-->
        <!--<span :class="{' active' : post._source.is_active}"-->
        <!--class="icon_animate"></span>-->
        <!--</template>-->

        <button
          v-if="post._source.is_favorite"
          id="dropdown-archive-articles"
          class="btn btn-studio-icon-theme post_archi_btn"
          style=""
          @click="unfavoritePostItemEventFire(post._source.id)"
        >
          <i v-tooltip="'Unfavorite'" class="far fa-heart icon is-favorite"></i>
          <span
            :class="{ ' active': post._source.is_active }"
            class="icon_animate"
          ></span>
        </button>

        <b-dropdown
          v-else
          id="dropdown-favorite-articles"
          ref="dropdown_favorite"
          variant="studio-icon-theme"
          class="studio-icon-theme-dropdown post_btn_rm"
          no-caret
          block
        >
          <template v-slot:button-content>
            <i
              v-tooltip="'Favorite'"
              class="far fa-heart icon"
              :class="{ 'animated tada': post._source.is_active }"
            ></i>
          </template>
          <BDropdownFavoriteArticle
            v-show="!post._source.is_favorite"
            :post_id="post._source.id"
            :post-index="index"
          />
        </b-dropdown>
        <!-- archive post button -->

        <template
          v-if="
            getAllAutomations.evergreen &&
            getAllAutomations.evergreen.length > 0
          "
        >
          <b-dropdown
            id="dropdown-actions-evergreen"
            variant="studio-icon-theme"
            class="studio-icon-theme-dropdown post_btn_rm"
            no-caret
          >
            <template v-slot:button-content>
              <i
                v-tooltip="'Add post to Evergreen Automation'"
                class="far fa-robot icon"
              ></i>
            </template>

            <b-dropdown-item
              v-for="(automation, key) in getAllAutomations.evergreen"
              :key="key"
              variant="studio-icon-theme"
              @click="addInEvergreenAutomation(post._source, automation._id)"
            >
              {{ automation.name }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template v-else>
          <button
            id="dropdown-archive-articles"
            class="btn btn-studio-icon-theme post_archi_btn"
            style=""
            @click="archivePostItemEventFire(post._source.id, 'archive')"
          >
            <i v-tooltip="'Archive Post'" class="far fa-archive icon"></i>
          </button>
        </template>

        <!--              <b-dropdown variant="studio-icon-theme" id="dropdown-archive-articles" class="studio-icon-theme-dropdown mr-3" no-caret block-->
        <!--                          >-->
        <!--                <template v-slot:button-content>-->
        <!--                  <i @click="archivePostItemEventFire(post._source.id, 'archive')" v-tooltip="'Archive Post'" class="far fa-archive icon"></i>-->
        <!--                </template>-->
        <!--              </b-dropdown>-->

        <!-- more actions button-->
        <b-dropdown
          id="dropdown-actions-articles"
          variant="studio-icon-theme"
          class="studio-icon-theme-dropdown"
          no-caret
        >
          <template v-slot:button-content>
            <i class="cs-dots-h icon"></i>
          </template>

          <template
            v-if="
              getAllAutomations.evergreen &&
              getAllAutomations.evergreen.length > 0
            "
          >
            <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="
                archivePostItemEventFire(post._source.id, 'archive')
              "
              ><i class="far fa-archive"></i> Archive Post
            </b-dropdown-item>
          </template>

          <b-dropdown-item
            variant="studio-icon-theme"
            target="_blank" rel="noopener"
            :href="sanitizeUrl(savePocketContent(post._source.url))"
            ><i class="fab fa-get-pocket"></i> Add to Pocket
          </b-dropdown-item>

          <b-dropdown-item
            variant="studio-icon-theme"
            @click.prevent="influencersWebPreview"
            ><i class="fa fa-users-crown"></i> View Influencers
          </b-dropdown-item>

          <b-dropdown-item
            v-if="
              getTopicType === 'archived' &&
              $route.params &&
              $route.params.module === 'archived'
            "
            variant="studio-icon-theme"
            @click.prevent="
              unarchive({ post_id: post._source.id, index: index })
            "
            ><i class="far fa-archive"></i> Unarchive
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import HtmlRenderer from "@common/components/htmlRenderer.vue";
import ArticleFavoriteDropdown from '../../folders/ArticleFavoriteDropdown'
import SharedCountTooltip from '../snippets/SharedCountTooltip'
import SentimentIcon from '../snippets/SentimentIcon'
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'
import {sanitizeUrl} from "@braintree/sanitize-url";

export default {
  components: {
    HtmlRenderer,
    SharedCountTooltip,
    SentimentIcon,
    ArticleFavoriteDropdown,
    BDropdownFavoriteArticle,
  },
  props: {
    post: {},
    index: {},
  },
  data() {
    return {
      loader: false,
    }
  },
  computed: {
    ...mapGetters([
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getMobileDevices',
      'getWorkspaces',
      'getYoutubeAccounts',
      'getBlogs',
      'getAllAutomations',
    ]),
  },
  mounted() {
    EventBus.$on('hide-favorite-dropdown', () => {
      console.log('Hide favorite dropdown...')
      this.$refs.dropdown_favorite.hide(true)
    })
  },
  beforeUnmount() {
    EventBus.$off('hide-favorite-dropdown')
  },
  methods: {
    sanitizeUrl,
    ...mapActions(['archive', 'unarchive', 'articlePreview']),
    changeTopicRouteLabel(item, tag) {
      if (this.$route.name === 'discover_search_web') {
        this.$router.push({ name: this.$route.name, query: { tag } })
      }
    },
    searchWebPreview() {
      const payload = {
        name: `${this.$route.name}_item_preview`,
        params: { post_id: this.post._source.id },
        query: { module: 'search', ...this.$route.query },
      }
      console.log(payload)
      this.$router.push(payload)
    },
    influencersWebPreview() {
      const payload = {
        name: `${this.$route.name}_item_influencers`,
        params: { post_id: this.post._source.id },
        query: { module: 'search', ...this.$route.query },
      }
      console.log(payload)
      this.$router.push(payload)
    },
    archivePostItemEventFire(postId, state = 'archive') {
      EventBus.$emit('archive-post-item', { postId, state })
    },
    addInEvergreenAutomation(post, automationId) {
      console.debug('before emit')
      EventBus.$emit('add-evergreen-post', {
        postId: post.id,
        post,
        automation_id: automationId,
      })
    },
    unfavoritePostItemEventFire(postId) {
      EventBus.$emit('unfavorite-post-item', { postId })
      this.$refs.dropdown_favorite.toggle(false)
    },
    changeRouteForAuthorGridView(author, domain) {
      // this.$router.push({ name: this.$route.name, query: { q: `author:${author} AND site:${domain}` } })

      this.$router.push({
        name: this.$route.name,
        query: { q: `author:'${author}'` },
      })
    },
    changeRouteByDomainGridView(domain) {
      this.$router.push({
        name: this.$route.name,
        query: { q: `site:${domain}` },
      })
    },

    async callGetInfluencerPreviewDiscoveryRedirect(account) {
      this.loader = true
      await this.$parent.getInfluencerPreviewDiscoveryRedirect(account)
      this.loader = false
    },
  },
}
</script>

<style lang="less" scoped>
.author_detail {
  span {
    cursor: pointer !important;
  }
}

.domain_url_detail {
  cursor: pointer !important;
}
</style>
