<template>
  <div class="modal_body basic_form m_t_15">
    <div class="flex-row">
      <div class="col-12 text-center">
        <h1>Alternate Posting Method - Mobile Notifications</h1>
        <p
          >If you plan to publish <b>Multiple Images</b> posts or
          <b>Stories</b> to Instagram, you'll need this alternate method because
          these formats are not supported by Instagram's API yet.</p
        >
        <br />
        <p
          >So, we recommend installing our mobile application on your mobile
          device (if you haven't already). Our mobile application is feature
          rich, it will help with your day-to-day planning and publishing on all
          networks.</p
        ><br />
      </div>
    </div>
    <div class="flex-row margin-auto d-flex">
      <div class="col text-center">
        <div class="grey-box">
          <img src="@src/assets/img/integration/insta-phone.svg" />
          <div class="flex-row grey-box-container">
            <span
              ><img
                src="@src/assets/img/integration/contentstudio-icon-sm.png"
              />
            </span>
            <i class="fa fa-caret-right"></i>
            <span
              ><img src="@src/assets/img/integration/insta-phone.svg" />
            </span>
            <i class="fa fa-caret-right"></i>
            <span
              ><img src="@src/assets/img/integration/instagram-sm.svg" />
            </span>
          </div>
          <h3>Download Mobile App</h3>
          <p
            >Note: Mobile notifications method requires a little manual
            intervention.</p
          >
          <div class="d-flex justify_center mt-8">
            <button class="btn btn-studio-theme-transparent btn-size-large mr-4 text-base" @click.prevent="showMobile">Proceed</button>
            <button
              href="https://docs.contentstudio.io/article/725-how-to-setup-your-zap"
              class="btn btn-studio-theme-transparent btn-size-large text-base"
              @click.prevent="redirectToMobileDoc"
              >Read Doc
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['showMobile'],
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getMobileDevices']),
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    redirectToZapierDoc() {
      window.open(
        'https://docs.contentstudio.io/article/725-how-to-setup-your-zap',
        '_blank', 'noopener, noreferrer'
      )
    },
    redirectToMobileDoc() {
      window.open(
        'https://docs.contentstudio.io/article/724-how-to-setup-your-mobile-application',
        '_blank', 'noopener, noreferrer'
      )
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped></style>
