<template>
  <div
      class="cst-editor-bluesky-tweets color-border rounded-xl px-3 py-3 mt-5 p-relative"
  >
    <div class="flex items-center">
      <div class="w-48 ml-1 flex items-center">
        <div
            class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-gray-400"
        >
          <img
              src="@assets/img/integration/bluesky-rounded.svg"
              alt=""
              class="w-5 h-5"
          />
        </div>
        <span class="mx-2 font-medium text-sm text-black-300"
        >Bluesky Threads</span
        >
        <v-menu
            :popper-triggers="['hover']"
            placement="bottom-end"
            popper-class="threaded-tweets__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
          ><img
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Bluesky Threads are available for: </div>
            <ul>
              <li>
                <img
                    src="@assets/img/integration/bluesky-rounded.svg"
                    alt="bluesky"
                    class="w-[1.5rem] h-[1.5rem] mr-2"
                />
                Profiles
              </li>
            </ul>
            <p class="threaded-tweets__message-box"
            >How to add Bluesky threads?
              <a
                  v-close-popover="true"
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="676154c4eb06695f64f33ccd"
              >Click here</a
              ></p
            >
          </template>
        </v-menu>
      </div>
      <div>
        <CstSwitch
            v-model="blueskyOptions.has_multi_bluesky"
            class="mx-2"
            :disabled="accounts.length < 1"
            size="small"
        />
      </div>
      <div class="ml-auto flex items-center gap-x-2">
        <div v-for="(account, index) in accounts" :key="index">
          <SocialProfile
              :platform-logo="account.platform_logo"
              badge-src="bluesky-rounded.svg"
              :selected="true"
          />
        </div>
      </div>
    </div>

    <div class="mt-1">
      <draggable
          v-model="blueskyOptions.multi_bluesky"
          :list="blueskyOptions.multi_bluesky"
          handle=".handle"
          @start="sortStart"
          @end="sortEnd"
      >
        <template v-slot:item="{ element: tweet, index }">
          <div
              class="mt-3 p-relative flex"
              :class="{ 'opacity-0': index === focusElement }"
          >
              <span
                  v-show="blueskyOptions.multi_bluesky.length > 1"
                  class="handle flex flex-col justify-center text-sm cursor-move bg-cs-secondary rounded-l-lg text-black-100 border-0 px-2 w-max"
              >
                <img src="@assets/img/icons/sorting_gray.svg" width="12" />
              </span>
            <LiteEditorBox
                :tweet-index="index"
                :account-type="accountType"
                :widgets="getEditorSettings.widgets"
                :max-limit="getEditorSettings.maxLimit"
                :twitter-text-enable="getEditorSettings.twitterTextEnable"
                :sharing-details="tweet"
                :accounts-data="accountsData"
                :apply-hashtag="applyHashtag"
                type="bluesky-tweet"
                v-bind="$attrs"
                v-on="$listeners"
                @onEditorBoxTextChange="
                  (type, message, count) =>
                    onEditorBoxTextChange(type, message, index, count)
                "
                @initializeVideoUpload="(type) => initializeVideoUpload(index)"
                @addMedia="
                  (type, media, mediaType) => addMedia(index, media, mediaType)
                "
                @removeMedia="
                  (type, mediaType, media, removeAll) =>
                    handleRemoveMedia(index, mediaType, media, removeAll)
                "
                @handle-change="
                  (thread, type) => onChangeBlueskyDetails(thread, index)
                "
                @handle-lite-editor-hashtags="
                  (flag, data) =>
                    handleBlueskyTweetHashtagsChanges(index, flag, data)
                "
                @set-link-preview="(preview, payload) => setLinkPreview(index, preview, payload)"
                @remove-link-preview="(type, removeImg, trigger) => removeLinkPreview(index, type, removeImg, trigger)"
                @remove-threaded-tweet="removeBlueskyTweets"
            />
          </div>
        </template>
      </draggable>
    </div>
    <div class="my-4 flex items-center">
      <div>
          <span
              class="ml-0.5 text-sm cursor-pointer rounded-lg bg-cs-secondary text-black-100 border-0 px-2 py-2"
              @click.prevent="addBlueskyTweet"
          >
            <i class="fas fa-plus before:text-black-100 mr-1"></i>
            Add more
          </span>
      </div>
    </div>
  </div>
</template>

<script>
import CstAccountCheckBox from '@ui/CheckBox/CstAccountCheckBox'
import {
  defaultBlueskyOptions,
  videoDefaultSharingDetails,
} from '@src/modules/composer_v2/views/composerInitialState'


import {blueskyPostLimit, } from '@src/modules/publish/config/api-utils'
import LiteEditorBox from '@src/modules/composer_v2/components/EditorBox/LiteEditorBox'
import CstSwitch from '@ui/Switch/CstSwitch'
import draggable from 'vuedraggable'
import {EventBus} from "@common/lib/event-bus";
import SocialProfile from "@common/components/SocialProfile.vue";
const clone = require('rfdc/default')

export default {
  name: 'EditorBlueskyBox',
  components: {
    SocialProfile,
    LiteEditorBox,
    CstAccountCheckBox,
    CstSwitch,
    draggable,
  },
  props: {
    blueskyOptions: {
      type: Object,
      default: () => {
        return { ...defaultBlueskyOptions }
      },
    },
    accountType: {
      type: String,
      default: '',
    },
    accounts: {
      type: Array,
      default: () => {
        return []
      },
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      focusElement: null,
      blueskyList: this.blueskyOptions?.multi_bluesky || [],
    }
  },

  computed: {
    isPremiumTwitter() {
      if (!this.accounts || this.accounts.length === 0) {
        return false;
      }
      return this.accounts.filter(
          (account) => account.channel_type === 'twitter'
      ).every(account =>
          ['premium', 'premiumplus', 'basic'].includes(account?.subscription_type?.toLowerCase())

      );
    },
    getEditorSettings() {
      return {
        maxLimit: blueskyPostLimit,
      }
    },

    // canAddAnotherTweet() {
    //   const lastThread = this.blueskyOptions.multi_bluesky[this.blueskyOptions.multi_bluesky.length - 1]
    //   return (lastThread.message || (lastThread.image && lastThread.image.length) || (lastThread.video && lastThread.video.link))
    // },
  },

  mounted() {
    console.debug('Bluesky Tweets mounted')
    EventBus.$on(
        'add-media-from-media-library-bluesky-tweet',
        ({type, media, mediaType, threadIndex}) => {
          console.debug(
              'add-media-from-media-library-bluesky-tweet',
              type,
              media,
              mediaType,
              threadIndex
          )
          this.addMedia(threadIndex, media, mediaType)
        }
    )
  },

  beforeUnmount() {
    EventBus.$off('add-media-from-media-library-bluesky-tweet')
  },

  methods: {
    sortStart(event) {
      this.focusElement = event.oldIndex
    },
    sortEnd(event) {
      this.focusElement = null
    },
    /**
     * @description Add another threaded tweet
     */
    addBlueskyTweet() {
      this.$emit('addBlueskyTweet')
    },

    /**
     * @description Close or remove a threaded tweet
     * @param {number} index
     */
    removeBlueskyTweet(index) {
      this.$emit('removeBlueskyTweet', index)
    },

    handleBlueskyTweetHashtagsChanges(index, flag, data) {
      this.$emit('handle-hashtags', 'bluesky', flag, data, index)
    },

    /**
     * @description Event handlers on Editor Box Text Change for Threaded Tweets
     * @param type {string} type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param message {string} message
     * @param index {int} thread index
     * @param count character count
     */
    onEditorBoxTextChange: function (type, message, index, count) {
      const thread = clone(this.blueskyOptions.multi_bluesky[index])
      thread.message = message
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Remove link preview
     * @param index index of thread
     * @param removeImage remove link preview image from details (true/false) default false
     * @param triggerEvent trigger event to parent component (true/false) default true
     * @param details Thread details object default null
     */
    removeLinkPreview: function (
        index,
        type = '',
        removeImg = false,
        triggerEvent = true,
        details = null
    ) {
      console.log('removeLinkPreview', index, type, removeImg, triggerEvent, details)
      details = details || clone(this.blueskyOptions.multi_bluesky[index])
      details.title = details.description = details.website = details.url = ''
      if (removeImg) {
        details.image = []
      }
      if (triggerEvent) {
        this.onChangeBlueskyDetails(details, index)
      }
      return details
    },

    /**
     * @description handle Sharing Link event
     * @param index index of thread
     * @param link
     */
    handleSharingLink(index, link) {
      const thread = clone(this.blueskyOptions.multi_bluesky[index])
      thread.url = link
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Set link preview for Thread
     * @param index index of thread
     * @param preview link preview object
     */
    setLinkPreview(index, preview, payload) {
      const thread = clone(this.blueskyOptions.multi_bluesky[index])
      // using same images if images are already added
      if (thread.image.length) {
        payload.image = thread.image
      }
      thread.title = payload.twitter_title
      thread.description = payload.twitter_description
      thread.image = payload.twitter_image
      thread.website = payload.twitter_website
      thread.image_suggestions = payload.image_suggestions

      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Initialize video upload from editor for Thread
     * @param index index of thread
     */
    initializeVideoUpload: function (index) {
      let thread = clone(this.blueskyOptions.multi_bluesky[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, true, false, thread)
      thread.video = videoDefaultSharingDetails
      thread.image_suggestions = []
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Close or remove a threaded tweet
     * @param {number} index
     */
    removeBlueskyTweets(index) {
      this.$emit('removeBlueskyTweet', index)
    },

    /**
     * @description Add media to editor for Thread
     * @param index index of thread
     * @param media media
     * @param mediaType media type (image, video, etc)
     */
    addMedia(index, media, mediaType) {
      console.debug('bluesky-tweet', index, media, mediaType)
      let thread = clone(this.blueskyOptions.multi_bluesky[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, false, false, thread)
      if (mediaType === 'image') {
        this.addImages(index, thread, media)
      } else if (mediaType === 'video') {
        this.addVideo(index, thread, media)
      }
    },

    /**
     * @description Add images to editor for Thread
     * @param index index of thread
     * @param thread thread
     * @param images array
     */
    addImages(index, thread, images) {
      // remove video
      thread.video = clone(videoDefaultSharingDetails)
      thread.image = images.length > 3 ? images.slice(0, 4) : images
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Add video to editor for Thread
     * @param index index of thread
     * @param thread thread
     * @param video video object
     */
    addVideo(index, thread, video) {
      console.debug('addVideo', index, thread, video)
      thread.image = []
      thread.video = video[0]
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Handle remove media for thread
     * @param index index of thread
     * @param mediaType mediaType string (image, video)
     * @param media media to remove object
     * @param removeAll remove all images (true/false) default false
     */
    handleRemoveMedia: function (index, mediaType, media, removeAll = false) {
      if (mediaType === 'image') {
        this.removeImage(index, media, removeAll)
      } else if (mediaType === 'video') {
        this.removeVideo(index, media)
      }
    },

    /**
     * @description Remove video from editor for Thread
     * @param index index of thread
     */
    removeVideo: function (index) {
      const thread = clone(this.blueskyOptions.multi_bluesky[index])
      thread.video = videoDefaultSharingDetails
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Remove image from editor media box for Thread
     * @param threadIndex index of thread
     * @param image image to remove
     * @param removeAll remove all images (true/false) default false
     */
    removeImage: function (threadIndex, image, removeAll = false) {
      const thread = { ...this.blueskyOptions.multi_bluesky[threadIndex] }
      let index = -1
      const self = this
      if (thread && thread.image && thread.image.length > 0 && !removeAll) {
        setTimeout(function () {
          index = thread.image.indexOf(image)
          if (index > -1) {
            thread.image.splice(index, 1)
          }
          self.onChangeBlueskyDetails(thread, threadIndex)
        }, 200)
      } else if (removeAll) {
        thread.image = []
        this.onChangeBlueskyDetails(thread, threadIndex)
      }
    },

    /**
     * @description Handle Location change for Thread
     * @param index index of thread
     * @param locationType
     * @param value location value
     */
    handleLocationChanges(index, locationType, value) {
      const thread = clone(this.blueskyOptions.multi_bluesky[index])
      thread.location = value
      this.onChangeBlueskyDetails(thread, index)
    },

    /**
     * @description Event handlers on change thread details
     * @param thread {Object} thread details
     * @param index {int} thread index
     */
    onChangeBlueskyDetails: function (thread, index) {
      this.$emit('onChangeBlueskyDetails', thread, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-editor-bluesky-tweets {
  border-top: 1px solid #dee2e6;
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  //&:focus-within {
  //  background-color: #fff;
  //  //border-color: #80bdff;
  //  border-color: #80bdff30;
  //  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
  //  outline: 0;
  //}
  textarea {
    background-color: #f4f7fa;
  }
}
</style>
