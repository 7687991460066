<template>
  <div class="scroll_content influencer-tweets social_preview_no_tab">
    <InstagramVideoPreview
      :loader="loaderValue"
      :asset_url="asset_url"
      :not-found="notFound"
      :video-value="videoCode"
      :video-height="videoHeight"
      :video-width="videoWidth"
    ></InstagramVideoPreview>
    <template v-if="getInfluencerLoaders.posts">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="posts && posts.length > 0">
      <div class="influencer-instagram-posts clear">
        <div
          v-for="(post, index) in posts"
          :key="index"
          class="instagram_post_preview_main"
          style="width: 100% !important"
        >
          <div class="instagram_post_preview">
            <div class="sec_padding head flex">
              <div class="profile_picture d-flex align-items-center">
                <div class="picture_block">
                  <img :src="preview.profile_image" alt="" />
                </div>
                <div class="text_block">
                  <p class="text" v-html="preview.full_name"></p>
                </div>
              </div>
              <template v-if="platform == 'instagram'">
                <a class="btn ml-auto" target="_blank" rel="noopener"  :href="sanitizeUrl(post.permalink)"
                  ><i class="far fa-external-link-square-alt text-lg"></i
                ></a>
              </template>
            </div>

            <div class="preview_images">
              <span v-html="preview.profile_th"></span>

              <div
                v-if="post.is_video"
                class="img play_icon instacover"
                :style="{
                  background:
                    'url(https://storage.googleapis.com/contentstudio-media-library-nearline/media_library/602a03c8a3f9461870212fd2/uncategorized/original/C5Nq95D2yp72sJ2p9GLT8rfXkpREkHFyuT3JaoL7.jpg)',
                  // 'url(https://instagram.com/p/' +
                  // post.short_code +
                  // '/media/?size=m)',
                }"
              >
                <i
                  class="fal fa-play-circle"
                  @click="showInstagramVideoAsset(post.asset_url)"
                ></i>

              </div>
              <div
                v-else
                :style="{ 'background-image': 'url(' + post.asset_url + ')' }"
                class="img"
              ></div>
            </div>

            <div
              class="sec_padding preview_desc"
              v-html="post.description.substring(0, 350) + '...'"
            ></div>
            <div class="social_stats">
              <div class="social_stats_inner d-flex">
                <div v-tooltip.top="'Likes'" class="stat_item">
                  <div class="name">
                    <p>
                      <i class="fal fa-heart"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.likes) }}</h3>
                  </div>
                </div>
                <div v-tooltip.top="'Comments'" class="stat_item">
                  <div class="name">
                    <p>
                      <i class="fal fa-comment"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.comments) }}</h3>
                  </div>
                </div>
                <div
                  v-if="post.is_video"
                  v-tooltip.top="'Video views'"
                  class="stat_item"
                >
                  <div class="name">
                    <p>
                      <i class="fal fa-eye"></i>
                    </p>
                  </div>
                  <div class="value">
                    <h3>{{ intToString(post.video_view_count) }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InfiniteLoading
          ref="infiniteLoading"
          direction="bottom"
          @infinite="infiniteHandler"
          ><span slot="spinner" class="pt-3 d-block">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
        </InfiniteLoading>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <p>No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, mapActions } from 'vuex'
import InstagramVideoPreview from './InstagramVideoPreview'
import {sanitizeUrl} from "@braintree/sanitize-url";

export default {
  components: { InstagramVideoPreview, InfiniteLoading },
  props: {
    posts: [],
    preview: {},
    platform: '',
  },
  data() {
    return {
      videoCode: null,
      videoHeight: null,
      videoWidth: null,
      loaderValue: false,
      notFound: false,
      asset_url: null,
      data: [],
      page: 1,
    }
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders']),
  },
  mounted() {},
  methods: {
    sanitizeUrl,
    ...mapActions(['fetchInfluencerPosts']),
    infiniteHandler(state) {
      if (this.platform == 'instagram') {
        this.fetchInfluencerPosts({
          screenName: this.preview.username,
          type: 'instagram',
          page: this.page,
        }).then((result) => {
          if (result.length == 0) {
            console.log('state completed')
            state.complete()
          } else {
            this.posts.push(...result)
            state.loaded()
            this.page += 1
          }
        })
      } else {
        state.complete()
      }
    },
    showInstagramVideoAsset(asset_url) {
      this.loaderValue = true
      this.$bvModal.show('influencerVideoPreview')

      this.asset_url = asset_url
      this.loaderValue = false
    },

    showInstagramVideoModal(shortCode) {
      this.loaderValue = true
      this.notFound = false
      this.videoCode = shortCode
      this.$bvModal.show('influencerVideoPreview')
      const url =
        'https://api.instagram.com/oembed?url=http://instagr.am/p/' +
        shortCode +
        '/'
      const self = this
      axios
        .get(url)
        .then((response) => {
          self.videoHeight = response.data.thumbnail_height
          self.videoWidth = response.data.thumbnail_width
          self.loaderValue = false
        })
        .catch((err) => {
          self.notFound = true
          console.debug('Instagram API', err)
        })
        .finally(() => {
          self.loaderValue = false
        })
    },
  },
}
</script>

<style lang="less">
.instagram_post_preview_main {
  width: 100% !important;
}

.instacover {
  background-size: cover !important;
  background-position: center !important;
}
</style>
