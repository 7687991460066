<script setup>
import { ref } from 'vue'
import PerformancePostPreviewModal from '@src/modules/analytics_v3/components/PerformancePostPreviewModal.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import {sanitizeUrl} from "@braintree/sanitize-url";

defineProps({
  selectedPost: {
    type: Object,
    default: () => ({}),
  },
  selectedAccount: {
    type: Object,
    default: () => ({}),
  },
})

const isLoading = ref(true)
const { getSocialImageRounded, tooltipHtml } = useComposerHelper()
const { postModalFields } = usePinterestAnalytics()

const onLoad = () => {
  isLoading.value = false
}
const handleModalShow = () => {
  isLoading.value = true
}
const handleModalHidden = () => {
  isLoading.value = true
}
</script>

<template>
  <PerformancePostPreviewModal
    :preview-post-data="selectedPost"
    :is-modal="false"
    :type="'post-details-modal'"
    @show="handleModalShow"
    @hidden="handleModalHidden"
  >
    <template v-slot:modal-title>
      <h4>Post Preview</h4>
    </template>
    <template v-slot:sidebar-title>
      <p class="font-weight-500 mb-[1rem]">Statistics</p>
    </template>
    <template v-slot:sidebar-content>
      <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
        <template v-for="({ iconSrc, label, key, tooltip }, index) in postModalFields" :key="index">
          <div class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]">
            <img
              :src="iconSrc"
              :alt="`${label} Icon`"
              class="w-6 h-6 my-auto"
            />
            <div class="flex flex-col ml-5">
              <span
              v-tooltip="{
                content: tooltipHtml(tooltip),
                theme: 'light',
                allowHTML: true,
              }"
               class="text-gray-800 group-hover:text-black-900"
               >
                {{ label }}
              </span>
              <span
                class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500 capitalize"
              >
                {{ selectedPost[key] }}
              </span>
            </div>
          </div>
        </template>

      </div>
    </template>
    <template v-slot:post-preview>
      <div class="flex justify-between items-center pb-3">
        <div class="flex justify-start items-center">
          <img
            :src="selectedAccount?.image"
            alt="Platform Logo"
            class="w-10 h-10 rounded-full"
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            "
          />
          <div
            class="flex flex-col ml-3 font-weight-500 text-black-900 select-none"
          >
            <p>{{ selectedAccount?.username }}</p>
            <p v-if="selectedPost?.board" class="text-gray-800 text-xs">{{ selectedPost?.board }}</p>

          </div>
        </div>
        <a :href="sanitizeUrl(selectedPost.link)" target="_blank" rel="noopener" >
          <img
            v-tooltip="{
              content: 'View post on pinterest',
            }"
            :src="getSocialImageRounded('pinterest')"
            alt="Platform Logo"
            class="h-8 w-8"
          />
        </a>
      </div>
      <p class="text-sm mt-3 whitespace-pre-line mb-0.5">
        {{ selectedPost.title }}
      </p>
      <p class="text-sm mt-3 whitespace-pre-line mb-0.5">
        {{ selectedPost.description }}
      </p>
      <div
        class="rounded-xl border !border-[#E9EFF6] flex flex-col justify-center items-center p-5 h-[54rem] media-card overflow-auto"
      >
        <clip-loader
          v-if="isLoading"
          class="spinner"
          :color="'#e0dfdf'"
          :size="'48px'"
        ></clip-loader>
        <iframe
          :src="selectedPost.embedLink"
          :style="{ border: 'none' }"
          :class="{
            isLoading: 'invisible',
          }"
          height="770"
          width="379"
          frameborder="0"
          scrolling="no"
          :onload="onLoad"
        />
      </div>
    </template>
  </PerformancePostPreviewModal>
</template>
