<template>
  <div class="feed_box d-flex align-items-start">
    <div class="feed_left">
      <div class="btn_block text-right">
        <div class="clearfix"></div>
        <v-menu
            placement="left"
            offset="5"
            :popper-triggers="['hover']"
            popper-class="tooltip-danger border-0"
        >
          <button class="btn btn_round mx-1 status-btn plan-status-btn" @click="EventBus.$emit('preview-plan', item._id)">
            <img
                width="16"
                height="16"
                :src="getPostStateImage(item)"
                :alt="`Status icon for ${item.post_state}`"
                class="status-icon"
            />
            <span class="d-none d-xl-inline">
        {{ getStatusText }}
      </span>

            <div class="ml-2 d-none d-xl-inline-block">
              <svg width="1rem" height="1rem" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="9" r="6" fill="#F2F4F6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0H11.0913C10.1527 0.529856 9.30821 1.20698 8.58927 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0ZM1 5.14286H6.65664C6.41267 5.78072 6.23214 6.44996 6.12277 7.14286H1C0.447715 7.14286 0 6.69514 0 6.14286C0 5.59057 0.447715 5.14286 1 5.14286ZM1 10.2857H6.12275C6.23211 10.9786 6.41263 11.6478 6.65659 12.2857H1C0.447715 12.2857 0 11.838 0 11.2857C0 10.7334 0.447715 10.2857 1 10.2857ZM1 15.4286H8.58914C9.30805 16.2216 10.1525 16.8987 11.0911 17.4286H1C0.447715 17.4286 0 16.9809 0 16.4286C0 15.8763 0.447715 15.4286 1 15.4286ZM16.4358 3.49561C13.5475 3.49561 11.2061 5.83704 11.2061 8.72535C11.2061 11.6137 13.5475 13.9551 16.4358 13.9551C19.3241 13.9551 21.6655 11.6137 21.6655 8.72535C21.6655 5.83704 19.3241 3.49561 16.4358 3.49561ZM9.20605 8.72535C9.20605 4.73247 12.4429 1.49561 16.4358 1.49561C20.4287 1.49561 23.6655 4.73247 23.6655 8.72535C23.6655 10.4698 23.0477 12.0699 22.019 13.3189L24.602 15.9019C24.9925 16.2924 24.9925 16.9256 24.602 17.3161C24.2115 17.7066 23.5783 17.7066 23.1878 17.3161L20.5457 14.6741C19.3788 15.4819 17.9625 15.9551 16.4358 15.9551C12.4429 15.9551 9.20605 12.7182 9.20605 8.72535Z" fill-opacity="0.7" fill="#000000"/>
              </svg>
            </div>
          </button>

          <template v-slot:popper>
            <div v-if="item.render_class && item.render_class.tooltip" class="p-2">
              <div>
                <p class="font-0-75rem">
                  This post is <strong>{{ item.render_class.tooltip }}</strong>

                  <template
                      v-if="
                (item.render_class.tooltip === 'Scheduled' ||
                  item.render_class.tooltip === 'Published') &&
                hasExecutionTime
              "
                  >
                    on
                    {{ getWorkspaceTimeZoneTime(
                      item.execution_time.date,
                      getAccountDateTimeFormat,
                  )
                    }}
                  </template>
                </p>
                <p class="font-0-75rem">Please click here to see details</p>
              </div>
            </div>
          </template>
        </v-menu>
      </div>

      <div class="feed-view-side-option">
        <i
            v-if="canEditPost(item)"
            v-tooltip.left="'Edit'"
            class="action_icon feed icon-edit-cs"
            @click.prevent="editPlan(item)"
        ></i>

        <i
            v-if="item.can_perform.duplicate"
            v-tooltip.left="'Duplicate'"
            class="action_icon feed far fa-clone"
            @click.prevent="clonePlan(item)"
        ></i>
        <div class="flex items-center gap-2 flex-row-reverse">
          <i
              v-if="item.can_perform.delete"
              v-tooltip.left="'Delete'"
              class="action_icon feed icon-delete-cs"
              @click.prevent="removePlan(item)"
          ></i>
          <!-- Loader -->
          <div
              v-if="isProcessing"
              class="mt-3"
          >
            <clip-loader
                :size="'15px'"
                :color="'#4165ed'"
            ></clip-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="feed_center">
      <div
          v-if="item.content_category || getItemTag"
          class="top_tags_category bb-0 pb-0"
          style="pointer-events: auto"
      >
        <div
            v-if="item.content_category"
            v-tooltip.top="'Content Category'"
            class="top-category"
            :style="{ 'background-color': item.content_category.color_code }"
        >{{ item.content_category.name }}
        </div>
      </div>
      <template v-if="item?.feed_preview?.channel !== ''">
        <div class="social_accounts_list">
          <div class="row">
            <div class="col-lg-12 py-0">
              <template v-if="item.blog_selection">
                <template v-for="(integration, key) in integrations" :key="key">
                  <template v-if="hasSpecificBlogSelection(integration)">
                    <PlatformItem
                        :item="item"
                        :type="integration"
                    ></PlatformItem>
                  </template>
                </template>
              </template>
              <FeedAccountListing
                  v-if="item.account_selection"
                  :accounts="getPlatforms"
                  :channels_class="'text-is-left-only'"
                  :channels_size="'34px'"
                  :item="item"
              ></FeedAccountListing>
            </div>
          </div>
        </div>
      </template>

      <div
          v-if="typeof item.labels !== 'undefined' && item.labels.length"
          class="top_tags_category bb-0 pb-0 flex-wrap gap-y-2"
          style="pointer-events: auto"
      >
        <template v-for="(label, key) in item.labels" :key="key">
          <div class="tags_block">
            <div
                v-if="getLabelById(label)"
                v-tooltip.top="'Label'"
                class="tag"
                :class="[getLabelById(label).color]"
            >{{ getLabelById(label).name }}
            </div>
          </div>
        </template>
      </div>

      <div class="social_preview_feed social_preview_no_tab">
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview?.channel === ''
          "
        >
          <NoSocialPreview
              :detail="getFeedSharingDetail(item, 'facebook')"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'facebook'
          "
        >
          <FacebookPreview
              :execution-time="item.execution_time"
              :common-box-status="item.common_box_status"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'facebook')"
              :publish-as="getFacebookPublishAs(item)"
              :carousel="item.carousel_options ? item.carousel_options : null"
              :carousel-account="getCarouselAccounts(item)"
              :facebook-post-type="getFacebookPostType(item)"
              :toggle-preview="true"
              type="feed_view"
              v-bind="$attrs"
              :facebook-share-to-story="item?.facebook_options?.facebook_share_to_story ?? false"
              :facebook-background-id="item?.facebook_options?.facebook_background_id ?? ''"
              :locations="item?.locations"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'twitter'
          "
        >
          <TwitterPreview
              :execution-time="item.execution_time"
              :common-box-status="item.common_box_status"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'twitter')"
              :thread="getThreadedTweets(item)"
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'linkedin'
          "
        >
          <LinkedinPreview
              :execution-time="item.execution_time"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :linkedin-options="item.linkedin_options"
              :detail="getFeedSharingDetail(item, 'linkedin')"
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'pinterest'
          "
        >
          <PinterestPreview
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :followers-count="180"
              :detail="getFeedSharingDetail(item, 'pinterest')"
              source-url=""
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'instagram'
          "
        >
          <InstagramPreview
              :common-box-status="item.common_box_status"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'instagram')"
              :instagram-post-type="item.instagram_post_type"
              :toggle-preview="true"
              :hide-footer="true"
              :comment="''"
              :user-tags="
              getFeedSharingDetail(item, 'instagram').image_tagging_details
            "
              :instagram-share-to-story="item?.instagram_share_to_story || false"
              type="feed_view"
              v-bind="$attrs"
              :instagram-collaborators="item?.instagram_collaborators || []"
              :locations="item?.locations"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'youtube'
          "
        >
          <YoutubePreview
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'youtube')"
              :video-title="youtubeOptionsByType(item, 'title')"
              :youtube-post-type="youtubeOptionsByType(item, 'postType')"
              :comment="''"
              type="feed_view"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'tumblr'
          "
        >
          <TumblrPreview
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'tumblr')"
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'gmb'
          "
        >
          <GmbPreview
              :execution-time="item.execution_time"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'gmb')"
              :cta="getGMBOptions(item, 'cta')"
              :event-title="getGMBOptions(item, 'title')"
              :event-date="
              getDateTimeFormat(getGMBOptions(item, 'start_date'), 'MMM DD') +
              ' - ' +
              getDateTimeFormat(getGMBOptions(item, 'end_date'), 'MMM DD')
            "
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'tiktok'
          "
        >
          <TikTokPreview
              type="feed_view"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'tiktok')"
              :tiktok-options="item?.tiktok_options"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'threads'
          "
        >
          <ThreadsPreview
              type="feed_view"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'threads')"
              :multi-threads="[...getMultiThreads]"
          />
        </template>
        <template
            v-if="
            item.feed_preview &&
            item.feed_preview.channel &&
            item.feed_preview.channel === 'bluesky'
          "
        >
          <BlueskyPreview
              :execution-time="item.execution_time"
              :common-box-status="item.common_box_status"
              :account="
              getFreshAccount(
                item.feed_preview.channel,
                item.feed_preview.account,
              )
            "
              :detail="getFeedSharingDetail(item, 'bluesky')"
              :thread="getBlueskyTweets(item)"
              type="feed_view"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </template>
      </div>

      <div
          v-if="item.blog_reference && item.type !== 'Social Content'"
          class="blog_preview_block"
      >
        <div class="blog_preview_inner">
          <template
              v-if="
              item.post &&
              (item.post.description || item.post.image.link || item.post.title)
            "
          >
            <div
                v-if="item.post.image.link || item.post.title"
                class="post_head m-0 p-0 bb-0"
            >
              <img
                  v-if="item.post.image.link"
                  class="featured_img"
                  :src="item.post.image.link"
                  alt=""
              />
              <span
                  v-if="item.post.title"
                  class="text-xl"
                  v-html="item.post.title"
              ></span>
            </div>
            <div v-if="item.post.description" class="post_description d-none">
              <div
                  class="curationCuratedDescription"
                  v-html="item.post.description"
              ></div>
            </div>
          </template>
          <template v-else>
            <div class="no_blog_content">
              <!--<img src="/img/no_block_content.png" alt="">-->
              <p class="bold">No content added yet.</p>
              <p class="light">Please write some content to get preview.</p>
            </div>
          </template>
        </div>
      </div>


    </div>
    <div class="feed_right">
      <div v-show="toggleStatus">
        <div
            v-if="item.status === 'review' || item.status === 'draft'"
            class="top_right_btn mb-0 feed_view_action_button"
        >
          <!-- automation case-->
          <template v-if="item.status === 'review' && !item.approval">
            <template v-if="item.can_perform.approval">
              <div
                  class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown"
              >
                <button
                    v-tooltip.top="'Approve'"
                    :disabled="item.is_processing"
                    type="button"
                    class="btn_click btn"
                    @click.prevent="
                    changePlanStatusMethod('scheduled', item, true)
                  "
                >Approve
                </button>
              </div>
              <div
                  class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown feed_toggle_dropdown_reject ml-2"
              >
                <button
                    v-tooltip.top="'Reject'"
                    :disabled="item.is_processing"
                    type="button"
                    class="btn_click btn"
                    @click.prevent="
                    changePlanStatusMethod('rejected', item, true)
                  "
                >Reject
                </button>
              </div>
            </template>
          </template>

          <template
              v-else-if="
              item.approval &&
              item.approval.status === 'pending_approval' &&
              checkApprovalStatus(item.approval)
            "
          >
            <div
                class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown"
            >
              <button
                  type="button"
                  class="btn_click btn"
                  :disabled="item.is_processing"
                  @click.prevent="callProcessPlanApproval(item, 'approve')"
              >Approve
              </button>
              <button
                  type="button"
                  class="btn_arrow btn dropdown-toggle dropdown-toggle-split"
                  :disabled="item.is_processing"
                  data-toggle="dropdown"
              >
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div
                    class="list_item"
                    @click.prevent="addCommentStatus('approve')"
                >Approve with comment
                </div>
              </div>
            </div>
            <div
                class="btn_dropdown btn-group default_style_dropdown feed_toggle_dropdown feed_toggle_dropdown_reject ml-2"
            >
              <button
                  type="button"
                  class="btn_click btn"
                  :disabled="item.is_processing"
                  @click.prevent="callProcessPlanApproval(item, 'reject')"
              >Reject
              </button>
              <button
                  type="button"
                  class="btn_arrow btn dropdown-toggle dropdown-toggle-split"
                  :disabled="item.is_processing"
                  data-toggle="dropdown"
              >
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div
                    class="list_item"
                    @click.prevent="addCommentStatus('reject')"
                >Reject with comment
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="comments-list flex flex-col min-w-[25rem] w-[27rem]">
            <CommentsAndNotes
                :key="item._id"
                :plan="item"
                :feed-view="true"
                :is-client="clientProfile"
            ></CommentsAndNotes>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlatformIdentifierValue } from '@common/lib/integrations'
import usePlannerHelper  from "@src/modules/planner_v2/composables/usePlannerHelper.js"
import {
  getStatusClass,
  itemSelectedPlatformVisibleStatus,
  planHeadAttachment,
  planHeadText,
} from '@common/lib/planner'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import { parseDescription } from '@common/lib/helper'
import { mapActions, mapGetters } from 'vuex'
import {socialChannelsNameArray, swalAttributes} from '@common/constants/common-attributes'
import { EventBus } from '@common/lib/event-bus'
import { planner } from '@src/modules/planner/store/mutation-types'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import useDateFormat from "@common/composables/useDateFormat";
import FacebookPreview from '@src/modules/composer_v2/components/SocialPreviews/FacebookPreview'
import NoSocialPreview from '@src/modules/composer_v2/components/SocialPreviews/NoSocialPreview'
import TwitterPreview from '@src/modules/composer_v2/components/SocialPreviews/TwitterPreview'
import InstagramPreview from '@src/modules/composer_v2/components/SocialPreviews/InstagramPreview'
import YoutubePreview from '@src/modules/composer_v2/components/SocialPreviews/YoutubePreview'
import LinkedinPreview from '@src/modules/composer_v2/components/SocialPreviews/LinkedinPreview'
import PinterestPreview from '@src/modules/composer_v2/components/SocialPreviews/PinterestPreview'
import TumblrPreview from '@src/modules/composer_v2/components/SocialPreviews/TumblrPreview'
import GmbPreview from '@src/modules/composer_v2/components/SocialPreviews/GmbPreview'
import ThreadsPreview from '@src/modules/composer_v2/components/SocialPreviews/ThreadsPreview'
import TikTokPreview from '@src/modules/composer_v2/components/SocialPreviews/TikTokPreview'
import Emoji from '@src/modules/publish/components/posting/social/emoji/Emoji'
import { commonMethods } from '@common/store/common-methods'
import PlatformItem from '../PlatformItem'
import FeedViewComment from './FeedViewComment'
import FeedViewApprovalStatus from './FeedViewApprovalStatus'
import FeedAccountListing from './FeedAccountListing'
import 'linkify-plugin-hashtag'
import isEmpty from "is-empty";
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
import CommentsAndNotes from "@modules/planner_v2/components/CommentsAndNotes.vue";
import usePlannerActions from "@modules/planner_v2/composables/usePlannerActions";
import BlueskyPreview from "@modules/composer_v2/components/SocialPreviews/BlueskyPreview.vue";

const importedMethods = {
  getStatusClass,
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformVisibleStatus,
  parseDescription,
  getPlatformIdentifierValue,
}

export default {
  components: {
    BlueskyPreview,
    FeedViewComment,
    PlatformItem,
    AtTa,
    FeedAccountListing,
    FeedViewApprovalStatus,
    Emoji,
    InstagramPreview,
    YoutubePreview,
    FacebookPreview,
    NoSocialPreview,
    TwitterPreview,
    LinkedinPreview,
    PinterestPreview,
    TumblrPreview,
    GmbPreview,
    TikTokPreview,
    ThreadsPreview,
    CommentsAndNotes
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  emits: ['remove-plan','change-status-with-comment'],
  setup(props){
    const {momentWrapper, getAccountDateTimeFormat} = useDateFormat()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    const {getPostStateImage,fetchPlanAccounts} = usePlannerHelper()
    const {clientProfile} = usePlannerActions(props.item)
    return {getPostStateImage,fetchPlanAccounts, momentWrapper, openDraftComposer, draftPlanId,clientProfile, getAccountDateTimeFormat}
  },
  data() {
    return {
      integrations: blogIntegrationsNames,
      messageType: 'message',
      members: this.getActiveWorkspaceMembersDetails,
      file_uploading: false,
      toggleStatus: true,
      commentActionStatus: '',
      isProcessing: false,
    }
  },
  computed: {
    EventBus() {
      return EventBus
    },
    getStatusText() {
      return this.item.partially_failed
          ? 'Partially Failed'
          : this.capitalizeFirstLetter(this.item.render_class.tooltip);
    },
    ...mapGetters([
      'getActiveWorkspaceMembersDetails',
      'getActiveWorkspaceMembersName',
      'getActiveWorkspaceMembersIds',
    ]),
    getPlatforms() {
      if (!this.item.account_selection) return {};

      return socialChannelsNameArray.reduce((acc, channel) => {
        if (this.item.account_selection[channel]) {
          acc[channel] = this.item.account_selection[channel];
        }
        return acc;
      }, {});
    },
    getMultiThreads() {
      return this.item.threads_options?.has_multi_threads ? this.item.threads_options.multi_threads : []
    },
    /**
     * Fetches sorted comment from external comment and actions
     * @type {ComputedRef<*[]>}
     */
    getSortedComments() {

      const mergedComments = [];

      // Merge all external comments
      if (this.item.external_comments?.length > 0) {
        mergedComments.push(...this.item.external_comments);
      }

      // Merge all internal comments
      if (this.item.comments?.length > 0) {
        mergedComments.push(...this.item.comments);
      }

      // Merge all external actions comments
      if (this.item.external_actions?.length > 0) {
        this.item.external_actions.forEach((action) => {
          if (action.comment) {
            mergedComments.push(action);
          }
        });
      }

      // Sort the merged comments by time created
      mergedComments.sort((a, b) => {
        const timeA = this.momentWrapper(a.created_at).formatDateTime();
        const timeB = this.momentWrapper(b.created_at).formatDateTime();
        return timeB.localeCompare(timeA);
      });

      return mergedComments
    },
    getFeedViewCardMembers() {
      if (this.messageType === 'message')
        return this.getActiveWorkspaceMembersDetails
      return this.getActiveWorkspaceClientMembersDetails
    },
    isTwitterPosting() {
      return this.item.feed_preview.channel === 'twitter'
    },
    isLinkedinPosting() {
      return this.item.feed_preview.channel === 'linkedin'
    },
    isPinterestPosting() {
      return this.item.feed_preview.channel === 'pinterest'
    },
    isInstagramPosting() {
      return this.item.feed_preview.channel === 'instagram'
    },
    getPlatformType() {
      return this.item.feed_preview.channel === 'tumblr' &&
      this.item.feed_preview.account
          ? 'tumblr_account'
          : this.item.feed_preview.channel
    },
    getItemTag() {
      if (this.item.evergreen_automation_id) return 'Evergreen'
      if (this.item.rss_automation_id) return 'RSS'
      if (this.item.repeat_post) return 'Repeat'
      return ''
    },
    getPlanAttachment() {
      const attachment = planHeadAttachment(this.item)
      if (attachment.url) {
        return attachment.type === 'image'
            ? attachment.url
            : attachment.thumbnail
      }
      return false
    },
    isPublishedPost() {
      return getStatusClass(this.item) === 'published'
    },
    isFailedPost() {
      return getStatusClass(this.item) === 'failed'
    },
    getAddedByName() {
      return this.item.added_by
    },
    hasExecutionTime() {
      return this.item.execution_time
    },
    isActionProcessing() {
      return this.item.is_processing
    },
    changePlanStatusActionVisibility() {
      return (
          getStatusClass(this.item) === 'reviewed' &&
          this.item.permissions.change_status
      )
    },
    getFirstAccountImage() {
      if (this.item.account_selection) {
        let itemValue
        Object.entries(this.item.account_selection).find((item) => {
          console.log(item)
          itemValue = item[1].find((itemToFind) => itemToFind.image)
          if (itemValue) return itemValue
        })
        return !itemValue
            ? 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            : itemValue
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    getFirstBlogImage() {
      if (this.item.blog_selection) {
        let itemValue
        Object.entries(this.item.blog_selection).find((item) => {
          console.log(item, item[1])
          if (item[1].website) {
            itemValue = item
          }
        })
        return !itemValue
            ? 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            : itemValue
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },
    getActiveWorkspaceClientMembersDetails() {
      const members = []
      this.getActiveWorkspaceMembersDetails.filter(function (member) {
        if (!member.membership || member.membership !== 'client')
          members.push(member)
      })
      return members
    },
  },

  mounted() {
    EventBus.$on('toggleStatus:' + this.item._id, (data) => {
      this.toggleStatus = false
    })
  },
  beforeUnmount() {
    EventBus.$off('toggleStatus:' + this.item._id)
  },
  methods: {
    ...importedMethods,
    ...mapActions(['changePlanStatus']),

    hideFromClientMessage(item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    addCommentStatus(type) {
      this.commentActionStatus = type
      this.item.new_comment = ''


      if (
          this.item.status === 'review' &&
          type === 'approve' &&
          this.item.execution_time?.date <
          this.momentWrapper().formatDateTime()
      ) {
        this.initializeMissedReviewedDatePicker(this.item)
      } else {
        this.$emit('change-status-with-comment', this.item, type)
        // this.toggleStatus = false
      }
    },

    hasSpecificBlogSelection(type) {
      return (
          this.item.blog_selection[type] && this.item.blog_selection[type].website
      )
    },

    async allowWritingComment() {
      this.item.write_comment = true
      this.item.new_comment = ''
      this.messageType = 'message'
      // $nextTick() used due to conditional render of textarea
      await this.$nextTick()
      this.$refs.ed.focus()
    },

    hideCommentBox(event) {
      if (this.item.write_comment && event.target.className !== 'comment-text' && event.target.className !== 'text' && event.target.className !== 'img') {
        this.item.write_comment = false
      }
      if(event.target.className ==="mention-dropdown-li profile_picture"){
        this.item.write_comment = true
      }
    },

    disallowWritingComment() {
      this.item.write_comment = false
      this.item.new_comment = ''


      this.messageType = 'message'
      this.toggleStatus = true
    },

    triggerAt() {
      const { ed } = this.$refs
      ed.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },

    getFeedSharingDetail(item, type) {
      if (!item.common_box_status) {
        const typeMapping = {
          facebook: item.facebook_sharing_details,
          twitter: item.twitter_sharing_details,
          linkedin: item.linkedin_sharing_details,
          pinterest: item.pinterest_sharing_details,
          instagram: item.instagram_sharing_details,
          youtube: item.youtube_sharing_details,
          tumblr: item.tumblr_sharing_details,
          gmb: item.gmb_sharing_details,
          tiktok: item.tiktok_sharing_details,
          bluesky: item?.bluesky_sharing_details ?? {},
          threads: item?.threads_sharing_details ?? {},
        };

        return typeMapping[type] || item.common_sharing_details;
      }

      return item.common_sharing_details;
    },

    getFacebookPublishAs(item) {
      if (item.feed_preview.account.type === 'Group') {
        return commonMethods.getFBGroupPostedAsAccount(
            this.findFacebookAccount(
                this.getFreshAccount(
                    item.feed_preview.channel,
                    item.feed_preview.account
                ).facebook_id
            ),
            item.facebook_options?.posted_as
                ? item.facebook_options.posted_as
                : false
        )
      }
      return null
    },
    getCarouselAccounts(item) {
      return item.carousel_options?.accounts.includes(
          item.feed_preview.account.facebook_id
      )
          ? this.getFreshAccount(
              item.feed_preview.channel,
              item.feed_preview.account
          )
          : null
    },
    getGMBOptions(item, type) {
      switch (type) {
        case 'cta':
          return item.gmb_options?.action_type
              ? item.gmb_options.action_type
              : ''

        case 'title':
          return item.gmb_options?.title ? item.gmb_options.title : ''

        case 'start_date':
          return item.gmb_options?.start_date ? item.gmb_options.start_date : ''

        case 'end_date':
          return item.gmb_options?.end_date ? item.gmb_options.end_date : ''
      }
      return ''
    },
    youtubeOptionsByType(item, type) {
      switch (type) {
        case 'title':
          return item.youtube_options?.title ? item.youtube_options.title : ''
        case 'postType':
          return item.youtube_options?.post_type
              ? item.youtube_options.post_type
              : ''
      }
      return ''
    },
    getThreadedTweets(item) {
      return item.twitter_options?.threaded_tweets
          ? item.twitter_options.threaded_tweets
          : []
    },
    getBlueskyTweets(item) {
      return item.bluesky_options?.has_multi_bluesky
          ? item.bluesky_options.multi_bluesky
          : []
    },
    getFacebookPostType(item) {
      return item.facebook_options?.post_type
    },
    findFacebookAccount(facebookId) {
      return this.getFacebookAccounts.items.find(
          (x) => x.facebook_id === facebookId
      )
    },
    capitalizeFirstLetter(string) {
      return string.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
      });
    },
    async addCommentAction(item, notification = false) {
      console.debug('addCommentAction', item)

      if (!item.new_comment || item.new_comment.trim().length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      }
      this.item.load_store = true


      const payload = {
        workspace_id: item.workspace_id,
        plan_id: item._id,
        comment: item.new_comment,
        mentioned_user: this.mentionedUserIdsList(item.new_comment),
        type: this.$route.name,
        title: item.title || null,
        is_note: this.messageType === 'note',
        media: this.item.new_comment_media ? this.item.new_comment_media : [],
        notification,
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)
      this.item.load_store = false

      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          if (!item.comments) item.comments = []
          // item.comments.unshift(res.data.comment)
          item.new_comment = ''
          item.write_comment = false
          item.new_comment_media = []
          this.messageType = 'message'
          // fallback to pusher
          if (
              res.data.comment._id &&
              !item.comments.find((item) => item._id === res.data.comment._id)
          ) {
            item.comments.unshift(res.data.comment)
          }
          // this.dispatchCommentSocket(res.data.comment,item._id,'add')
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
        this.toggleStatus = true
      }
    },

    async uploadCommentImage(event) {
      this.file_uploading = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.file_uploading = false
      if (res) {
        if (res.data.status) {
          if (this.item.new_comment_media) {
            this.item.new_comment_media.push(res.data.media)
          } else {
            this.item.new_comment_media = [res.data.media]

          }
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },

    async callProcessPlanApproval(plan, status, comment = false) {
      const res = await this.processPlanApproval(plan, status, comment)
      if (res && comment) await this.addCommentAction(plan, true)
    },

    async editPlan(item) {
      console.debug('Method::editPlan', item)
      const res = await this.openDraftComposer(
          '⚠️ Unfinished Post is in the Composer!',
          'You have a post currently in the composer that is minimized. What would you like to do?',
          'Save & Edit Selected',
          'Return to Composer'
      )
      if(res === null) {

      } else if(!res) {
        EventBus.$emit('reset-composer-data')
        item.stateObject = this
        this.$store.dispatch('editPlan', item)
      } else {
        const workspace = this.$route.params.workspace
        history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
        this.$bvModal.show('composer-modal')
      }
    },
    async removePlan(plan) {

      if (plan?.post_state === 'published' && !plan?.blog_reference) {
        this.isProcessing = true
        await this.fetchPlanAccounts(plan?._id)
        this.$bvModal.show('delete-post-modal')
        this.isProcessing = false
        return
      }
      const res = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to delete your post?',
          {
            title: 'Remove Post',
            ...swalAttributes(),
          }
      )
      if (res) {
        const response = await this.$store.dispatch('removePlan', { id: plan?._id })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your post, please try again.',
            type: 'error',
          })
        } else {
          this.$emit('remove-plan', plan?._id)
        }
      }
    },
    async clonePlan(item) {
      console.debug('Method::clonePlan', item)
      const res = await this.openDraftComposer(
          '⚠️ Unfinished Post is in the Composer!',
          'You have a post currently in the composer that is minimized. What would you like to do?',
          'Save & Create Duplicate',
          'Return to Composer'
      )
      if(res === null) {

      } else if(!res) {
        EventBus.$emit('reset-composer-data')
        item.stateObject = this
        this.$store.dispatch('clonePlan', item)
      } else {
        const workspace = this.$route.params.workspace
        history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
        this.$bvModal.show('composer-modal')
      }
    },
    replacePlan(plan) {
      this.$store.commit(planner.SET_CONFIRMATION_PLAN, plan)
      this.$bvModal.show('replacePlan')
    },
    displayFile(type, media, index) {
      console.debug('Method::displayFile', type, media, index)
      EventBus.$emit('displayFile', { type, media, index })
      this.$bvModal.show('display-file-modal')
    },
    /**
     * Method to update the comments list
     */
    updateCommentsList(event) {

      switch(event.action) {

        case 'delete':
          this.item.comments = this.item.comments.filter(
              (commentItem) => commentItem._id !== event.comment_id
          )
          break

        case 'set_comment_fields':
          this.item.comments = this.item.comments.map((commentItem) => {
            if(commentItem._id === event.comment_id) {
              commentItem.new_comment = event.new_comment
              commentItem.can_update_comment = event.can_update_comment
            }
            return commentItem
          })
          break

        case 'update_comment':
          this.item.comments = this.item.comments.map((commentItem) => {
            if(commentItem._id === event.comment_id) {
              commentItem.new_comment = ''
              commentItem.can_update_comment = false
              commentItem.comment = event.updated_comment
            }
            return commentItem
          })
          break

        case 'update_media':
          this.item.comments = this.item.comments.map((commentItem) => {
            if(commentItem._id === event.comment_id) {
              commentItem.media.push(event.media)
            }
            return commentItem
          })
          break

        default:
          break
      }
    }
  },
}
</script>

<style lang="less">
.is_comments_tab_hide {
  .nav-tabs {
    display: none !important;
  }
}

.planner_component .planner_feed_view .feed_box .feed_left {
  min-width: 200px;
  width: 200px;
}

// there was a gap coming to the below items and due to that we have set the margin-bottom to 0px;
.feed_right {
  .top_right_btn {
    margin-bottom: 8px !important;
  }
}

.feed-view-side-option {
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: flex-end;
}

.feed-view-options {
  text-align: right;
  padding: 12px;
  position: absolute;
  i {
    cursor: pointer;
    color: #7d7d7d;
    padding: 0px 4px;
  }
  .dropdown-menu.show {
    box-shadow: 5px 3px 5px 2px #80808017;
  }
  .dropdown-menu {
    ul {
      text-align: left;
      li {
        padding: 8px 0.875rem;
        &:hover {
          background-color: #f5f9fc;
          cursor: pointer;
        }
        a {
          color: black;
          font-size: 15px;
          text-align: left;
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
.bb-0 {
  border-bottom: 0 !important;
}

.planner_component
.planner_feed_view
.feed_box
.feed_right
.comment_block
.edit_input
.profile_picture
.text_block
.btn_block
.btn[disabled='disabled']
span {
  color: #fff !important;
}
.w-100p {
  width: 100px;
}

i.partially_failed {
  transform: rotate(270deg);
  color: #c00000;
}

@media (max-width: 1340px) {
  .d-xl-inline,
  .d-xl-inline-block {
    display: none !important;
  }
  .plan-status-btn{
    width: auto !important;
    padding: 6px 8px !important;
    margin-left: 0.6rem !important;
  }
  .feed_left {
    width: auto !important;
    min-width: unset !important;
    padding-right: 10px !important;
  }
}
</style>
