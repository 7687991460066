<template>
  <div
      class="cst-editor-threaded-tweets color-border rounded-xl px-3 py-3 mt-5 p-relative bg-white"
  >
    <div class="flex items-center">
      <div class="w-48 ml-1 flex items-center">
        <div
            class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg "
        >
          <img
              src="../../../../assets/img/integration/threads-rounded.svg"
              alt="threads"
              class="w-[1.4rem] h-[1.4rem]"
          />
        </div>
        <span class="mx-2 font-medium text-sm text-black-300"
        >Multi Meta Threads</span
        >
        <v-menu
            :popper-triggers="['hover']"
            placement="bottom-end"
            popper-class="threaded-tweets__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
          ><img
              src="../../../../assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Multi Meta Threads are available for: </div>
            <ul>
              <li> <img
                  src="../../../../assets/img/integration/threads-rounded.svg"
                  alt="threads"
                  class="w-[1.5rem] h-[1.5rem] mr-2"
              /> Profiles </li>
            </ul>
            <p class="threaded-tweets__message-box"
            >How to add multi meta threads?
              <a
                  v-close-popover="true"
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="66a1de2f16e6a80d1e1d0760"
              >Click here</a
              ></p
            >
          </template>
        </v-menu>
      </div>
      <div>
        <CstSwitch
            v-model="threadsOptions.has_multi_threads"
            class="mx-2"
            :disabled="accounts.length < 1"
            size="small"
        />
      </div>
      <div class="ml-auto flex items-center gap-x-2">
        <div v-for="(account, index) in accounts" :key="index">
          <SocialProfile
              :platform-logo="account.platform_logo"
              badge-src="threads-rounded.svg"
              :selected="true"
          />
        </div>
      </div>
    </div>

    <div class="mt-1">
      <draggable
          v-model="threadsOptions.multi_threads"
          :list="threadsOptions.multi_threads"
          handle=".handle"
          @start="sortStart"
          @end="sortEnd"
      >
        <template v-slot:item="{ element: tweet, index }">
          <div
              class="mt-3 p-relative flex"
              :class="{ 'opacity-0': index === focusElement }"
          >
              <span
                  v-show="threadsOptions.multi_threads.length > 1"
                  class="handle flex flex-col justify-center text-sm cursor-move bg-cs-secondary rounded-l-lg text-black-100 border-0 px-2 w-max"
              >
                <img src="../../../../assets/img/icons/sorting_gray.svg" width="12" />
              </span>
            <LiteEditorBox
                :tweet-index="index"
                :account-type="accountType"
                :widgets="getEditorSettings.widgets"
                :max-limit="getEditorSettings.maxLimit"
                :twitter-text-enable="getEditorSettings.twitterTextEnable"
                :sharing-details="tweet"
                :accounts-data="accountsData"
                :apply-hashtag="applyHashtag"
                type="multi-threads"
                v-bind="$attrs"
                v-on="$listeners"
                @onEditorBoxTextChange="
                  (type, message, count) =>
                    onEditorBoxTextChange(type, message, index, count)
                "
                @initializeVideoUpload="(type) => initializeVideoUpload(index)"
                @addMedia="
                  (type, media, mediaType) => addMedia(index, media, mediaType)
                "
                @removeMedia="
                  (type, mediaType, media, removeAll) =>
                    handleRemoveMedia(index, mediaType, media, removeAll)
                "
                @handle-change="
                  (thread, type) => onChangeThreadDetails(thread, index)
                "
                @handle-lite-editor-hashtags="
                  (flag, data) =>
                    handleThreadedTweetHashtagsChanges(index, flag, data)
                "
                @set-link-preview="(preview, payload) => setLinkPreview(index, preview, payload)"
                @remove-link-preview="(type, removeImg, trigger) => removeLinkPreview(index, type, removeImg, trigger)"
                @remove-threaded-tweet="removeMultiThreads"
            />
          </div>
        </template>
      </draggable>
    </div>
    <div class="my-4 flex items-center">
      <div>
          <span
              v-tooltip="isMultiThreadsLimitReached ? 'You can add up to 10 threads' : 'Add another thread'"
              :class="{'opacity-50 cursor-not-allowed': isMultiThreadsLimitReached}"
              class="ml-0.5 text-sm cursor-pointer rounded-lg bg-cs-secondary text-black-100 border-0 px-2 py-2"
              @click.prevent="addMultiThreads"
          >
            <i class="fas fa-plus before:text-black-100 mr-1"></i>
            Add another thread
          </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defaultThreadsOptions,
} from '@modules/composer_v2/views/composerInitialState'

import {threadsPostLimit} from '@modules/publish/config/api-utils'
import LiteEditorBox from '@modules/composer_v2/components/EditorBox/LiteEditorBox.vue'
import CstSwitch from '@ui/Switch/CstSwitch.vue'
import draggable from 'vuedraggable'
import {EventBus} from "@common/lib/event-bus";
import SocialProfile from "@common/components/SocialProfile.vue";
const clone = require('rfdc/default')

export default {
  name: 'EditorMultiThreadsBox',
  components: {
    SocialProfile,
    LiteEditorBox,
    CstSwitch,
    draggable,
  },
  props: {
    threadsOptions: {
      type: Object,
      default: () => {
        return { ...defaultThreadsOptions }
      },
    },
    accountType: {
      type: String,
      default: '',
    },
    accounts: {
      type: Array,
      default: () => {
        return []
      },
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      focusElement: null,
      threadsList: this.threadsOptions?.multi_threads || [],
    }
  },

  computed: {
    getEditorSettings() {
      return {
        maxLimit: threadsPostLimit,
      }
    },
    isMultiThreadsLimitReached() {
      return this.threadsOptions.multi_threads.length >= 10
    },
  },

  mounted() {
    console.debug('Threads mounted')
    EventBus.$on(
        'add-media-from-media-library-multi-threads',
        ({type, media, mediaType, threadIndex}) => {
          console.debug(
              'add-media-from-media-library-multi-threads',
              type,
              media,
              mediaType,
              threadIndex
          )
          this.addMedia(threadIndex, media, mediaType)
        }
    )
  },

  beforeUnmount() {
    EventBus.$off('add-media-from-media-library-multi-threads')
  },

  methods: {
    sortStart(event) {
      this.focusElement = event.oldIndex
    },
    sortEnd(event) {
      this.focusElement = null
    },
    /**
     * @description Add another threaded tweet
     */
    addMultiThreads() {

      if(this.isMultiThreadsLimitReached) {
        return
      }

      this.$emit('addMultiThreads')
    },

    /**
     * @description Close or remove a threaded tweet
     * @param {number} index
     */
    removeMultiThreads(index) {
      this.$emit('removeMultiThreads', index)
    },
    /**
     * @description Event handlers on Editor Box Text Change for Threaded Tweets
     * @param type {string} type of editor (common, facebook, twitter, instagram, linkedin, youtube, pinterest, gmb, tumblr, tiktok)
     * @param message {string} message
     * @param index {int} thread index
     * @param count character count
     */
    onEditorBoxTextChange: function (type, message, index, count) {
      const thread = clone(this.threadsOptions.multi_threads[index])
      thread.message = message
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Remove link preview
     * @param index index of thread
     * @param removeImage remove link preview image from details (true/false) default false
     * @param triggerEvent trigger event to parent component (true/false) default true
     * @param details Thread details object default null
     */
    removeLinkPreview: function (
        index,
        type = '',
        removeImg = false,
        triggerEvent = true,
        details = null
    ) {
      details = details || clone(this.threadsOptions.multi_threads[index])
      details.title = details.description = details.website = details.url = ''
      if (removeImg) {
        details.multimedia = []
      }
      if (triggerEvent) {
        this.onChangeThreadDetails(details, index)
      }
      return details
    },

    /**
     * @description handle Sharing Link event
     * @param index index of thread
     * @param link
     */
    handleSharingLink(index, link) {
      const thread = clone(this.threadsOptions.multi_threads[index])
      thread.url = link
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Set link preview for Thread
     * @param index index of thread
     * @param preview link preview object
     */
    setLinkPreview(index, preview, payload) {
      const thread = clone(this.threadsOptions.multi_threads[index])
      // using same images if images are already added
      if (thread.image.length) {
        payload.image = thread.image
      }

      thread.title = payload.twitter_title
      thread.description = payload.twitter_description
      thread.multimedia.push(...payload.twitter_image)
      thread.website = payload.twitter_website
      thread.image_suggestions = payload.image_suggestions

      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Initialize video upload from editor for Thread
     * @param index index of thread
     */
    initializeVideoUpload: function (index) {
      let thread = clone(this.threadsOptions.multi_threads[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, false, false, thread)
      thread.image_suggestions = []
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Add media to editor for Thread
     * @param index index of thread
     * @param media media
     * @param mediaType media type (image, video, etc)
     */
    addMedia(index, media, mediaType) {
      console.debug('multi-threads', index, media, mediaType)
      let thread = clone(this.threadsOptions.multi_threads[index])
      // remove link preview if it is added
      thread = this.removeLinkPreview(index, false, false, thread)
      if (mediaType === 'multimedia') {
        this.addMultiMedia(index, thread, media)
      }
    },

    /**
     * @description Add images to thread
     * @param index
     * @param thread
     * @param media
     */
    addMultiMedia(index, thread, media) {
      thread.multimedia = thread.multimedia.concat(media)
      this.onChangeThreadDetails(thread, index)
    },

    /**
     * @description Handle remove media for thread
     * @param index index of thread
     * @param mediaType mediaType string (image, video)
     * @param media media to remove object
     * @param removeAll remove all images (true/false) default false
     */
    handleRemoveMedia: function (index, mediaType, media, removeAll = false) {
      this.removeMultimedia(index, media)
    },

    /**
     * This method is responsible for removing the media from multimedia array
     * @param type
     * @param media
     * @param index
     */
    removeMultimedia(threadsIndex, media) {

      if(!media) return
      const thread = clone(this.threadsOptions.multi_threads[threadsIndex])

      let index = -1
      if (typeof media === 'string') {
        index = thread.multimedia.findIndex((m) => typeof m === 'string' && m === media)
      } else if (typeof media === 'object') {
        index = thread.multimedia.findIndex((m) => typeof m === 'object' && m.url === media.url)
      }

      thread.multimedia.splice(index, 1)

      if (thread.multimedia.length === 0) {
        thread.multimedia = []
      }

      this.onChangeThreadDetails(thread, threadsIndex)
    },

    handleThreadedTweetHashtagsChanges(index, flag, data) {
      this.$emit('handle-hashtags', 'threads', flag, data, index)
    },

    /**
     * @description Event handlers on change thread details
     * @param thread {Object} thread details
     * @param index {int} thread index
     */
    onChangeThreadDetails: function (thread, index) {
      this.$emit('onChangeMultiThreadsDetails', thread, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-editor-threaded-tweets {
  border-top: 1px solid #dee2e6;
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  //&:focus-within {
  //  background-color: #fff;
  //  //border-color: #80bdff;
  //  border-color: #80bdff30;
  //  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
  //  outline: 0;
  //}
  textarea {
    background-color: #f4f7fa;
  }
}
</style>
