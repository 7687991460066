<template>
  <div
    class="profile_picture d-flex align-items-start mt-3"
    :style="isHidden ? 'opacity:0.7;' : ''"
  >
    <div class="picture_block picture_block_background">
      <div
        class="img"
        :style="'background:url(' + getUserImage(comment.posted_by) + ')'"
      ></div>
    </div>
    <div class="text_block">
      <div class="text">
        <span class="username mr-2">
          <strong
            v-html="
              comment.posted_by.name
                ? comment.posted_by.name
                : comment.posted_by.user_name
            "
          ></strong>
        </span>
        <span
          class="comment_text"
          v-html="getMentionedUser(comment.comment_message)"
        ></span>
      </div>
      <div class="sub_text">
        <div class="option_block d-flex align-items-center p-0">
          <span class="time mr-3">{{
            commonMethods.timesAgo(comment.created_time)
          }}</span>
          <clip-loader
            v-if="isLoading"
            class="d-inline-block align-middle mr-1"
            color="rgba(152, 158, 181, 0.8)"
            :size="'12px'"
          ></clip-loader>
          <span v-if="!isHidden" class="span_item">
            <span
              class="like_text cursor_pointer"
              style="color: red"
              @click="deletePostComment"
            >
              <strong>Delete</strong>
            </span>
          </span>
          <span v-if="!isHidden" class="span_item">
            <span
              class="like_text cursor_pointer"
              style="color: darkgray"
              @click="hideUnhidePostComment"
            >
              <strong>Hide</strong>
            </span>
          </span>
          <span v-if="isHidden" class="span_item">
            <span
              class="like_text cursor_pointer"
              style="color: darkgray"
              @click="hideUnhidePostComment"
            >
              <strong>Unhide</strong>
            </span>
          </span>
          <span v-if="!isHidden" class="span_item">
            <span
              class="like_text cursor_pointer"
              @click="setCanWrite(rootComment, comment.posted_by.user_name)"
            >
              <strong>Reply</strong>
            </span>
          </span>
        </div>
        <span
          v-if="isRoot && repliesCount > 0"
          class="replies-toggle"
          @click="setCanShowReplies(comment)"
          >{{ showReplies ? 'Hide Replies' : 'View Replies' }} ({{
            repliesCount
          }})</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_IMAGE } from '@src/modules/inbox/store/constants'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import { swalAttributes } from '@common/constants/common-attributes'

export default {
  components: {},
  props: [
    'rootIndex',
    'subIndex',
    'rootComment',
    'rootCommentId',
    'isRoot',
    'post',
    'comment',
    'repliesCount',
    'showReplies',
    'setCanWrite',
    'setCanShowReplies',
    'changeStatus', // prop functions
  ],
  data() {
    return {
      commonMethods,
      isLoading: false,
      isHidden: false,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    this.isHidden =
      typeof this.comment.is_hidden !== 'undefined' &&
      this.comment.is_hidden != null &&
      this.comment.is_hidden
  },
  methods: {
    ...mapActions(['deleteComment', 'hideUnhideComment']),
    getMentionedUser(text) {
      const regex = /@[A-Za-z0-9_@./#&+-]*/g
      const mentionedUsers = text.match(regex)
      if (mentionedUsers) {
        mentionedUsers.filter((name) => {
          const replaceText =
            '<a style="cursor: pointer; color: #00aced !important;" target="_blank" rel="noopener"  href="https://www.instagram.com/' +
            name.substring(1) +
            '">' +
            name +
            '</a>'

          text = text.replace(new RegExp(name, 'g'), replaceText)
        })
      }
      return text
    },
    async deletePostComment() {
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your comment?',
        {
          title: 'Remove Comment',
          ...swalAttributes(),
        }
      )
      if (!this.isLoading && res) {
        this.isLoading = true
        const payload = {
          platform_type: 'instagram',
          comment_id: this.comment.comment_id,
          platform_id: this.comment.platform_id,
          workspace_id: this.comment.workspace_id,
          reply_of: this.isRoot ? null : this.rootComment.comment_id,
        }
        const response = await this.deleteComment(payload)
        if (response.isValid) {
          const subId = this.isRoot ? null : this.comment.comment_id
          this.changeStatus(
            'delete',
            this.rootComment.comment_id,
            subId,
            response
          )
          this.isLoading = false
        } else {
          console.log(response)
          this.isLoading = false
        }
      }
    },
    async hideUnhidePostComment() {
      if (!this.isHidden) {
        const res = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to hide comment?',
          {
            title: 'Hide Comment',
            ...swalAttributes(),
          }
        )
        if (!res) return
      }
      if (!this.isLoading) {
        this.isLoading = true
        const payload = {
          platform_type: 'instagram',
          comment_id: this.comment.comment_id,
          is_hidden: !this.isHidden,
          platform_id: this.comment.platform_id,
          workspace_id: this.comment.workspace_id,
          reply_of: this.isRoot ? null : this.rootComment.comment_id,
        }
        const response = await this.hideUnhideComment(payload)
        if (response.isValid) {
          const subId = this.isRoot ? null : this.comment.comment_id
          this.changeStatus(
            'hide',
            this.rootComment.comment_id,
            subId,
            response
          )
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      }
    },
    getUserImage(postedBy) {
      return postedBy.image ? postedBy.image : DEFAULT_IMAGE
    },
  },
  watch: {
    'comment.is_hidden': async function (newVal, oldVal) {
      // watch it
      this.isHidden = newVal
    },
  },
}
</script>

<style lang="less" scoped>
.replies-toggle {
  float: right;
  text-decoration: underline;
  color: #385898;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
}
</style>
