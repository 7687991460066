<script>
import { mapActions, mapGetters } from 'vuex'

import { commonMethods } from '@common/store/common-methods'
import ZapierWarnings from '@common/components/zapier/ZapierWarnings'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import ThreadedTweetsSection from '@src/modules/publish/components/posting/social/sections/ThreadedTweetsSection'
import FirstCommentSection from '@src/modules/publish/components/posting/social/sections/FirstCommentSection'
import { socialChannelsArray } from '@common/constants/common-attributes'
import CarouselPostSection from '@src/modules/publish/components/posting/social/sections/CarouselPostSection'
import { EventBus } from '@common/lib/event-bus'
import HashtagSuggessions from '@src/modules/publish/components/posting/social/HashtagSuggessions.vue'
import { commonTypes } from '@src/modules/publish/store/states/mutation-types.js'
import ModelLayout from '../posting/ModelLayout'
import ModelSidebar from '../posting/ModelSidebar'
import AccountSelection from '../posting/social/AccountSelection'
import ToggleSelection from '../posting/social/ToggleSelection'
import SingleBox from '../posting/social/boxes/SingleBox'
import FacebookBox from '../posting/social/boxes/FacebookBox'
import TwitterBox from '../posting/social/boxes/TwitterBox'
import PinterestBox from '../posting/social/boxes/PinterestBox'
import LinkedinBox from '../posting/social/boxes/LinkedinBox'
import TumblrBox from '../posting/social/boxes/TumblrBox'
import PublishingTimeOptions from '../posting/PublishingTimeOptions'
import GmbOptions from '../posting/social/GmbOptions'
import InstagramBox from '../posting/social/boxes/InstagramBox'
import YoutubeBox from '../posting/social/boxes/YoutubeBox'
import TiktokBox from '../posting/social/boxes/TiktokBox'
import GmbBox from '../posting/social/boxes/GmbBox'
import YoutubeOptions from '../posting/social/YoutubeOptions'
import ContentCategorySelection from '../posting/social/ContentCategorySelection'
import ContentCategoryNote from '../posting/social/ContentCategoryNote'
import CreatePublicationFolder from '../../../composer/components/CreatePublicationFolder'

export default {
  components: {
    HashtagSuggessions,
    CarouselPostSection,
    ErrorSection,
    GmbOptions,
    InstagramBox,
    GmbBox,
    PublishingTimeOptions,
    TumblrBox,
    LinkedinBox,
    PinterestBox,
    TwitterBox,
    FacebookBox,
    SingleBox,
    ToggleSelection,
    AccountSelection,
    ModelSidebar,
    ModelLayout,
    ContentCategorySelection,
    ContentCategoryNote,
    ZapierWarnings,
    YoutubeOptions,
    YoutubeBox,
    TiktokBox,
    FirstCommentSection,
    CreatePublicationFolder,
    ThreadedTweetsSection,
  },
  props: {
    isCSV: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instagramSelection: {
        no_access_accounts: [],
        all_access_accounts: [],
      },
      showFirstCommentSection: false,
      first_comment: {
        comment: '',
        location: null,
        accounts: {
          facebook: [],
          instagram: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getPublishSelection',
      'getAccountSelection',
      'getDraftCancelAction',
      'getTwitterVariations',
      'getTwitterVariations',
      'getTwitterVariationsIndex',
      'getCarouselOptions',
      'getInstagramAccounts',
      'getCommonSharingDetails',
      'getInstagramSharingDetails',
      'getSocialSharingFirstComment',
      'getYoutubeOptions',
    ]),
    checkValidRoute() {
      return (
        this.$route.name !== 'saveBulkCsvAutomation' &&
        this.$router.currentRoute.name !== 'calender_plans' &&
        this.$router.currentRoute.name !== 'list_plans' &&
        this.$router.currentRoute.name !== 'feed_view' &&
        this.$route.name !== 'media-library'
      )
    },
    isFirstCommentEnabled() {
      const accountList = []
      if (this.firstCommentAccountSelection.length) {
        socialChannelsArray.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if (
              (channel.name === 'facebook' ||
                channel.name === 'linkedin' ||
                (channel.name === 'youtube' &&
                  !this.getYoutubeOptions.made_for_kids &&
                  this.getYoutubeOptions.privacy_status === 'public') ||
                channel.name === 'instagram') &&
              this.firstCommentAccountSelection.includes(account[channel.key])
            ) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key },
              })
            }
          })
        })
      }

      return accountList.length > 0
    },

    isCarouselPostEnabled() {
      let isFBPageSelected = false
      this.getFacebookAccounts.items.forEach((account) => {
        if (
          account.type === 'Page' &&
          this.getAccountSelection.facebook.includes(account.facebook_id)
        ) {
          isFBPageSelected = true
        }
      })
      return isFBPageSelected
    },

    isThreadedTweetsEnabled() {
      return this.getAccountSelection?.twitter?.length > 0
    },

    firstCommentAccountSelection() {
      const facebookAccounts = this.getAccountSelection.facebook
      const instagramAccounts = this.getAccountSelection.instagram
      const youtubeAccounts = this.getAccountSelection.youtube
      const linkedinAccounts = this.getAccountSelection.linkedin

      return [
        ...facebookAccounts,
        ...instagramAccounts,
        ...youtubeAccounts,
        ...linkedinAccounts,
      ]
    },
    zapierError() {
      if (this.getCommonBoxOptions.status) {
        return this.zapierAttachmentError(this.getCommonSharingDetails)
      }
      return this.zapierAttachmentError(this.getInstagramSharingDetails)
    },
    accountSelection() {
      // to deep watch object nested property which is social account selection
      return (
        this.getAccountSelection.facebook.length === 0 &&
        this.getAccountSelection.twitter.length === 0 &&
        this.getAccountSelection.pinterest.length === 0 &&
        this.getAccountSelection.linkedin.length === 0 &&
        this.getAccountSelection.tumblr.length === 0 &&
        this.getAccountSelection.instagram.length === 0 &&
        this.getAccountSelection.gmb.length === 0 &&
        this.getAccountSelection.youtube.length === 0 &&
        this.getAccountSelection.tiktok.length === 0
      )
    },
  },
  watch: {
    first_comment: function (val) {
      this.setFirstCommentMessage(val.comment)
    },
    isFirstCommentEnabled: function (val) {
      if (val === false) {
        this.setHasFirstComment(false)
        this.showFirstCommentSection = false
      }
    },
    isCarouselPostEnabled: function (val) {
      if (val === false) {
        this.getCarouselOptions.is_carousel_post = false
      }
    },
    'getAccountSelection.instagram': async function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.instagramSelection = {
          no_access_accounts: [],
          all_access_accounts: [],
        }
      } else {
        this.instagramSelection =
          commonMethods.zapierLinkedInstagramAccountExist(this)
      }
    },
    accountSelection: function (val) {
      // to toggle common box if no account is selected
      if (val) {
        this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true)
      }
    },
  },
  created() {
    this.initializePublishSection('Social')
  },
  mounted() {
    // this.$nextTick(function () {
    //
    // })

    EventBus.$on('fetchedFirstComment', (data) => {
      this.showFirstCommentSection = data.has_first_comment
      this.first_comment = {
        comment: data.first_comment_message,
        location: data.first_comment_location,
        account: data.first_comment_accounts,
      }
    })

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'social_share_modal') {
        this.setHasFirstComment(false)
        this.setFirstCommentMessage('')

        this.showFirstCommentSection = false
        this.first_comment = {
          comment: '',
          location: null,
          account: {
            facebook: [],
            instagram: [],
          },
        }
      }
    })
  },
  methods: {
    ...mapActions([
      'setDraftCancelAction',
      'setHasFirstComment',
      'setFirstCommentMessage',
    ]),
    handleFirstCommentChange(val) {
      // Changing toggle state in store
      this.setHasFirstComment(val)
      this.showFirstCommentSection = val
    },
    onHide(event) {
      if (this.checkValidRoute) {
        if (!this.getPublishSelection.plan_id && !this.getDraftCancelAction) {
          let validForSave = false

          const selection = this.getAccountSelection
          if (
            selection.facebook.length > 0 ||
            selection.twitter.length > 0 ||
            selection.pinterest.length > 0 ||
            selection.linkedin.length > 0 ||
            selection.tumblr.length > 0 ||
            selection.instagram.length > 0 ||
            selection.tiktok.length > 0 ||
            selection.gmb.length > 0
          ) {
            validForSave = true
          } else if (this.socialShareDraftValidation(false)) {
            validForSave = true
          }
          // here we also check about editor value
          if (validForSave) {
            event.preventDefault()
            this.setDraftCancelAction(false)
            this.$bvModal.show('socialConfirmationDialog')
            return false
          }
        }
      }
    }
  },
  beforeUnmount () {
    EventBus.$off('fetchedFirstComment')
  },
}
</script>

<template>
  <div>
    <CreatePublicationFolder></CreatePublicationFolder>
    <b-modal
      id="social_share_modal"
      modal-class="social_share_modal social_share_only top_left_design_box"
      static
      no-enforce-focus
      hide-footer
      hide-header
      @hide="onHide"
    >
      <ModelLayout type="Social" :is-c-s-v="isCSV"></ModelLayout>

      <div class="modal_body" style="min-height: 300px">
        <div class="modal_inner_content basic_form" style="overflow: visible">
          <!--<p class="saved">Saved a few seconds ago</p>-->
          <div class="d-flex align-items-stretch">
            <div class="content" style="padding-right: 20px">
              <div class="content_inner">
                <ContentCategorySelection
                  v-if="
                    !getPublishLoaders.initializeArticlePostCreation &&
                    !getPublishSelection.queueStatus &&
                    getUserRole !== 'Approver'
                  "
                  :module="'publish'"
                ></ContentCategorySelection>
                <ContentCategoryNote
                  v-if="
                    !getPublishLoaders.initializeArticlePostCreation &&
                    !getPublishSelection.queueStatus &&
                    isGlobalContentCategory
                  "
                ></ContentCategoryNote>
                <AccountSelection
                  v-if="
                    !getPublishLoaders.initializeArticlePostCreation &&
                    !isGlobalContentCategory
                  "
                  :show-label="false"
                  :show-publish-as="true"
                ></AccountSelection>
                <span
                  v-if="getPublishLoaders.initializeArticlePostCreation"
                  class="text-center"
                  style="display: block"
                >
                  <clip-loader
                    color="#4165ed"
                    :size="'16px'"
                    style="margin-bottom: 0.625rem"
                  ></clip-loader>
                  <span v-if="$route.path.includes('/discovery/')"
                    >Fetching data & creating post...</span
                  >
                </span>

                <template v-if="getCommonBoxOptions.globalCategoryStatus">
                  <beat-loader :color="'#436aff'"></beat-loader>
                </template>

                <!--&& !isGlobalContentCategory-->
                <!-- <div class="input_field" v-if="getAccountSelection.pinterest.length > 0">
                    <div class="compose_warning_box text-center">
                        <i
                            class="far fa-question-circle test">
                        </i>
                        <p>
                            Want to learn how Pinterest publishing works? Learn about posting via
                            <a
                                href="https://docs.contentstudio.io/article/836-how-to-publish-on-pinterest-via-zapier"
                                target="_blank" rel="noopener"  class="insta-link">Zapier
                            </a>
                        </p>
                    </div>
                </div> -->

                <div
                  v-if="getAccountSelection.instagram.length > 0"
                  class="input_field"
                >
                  <div class="compose_warning_box text-center">
                    <i class="far fa-question-circle test"> </i>
                    <p>
                      Want to learn how Instagram publishing works? Learn about
                      posting via
                      <a
                        href="https://docs.contentstudio.io/article/829-how-to-post-through-instagram-api"
                        target="_blank" rel="noopener"
                        class="insta-link"
                        >Instagram API
                      </a>
                      or
                      <a
                        href="https://docs.contentstudio.io/article/732-how-to-post-through-push-notifications"
                        target="_blank" rel="noopener"
                        class="insta-link"
                        >Mobile Notifications
                      </a>
                    </p>
                  </div>
                </div>

                <ToggleSelection
                  v-if="!getPublishLoaders.initializeArticlePostCreation"
                ></ToggleSelection>

                <SingleBox
                  :class="{
                    hide:
                      !getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus,
                  }"
                ></SingleBox>

                <FacebookBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.facebook.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.facebook),
                  }"
                ></FacebookBox>

                <TwitterBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.twitter.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.twitter),
                  }"
                ></TwitterBox>
                <!--  Threaded tweets toggle-->
                <div
                  v-if="
                    !getCommonBoxOptions.status &&
                    getAccountSelection['twitter'] &&
                    getAccountSelection['twitter'].length > 0 &&
                    getTwitterOptions.has_threaded_tweets === false
                  "
                  class="post_share_option post_share_option_social_threaded_tweet basic_form_inline clear-both"
                >
                  <div class="d-flex align-items-start mb-3">
                    <div class="left_input">
                      <p class="label"
                        >Add threaded Tweets
                        <!-- Open Article in a modal -->
                        <a
                          v-tooltip.top="
                            'Learn more about threaded tweets'
                          "
                          class="beacon ml-2"
                          data-beacon-article-modal="61826fd112c07c18afde3ed5"
                          href="#"
                        >
                          <i class="far fa-question-circle"></i>
                        </a>
                      </p>
                    </div>
                    <div class="right_input d-flex align_center">
                      <label class="switch-radio">
                        <input
                          v-model="getTwitterOptions.has_threaded_tweets"
                          type="checkbox"
                          :disabled="!isThreadedTweetsEnabled"
                        />
                        <span
                          class="slider round"
                          :class="{ 'disabled grey': !isThreadedTweetsEnabled }"
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>

                <ErrorSection
                  v-if="getCommonBoxOptions.status"
                  :errors="socialPostContentErrors()"
                  :warnings="socialPostContentWarnings()"
                ></ErrorSection>

                <!--  Threaded tweets toggle end-->
                <transition name="slide-bottom">
                  <ThreadedTweetsSection
                    v-show="
                      getTwitterOptions.has_threaded_tweets &&
                      getAccountSelection['twitter'].length > 0
                    "
                  />
                </transition>

                <LinkedinBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.linkedin.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.linkedin),
                  }"
                ></LinkedinBox>

                <PinterestBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.pinterest.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.pinterest),
                  }"
                ></PinterestBox>

                <TumblrBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.tumblr.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.tumblr),
                  }"
                ></TumblrBox>

                <InstagramBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.instagram.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.instagram),
                  }"
                ></InstagramBox>

                <ZapierWarnings
                  v-if="instagramSelection.no_access_accounts.length > 0"
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.instagram.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.instagram),
                  }"
                  :aspect_ratio="false"
                  :no_access_accounts="instagramSelection.no_access_accounts"
                ></ZapierWarnings>

                <YoutubeBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.youtube.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.youtube),
                  }"
                ></YoutubeBox>

                <TiktokBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.tiktok.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.tiktok),
                  }"
                ></TiktokBox>

                <GmbBox
                  :class="{
                    hide:
                      getCommonBoxOptions.status ||
                      getPublishLoaders.initializeArticlePostCreation ||
                      getCommonBoxOptions.globalCategoryStatus ||
                      (getAccountSelection.gmb.length === 0 &&
                        !isGlobalContentCategorySelected) ||
                      (isGlobalContentCategorySelected &&
                        !getCommonBoxOptions.globalCategoryChannels.gmb),
                  }"
                ></GmbBox>

                <!--<single-box :class="{hide:!getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation}"></single-box>-->
                <!--<facebook-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.facebook.length === 0 }"></facebook-box>-->
                <!--<twitter-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.twitter.length === 0 }"></twitter-box>-->
                <!--<linkedin-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.linkedin.length === 0 }"></linkedin-box>-->
                <!--<pinterest-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.pinterest.length === 0 }"></pinterest-box>-->
                <!--<tumblr-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.tumblr.length === 0 }"></tumblr-box>-->
                <!--<instagram-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.instagram.length === 0 }"></instagram-box>-->
                <!--<gmb-box :class="{hide:getCommonBoxOptions.status || getPublishLoaders.initializeArticlePostCreation || getAccountSelection.gmb.length === 0 }"></gmb-box>-->

                <ErrorSection
                  v-if="!getCommonBoxOptions.status"
                  :errors="socialPostContentErrors()"
                  :warnings="socialPostContentWarnings()"
                ></ErrorSection>

                <ZapierWarnings
                  v-if="instagramSelection.no_access_accounts.length > 0"
                  :class="{
                    hide:
                      !getCommonBoxOptions.status ||
                      getCommonBoxOptions.globalCategoryStatus,
                  }"
                  :aspect_ratio="false"
                  :no_access_accounts="instagramSelection.no_access_accounts"
                ></ZapierWarnings>

                <template
                  v-if="!getPublishLoaders.initializeArticlePostCreation"
                >
                  <HashtagSuggessions></HashtagSuggessions>

                  <div
                    v-if="getPublishSelection.status !== 'published'"
                    class="post_share_option"
                  >
                    <div
                      v-if="getPublishSelection.reopen"
                      class="text-center warning_box mt-3"
                    >
                      <p
                        >This post is either missed review, failed or rejected
                        that's why publishing time options have been reset.</p
                      >
                    </div>
                    <GmbOptions
                      v-if="getAccountSelection.gmb.length"
                    ></GmbOptions>
                    <YoutubeOptions
                      v-if="getAccountSelection.youtube.length"
                    ></YoutubeOptions>
                    <!-- <pinterest-options v-if="getAccountSelection.pinterest.length"></pinterest-options> -->
                    <div
                      v-if="getPublishSelection.queueStatus"
                      class="post_footer text-right clear"
                    >
                      <div class="warning_box pull-left"
                        >This post is queued and scheduled on
                        <strong>
                          {{
                            getWorkspaceTimeZoneTime(
                              getPublishSelection.planExecutionTime['date'],
                              'MMM DD, hh:mm a',
                            )
                          }}
                        </strong>
                        <i
                          v-if="getUserRole !== 'Approver'"
                          class="icon-edit-cs"
                          @click.prevent="editQueuePost"
                        ></i>
                      </div>
                      <button
                        v-if="!checkPendingApproval"
                        class="btn btn-studio-theme-space"
                        :disabled="
                          getPublishLoaders.processSocialShare ||
                          sharingPendingProcessStatus ||
                          getPublishLoaders.processSavePublication
                        "
                        @click.prevent="processSocialShare"
                      >
                        <span>Update</span>
                        <clip-loader
                          v-if="getPublishLoaders.processSocialShare"
                          :color="'#ffffff'"
                          :size="'16px'"
                        ></clip-loader>
                      </button>
                    </div>

                    <!--carousel-post-section-->
                    <transition name="slide-bottom">
                      <CarouselPostSection
                        v-show="getCarouselOptions.is_carousel_post"
                        :is-carousel-post-enabled="isCarouselPostEnabled"
                      />
                    </transition>

                    <transition name="slide-bottom">
                      <FirstCommentSection
                        v-show="showFirstCommentSection"
                        v-model="first_comment"
                        :is-first-comment-enabled="isFirstCommentEnabled"
                        @firstCommentChange="handleFirstCommentChange"
                      />
                    </transition>
                    <PublishingTimeOptions
                      :first_comment="first_comment"
                      :is-first-comment-enabled="isFirstCommentEnabled"
                      :is-carousel-post-enabled="isCarouselPostEnabled"
                      :is-facebook-selected="
                        getAccountSelection.facebook.length > 0
                      "
                      :is-instagram-selected="
                        instagramSelection.no_access_accounts.length > 0 ||
                        instagramSelection.all_access_accounts.length > 0
                      "
                      :no_access_accounts="
                        instagramSelection.no_access_accounts
                      "
                      :all_access_accounts="
                        instagramSelection.all_access_accounts
                      "
                      :zapier-errors="zapierError"
                      type="Social"
                      @firstCommentChange="handleFirstCommentChange"
                    >
                    </PublishingTimeOptions>
                    <div
                      v-if="
                        getPublishSelection.queueStatus &&
                        getUserRole !== 'Approver'
                      "
                      class="post_footer text-right clear"
                    >
                      <div class="warning_box justify-content-center"
                        >This post is queued and scheduled on
                        <strong>
                          {{
                            getWorkspaceTimeZoneTime(
                              getPublishSelection.planExecutionTime['date'],
                              'MMM DD, hh:mm a',
                            )
                          }}
                        </strong>
                        <i
                          class="icon-edit-cs"
                          @click.prevent="editQueuePost"
                        ></i>
                      </div>
                      <button
                        v-if="!checkPendingApproval"
                        class="btn btn-studio-theme-space mt-3"
                        :disabled="
                          getPublishLoaders.processSocialShare ||
                          sharingPendingProcessStatus ||
                          getPublishLoaders.processSavePublication
                        "
                        @click.prevent="processSocialShare"
                      >
                        <span>Update</span>
                        <clip-loader
                          v-if="getPublishLoaders.processSocialShare"
                          :color="'#ffffff'"
                          :size="'16px'"
                        ></clip-loader>
                      </button>
                    </div>
                  </div>

                  <div v-else class="post_share_option">
                    <div class="post_footer">
                      <div class="d-inline-block success_box warning_box_left">
                        <p
                          >Published on
                          {{
                            getWorkspaceTimeZoneTime(
                              getPublishSelection.planExecutionTime['date'],
                              'MMM DD, hh:mm a',
                            )
                          }}</p
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <ModelSidebar
              class="social_post_side_filter_model social_share_model_sidebar"
              type="Social"
              :is-c-s-v="isCSV"
            ></ModelSidebar>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
