<script setup>
import { computed, watch, defineProps, ref } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/linkedin_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

// state
const {
  overviewPublishingBehaviourData,
  dataZoomOptions,
  isReportView,
  isLoadingStates,
  routes,
  analyticsDesignSystem,
  legendOptions,

  generateStackedChartOptions,
  fetchMedia,
} = useLinkedinAnalytics('Publishing Behaviour')

const props = defineProps({
  type: {
    type: String,
    default: 'impressions',
  },
})

const optionsDropdown = [
  {
    label: 'Publishing Behaviour by Engagements',
    key: 'engagements',
    chartType: 'bar',
    tooltip: `Publishing Behavior by Engagement measures the interactions (likes, comments, shares) your posts receive during the selected time period, reflecting how actively users are engaging with your content.`,
  },
  {
    label: 'Publishing Behaviour by Impressions',
    key: 'impressions',
    chartType: 'bar',
    tooltip: `Publishing Behavior by Impressions shows the number of times your posts are displayed to users during the selected time period, helping you understand the visibility and reach of your content.`,
  },
  {
    label: 'Publishing Behaviour by Reach',
    key: 'reach',
    chartType: 'bar',
    tooltip: `Publishing Behavior by Reach indicates how many unique users have seen your posts during the selected time period, providing insight into the extent of your content's visibility.`,
  },
]

const postTypeDropdown = [
  { label: 'Image', key: 'images' },
  { label: 'Video', key: 'videos' },
  { label: 'Carousel', key: 'carousel' },
  { label: 'Text', key: 'text' },
  { label: 'Link', key: 'link' },
]

const engagementChartMap = [
  {
    name: 'Likes',
    stack: 'Engagements',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'likes',
    yIndex: 0,
  },
  {
    name: 'Comments',
    stack: 'Engagements',
    color: '#8081B9',
    position: 'left',
    api_response_key: 'comments',
    yIndex: 0,
  },
  {
    name: 'Shares',
    stack: 'Engagements',
    color: '#F2CA6B',
    position: 'left',
    api_response_key: 'shares',
    yIndex: 0,
  },
  {
    name: 'Total Posts',
    stack: 'Posts',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]

const impressionsChartMap = [
  {
    name: 'Impressions',
    stack: 'Impressions',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'impressions',
    yIndex: 0,
  },
  {
    name: 'Total Posts',
    stack: 'Posts',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]

const reachChartMap = [
  {
    name: 'Reach',
    stack: 'Reach',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'reach',
    yIndex: 0,
  },
  {
    name: 'Total Posts',
    stack: 'Posts',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.key === 'engagements' || optionsDropdown[0].key)
)

const openedModal = ref(false)

const isOptionTypeEmpty = ref(false)

const selectedOptionPostType = ref(postTypeDropdown.map((i) => i.key))

const previousSelectedPostType = ref(selectedOptionPostType.value)

// Dynamic chart configuration generation
const generateSeries = (charts, options) => {
  return charts.map((chart) => ({
    name: chart.name,
    type: 'bar',
    stack: chart?.stack || undefined,
    data: options[chart.api_response_key] || [],
    color: chart.color,
    colorBy: 'series',
    barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
    yAxisIndex: chart?.yIndex,
    areaStyle: { opacity: 0.4, cursor: 'auto' },
    cursor: 'auto',
  }))
}

const generateLegend = (charts) => ({
  ...legendOptions,
  data: charts
    .map((chartType) =>
      !IGNORE_LEGENDS.includes(chartType.name) ? chartType.name : null
    )
    .filter(Boolean),
})

const updateChartOptions = (chartOptions, charts, options) => {
  chartOptions.value.series = generateSeries(charts, options)
  chartOptions.value.legend = generateLegend(charts)
  chartOptions.value.xAxis.data = options?.buckets || []
}

const engagementChartOptions = ref(
  generateStackedChartOptions(engagementChartMap)
)
const impressionsChartOptions = ref(
  generateStackedChartOptions(impressionsChartMap)
)
const reachChartOptions = ref(generateStackedChartOptions(reachChartMap))

const getSelectedType = computed(() => {
  return isReportView.value
    ? optionsDropdown.find((i) => i.key === props.type)?.label || ''
    : selectedOptionType.value.label
})

const getSelectedPostType = computed(() => {
  if (selectedOptionPostType.value.length === 1) {
    return postTypeDropdown.find(
      (i) => i.key === selectedOptionPostType.value[0]
    ).label
  }
  if (selectedOptionPostType.value.length === postTypeDropdown.length) {
    return 'All'
  } else {
    return `${selectedOptionPostType.value.length} Selected`
  }
})

const isNoData = computed(() => {
  return (
    overviewPublishingBehaviourData.value?.publishing_behaviour?.buckets
      ?.length === 0
  )
})

const getSelectedChartOptions = computed(() => {
  const option = isReportView.value ? props.type : selectedOptionType.value.key
  switch (option) {
    case 'impressions':
      return impressionsChartOptions.value
    case 'engagements':
      return engagementChartOptions.value
    case 'reach':
      return reachChartOptions.value
    default:
      return impressionsChartOptions.value
  }
})

const IGNORE_LEGENDS = ['Total Posts']

watch(
  () => overviewPublishingBehaviourData.value,
  () => {
    const options =
      overviewPublishingBehaviourData.value?.publishing_behaviour || {}
    updateChartOptions(engagementChartOptions, engagementChartMap, options)
    updateChartOptions(impressionsChartOptions, impressionsChartMap, options)
    updateChartOptions(reachChartOptions, reachChartMap, options)
  },
  {
    deep: true,
    immediate: true,
  }
)
watch(
  () => selectedOptionPostType.value,
  (newVal) => {
    isAllSelected.value = newVal.length === postTypeDropdown.length
  }
)

const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_PUBLISHING_BEHAVIOUR]
)

const isAllSelected = ref(
  postTypeDropdown.length === selectedOptionPostType.value.length
)

const toggleSelectAllTypes = () => {
  if (selectedOptionPostType.value.length === postTypeDropdown.length) {
    selectedOptionPostType.value = []
    isAllSelected.value = false
  } else {
    selectedOptionPostType.value = postTypeDropdown.map((i) => i.key)
    isAllSelected.value = true
  }
}

const applyFilters = () => {
  if (
    selectedOptionPostType.value.length ===
      previousSelectedPostType.value.length &&
    selectedOptionPostType.value.every(
      (value, index) => value === previousSelectedPostType.value[index]
    )
  ) {
    return
  }

  previousSelectedPostType.value = [...selectedOptionPostType.value]

  if (selectedOptionPostType.value.length === 0) {
    isOptionTypeEmpty.value = true
    return
  } else {
    isOptionTypeEmpty.value = false
  }

  fetchMedia(routes.OVERVIEW_PUBLISHING_BEHAVIOUR, {
    media_type: selectedOptionPostType.value,
  })
}

const resetPrevious = () => {
  selectedOptionPostType.value = previousSelectedPostType.value
}
</script>

<template>
  <AnalyticsCardWrapper
    type="publishing-behaviour-linkedin"
    :enable-modal="true"
    :is-modal="openedModal"
    @toggle-is-modal="(value) => (openedModal = value)"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ getSelectedType }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(item, index) in optionsDropdown"
                  :key="`activity_type_${index}`"
                >
                  <CstDropdownItem @click="selectedOptionType = item">
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ selectedOptionType.tooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
        <!-- Post select dropdown -->
        <CstDropdown
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes="!rounded-md !border-[#70707029]"
          button-classes="flex !px-5 !w-48 !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          @on-close="resetPrevious"
        >
          <template v-slot:arrow>
            <img :src="ChevronDownIcon" alt="chevron-down" />
          </template>
          <template v-slot:selected>
            <p class="text-sm font-weight-500 capitalize">
              Post Type: {{ getSelectedPostType }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem :close-on-click="false">
              <SimpleCheckbox
                v-model="isAllSelected"
                class="all"
                @change="toggleSelectAllTypes"
              >
                <template v-slot:label>
                  <p class="text-gray-900 text-sm">Select All</p>
                </template>
              </SimpleCheckbox>
            </CstDropdownItem>

            <hr class="my-1" />

            <template
              v-for="(item, index) in postTypeDropdown"
              :key="`activity_type_${index}`"
            >
              <CstDropdownItem :close-on-click="false">
                <SimpleCheckbox
                  v-model="selectedOptionPostType"
                  :name="item.key"
                  class="group"
                  :error-message="'Reels data is not available for the Impressions by Instagram'"
                >
                  <template v-slot:label>
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </template>
                </SimpleCheckbox>
              </CstDropdownItem>
            </template>
            <div class="p-1 sticky bottom-0 border-top bg-white z-50">
              <CstDropdownItem
                class="active:bg-transparent hover:bg-transparent !p-0.5 text-right"
                size="small"
                :close-on-click="true"
              >
                <CstButton size="small" text="Apply" @click="applyFilters" />
              </CstDropdownItem>
            </div>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <div v-else-if="!isNoData && !isOptionTypeEmpty">
          <MainGraph
            :key="Math.random()"
            type="bar"
            :chart-options="
              slotProps.isModal
                ? {
                    ...getSelectedChartOptions,
                    ...dataZoomOptions,
                    grid: { ...getSelectedChartOptions.grid, bottom: 70 },
                  }
                : getSelectedChartOptions
            "
            :is-modal="slotProps.isModal"
          />
        </div>
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
