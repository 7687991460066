<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <!-- direct or notification toggle can be added here -->
    <!-- more options like comment disabled to be added -->

    <div class="my-4 flex flex-row items-center">
      <div class="w-2/12">Publish As</div>
      <div class="w-10/12 flex items-center">
        <div class="w-8/12 md:w-12/12">
          <CstRadio
            id="tiktok-direct"
            class="w-max mr-4"
            name="tiktok-publishing-type-direct"
            :model-value="postingMethod"
            value="direct"
            @change="setTikTokPublishingMethod"
          >
            <div class="flex flex-col">
              <span class="flex"
                >Direct Publishing via API
                <v-menu
                  class="mx-2 flex items-center gap-x-1"
                  :popper-triggers="['hover']"
                  placement="bottom-start"
                  popper-class="first-comment__info-popover"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <a href="#"
                    ><i
                      class="far fa-question-circle text-base text-blue-900"
                    ></i
                  ></a>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div
                      >Direct publishing via TikTok API, offers limited posting
                      options.
                      <a
                        v-close-popper
                        href="https://docs.contentstudio.io/article/1018-how-to-post-carousol-on-tiktok"
                        target="_blank" rel="noopener"
                        >Learn more</a
                      >
                    </div>
                  </template>
                </v-menu>
              </span>
            </div>
          </CstRadio>
        </div>
        <div class="w-4/12 md:w-6/12">
          <CstRadio
            id="tiktok-notification"
            class="w-max mr-0"
            name="tiktok-publishing-type-notification"
            :model-value="postingMethod"
            value="notification"
            @change="setTikTokPublishingMethod"
          >
            <div class="flex flex-col">
              <span class="flex"
                >TikTok App Notifications

                <v-menu
                  class="mx-2 flex items-center gap-x-1"
                  :popper-triggers="['hover']"
                  placement="bottom-start"
                  popper-class="first-comment__info-popover"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <a href="#"
                    ><i
                      class="far fa-question-circle text-base text-blue-900"
                    ></i
                  ></a>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div
                      >Publishing via TikTok App Notification, offers all TikTok
                      features.
                      <a
                        v-close-popper
                        href="https://docs.contentstudio.io/article/923-how-to-post-to-tiktok-using-content-studio"
                        target="_blank" rel="noopener"
                        >Learn more</a
                      >
                    </div>
                  </template>
                </v-menu>

                <img
                    src="@src/assets/img/common/new_tag.svg"
                    alt="new-tag"
                    class="ml-2"
                />
              </span>
            </div>
          </CstRadio>
        </div>
      </div>
    </div>

    <div class="my-5 flex flex-row items-center">
      <div class="w-2/12">Post Type</div>
      <div class="w-10/12 flex items-center">
        <CstRadio
          id="tiktok-video"
          class="w-max mr-4"
          name="tiktok-post-type-video"
          :model-value="postType"
          value="video"
          @change="setTiktokPostType"
        >
          Video
        </CstRadio>
        <CstRadio
          id="tiktok-carousel"
          class="w-max mr-0"
          name="tiktok-post-type-carousel"
          :model-value="postType"
          value="carousel"
          @change="setTiktokPostType"
        >
          Image Carousel
        </CstRadio>
        <v-menu
          :popper-triggers="['hover']"
          placement="bottom-start"
          popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <i class="far fa-question-circle ml-2 text-base text-blue-900" />
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Requirements for carousel post:</div>
            <ul>
              <li>- Only images are allowed in carousel post.</li>
              <li>- Minimum 2 images required.</li>
              <li>- Maximum 35 images allowed.</li>
              <li>- Maximum width 1920 and height 1080 for single image.</li>
              <li>- Maximum 20MB size for single image.</li>
            </ul>
          </template>
        </v-menu>
      </div>
    </div>

    <div v-if="isCarouselPost" class="my-4 flex flex-row items-center">
      <div class="w-2/12">Post Title</div>
      <div class="w-10/12">
        <CstInputFields
          :value="tiktokOptions.title"
          maxlength="90"
          class="w-full"
          type="text"
          size="small"
          @value="onTitleChange"
        />
      </div>
    </div>
    <template v-if="!isNotificationPublishing">
      <div class="my-4 flex items-center">
        <div class="w-2/12">Who can see</div>
        <div class="w-10/12 flex items-center">
          <div class="w-5/12">
            <CstDropdown
              class="w-full"
              size="small"
              button-classes="flex justify-between bg-cs-light-gray required-input"
            >
              <template v-slot:selected>
                <template v-if="selectedCategory.value">
                  {{ selectedCategory.name }}
                </template>
                <template v-else>
                  <span class="text-gray-500"
                    >Select Who can see this post</span
                  >
                </template>
              </template>

              <template v-slot>
                <CstDropdownItem
                  v-for="(item, index) in ViewOptions"
                  :key="index"
                  @click="onSelectCategory(item)"
                >
                  {{ item.name }}
                </CstDropdownItem>
              </template>
            </CstDropdown>
          </div>
        </div>
      </div>
      <div class="my-6 flex flex-row items-center">
        <div class="w-full w-12/12 flex flex-row items-center">
          <div class="w-2/12">{{ AllowText }}</div>
          <div class="w-3/12 flex space-x-2 items-center">
            <CstSwitch v-model="allowComment" size="small"></CstSwitch>
            <span
              v-if="!isCarouselPost"
              class="font-normal text-sm text-gray-900 mr-2"
              >Comment</span
            >
          </div>
          <template v-if="!isCarouselPost">
            <div class="w-3/12 flex space-x-2 items-center">
              <CstSwitch v-model="allowDuet" size="small"></CstSwitch>
              <span class="font-normal text-sm text-gray-900 mr-2">Duet</span>
            </div>
            <div class="w-2/12 flex space-x-2 items-center">
              <CstSwitch v-model="allowStitch" size="small"></CstSwitch>
              <span class="font-normal text-sm text-gray-900 mr-2">Stitch</span>
            </div>
          </template>
        </div>
      </div>
      <template v-if="isCarouselPost">
        <div class="my-6 flex flex-row items-center">
          <div class="w-full w-12/12 flex flex-row items-center">
            <div class="w-2/12">Add Auto-Music</div>
            <div class="w-3/12 flex space-x-2 items-center">
              <CstSwitch v-model="autoAddMusic" size="small"></CstSwitch>
              <v-menu
                :popper-triggers="['hover']"
                placement="bottom-start"
                popper-class="first-comment__info-popover"
              >
                <!-- This will be the popover target (for the events and position) -->
                <i
                  class="far fa-question-circle ml-2 text-base text-blue-900"
                />
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <span class="font-normal text-sm text-gray-900 mr-2"
                    >To enhance your TikTok carousel post, enable auto-music to
                    let TikTok's algorithm add music automatically based on your
                    content.</span
                  >
                </template>
              </v-menu>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import CstInputFields from '@ui/Input/CstInputFields.vue'
import CstRadio from '@ui/Radio/CstRadio.vue'
import CstSwitch from '@ui/Switch/CstSwitch'

export default {
  components: {
    CstDropdownItem,
    CstDropdown,
    CstInputFields,
    CstRadio,
    CstSwitch,
  },
  props: {
    tiktokOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      postType: 'video',
      postingMethod: 'direct',
      ViewOptions: [
        {
          name: 'Public To Everyone',
          value: 'PUBLIC_TO_EVERYONE',
        },
        {
          name: 'Mutual Follow Friends',
          value: 'MUTUAL_FOLLOW_FRIENDS',
        },
        {
          name: 'Self Only',
          value: 'SELF_ONLY',
        },
      ],
      selectedCategory: {
        name: '',
        value: '',
      },
      allowComment: false,
      allowDuet: false,
      allowStitch: false,
      autoAddMusic: false,
    }
  },
  computed: {
    isCarouselPost() {
      return this.postType === 'carousel'
    },
    isNotificationPublishing() {
      return this.postingMethod === 'notification'
    },
    AllowText() {
      return this.isCarouselPost ? 'Allow Comments' : 'Allow User to'
    },
  },
  watch: {
    allowComment(val) {
      this.tiktokOptions.disable_comment = !val
    },
    allowDuet(val) {
      this.tiktokOptions.disable_duet = !val
    },
    allowStitch(val) {
      this.tiktokOptions.disable_stitch = !val
    },
    autoAddMusic(val) {
      this.tiktokOptions.auto_add_music = val
    },
    postType(val) {
      this.tiktokOptions.post_type = val
    },
    postingMethod(val) {
      this.tiktokOptions.publishing_method = val
    },
  },
  mounted() {
    if (this.tiktokOptions.privacy_level) {
      this.selectedCategory = this.ViewOptions.find(
        (item) => item.value === this.tiktokOptions.privacy_level
      )
    }
    this.allowComment = !this.tiktokOptions.disable_comment
    this.allowDuet = !this.tiktokOptions.disable_duet
    this.allowStitch = !this.tiktokOptions.disable_stitch
    this.postType = this.tiktokOptions?.post_type || 'video'
    this.postingMethod = this.tiktokOptions?.publishing_method || 'direct'
    this.autoAddMusic = this.tiktokOptions?.auto_add_music
  },
  methods: {
    onSelectCategory(item) {
      this.tiktokOptions.privacy_level = item.value
      this.selectedCategory = item
    },

    onTitleChange(value) {
      this.tiktokOptions.title = value
    },

    setTiktokPostType(value) {
      this.postType = value
    },
    setTikTokPublishingMethod(value) {
      this.postingMethod = value
    },
  },
}
</script>
