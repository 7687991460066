<template>
  <div class="feed-grid-view-container__inner">
    <div class="feed-grid-view-container__inner__image_block">
      <div class="feed-grid-view-container__inner__image_block__tags">
        <template v-for="(item, i) in post.topics" :key="i">
          <span
              v-for="(finalized_topic, k) in checkIfMultipleTopics(item)"
              :key="k"
              class="feed-grid-view-container__inner__image_block__tags__item">
              <template v-if="item">{{ finalized_topic }}</template>
            </span>
        </template>
      </div>
      <div
        :style="{ backgroundImage: `url(${backgroundImageURL(post)})` }"
        class="feed-grid-view-container__inner__image"
      ></div>
    </div>
    <div class="feed-grid-view-container__inner__content_block">
      <div class="feed-grid-view-container__inner__content_block__height">
        <p
          :title="limitTextLength(post.title, 0)"
          class="line-clamp-2 font-medium mt-3.5 mb-2.5 cursor-pointer"
          @click.prevent="routeToPreview"
          v-html="limitTextLength(post.title, 94)"
        ></p>
        <template v-if="post.author">
          <p class="feed-grid-view-container__inner__content_block__author">
            By <span style="color: #4e92ff" v-html="post.author"></span>

            <template
v-for="(account, viewAccountsIndex) in getTwitterAccountsForView(
                post.twitter_accounts,
              )"
              :key="`viewAccounts_${viewAccountsIndex}`"
            >
              <a
                  v-if="viewAccountsIndex < 1"
                  :key="`viewAccounts_${viewAccountsIndex}`"
                  style="cursor: pointer"
                  target="_blank" rel="noopener"
                  :href="sanitizeUrl(getTwitterLink(account))"
              >
                {{ account }}</a
              >
              <a
                  v-else-if="post.twitter_handler"
                  :key="`handler_${viewAccountsIndex}`"
                  target="_blank" rel="noopener"
                  :href="sanitizeUrl(getTwitterLink(post.twitter_handler))"
              >
                {{ post.twitter_handler }}</a
              >
            </template>

            <clip-loader
              v-if="loader"
              class="d-inline-block ml-1"
              :color="'#436aff'"
              :size="'12px'"
            ></clip-loader>
          </p>
        </template>

        <div
          class="clear feed-grid-view-container__inner__content_block__information"
        >
          <div class="d-flex flex-row align-items-center">
            <div
              class="feed-grid-view-container__inner__content_block__information__author mr-2"
            >
              <a
                v-if="post.url_netloc"
                v-tooltip="trimURL(post.url_netloc)"
                class="domain_url_detail"
                href="javascript:;"
                >{{ trimURL(post.url_netloc) }}</a
              >
            </div>

            <div
              class="feed-grid-view-container__inner__content_block__information__author"
            >
              <p class="full_width_author"
                >{{ $filters.relative(post.post_date) }}
                <span
                  v-if="post.related_ids_count > 1"
                  style="float: right"
                  class="similar_stories"
                  @click.prevent="retrieveSimilarArticles(post.id, post._index)"
                  >{{ post.related_ids_count }} similar articles</span
                ></p
              >
              <!--v-if="post._source.related_ids_count"-->
            </div>
          </div>
          <div
            v-if="getGridViewTopInfluencers"
            class="article-grid-view-top-influencers"
          >
            <div
              v-if="getGridViewTotalInfluencers"
              class="article-grid-view-top-influencers__left"
            >
              <p
                ><i class="icon-profile-cs pr-1"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(getGridViewTotalInfluencers)
                }}</strong>
                influencers</p
              >
            </div>
            <!-- showSharers(post._source.id)-->
            <!--                  @click.prevent="routeToTwitterInfluencer(post.id)"-->
            <div
              v-if="getGridViewTotalInfluencers > 0"
              class="article-grid-view-top-influencers__right ml-auto"
            >
              <template
                v-for="(influencer, topInfluencerIndex) in getGridViewTopInfluencers"
                :key="`topInfluencer_${topInfluencerIndex}`"
              >
                <!--                      v-if="index <= 3"-->
                <a
                  v-tooltip="{
                    content: channelTooltipHTML('influencer', influencer),
                    classes: 'team_tooltip',
                    allowHTML: true,
                    theme: 'light',
                  }"
                  target="_blank" rel="noopener"
                  :href="sanitizeUrl('https://twitter.com/' + influencer.screen_name)"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      background: 'url(' + influencer.profile_image + ')',
                    }"
                  ></div>

                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
            </div>
          </div>
        </div>

      </div>
      <div class="feed-grid-view-container__inner__action_buttons">
        <!-- share buttons -->
        <b-dropdown
          id="dropdown-share-articles"
          variant="studio-icon-theme"
          class="studio-icon-theme-dropdown mr-3"
          no-caret
        >
          <template v-slot:button-content>
            <i v-tooltip="'Share this post'" class="far fa-share-alt icon"></i>
            <b-badge
              v-if="post.shared_count"
              pill
              variant="light"
              class="ml-2"
              >{{ post.shared_count }}</b-badge
            >
          </template>
          <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="shareToSocial(post.record_id)"
            ><i class="far fa-share"></i> Social Media
          </b-dropdown-item>
          <b-dropdown-item
            v-show="false"
            variant="studio-icon-theme"
            @click.prevent="routeToBlogPost(post.record_id)"
            ><i class="far fa-rss"></i> Blog Post
          </b-dropdown-item>
        </b-dropdown>

        <!-- favorite article button -->
        <div
          class="dropdown b-dropdown studio-icon-theme-dropdown mr-3 btn-group"
        >
          <template v-if="post.is_starred">
            <button
              class="btn btn-studio-icon-theme"
              @click.prevent="
                EventBus.$emit('feeder_starred_action', {
                  record_id: post.record_id,
                  index: index,
                  state: 'unstarred',
                })
              "
            >
              <i
                v-tooltip="'Unfavorite'"
                class="far fa-heart icon is-favorite"
              ></i>
              <span
                :class="{ ' active': post.is_starred }"
                class="icon_animate"
              ></span>
            </button>
          </template>
          <template v-else>
            <button
              class="btn btn-studio-icon-theme"
              @click.prevent="
                EventBus.$emit('feeder_starred_action', {
                  record_id: post.record_id,
                  index: index,
                  state: 'starred',
                })
              "
            >
              <i v-tooltip="'Favorite'" class="far fa-heart icon"></i>
            </button>
          </template>
        </div>

        <!-- archive article button -->
        <button
          id="dropdown-archive-articles"
          class="btn btn-studio-icon-theme post_archi_btn"
          style=""
        >
          <template v-if="isArchive">
            <i
              v-tooltip.top="'Unarchive Post'"
              class="far fa-archive icon is-archived"
              @click="
                EventBus.$emit('feeder_unarchive_action', {
                  record_id: post.record_id,
                  index: index,
                  domain_uuid: post.domain_uuid,
                  state: 'unarchive',
                })
              "
            ></i>
          </template>
          <template v-else>
            <i
              v-tooltip.top="'Archive Post'"
              class="far fa-archive icon"
              @click="
                EventBus.$emit('feeder_archive_action', {
                  record_id: post.record_id,
                  index: index,
                  domain_uuid: post.domain_uuid,
                  state: 'archive',
                })
              "
            ></i>
          </template>
        </button>

        <!-- more actions button-->
        <b-dropdown
          id="dropdown-actions-articles"
          variant="studio-icon-theme"
          dropleft
          class="studio-icon-theme-dropdown"
          no-caret
        >
          <template v-slot:button-content>
            <i v-tooltip.top="'More options'" class="cs-dots-h icon"></i>
          </template>

          <b-dropdown-item
            variant="studio-icon-theme"
            target="_blank" rel="noopener"
            :href="sanitizeUrl(savePocketContent(post.url))"
            ><i class="fab fa-get-pocket"></i> Add to Pocket
          </b-dropdown-item>

          <b-dropdown-item
            v-show="false"
            v-if="getGridViewTotalInfluencers"
            variant="studio-icon-theme"
            @click.prevent="showSharers(post.id)"
            ><i class="fa fa-users-crown"></i> View Influencers
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {UNKNOWN_ERROR} from "@common/constants/messages";
import {EventBus} from "@common/lib/event-bus";
import {feederItemMixin} from '@src/modules/discovery/components/feeder/mixins/feeder-item-mixin.js'
import FeederSentimentIcon from '../feed-item/FeederSentimentIcon'
import FeederSharedCountTooltip from '../feed-item/FeederSharedCountTooltip'
import {sanitizeUrl} from "@braintree/sanitize-url";

export default {
  components: {
    FeederSharedCountTooltip,
    FeederSentimentIcon
  },
  mixins: [feederItemMixin],
  props: {
    post: {
      type: Object,
      default: () => {}
    },
    index: {},
    isArchive: { type: Boolean, default: false },
    route_name: {
      type: String,
      default: ''
    }
  },
  computed: {
    EventBus() {
      return EventBus
    },
    getGridViewTopInfluencers () {
      if (this.post.hasOwnProperty('top_influencers')) {
        return this.post.top_influencers
      }
      if (this.post.hasOwnProperty('top_twitter_influencers')) {
        return this.post.top_twitter_influencers
      }
      return []
    },
    getGridViewTotalInfluencers () {
      if (this.post.hasOwnProperty('influencers_shares')) {
        return this.post.influencers_shares
      }
      if (this.post.hasOwnProperty('total_influencers_shares')) {
        return this.post.total_influencers_shares
      }
      return 0
    }
  },
  methods: {
    sanitizeUrl,
    testPosts () {
      console.log(this.$parent)
    },
    routeToTwitterInfluencer (id) {
      this.showSharers(id)
      const payload = {
        name: 'aggregate_feed_twitter_influencer',
        params: { post_id: id },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },

    routeToPreview() {
      const payload = {
        name: `${this.route_name}_preview`,
        params: {post_id: this.post.record_id},
        query: {...this.$route.query, module: 'feeds'}
      }
      console.log(payload)
      this.$router.push(payload)
    },
    async routeToShareSocial() {
      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')

      const payload = {
        post_id: this.post.id,
        type_of: 'social'
      }

      const res = await this.$store.dispatch('shareFeederPost', payload)
      if (res.data.data.post) {
        const options = {
          _id: res.data.data.post.id,
          post_id: res.data.data.post.id,
          url: res.data.data.post.url,
          title: res.data.data.post.title,
          image: res.data.data.post.image
        }

        EventBus.$emit('publication-composer-post', {
          mode: 'discovery-post',
          options,
        })

      } else {
        console.debug("share feed post error")
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.$bvModal.hide('composer-modal')
        EventBus.$emit('publication-composer-post-loader', false)
      }
    },
    routeToBlogPost (postId) {
      const payload = {
        name: 'aggregate_feed_item_blog_post',
        params: { post_id: postId },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    }
  }
}
</script>
