<template>
  <div
    class="block w-full cst-drop-shadow bg-white rounded-xl mb-3 scroll-snap-start overflow-y-hidden"
  >
    <div v-if="!togglePreview" class="flex w-full items-center px-3 py-3">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
      >
        <img
          src="@assets/img/integration/instagram-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div class="relative text-white">
      <div v-show="istruncated" class="view-more-gradient"></div>
      <!--   background   -->
      <div class="ratio ratio-9x16 preview-background !bg-[#020B12]">
        <img
          class="w-full object-contain"
          :src="thumbnail || video.thumbnail"
          alt="thumbnail"
        />
      </div>

      <!-- playback icon -->
      <div
        class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
        @click.prevent="
          displayFile('video', video.converted_video || video.link, 0)
        "
      >
        <i
          class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
        ></i>
      </div>

      <!--   header   -->
      <div class="absolute top-px left-0 w-full text-xl text-white p-5">
        <div class="flex justify-between items-center">
          <i
            class="w-5 h-5 flex justify-center items-center far fa-angle-left"
          ></i>

          <span class="text-bold-500 text-base select-none"> Reels </span>

          <i
            class="w-5 h-5 flex justify-center items-center fab fa-instagram"
          ></i>
        </div>
      </div>

      <!--   footer   -->
      <div
        class="absolute bottom-px left-0 w-full p-5 text-white z-30"
        :class="{ 'view-less-gradient': !istruncated }"
      >
        <div class="flex space-x-4">
          <div class="min-w-0 flex flex-col justify-end">
            <div class="flex items-center gap-y-2">
              <img
                :src="accountImage"
                alt=""
                class="rounded-full object-cover border border-solid border-black"
                width="35"
                height="35"
                @error="
                  $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                "
              />
              <div class="flex flex-col justify-center px-2">
                <div class=" font-bold select-none">
                  {{ accountName }}
                </div>
                <span
                  v-if="isLocationAdded"
                  v-tooltip="isLocationAdded"
                  class="font-normal text-xs mt-1 truncate"
                >
                 {{ isLocationAdded.length > 45 ? isLocationAdded.slice(0, 45) + '...' : isLocationAdded }}
                </span>
              </div>
            </div>
            <div class="pt-3 py-0.5">
              <span v-if="description">
                <div class="overflow-auto no-scrollbar max-h-[14rem]">
                  {{ descriptionText }}
                  <template v-if="description.length > 45">
                    <span
                      class="cursor-pointer font-bold text-white"
                      @click="istruncated = !istruncated"
                    >
                      {{ !istruncated ? 'View More' : 'View Less' }}
                    </span>
                  </template>
                </div>
              </span>
            </div>
          </div>
          <div class="ml-auto">
            <div class="flex flex-col justify-center items-center">
              <i
                class="far fa-heart text-xl w-6 h-6 flex justify-center items-center"
              ></i>
              <p class="text-bold-500 text-xs whitespace-nowrap mt-1.5"
                >14.8 k</p
              >
            </div>
            <div class="flex flex-col justify-center items-center mt-3.5">
              <i
                class="far fa-comment text-xl w-6 h-6 flex justify-center items-center"
              ></i>
              <p class="text-bold-500 text-xs whitespace-nowrap mt-1.5">521</p>
            </div>
            <div class="flex flex-col justify-center items-center mt-3.5">
              <i
                class="far fa-paper-plane text-xl w-6 h-6 flex justify-center items-center"
              ></i>
            </div>
            <div class="flex flex-col justify-center items-center mt-4">
              <i
                class="far fa-ellipsis-h text-xl w-6 h-6 flex justify-center items-center"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'InstagramReelPreview',
  props: {
    accountName: {
      type: String,
      default: '',
      required: true,
    },
    accountImage: {
      type: String,
      required: true,
    },
    video: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    togglePreview: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
      default: '',
      required: false,
    },
    isLocationAdded:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      istruncated: false,
    }
  },
  computed: {
    descriptionText() {
      if (!this.istruncated && this.description.length > 45) {
        return this.description.slice(0, 45) + '...'
      } else {
        return this.description
      }
    },
  },
  methods: {
    /**
     * Previewing/playing video file via event bus.
     * @param type
     * @param media
     * @param index
     */
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-background {
  &::after {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 72%,
      rgba(133, 133, 133, 0.3015406846) 100%
    );
    content: '';
    @apply absolute block w-full h-full top-0 left-0;
  }
}
.view-less-gradient {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 82%
    )
    no-repeat;
}
.view-more-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0.1) 40%
    )
    no-repeat;
  z-index: 20;
}
</style>
