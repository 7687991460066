<template>
  <div class="topics-pane">
    <TopicsSidebar />
    <div class="topics-top-pane force-white-bg">
      <div class="topics-top-pane__inner">
        <div class="topics-top-pane__inner__left_section">
          <div class="topics-top-pane__inner__left_section__inner">
            <h3>Curated Topic</h3>
            <h2>{{ curated_topic.name }}</h2>
          </div>
          <div class="topics-top-pane__inner__left_section__follow_container">
            <button
              v-if="displayFollowButton"
              class="btn topics-top-pane__inner__left_section__follow_button"
              @click.prevent="
                $store.dispatch('followCuratedTopic', { topic: curated_topic })
              "
            >
              <span>FOLLOW</span>
            </button>
          </div>
        </div>
        <!-- INFO :: Might needed in future -->
        <!-- <div class="topics-top-pane__inner__right_section">
          <div class="topics-top-pane__inner__right_section__content_types">
            <div
              class="topics-top-pane__inner__right_section__content_types__buttons"
            >
              <router-link
                :to="{
                  name: 'discover_topics_curated_web_content',
                  params: { topic_id: $route.params.topic_id },
                }"
                :class="{
                  active: $route.name === 'discover_topics_curated_trending',
                }"
              >
                <i class="icon-Content-Feed_icon"></i>
                <span>Content Feed</span>
              </router-link>
              <router-link
                href="javascript:;"
                :to="{
                  name: 'discover_topics_curated_web_insights',
                  params: { topic_id: $route.params.topic_id },
                  query: { topic: $route.query.topic },
                }"
              >
                <i class="icon-Insights_icon"></i>
                <span>Insights</span>
              </router-link>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <CuratedAndCustomTopicsChannel :module="'curated'" />
        <CuratedAndCustomTopicsFilters :total_results="total_results" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import proxy, { getProxyCancelToken } from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import TopicsSidebar from '../../sub-components/TopicsSidebar'
import TopicsFilterResults from '../../sub-components/TopicsFilterResults'
import CuratedAndCustomTopicsFilters from '../../channel-filters/CuratedAndCustomTopicsFilters'
import CuratedAndCustomTopicsChannel from '../../sub-components/CuratedAndCustomTopicsChannel'
import { fetchCuratedTopicByTagURL } from '../../../../config/api-utils'
import { discoverTypes } from '../../../../store/mutation-types'
export default {
  components: {
    TopicsSidebar,
    TopicsFilterResults,
    CuratedAndCustomTopicsChannel,
    CuratedAndCustomTopicsFilters
  },
  data () {
    return {
      total_results: 0,
      curated_topic: {}
    }
  },
  computed: {
    displayFollowButton () {
      if (this.$route.name === 'discover_topics_curated_trending') return false
      if (this.$route.query.topic) {
        const ItemAvailable = this.$store.getters.getMyCuratedTopicsList.filter(
          (item) => item.name === this.$route.query.topic
        )
        console.log(`Item is Available for Topic ${ItemAvailable}`)
        if (ItemAvailable.length > 0) return false
        return true
      }
      return false
    }
  },
  watch: {
    '$route.params.topic_id' (value) {
      this.curated_topic = {}
      this.fetchCuratedTopic()
    },
    '$route.params.topic' (value) {
      this.curated_topic = {}
      this.fetchCuratedTopic()
    }
  },
  created () {
    this.fetchCuratedTopic()
  },
  mounted () {
    EventBus.$on('archive-post-item', (data) => {
      console.log('archive-post-item', data, this.total_results)
      this.total_results -= 1
    })
  },
  beforeUnmount () {
    EventBus.$off('archive-post-item')
  },
  methods: {
    fetchCuratedTopic () {
      if (this.$route.params.topic_id) {
        proxy
          .post(`${discoveryBaseUrl}v2/topics/retrieve_curated_topic_by_id`, {
            workspace_id: this.$store.getters.getActiveWorkspace._id,
            topic_id: this.$route.params.topic_id
          })
          .then((res) => {
            if (res.data.data) {
              this.curated_topic = res.data.data
            }
          })
          .catch((err) => {})
      } else {
        // if there is no topic available and user click on the topic label from the grid view item.
        proxy
          .post(`${discoveryBaseUrl}v1/topics/find`, {
            tag: this.$route.query.topic,
            workspace_id: this.$store.getters.getActiveWorkspace._id
          })
          .then((res) => {
            if (res.data.status) {
              this.curated_topic = res.data.data
            }
            return res
          })
          .catch(() => null)
          .finally()
      }
    }
  }
}
</script>
