<template>
  <div
    class="flex items-center p-4 w-full max-w-sm bg-white text-gray-800 rounded-xl shadow-sm"
    role="alert"
  >
    <div
      class="inline-flex flex-shrink-0 justify-center items-center w-10 h-10 rounded-lg"
      :style="styleObject"
    >
      <img
        class="w-5 h-5"
        :src="require(`@assets/img/notification_icons/${imgSource}`)"
        alt="icon"
      />
      <span class="sr-only">icon</span>
    </div>
    <HtmlRenderer
      class="ml-3 text-sm not-italic font-normal text-left"
      :html-content="message"
    />
    <button
      type="button"
      class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-800 border-0 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
      data-dismiss-target="#toast-default"
      aria-label="Close"
      @click="closeToast"
    >
      <span class="sr-only">Close</span>
      <svg
        aria-hidden="true"
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'

export default {
  name: 'Toast',
  components: {
    HtmlRenderer
  },
  props: {
    message: {
      type: String,
      default: 'Action Performed Successfully.',
    },
    type: {
      type: String,
      default: 'success',
      validator: function (value) {
        // The value must match one of these strings
        return ['success', 'info', 'warn', 'error'].indexOf(value) !== -1
      },
    },
    closeToastCallback: {
      type:Function,
      default: () => {},
    }
  },
  emits: ['close'],
  computed: {
    styleObject: function () {
      switch (this.type) {
        case 'info':
          return {
            'background-color': 'rgba(10, 151, 243, 0.1)',
          }
        case 'warn':
          return {
            'background-color': 'rgba(255, 187, 2, 0.1)',
          }
        case 'error':
          return {
            'background-color': 'rgba(224, 47, 95, 0.1)',
          }
        default:
          return {
            'background-color': 'rgba(30, 203, 123, 0.1)',
          }
      }
    },
    imgSource: function () {
      switch (this.type) {
        case 'info':
          return 'info.svg'
        case 'warn':
          return 'alert-circle.svg'
        case 'error':
          return 'x-circle.svg'
        default:
          return 'check-circle.svg'
      }
    },
  },
  methods: {
    closeToast() {
      this.closeToastCallback()
      this.$emit('close')
    },
  },
}
</script>
