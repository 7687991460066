<template>
  <CstConfirmationPopup
    modal-name="import-update"
    primary-button-text="Got it!"
    :loading="loading"
    @confirmed="handleConfirm"
    @cancelled="handleCancel"
    :no-close-button="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :footer-class="'justify-between'"
  >
    <template v-slot:head>
      <div class="flex items-center justify-between w-full">
        <h4 class="text-lg font-medium">Bulk Upload Automation: New Features Added 🚀</h4>
        <a
          href="#"
          class="beacon"
          data-beacon-article-modal="677b82779a830000cc29b3a3"
        >
          Learn more
        </a>
      </div>
    </template>
    <template v-slot:body>
      <div class="pt-4 text-[16px]">
        <p class="text-black-600 mb-4">
          We have enhanced the bulk upload process to support:
        </p>

        <ul class="text-black-600 space-y-2 mb-4 pl-2">
          <li>
            <span>1. </span>
            <span><b>Video Posts:</b> Schedule and publish video posts effortlessly.</span>
          </li>
          <li>
            <span>2. </span>
            <span><b>Multiple Image Posts:</b> Create posts with multiple images in a single upload.</span>
          </li>
        </ul>

        <p class="text-black-600 mb-4">
          Use the latest CSV template to access these features.
        </p>
      </div>
    </template>
    <template v-slot:footer="{ onCancel, onConfirm }">
          <a
            href="https://storage.googleapis.com/contentstudio-media-library-nearline/contentstudio/bulk_uploader_csv_template-v2.csv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Updated CSV Template
          </a>
          <CstButton
            variant="primary"
            class="!bg-green-600 border-0"
            @click="onConfirm"
          >
            Got it!
          </CstButton>
      </template>
  </CstConfirmationPopup>
</template>

<script setup>
import { ref, watch, inject, onMounted } from 'vue'
import CstConfirmationPopup from '@/src/components/UI/Popup/CstConfirmationPopup.vue'
import CstButton from '@/src/components/UI/Button/CstButton.vue'
import useHelper from '@/src/modules/common/composables/useHelper'
import { useStore } from '@state/base'

const props = defineProps({
  showImportModal: {
    type: Boolean,
    default: true,
  },
})

const root = inject('root')
const { $bvModal } = root

const { dispatch } = useStore()
const { setPreferenceStatus } = useHelper()
const loading = ref(false)

onMounted(() => {
  if (props.showImportModal) {
    $bvModal.show('import-update-confirmation-modal')
  }
})

watch(() => props.showImportModal, (newValue) => {
  if (newValue) {
    $bvModal.show('import-update-confirmation-modal')
  } else {
    $bvModal.hide('import-update-confirmation-modal')
  }
})

const handleConfirm = async () => {
  loading.value = true
  try {
    const result = await setPreferenceStatus('bulk_uploader_view', false)
    if (result.success) {
      $bvModal.hide('import-update-confirmation-modal')
      dispatch('setBulkUploaderViewStatus', false)
    } else {
      console.error('Error in setPreferenceStatus', result.error)
    }
  } finally {
    loading.value = false
  }
}

const handleCancel = () => {
  $bvModal.hide('import-update-confirmation-modal')
}
</script>

<style>
@media (min-width: 576px) {
  #import-update-confirmation-modal .modal-dialog {
    max-width: 700px !important;
    margin: 1.75rem auto;
  }
}
</style>
