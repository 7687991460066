<template>
  <div class="max-w-3xl">
    <CstAlert type="info" class="mb-6">
      <template v-if="data.step === 1">
        Two-factor authentication adds an extra layer of security to your
        account by requiring access to an authenticator app on your phone when
        you log in.
      </template>
      <template v-else-if="data.step === 2">
        Two-factor authentication will not be enabled until you complete these
        steps.
        <a
          class="!underline text-blue-500 beacon"
          href="#"
          data-beacon-article-modal="6320213c7164226be0c8562d"
          target="_blank" rel="noopener"
          >Read more</a
        >
        on how to perform these steps.
      </template>
      <template v-else-if="data.step === 3">
        Two-factor authentication is now enabled, save the following
        recovery/backup codes. It will be the only way to access your account
        incase you misplace your device.
        <a
          class="!underline text-blue-500 beacon"
          href="#"
          data-beacon-article-modal="6321652ac713d51da3ede3cb"
          target="_blank" rel="noopener"
          >Read more</a
        >
        about why these codes are necessary and how to use them?
      </template>
    </CstAlert>
    <div>
      <template v-if="data.step === 1">
        <div v-if="data.enabled">
          Two-factor authentication is now enabled, to disable enter your
          password.
        </div>
        <div v-else>
          Two-factor authentication on your account is currently disabled,
          please enter your password to get started.
        </div>

        <div class="w-full mt-5">
          <CstFloatingLabelInput
            id="password-2ff"
            v-model="data.password"
            type="password"
            label="Password"
            show-icon-left
            @enter="handleNext"
          >
            <template v-slot:icon>
              <i class="icon-Password"></i>
            </template>
          </CstFloatingLabelInput>
        </div>
      </template>
      <template v-else-if="data.step === 2">
        <div>
          <ol class="mb-4">
            <li class="flex mb-3 items-start">
              <div
                class="mr-3 inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary font-weight-500"
              >
                1
              </div>
              <div class="pt-1.5">
                Install Google Authenticator App for
                <a
                  class="mx-0.5 !underline text-blue-500"
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank" rel="noopener"
                  >Apple</a
                >
                or
                <a
                  class="mx-0.5 !underline text-blue-500"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank" rel="noopener"
                  >Android</a
                >
                devices.
              </div>
            </li>
            <li class="flex mb-3 items-start">
              <div
                class="mr-3 inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary font-weight-500"
              >
                2
              </div>
              <div class="flex flex-col items-center pt-1.5">
                Scan this QR code into your Google Authenticator app.
                <img
                  :src="data.qrcode_image"
                  width="150"
                  height="150"
                  alt="qr-code"
                />
              </div>
            </li>
            <li class="flex items-start">
              <div
                class="mr-3 inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary font-weight-500"
              >
                3
              </div>
              <div class="pt-1.5">
                Enter the generated code below to confirm.
              </div>
            </li>
          </ol>
        </div>
      </template>

      <template v-else-if="data.step === 3">
        <div>
          <!--    Backup codes list    -->
          <div
            id="backup-codes"
            class="grid grid-cols-3 gap-12 p-12 border-2 border-solid text-center"
          >
            <div v-for="(item, index) in data.backupCodes" :key="index">
              <span
                class="font-weight-500 text-base"
                :class="{ 'line-through': item.consumed }"
              >
                {{ item.code }}
              </span>
            </div>
          </div>

          <div class="grid grid-cols-3 max-w-md mt-3 text-center mx-auto">
            <button
              v-tooltip.top="
                'Download the backup codes as .txt file'
              "
              class="border-0 bg-white text-blue-600 hover:text-blue-700 border-transparent focus:border-transparent focus:ring-0 border-0 focus:outline-none"
              :disabled="data.loader"
              @click="() => downloadTextFile()"
            >
              <i class="fad fa-download mr-1.5"></i>
              Download
            </button>
            <button
              v-tooltip.top="'Print the backup codes'"
              class="border-0 bg-white text-blue-600 hover:text-blue-700 border-transparent focus:border-transparent focus:ring-0 border-0 focus:outline-none"
              :disabled="data.loader"
              @click="printBackupCode"
            >
              <i class="fad fa-print mr-1.5"></i>
              Print
            </button>
            <button
              v-clipboard:copy="textToCopy"
              v-clipboard:success="() => onTextCopy('success')"
              v-clipboard:error="() => onTextCopy('error')"
              v-tooltip.top="'Copy the backup codes in plain text'"
              class="border-0 bg-white text-blue-600 hover:text-blue-700 border-transparent focus:border-transparent focus:ring-0 border-0 focus:outline-none"
              :disabled="data.loader"
            >
              <i class="fad fa-copy mr-1.5"></i>
              Copy
            </button>
          </div>
        </div>
      </template>

      <div class="mt-6 flex items-center justify-between w-full">
        <div v-if="data.step === 2" class="mr-4 flex-1">
          <CstInputField
            type="text"
            :value="data.code"
            placeholder="Enter code"
            class="w-full"
            :maxlength="6"
            @value="(code) => (data.code = code)"
            @keydown="filterKey"
            @onPressEnter="handleNext"
          />
        </div>
        <div>
          <div v-if="!data.loader && data.step === 3" class="mr-4">
            Generated On: {{ data.backupCodesDate }}
          </div>
          <div
            v-if="data.step === 1 && is2FAEnabled"
            class="mr-4 underline text-blue-400 hover:text-blue-500 cursor-pointer"
            @click="fetchExistingBackupCodes"
          >
            View back up codes
          </div>
        </div>
        <div>
          <template v-if="data.step === 3 && data.codeGenerated">
            <CstButton
              class="mr-3"
              :loading="data.loader"
              @click="handleRegenerateCodes(true)"
              >{{ getButtonText }}
            </CstButton>
            <CstButton @click="handleNext">Done</CstButton>
          </template>
          <template v-else>
            <CstButton
              v-if="showCancelButton"
              variant="secondary"
              class="mr-3 w-28"
              @click="resetState"
            >
              Cancel
            </CstButton>
            <CstButton
              :class="{ 'bg-[#e02f5f]': data.enabled && data.step === 1 }"
              :disabled="isButtonDisabled"
              :loading="data.loader"
              @click="handleNext"
              >{{ getButtonText }}
            </CstButton>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue'
import CstAlert from '@ui/Alert/CstAlert'
import CstButton from '@ui/Button/CstButton'
import CstInputField from '@ui/Input/CstInputFields'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import {
  ACTION_TYPES,
  downloadPdfBackupCodes,
  serviceGoogle2fa,
} from '@src/modules/setting/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import { useStore } from '@state/base'

export default defineComponent({
  components: {
    CstAlert,
    CstButton,
    CstInputField,
    CstFloatingLabelInput,
  },
  setup() {
    const { dispatch, getters } = useStore()

    const data = reactive({
      step: 1,
      enabled: false,
      password: '',
      code: '',
      codeGenerated: false,
      qrcode_image: '',
      backupCodes: [],
      backupCodesDate: '',
      loader: false,
    })

    const resetState = () => {
      data.step = 1
      data.enabled = false
      data.password = ''
      data.code = ''
      data.codeGenerated = false
      data.qrcode_image = ''
      data.backupCodes = []
      data.backupCodesDate = ''
    }

    onMounted(async () => {
      if (is2FAEnabled.value) {
        data.enabled = true
      }
    })

    /**
     * Method to convert base64 to normal image URL
     * @param dataURI
     * @returns {Blob}
     */
    const dataURItoBlob = (dataURI) => {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString

      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1])
      else byteString = unescape(dataURI.split(',')[1])

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    }

    /*
     * Get the existing backup codes
     */
    const fetchExistingBackupCodes = async () => {
      try {
        const response = await serviceGoogle2fa(ACTION_TYPES.FETCH_BACKUP_CODES)

        data.backupCodes = response.backup_codes.filter(
          (code) => !code.manually_generated
        )
        data.backupCodesDate = getWorkspaceTimeZoneTime(
          response.created_at,
          getters.getWorkspaces.activeWorkspace.timezone,
          'MMM D, YYYY'
        )

        data.step = 3
        data.codeGenerated = true
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    /*
     * Generate backup codes
     */
    const handleRegenerateCodes = async (regenerate = false) => {
      data.loader = true
      // fetch the backup codes
      const response = await serviceGoogle2fa(
        ACTION_TYPES.GENERATOR_BACKUP_CODES
      )
      data.backupCodes = response.backup_codes.filter(
        (code) => !code.manually_generated
      )
      data.backupCodesDate = getWorkspaceTimeZoneTime(
        response.created_at,
        getters.getWorkspaces.activeWorkspace.timezone,
        'MMM D, YYYY'
      )

      if (regenerate) {
        data.codeGenerated = false
      }

      dispatch('toastNotification', {
        message: 'Backup codes generated successfully',
        type: 'success',
      })

      data.loader = false
    }

    /*
     * Method to handle click event on next button
     */
    const handleNext = async () => {
      if (data.loader) {
        return true
      }

      data.loader = true
      switch (data.step) {
        case 1:
          if (data.password.trim() === '') {
            data.loader = false
            return
          }

          if (data.enabled) {
            // Make the call /2fa/generator/google for a unique QR code
            try {
              await serviceGoogle2fa(ACTION_TYPES.DISABLE_2FA, {
                password: data.password,
              })

              data.step = 1
              getters.getProfile['2fa_enabled'] = false
              data.enabled = false
              data.password = ''

              dispatch('toastNotification', {
                message: '2FA disabled successfully',
                type: 'success',
              })
            } catch (error) {
              dispatch('toastNotification', {
                message: error.message || UNKNOWN_ERROR,
                type: 'error',
              })
            }
          } else {
            // Make the call /2fa/generator/google for a unique QR code
            try {
              const response = await serviceGoogle2fa(ACTION_TYPES.ENABLE_2FA, {
                password: data.password,
              })

              const blob = dataURItoBlob(
                `data:image/png;base64,${response.qrcode_image}`
              )
              data.qrcode_image = URL.createObjectURL(blob)
              data.step++
            } catch (error) {
              dispatch('toastNotification', {
                message: error.message || UNKNOWN_ERROR,
                type: 'error',
              })
            }
          }
          break
        case 2:
          if (data.code.trim() === '') {
            data.loader = false
            return
          }

          // Verify the code
          try {
            await serviceGoogle2fa(ACTION_TYPES.VALIDATE_2FA, {
              code: data.code,
            })

            // fetch the backup codes
            await handleRegenerateCodes()

            data.step++

            dispatch('toastNotification', {
              message: '2FA enabled successfully',
              type: 'success',
            })
          } catch (error) {
            dispatch('toastNotification', {
              message: error.message || UNKNOWN_ERROR,
              type: 'error',
            })
          }

          break
        case 3:
          resetState()
          data.step = 1
          data.enabled = true
          break
      }

      data.loader = false
    }

    /*
     * Computed property to check if the 2 factor is enabled
     */
    const is2FAEnabled = computed(() => {
      return getters.getProfile['2fa_enabled'] || data.enabled
    })

    /*
     * Method to handle text copy
     */
    const onTextCopy = (type) => {
      if (type === 'success') {
        dispatch('toastNotification', {
          message: 'Backup codes copied to clipboard',
          type: 'success',
        })
      } else {
        dispatch('toastNotification', {
          message: 'Failed to copy backup codes',
          type: 'error',
        })
      }
    }

    /*
     * Method to create and download backup codes txt file
     */
    const downloadTextFile = (
      filename = `backup_code_${getters.getProfile.email.split('@')[0]}.txt`
    ) => {
      const backupCodes = textToCopy.value

      const element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(backupCodes)
      )
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }

    /*
     * Method to create and print backup codes file
     */
    const printBackupCode = async () => {
      try {
        const data = await downloadPdfBackupCodes({
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })

        window.open(URL.createObjectURL(data))
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    /*
     * Computing property to format backup codes date
     */
    const textToCopy = computed(() => {
      const backupCodes = data.backupCodes.map((item) => item.code).join('\n')

      return `
SAVE YOUR BACKUP CODES
Keep these backup codes somewhere safe but accessible.
-------------------------------------------------------


Here are your backup codes:

${backupCodes}


* Backup codes for ${getters.getProfile.email}
* You can only use each backup code once.
* Need help? Contact support at support@contentstudio.io


These codes were generated on date ${data.backupCodesDate}
      `
    })

    /*
     * Computing property to get button text
     */
    const getButtonText = computed(() => {
      if (data.step === 1) {
        if (data.enabled) {
          return 'Disable'
        }
        return 'Enable'
      } else if (data.step === 2) {
        return 'Confirm'
      } else if (data.step === 3) {
        if (data.codeGenerated) {
          return 'Regenerate Codes'
        }
        return 'Ok, I understand'
      }
    })

    /*
     * Computing property to disbable the enable button
     */
    const isButtonDisabled = computed(() => {
      if (data.loader) {
        return true
      }

      if (data.step === 1) {
        return !data.password.trim()
      } else if (data.step === 2) {
        return !data.code.trim()
      }

      return false
    })

    /*
     * Computing property to handle cancel button visibility
     */
    const showCancelButton = computed(() => {
      return data.step === 2
    })

    /*
     * Method to handle code input validation
     */
    const filterKey = (e) => {
      const isModifierkeyPressed = e.metaKey || e.ctrlKey || e.shiftKey
      const isCursorMoveOrDeleteAction =
        [46, 8, 37, 38, 39, 40].indexOf(e.keyCode) !== -1
      const isNumKeyPressed =
        (e.keyCode >= 48 && e.keyCode <= 58) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      const vKey = 86
      const cKey = 67
      const aKey = 65
      switch (true) {
        case isCursorMoveOrDeleteAction:
        case isModifierkeyPressed === false && isNumKeyPressed:
        case (e.metaKey || e.ctrlKey) &&
          [vKey, cKey, aKey].indexOf(e.keyCode) !== -1:
          break
        default:
          e.preventDefault()
      }
    }

    return {
      data,
      getButtonText,
      isButtonDisabled,
      handleNext,
      downloadTextFile,
      textToCopy,
      onTextCopy,
      is2FAEnabled,
      showCancelButton,
      fetchExistingBackupCodes,
      handleRegenerateCodes,
      resetState,
      printBackupCode,
      filterKey,
    }
  },
})
</script>
