<script>
import { mapGetters } from 'vuex'
import { EMPTY_DEVICES_ERROR } from '@common/constants/messages'

export default {
  components: {},

  data () {
    return {
      tabStatus: {
        first: true,
        second: false,
        third: false
      }
    }
  },

  created () {},
  computed: {
    ...mapGetters([
      'getInstagramConnectorAccount',
      'getMobileDevices',
      'getFetchDevicesLoader'
    ])
  },
  mounted () {},

  methods: {
    isTabActive (tab) {
      return this.tabStatus[tab]
    },
    isTabActionCompleted (tab) {
      if (tab === 'first') {
        return !this.isTabActive('first')
      }
      return !this.isTabActive('first') && !this.isTabActive('second')
    },
    async changeActiveInstagramConnectionTab (tab) {
      console.debug('Method::changeActiveInstagramConnectionTab', tab)

      switch (tab) {
        case 1:
          this.tabStatus.first = true
          this.tabStatus.second = false
          this.tabStatus.third = false
          break
        case 2:
          this.tabStatus.first = false
          this.tabStatus.second = true
          this.tabStatus.third = false
          break
        case 3:
          await this.$store.dispatch('fetchMobileDevices')
          if (this.getMobileDevices.length > 0) {
            this.tabStatus.first = false
            this.tabStatus.second = false
            this.tabStatus.third = true
          } else {
            this.alertMessage(EMPTY_DEVICES_ERROR, 'error')
          }
          break
        default:
          this.tabStatus.first = true
          this.tabStatus.second = false
          this.tabStatus.third = false
          this.$bvModal.hide('instagram-connect-modal')
      }
    }
  }
}
</script>

<template>
  <div class="modal_body basic_form">
    <div class="instta_steps_block">
      <div class="insta_steps_inner">
        <div class="top_section">
          <div class="steps_count">
            <div class="count_inner">
              <a
                class="d-flex align-items-center justify-content-center"
                :class="{
                  active: isTabActive('first') === true,
                  past: isTabActionCompleted('first'),
                }"
              >
                <span>1</span>
              </a>
              <a
                class="d-flex align-items-center justify-content-center"
                :class="{
                  active: isTabActive('second') === true,
                  past: isTabActionCompleted('second'),
                }"
              >
                <span>2</span>
              </a>
              <a
                class="d-flex align-items-center justify-content-center"
                :class="{ active: isTabActive('third') === true }"
              >
                <span>3</span>
              </a>
            </div>
          </div>
        </div>

        <div
          class="step_one step_block"
          :class="{ 'd-none': !isTabActive('first') }"
        >
          <div class="text_section">
            <h2>Download Our App</h2>
            <p
              >To schedule posts on Instagram, you need to install our
              <a target="_blank" rel="noopener"  href="https://trk.contentstudio.io/cs-ios-app"> iOS </a> or
              <a target="_blank" rel="noopener"  href="http://trk.contentstudio.io/cs-android-app"
                >Android App</a
              >
              on your device. This app will be sending posts to Instagram when
              the time comes.</p
            >
            <p
              >Your Instagram account
              <template
                v-if="
                  getInstagramConnectorAccount &&
                  getInstagramConnectorAccount.username
                "
              >
                @<span class="bold">{{
                  getInstagramConnectorAccount.username
                }}</span></template
              >
              is now connected.
            </p>
          </div>

          <div
            v-if="
              getInstagramConnectorAccount &&
              getInstagramConnectorAccount.username
            "
            class="profile_picture_section"
          >
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  class="img"
                  :style="{
                    background:
                      'url(' +
                      instagramImage(getInstagramConnectorAccount) +
                      ')',
                  }"
                ></div>
              </div>
              <div class="text_block">
                <p class="text">{{ getInstagramConnectorAccount.full_name }}</p>
                <p class="sub_text">Profile</p>
              </div>
            </div>
          </div>

          <div class="step_img">
            <img src="@src/assets/img/settings/insta_steps/step1.png" alt="" />
          </div>

          <div class="app_btn">
            <a
              target="_blank" rel="noopener"
              href="https://trk.contentstudio.io/cs-ios-app"
              class=""
            >
              <img
                src="@src/assets/img/settings/apple_store.jpg"
                alt=""
              />
            </a>
            <a
              target="_blank" rel="noopener"
              href="http://trk.contentstudio.io/cs-android-app"
              class="ml-2"
            >
              <img
                src="@src/assets/img/settings/google_play.jpg"
                alt=""
              />
            </a>
          </div>

          <div class=" text-center mt-5">
            <button
              class="mr-3 btn btn-studio-theme-grey-space btn-size-large"
              disabled="disabled"
              >Back</button>
            <button
              class="btn btn-studio-theme-space  btn-size-large"
              @click="changeActiveInstagramConnectionTab(2)"
            >
              Next Step
            </button>
          </div>
        </div>

        <div
          class="steps_two step_block"
          :class="{ 'd-none': !isTabActive('second') }"
        >
          <div class="text_section">
            <h2>SignIn to Connect Web and Mobile App</h2>
          </div>
          <div class="grid grid-cols-2">
            <div class="step_img">
              <img src="@src/assets/img/settings/insta_steps/step2.png" alt="" />
            </div>
            <div class="bottom_text flex justify-start flex-col">
              <p class="bold text-left"
              >Before posting to Instagram, sign in to your instagram app. To do
                it, follow these quick steps:</p
              >
              <ul class="list">
                <li>Open the ContentStudio app on your mobile device.</li>
                <li>Enter your Email & Password.</li>
                <li>Tap on SignIn button.</li>
              </ul>
            </div>
          </div>
          <div class="text-center mt-5">
            <button
                class="mr-3 btn btn-studio-theme-grey-space btn-size-large"
                @click="changeActiveInstagramConnectionTab(1)"
            >Back
            </button>
            <button
                class="btn btn-studio-theme-space  btn-size-large"
                :class="{ active: getFetchDevicesLoader }"
                :disabled="getFetchDevicesLoader"
                @click="changeActiveInstagramConnectionTab(3)"
            >
              Next Step
              <clip-loader
                  v-if="getFetchDevicesLoader"
                  :color="'#ffffff'"
                  :size="'16px'"
              ></clip-loader>
            </button>
          </div>

        </div>

        <div
          class="steps_three step_block"
          :class="{ 'd-none': !isTabActive('third') }"
        >
          <div class="text_section">
            <h2>Now you can Schedule posts!</h2>
          </div>
          <div class="steps_img_col">
            <div class="d-flex align-items-center img_only">
              <div class="step_img">
                <img
                  style="max-width: 90%"
                  src="@src/assets/img/settings/insta_steps/step3_1.png"
                  alt=""
                />
              </div>
              <div class="step_img">
                <img
                  src="@src/assets/img/settings/insta_steps/step3_2.png"
                  alt=""
                />
              </div>
              <div class="step_img">
                <img
                  src="@src/assets/img/settings/insta_steps/step3_3.png"
                  alt=""
                />
              </div>
            </div>
            <div class="d-flex align-items-start text_only">
              <div class="step_img">
                <p
                  >Compose and schedule your post from the web-version of
                  ContentStudio.</p
                >
              </div>
              <div class="step_img">
                <p
                  >You’ll receive a notification/reminder on your mobile device
                  at the scheduled time.</p
                >
              </div>
              <div class="step_img">
                <p
                  >Follow the steps on the ContentStudio mobile app to share
                  your post on Instagram.</p
                >
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <button
              class="mr-3 btn btn-studio-theme-grey-space btn-size-large"
              @click="changeActiveInstagramConnectionTab(2)"
              >Back
            </button>
            <button
              class="btn btn-studio-theme-space  btn-size-large"
              @click="changeActiveInstagramConnectionTab()"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
