// Importing necessary modules and components
import { computed, ref } from 'vue' // Vue composition API functions
import proxy from '@common/lib/http-common' // HTTP proxy for API calls
import moment from 'moment' // Date manipulation library
import { useStore } from '@state/base' // Vuex store
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics' // Custom analytics composable
import useNumber from '@common/composables/useNumber' // Number formatting utility
import useDateFormat from '@common/composables/useDateFormat' // Date formatting utility

// Importing icon assets
import FollowersGrowthIcon from '@/src/assets/img/icons/analytic/colored/outlined/followers-color-outlined-icon.svg'
import ReachIcon from '@/src/assets/img/icons/analytic/colored/outlined/reach-color-outlined-icon.svg'
import PageViewsColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-pink-color-outlined-icon.svg'
import TotalPostColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/post-color-outlined-icon.svg'
import EngagementColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/engagement-color-outlined-icon.svg'
import PostLikesColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-yellow-color-outlined-icon.svg'
import PageCommentColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/comment-green-color-outlined-icon.svg'
import PostCommentColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/comment-purple-color-outlined-icon.svg'
import PageSharesColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/shares-blue-color-outlined-icon.svg'
import PostSharesColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/shares-red-color-outlined-icon.svg'
import EngagementRateColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/percent-color-outlined-icon.svg'
import PostClicksColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/clicks-color-outlined-icon.svg'

// Importing helper functions
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

// Importing more icon assets
import EngagementIcon from '@/src/assets/img/icons/analytic/plain/total-engagement.svg'
import CommentsIcon from '@/src/assets/img/icons/analytic/plain/comments-icon.svg'
import SharesIcon from '@/src/assets/img/icons/analytic/plain/shares.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/plain/post-impressions.svg'
import PostTypeIcon from '@/src/assets/img/icons/analytic/plain/post-type.svg'
import HeartIcon from '@/src/assets/img/icons/analytic/plain/heart.svg'
import ReachPlainIcon from '@/src/assets/img/icons/analytic/plain/reach.svg'

// Importing utility functions
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
const { momentWrapper } = useDateFormat()
const { camelToSnake } = useComposerHelper()
const { getAccountId, getPostImage } = useAnalyticsUtils()
const { formatNumber } = useNumber()
const { ANALYTICS_DELIMITER } = useAnalytics()

// Map to keep track of ongoing API requests
const ongoingRequests = new Map()

// Reactive references for selected account and posts limit
const selectedAccount = ref(null)
const postsLimit = ref(10)

// Reactive reference for loading states of various components
const isLoadingStates = ref({})

// Mapping of media types to their display names
const mediaTypeMap = {
  link: 'Link',
  text: 'Text',
  images: 'Image',
  carousel: 'Carousel',
  videos: 'Video',
}

// API endpoint routes for different data types
const routes = {
  SUMMARY: 'summary',
  OVERVIEW_AUDIENCE_GROWTH: 'audienceGrowth',
  OVERVIEW_PUBLISHING_BEHAVIOUR: 'publishingBehaviour',
  OVERVIEW_TOP_POSTS: 'topPosts',
  OVERVIEW_HASHTAGS: 'hashtags',
  OVERVIEW_POSTS_PER_DAY: 'postsPerDays',
  OVERVIEW_FOLLOWERS_DEMOGRAPHICS: 'followersDemographics',
  TOP_POSTS: 'getTopPosts',
}

// Types of data rollups
const rollupTypes = {
  AUDIENCE: 'audience',
  HASHTAGS: 'hashtags',
}

// Importing default date range from analytics module
const { DEFAULT_DATE_RANGE } = useAnalytics()

// Reactive reference for date range selection
const dateRange = ref(DEFAULT_DATE_RANGE)

// Reactive references for various data fetched from API
const cardsData = ref({})
const audienceGrowthData = ref({})
const overviewTopPostsData = ref({})
const overviewPublishingBehaviourData = ref({})
const overviewHashtagsData = ref({})
const overviewPostsPerDay = ref({})
const overviewFollowersDemographicsData = ref({})
const topPostsData = ref([])

// List of cards to display with their corresponding data keys, tooltips, and icons
const cards = ref([
  {
    title: 'Followers',
    key: 'followers',
    tooltip:
      'The total number of users following your LinkedIn page from its creation to the selected end date.',
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Page Views',
    key: 'page_views',
    tooltip:
      'The total number of times your LinkedIn page was viewed by from its creation to the selected end date.',
    icon: PageViewsColorIcon,
  },
  {
    title: 'Page Reach',
    key: 'page_reach',
    tooltip:
      'The total number of unique users who saw any content from your LinkedIn page from its creation to the selected end date.',
    icon: ReachIcon,
  },
  {
    title: 'Page Comments',
    key: 'page_comments',
    tooltip:
      'The total number of comments on posts published from your LinkedIn page from its creation to the selected end date.',
    icon: PageCommentColorIcon,
  },
  {
    title: 'Page Shares',
    key: 'page_shares',
    tooltip:
      'The total number of times content from your LinkedIn page was shared by users from its creation to the selected end date.',
    icon: PageSharesColorIcon,
  },
  {
    title: 'Total Posts',
    key: 'total_posts',
    tooltip:
      'The total number of posts you have published on your LinkedIn page during the selected time period.',
    icon: TotalPostColorIcon,
  },
  {
    title: 'Post Engagement',
    key: 'total_engagement',
    tooltip:
      'The total number of interactions (likes, comments, shares) on your LinkedIn posts during the selected time period.',
    icon: EngagementColorIcon,
  },
  {
    title: 'Post Likes',
    key: 'post_likes',
    tooltip:
      'The total number of likes your posts received on LinkedIn during the selected time period.',
    icon: PostLikesColorIcon,
  },
  {
    title: 'Post Comments',
    key: 'post_comments',
    tooltip:
      'The total number of comments left on your LinkedIn posts during the selected time period.',
    icon: PostCommentColorIcon,
  },
  {
    title: 'Post Shares',
    key: 'post_shares',
    tooltip:
      'The total number of times users shared your LinkedIn posts during the selected time period.',
    icon: PostSharesColorIcon,
  },
  {
    title: 'Post Clicks',
    key: 'post_clicks',
    tooltip:
      'The total number of times users clicked on your LinkedIn posts during the selected time period.',
    icon: PostClicksColorIcon,
  },

  {
    title: 'Engagement Rate',
    key: 'engagement_rate',
    tooltip:
      'The average engagement (likes, comments, shares) received per post on your LinkedIn page during the selected time period.',
    icon: EngagementRateColorIcon,
  },
])

// Keys for audience growth rollup data
const audienceRollupKeys = ref([
  {
    title: 'Followers',
    key: 'total_follower_count',
    tooltip:
      '<b>Followers</b><br/>Shows the total number of followers of you instagram page, along with the percentage increase or decrease compared to the previous period.',
  },
  {
    title: 'Organic Followers',
    key: 'organic_follower_count',
    tooltip:
      '<b>Organic Followers</b><br/>Shows the total number of new followers gained on your Instagram page during the selected period. This value can be positive (indicating an increase in followers) or negative (indicating a decrease in followers), along with the percentage change compared to the previous period.',
  },
  {
    title: 'Paid Followers',
    key: 'paid_follower_count',
    tooltip:
      '<b>Paid Followers</b><br/>Shows the total number of new followers gained on your Instagram page during the selected period. This value can be positive (indicating an increase in followers) or negative (indicating a decrease in followers), along with the percentage change compared to the previous period.',
  },
  {
    title: 'Avg. Followers',
    key: 'avg_follower_count',
    tooltip:
      '<b>Average Followers</b><br/>Shows the total number of new followers gained on your Instagram page during the selected period. This value can be positive (indicating an increase in followers) or negative (indicating a decrease in followers), along with the percentage change compared to the previous period.',
  },
])
// Keys for audience growth rollup data
const hashtagsRollupKeys = ref([
  {
    title: 'Hashtags',
    key: 'total_hashtags',
    tooltip:
      '<b>Hashtag Used</b><br/>The total number of hashtags applied in your posts during the selected time period.',
  },
  {
    title: 'Times Used',
    key: 'total_times_used',
    tooltip:
      '<b>Times Used</b><br/>The total number of times hashtags were used across your posts within the selected time period.',
  },
  {
    title: 'Impressions',
    key: 'total_impressions',
    tooltip:
      '<b>Impressions</b><br/>The overall engagement (likes, comments, shares) generated by posts with hashtags during the selected time period.',
  },
  {
    title: 'Reach',
    key: 'total_reach',
    tooltip:
      '<b>Reach</b><br/>The overall engagement (likes, comments, shares) generated by posts with hashtags during the selected time period.',
  },
  {
    title: 'Engagement',
    key: 'total_engagement',
    tooltip:
      '<b>Engagement</b><br/>The overall engagement (likes, comments, shares) generated by posts with hashtags during the selected time period.',
  },
  {
    title: 'Likes',
    key: 'total_likes',
    tooltip:
      '<b>Likes</b><br/>The total number of likes received on posts with hashtags within the selected time period.',
  },
  {
    title: 'Comments',
    key: 'total_comments',
    tooltip:
      '<b>Comments</b><br/>The total number of comments on posts with hashtags during the selected time period.',
  },
  {
    title: 'Shares',
    key: 'total_shares',
    tooltip:
      '<b>Shares</b><br/>The total number of times posts with hashtags were saved by users during the selected time period.',
  },
])

// Function to get the formatted date range string
const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
    .join(' - ')
}

// Function to calculate growth and difference between current and previous values
const calculateGrowthAndDiff = (current, previous) => {
  if (!current || !previous) {
    return { growth: 0, difference: 0 }
  }
  const difference = current - previous || 0
  const divisor = previous > 0 ? previous : 1
  const growth =
    difference === 0 ? 0 : formatNumber((difference / divisor) * 100)
  return { growth, difference }
}

// Function to get data for a specific card
const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: card?.icon,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, previous } = cardsData.value
  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  let dataObject = {}
  if (card.polarityKeys) {
    const positive = current?.[card.polarityKeys.positive] || 0
    const negative = current?.[card.polarityKeys.negative] || 0
    const previousPositive = previous?.[card.polarityKeys.positive] || 0
    const previousNegative = previous?.[card.polarityKeys.negative] || 0

    const total = positive + negative
    const previousTotal = previousPositive + previousNegative
    const { growth, difference } = calculateGrowthAndDiff(total, previousTotal)

    dataObject = {
      showPositiveNegative: true,
      positive: formatNumber(positive),
      negative: formatNumber(negative),
      total: formatNumber(total),
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: total || defaultValue.originalValue,
    }
  } else {
    dataObject = {
      total: formatNumber(+current?.[card.key]) || defaultValue.total,
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: current?.[card.key] || defaultValue.originalValue,
    }
  }

  return {
    title: card.title,
    tooltip: card.tooltip,
    icon: card.icon,
    ...dataObject,
  }
}

// Function to get rollup data for audience, reels, or stories
const getRollupData = (dataSource, card, key) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: '',
  }

  const rollupData = dataSource.value?.[key]
  if (!rollupData) {
    return defaultValue
  }

  const { current = {}, previous = {} } = rollupData

  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  return {
    title: card.title,
    total: formatNumber(current?.[card.key]) || defaultValue.total,
    growth: growth || defaultValue.growth,
    difference: difference || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card?.tooltip || defaultValue.tooltip,
  }
}

// List of valid headers for the posts table
const validPostsTableHeaders = [
  'thumbnail',
  'mediaType',
  'impressions',
  'reach',
  'totalEngagement',
  'favorites',
  'comments',
  'repost',
]

// List of valid headers for the posts table in report view
const validPostsTableHeadersReport = [
  'thumbnail',
  'mediaType',
  'impressions',
  'reach',
  'totalEngagement',
  'favorites',
  'comments',
  'repost',
]

// Headers that are not sortable
const nonSortableItems = ['thumbnail', 'description', 'mediaType']

// Object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  mediaType: 'Media Type',
  totalEngagement: 'Engagements',
  impressions: 'Impressions',
  reach: 'Reach',
  favorites: 'Likes',
  comments: 'Comments',
  repost: 'Shares',
}

// Object mapping of header values to their corresponding tooltips
const headerTooltips = {
  mediaType: 'The format of the post— Text, Image, Carousel, Video or Link.',
  totalEngagement:
    'The total interactions (likes, comments, shares) this post received.',
  impressions: 'The total number of times this post was displayed to users.',
  reach: 'The total number of unique accounts that saw this post.',
  favorites: 'The total number of likes this post received.',
  comments: 'The total number of comments left on this post.',
  repost: 'The total number of times this post was shared by users.',
}

// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  thumbnail: (value) => value,
  mediaType: (value) => value,
  description: (value) => value,
  totalEngagement: (value) => formatNumber(value),
  impressions: (value) => formatNumber(value),
  reach: (value) => formatNumber(value),
  favorites: (value) => formatNumber(value),
  comments: (value) => formatNumber(value),
  repost: (value) => formatNumber(value),
}

// Method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// Method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// Method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

// Fields to display in the post modal
const postModalFields = [
  {
    label: 'Engagements',
    key: 'totalEngagement',
    iconSrc: EngagementIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `The total interactions (likes, comments, shares) this post received.`,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    iconSrc: ImpressionsIcon,
    iconClass: '',
    tooltip: `The total number of times this post was displayed to users.`,
  },
  {
    label: 'Reach',
    key: 'reach',
    iconSrc: ReachPlainIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `The total number of unique accounts that saw this post.`,
  },
  {
    label: 'Likes',
    key: 'favorites',
    iconSrc: HeartIcon,
    iconClass: '',
    tooltip: `The total number of likes this post received.`,
  },
  {
    label: 'Comments',
    key: 'comments',
    iconSrc: CommentsIcon,
    iconClass: '',
    tooltip: `The total number of comments left on this post.`,
  },
  {
    label: 'Shares',
    key: 'repost',
    iconSrc: SharesIcon,
    iconClass: '',
    tooltip: `The total number of times this post was shared by users.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: PostTypeIcon,
    iconClass: '',
    tooltip: `The format of the post— Text, Image, Carousel,  Video or Link.`,
  },
]

// Function to get rollup data keys and data retrieval function based on type
const getRollup = (type) => {
  switch (type) {
    case rollupTypes.AUDIENCE:
      return {
        keys: audienceRollupKeys.value,
        getData: (card) =>
          getRollupData(audienceGrowthData, card, 'audience_growth_rollup'),
      }
    case rollupTypes.HASHTAGS:
      return {
        keys: hashtagsRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewHashtagsData, card, 'top_hashtags_rollup'),
      }
    default:
      return {
        keys: [],
        getData: () => {},
      }
  }
}

// Function to get the API key for a header, used in sorting
const getHeaderApiKey = (header) => {
  if (tableSortableItems.value.includes(header)) {
    return camelToSnake(header)
  }
  return ''
}

// Computed property to check if media can be fetched
const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

// Computed property to get fan count (number of followers)
const fanCount = computed(() => {
  return cardsData.value?.current?.followers_count || 0
})

// Computed property to get sortable table items
const tableSortableItems = computed(() => {
  return validPostsTableHeaders.filter(
    (header) => !nonSortableItems.includes(header)
  )
})

// Function to set loading state for a specific type
const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

// Function to transform the fetched posts data
const transformObject = (data) => {
  // Assuming data is an array of posts
  return data.map((post) => {
    let thumbnail = getPostImage('linkedin', post)
    if (post?.media_type?.toLowerCase() === 'carousel') {
      thumbnail =
        'https://storage.googleapis.com/lumotive-web-storage/no-carousel-available-small.jpg'
    }
    return {
      id: post.linkedin_id,
      postId: post.post_id,
      activity: post.activity,
      mediaType: mediaTypeMap[post.media_type] || post.media_type,
      permalink: post.article_url,
      articleTitle: post.article_title,
      media: post.media,
      type: post.type,
      hashtags: post.hashtags,
      comments: parseInt(post.comments) || 0,
      totalEngagement: parseInt(post.total_engagement) || 0,
      favorites: parseInt(post.favorites) || 0,
      title: post.title,
      dayOfWeek: post.day_of_week,
      hourOfDay: post.hour_of_day,
      createdAt: post.created_at,
      storedEventAt: post.saving_time,
      pollData: post.poll_data,
      reach: parseInt(post.reach) || 0,
      repost: parseInt(post.repost) || 0,
      postClicks: parseInt(post.post_clicks) || 0,
      impressions: parseInt(post.impressions) || 0,
      description: post.title, // Using 'title' as 'description' since there's no 'caption' field
      thumbnail,
    }
  })
}

/**
 * Composable function for linkedin Analytics
 * @param {String} defaultLabel - Default label for data (optional)
 * @returns {Object} - Contains reactive properties and methods for linkedin analytics
 */
export default function useLinkedinAnalytics(defaultLabel = 'Data') {
  // Access Vuex store getters
  const { getters } = useStore()

  // Destructure functions and constants from useAnalytics composable
  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    generateStackedChartOptions,
  } = useAnalytics(defaultLabel)

  /**
   * Function to fetch media data based on type
   * @param {String} type - Type of data to fetch (e.g., 'summary', 'top_posts')
   * @param {Object} extraPayload - Additional payload for the request
   * @param {Boolean} append - Whether to append data or not
   */
  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      // Check if there's an ongoing request of the same type and abort it
      if (ongoingRequests.has(type)) {
        ongoingRequests.get(type).abort()
      }

      // Create a new AbortController for the new request
      const controller = new AbortController()
      const { signal } = controller
      ongoingRequests.set(type, controller)

      try {
        setIsloading(type, true)
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          accounts: [getAccountId(selectedAccount.value)],
          facebook_accounts: [],
          twitter_accounts: [],
          instagram_accounts: [],
          pinterest_accounts: [],
          linkedin_accounts: [],
          tiktok_accounts: [],
          youtube_accounts: [],
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          type,
          ...extraPayload,
        }
        const { data } = await proxy.post(
          BASE_ENDPOINT + 'linkedin/' + type,
          payload,
          { signal }
        )

        // Handle the data based on the type
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data?.overview || {}
            break
          case routes.OVERVIEW_AUDIENCE_GROWTH:
            audienceGrowthData.value = data || {}
            break
          case routes.OVERVIEW_TOP_POSTS:
            overviewTopPostsData.value = data?.top_posts || []
            break
          case routes.OVERVIEW_PUBLISHING_BEHAVIOUR:
            overviewPublishingBehaviourData.value = data || {}
            break
          case routes.OVERVIEW_HASHTAGS:
            overviewHashtagsData.value = data || {}
            break
          case routes.OVERVIEW_POSTS_PER_DAY:
            overviewPostsPerDay.value = data?.posts_per_days?.data?.days
            break
          case routes.OVERVIEW_FOLLOWERS_DEMOGRAPHICS:
            overviewFollowersDemographicsData.value =
              data?.follower_demographics || {}
            break
          case routes.TOP_POSTS:
            topPostsData.value = transformObject(data?.top_posts) || []
            break
          default:
            break
        }
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log(`Request for ${type} was aborted`)
          return
        } else {
          console.error('FETCH DATA:::', e)
        }
      }
      setIsloading(type, false)
      // Clean up the ongoing request map
      ongoingRequests.delete(type)
    }
  }

  /**
   * Function to format time string to readable format
   * @param {String} timeString - Time string to format
   * @returns {String} - Formatted time string
   */
  const getTimeFormatted = (timeString) => {
    return moment(timeString).format('MMMM Do, YYYY hh:mm A')
  }

  // Return all reactive properties and methods
  return {
    // Constants
    ANALYTICS_DELIMITER,
    analyticsDesignSystem,
    // Variables
    routes,
    rollupTypes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,
    postsLimit,
    postModalFields,
    tableSortableItems,
    // State
    fanCount,
    selectedAccount,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    isReportView,
    screenWidth,
    isLoadingStates,
    audienceGrowthData,
    overviewPostsPerDay,
    overviewTopPostsData,
    overviewHashtagsData,
    overviewPublishingBehaviourData,
    overviewFollowersDemographicsData,
    topPostsData,
    audienceRollupKeys,
    validPostsTableHeaders,
    validPostsTableHeadersReport,
    nonSortableItems,
    mediaTypeMap,
    cardsData,
    // Methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getTimeFormatted,
    tooltipFormatterScroll,
    setIsloading,
    getRollup,
    getHeaderTitles,
    getHeaderTooltips,
    getBodyValues,
    getHeaderApiKey,
    generateStackedChartOptions,
  }
}
