<!-- ExternalCalendarEvent.vue -->
<template>
  <div class="p-1">
    <div v-if="structuredPlan && type === 'plan'">
      <div class="v-calendar-view">
        <div
            v-if="structuredPlan"
            class="event_inner cursor-pointer "
            @click.stop="emit('preview-plan', event?.extendedProps)"
        >
          <div class="top_tags_category mx-1">
            <div
                v-if="structuredPlan?.contentCategory?.name"
                v-tooltip.top="structuredPlan?.contentCategory?.name"
                class="top-category !px-2 !py-1 !text-sm !font-normal !rounded-md"
                :style="{
            'background-color': structuredPlan?.contentCategory?.color_code,
          }"
            >{{ structuredPlan?.contentCategory?.name }}
            </div>
          </div>

          <div class="event_head min-w-[200px] d-flex align-items-center">
            <div class="head_left">
              <p class="d-flex align-items-center">
                <img
                    v-tooltip="structuredPlan?.render?.tooltip"
                    :src="getPostStateImage(structuredPlan)"
                    alt="status"
                    class="status_color left-icon !w-4 !h-4 d-flex align-items-center justify-content-center"
                />
                <span>{{ formatTime(structuredPlan?.executionTime) }}</span>
              </p>
            </div>
            <div class="head_right">
              <template v-if="structuredPlan.selectedAccounts.length">
                <template
                    v-for="(account, index) in structuredPlan.selectedAccounts.slice(
                0,
                2
              )"
                    :key="index"
                >
              <span
                  v-tooltip="account.name"
                  class="relative inline-block mr-1.5"
              >
                <img
                    :src="account.image"
                    alt="DP"
                    class="rounded-full object-cover border pointer-events-none w-6 h-6"
                    onerror="this.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                />
                <img
                    :src="getSocialImageRounded(account.platform)"
                    :alt="account.platform"
                    class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                />
              </span>
                </template>
                <template v-if="structuredPlan.selectedAccounts.length > 2">
              <span class="pt-1">
                +{{ structuredPlan.selectedAccounts.length - 2 }}
              </span>
                </template>
              </template>

              <div v-else>—</div>
            </div>
          </div>

          <div class="event_content">
            <p
                v-if="structuredPlan.message"
                v-html="getCalanderPostText(structuredPlan?.message, 100)"
            ></p>
            <div class="img preview-here relative">
              <!--data-plan will be use to find plan media to display in the modal-->
              <img
                  v-if="structuredPlan.mediaType === 'image'"
                  id="viewItemAttachment"
                  :src="structuredPlan.media[0]"
                  :data-plan="event?._id"
                  alt="img"
                  @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
                  @click.stop="emit('on-lightbox', 'image', structuredPlan.media)"
              />

              <img
                  v-else-if="structuredPlan.mediaType === 'video'"
                  id="viewItemAttachment"
                  :src="structuredPlan.media?.thumbnail"
                  :data-plan="event?._id"
                  alt="img"
                  @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
            "
              />

              <div v-if="structuredPlan?.mediaType" class="absolute font-bold leading-tight bottom-1 right-2 p-1 bg-black-100 opacity-70 text-white rounded-md text-center text-capitalize">{{ structuredPlan.mediaType }}</div>

            </div>

            <div class="filter_label">
              <template v-for="(label, key) in structuredPlan?.labels">
                <div
                    v-if="label"
                    :key="key"
                    v-tooltip="label?.name"
                    class="label_tag !px-2 !py-1 !text-sm !rounded-md"
                    :style="getLabelColor(label.color)"
                >{{ label?.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="v-note-view mb-2">
      <div
          :style="{
      backgroundColor: `${getLighterColor(structuredPlan.note_color)}`,
      border: `1px solid ${structuredPlan.note_color}`,
    }"
          :id="generateUniqueId(structuredPlan._id)"
          class="event_content p-2 rounded-lg cursor-pointer"
          title="View note"
      >
        <div class="flex items-center justify-between leading-none">
          <div class="flex-1 min-w-0 mr-2"> <!-- Added flex-1 and min-w-0 for proper truncation -->
            <span class="text-black-700 block truncate">
            <strong>{{ getNoteBlockTitle(structuredPlan).title }}</strong>
            <template v-if="getNoteBlockTitle(structuredPlan).description">
        - <span class="font-normal">{{ getNoteBlockTitle(structuredPlan).description }}</span>
      </template>
        </span>
          </div>
          <span
              v-if="structuredPlan.start_date !== structuredPlan.end_date"
              class="text-black-500 whitespace-nowrap flex-shrink-0">
            {{ formatNotesDate(structuredPlan.start_date, timeZone) }} - {{ formatNotesDate(structuredPlan.end_date, timeZone) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import moment from 'moment'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import {
  convertToWorkspaceTimeZone,
} from '@common/lib/helper'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import useLabelAndCampaignAnalytics from '@modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'
import {formatNotesDate} from '@common/lib/helper'

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  timeZone:{
    type: String,
    required: true
  },
  type: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['preview-plan', 'on-lightbox'])

const {
  getPostStateImage,
  renderClass,
  getCalanderPostText,
  generateUniqueId,
  getNoteBlockTitle,
  getLighterColor
} = usePlannerHelper()

const { getSocialImageRounded } = useComposerHelper()
const { getLabelColor } = useLabelAndCampaignAnalytics()

const structuredPlan = computed(() => {
  const item = props.event?.extendedProps
  console.log('structuredPlan', props.event?.extendedProps)

  if(props.type === 'note') {
    return item
  }

  const data = {
    id: item._id,
  }

  // Post Type
  if (
    item.type === 'Composer Social' ||
    item.type === 'Social Content' ||
    item.type === 'Evergreen Automation' ||
    ((item.type === 'Automation Article' || item.type === 'Automation Video') &&
      'account_selection' in item)
  ) {
    data.planType = 'Social Post'
  } else if (
    item.type === 'Composer Article' ||
    ((item.type === 'Automation Article' || item.type === 'Automation Video') &&
      'blog_selection' in item)
  ) {
    data.planType = 'Blog Post'
  } else {
    data.planType = item.type
  }

  if (data.planType === 'Blog Post') {
    data.message = item.post.title ?? ''

    if (item?.post?.image?.link) {
      data.media = [item.post.image.link]
      data.mediaType = 'image'
    }
  } else {
    // if the common box is checked
    if (item.common_box_status) {
      const sharingDetails = item.common_sharing_details

      // Plan message
      data.message = sharingDetails?.message ?? ''

      // Plan media
      if (sharingDetails.image.length > 0) {
        data.media = sharingDetails.image
        data.mediaType = 'image'
      } else if (sharingDetails.video.link) {
        data.media = sharingDetails.video
        data.mediaType = 'video'
      } else {
        data.media = null
      }
    } else {
      // loop through the accounts selection object and pick the first one for the details
      for (const account in item.account_selection) {
        if (item.account_selection[account].length > 0) {
          const sharingDetails = item[`${account}_sharing_details`]
          // Plan message
          data.message = sharingDetails?.message ?? ''

          // Plan media
          if (
            sharingDetails.image &&
            typeof sharingDetails.image === 'string'
          ) {
            data.media = [sharingDetails.image]
            data.mediaType = 'image'
          } else if (sharingDetails.image.length > 0) {
            data.media = sharingDetails.image
            data.mediaType = 'image'
          } else if (sharingDetails.video.link) {
            data.media = sharingDetails.video
            data.mediaType = 'video'
          }

          break
        }
      }
    }
    if (item.linkedin_options?.is_carousel) {
      if (item.linkedin_options?.document_added) {
        data.media = [item.linkedin_options.document?.thumbnail]
      }
      data.mediaType = 'carousel'
    }

    if (
      item.carousel_options?.is_carousel_post &&
      item.carousel_options?.cards[0]?.picture
    ) {
      data.media = [item.carousel_options.cards[0].picture]
      data.mediaType = 'carousel'
    }
  }

  // Post Created By
  data.createdBy = item.added_by

  // Selected Accounts, also structuring the selected accounts for the table row
  data.selectedAccounts = []

  if (data.planType === 'Blog Post' && item.blog_details) {
    data.selectedAccounts.push({
      image: item.blog_details.platform_logo,
      name: item.blog_details.platform_name,
      truncateName:
        item.blog_details.platform_name.length > 15
          ? `${item.blog_details.platform_name.substring(0, 15)}...`
          : item.blog_details.platform_name,
      platform: item.blog_details.platform_type,
    })
  } else {
    for (const account in item.account_selection) {
      if (item?.account_selection[account].length > 0) {
        const accountDetails = item?.account_selection[account].map(
          (detail) => {
            let image = detail.image
            let name = detail.platform_name

            if (!image) {
              if (account === 'gmb') {
                image =
                  'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
              } else if (
                account === 'youtube' ||
                account === 'tumblr' ||
                account === 'tiktok' ||
                account === 'threads'
              ) {
                image = detail.platform_logo
              } else {
                image =
                  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              }
            }

            if (!name) {
              name = detail.name
            }

            return {
              ...detail,
              image,
              name,
              truncateName:
                name.length > 15 ? `${name.substring(0, 15)}...` : name,
              platform: account,
            }
          }
        )
        data.selectedAccounts.push(...accountDetails)
      }
    }
  }


  // Post Execution Time (Converted to 'DD MMM YY, hh:mm A' format)
    data.executionTime = convertToWorkspaceTimeZone(
        item.execution_time?.date,
        props.timeZone
    )

  // Post Status
  data.render = renderClass(item)
  data.status = data.render?.tooltip

  data.post_state = item.post_state

  if (item.partially_failed) {
    data.partially_failed = item.partially_failed
  }

  data.contentCategory = item?.content_category

  data.labels = item?.labels_info
  return data
})

const formatTime = (date) => {
  return moment.utc(date).format('hh:mm a')
}
</script>
