<template>
  <b-modal
    id="media-storage-limits-exceeded-modal"
    size="xl"
    :modal-class="'instaConnect modal fade normal_modal'"
    dialog-class="max_width_for_modal_1000"
    hide-header
    hide-footer
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Subscription Limits Have Been Exceeded</h2>
      <button
        v-tooltip.bottom="'Close'"
        type="button"
        class="close button-custom"
        data-dismiss="modal"
        @click="$bvModal.hide('media-storage-limits-exceeded-modal')"
      >
        &times;
      </button>
    </div>
    <div class="modal-content">
      <div class="modal_body basic_form m_t_15">
        <div class="flex-row">
          <div class="col-12 text-center">
            <p>
              Hi {{ getProfile.firstname }}, we have noticed that the
              limitations of your subscription have been exceeded.</p
            >
            <p>
              As per your subscription plan you are allowed
              {{
                bytesToSize(
                  getPlan.subscription.limits
                    ? getPlan.subscription.limits.media_storage
                    : 0,
                )
              }}
              of which you have consumed
              {{
                bytesToSize(
                  getPlan.used_limits ? getPlan.used_limits.media_storage : 0,
                )
              }}
            </p>
          </div>
        </div>
        <h3 class="text-center mt-5 mb-3">What you can do to solve this?</h3>
        <div class="flex-row margin-auto d-flex">
          <div class="col text-center center_both_blocks">
            <div class="grey-box center_child height-auto">
              <div class="subscription-plan-limits height-100">
                <div
                  class="text_block_container height-100 d-flex flex-column justify_space_between"
                >
                  <h5 v-if="!isSuperAdmin" class="ml-0">
                    <b><u>Option 1:</u></b>

                    Please notify the account owner
                    <b
                      ><a :href="sanitizeUrl('mailto:' + getSuperAdminDetails().email)">{{
                        getSuperAdminDetails().email
                      }}</a></b
                    >
                    to settle this account by either increasing your limits (via
                    add-ons) or removing the extra limits to continue using the
                    account.
                  </h5>
                  <h5 v-else class="ml-0"
                    ><b><u>Option 1:</u></b> Click on the button below to
                    increase your limits with add-ons</h5
                  >
                  <div
                    v-if="isSuperAdmin && !getPlan?.subscription?.paddle_billing"
                    class="text_center"
                    @click="showLimitModal"
                  >
                    <b-button variant="studio-theme-space"
                      >Increase Limits</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="grey-box center_child height-auto">
              <div class="subscription-plan-limits height-100">
                <div
                  class="text_block_container ml-0 height-100 d-flex flex-column justify_space_between"
                >
                  <h5
                    ><b><u>Option 2:</u></b> Remove extra items to stay within
                    your limits</h5
                  >
                  <!--                                  $route.name !== 'composerSocial'-->
                  <template v-if="$route.name === 'composerSocial'">
                    <i>Note: The post will be saved as Draft in planner.</i>
                  </template>

                  <div class="text_center" @click="manageLimitHandler">
                    <b-button variant="studio-theme-space"
                      >Manage Limits</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from '@src/modules/common/lib/event-bus'
import { mapGetters } from 'vuex'
import { sanitizeUrl } from '@braintree/sanitize-url'

export default {
  props: ['isSuperAdmin'],
  computed: {
    ...mapGetters(['getProfile', 'getPlan', 'getWorkspaces']),
  },
  methods: {
    sanitizeUrl,
    showLimitModal() {
      this.$bvModal.hide('media-storage-limits-exceeded-modal')
      this.$bvModal.show('increase-limits-dialog')
    },
    manageLimitHandler() {
      if (this.$route.name === 'composerSocial') {
        EventBus.$emit('save_draft')
      }
      this.$bvModal.hide('media-storage-limits-exceeded-modal')
      this.$bvModal.hide('upload-media-modal')
      $('#addEvergreenPost').modal('hide')
      this.$router.push({ name: 'media-library', query: { type: 'all' } })
    },
    getSuperAdminDetails() {
      const teamMembers = this.getWorkspaces.activeWorkspace.members

      if (teamMembers) {
        for (let i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (member.role === 'super_admin') {
            return member.user
          }
        }
      }

      return {
        email: '',
      }
    },
  },
}
</script>

<style lang="less" scoped>
.center_both_blocks {
  display: flex !important;
}
.center_child {
  flex: 1;
}
.text_block_container {
  text-align: start;
  margin-top: 20px;
}
</style>
