<script>
import { mapGetters } from 'vuex'
import {
  SAVE_PLATFORMS_SUCCESS,
  SAVE_PLATFORMS_ERROR,
} from '@common/constants/messages'
import { platformImage } from '@common/lib/integrations'
import {
  facebook,
  linkedin,
  pinterest,
  gmb,
  blog,
  tumblr,
  instagram,
  youtube,
} from '@src/modules/integration/store/states/mutation-types'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import AccountSelectionDropdown from '@src/components/common/AccountSelectionDropdown.vue'
import { EventBus } from '@common/lib/event-bus'
import {sanitizeUrl} from "@braintree/sanitize-url";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AccountSelectionDropdown,
  },

  data() {
    return {
      selectedPlatform: {
        firstItems: [],
        secondItems: [],
      },
      selectAll: false,
    }
  },
  computed: {
    ...mapGetters([
      'getPlatformsConnectionState',
      'getSavePlatformsLoader',
      'getWorkspaces',
      'getMobileDevices',
    ]),

    isFacebookConnection() {
      return this.platformName === 'Facebook'
    },

    isWebflowConnection() {
      return this.platformName === 'Webflow'
    },

    isInstagramConnection() {
      return this.platformName === 'Instagram'
    },

    platformName() {
      /* eslint-disable */
      return this.getPlatformsConnectionState &&
        this.getPlatformsConnectionState.process
        ? _.startCase(this.getPlatformsConnectionState.process)
        : ''
      /* eslint-enable */
    },

    platformConnectionTitle() {
      switch (this.platformName) {
        case 'Instagram':
          return 'Choose Instagram business profiles you want to connect.'
        case 'Facebook':
          return 'Choose Facebook pages and groups you want to connect.'
        case 'Pinterest':
          return 'Choose Pinterest boards you want to connect.'
        case 'Linkedin':
          return 'Choose Linkedin profile and pages you want to connect.'
        case 'Tumblr':
          return 'Choose Tumblr profile and blogs you want to connect.'
        case 'Gmb':
          return 'Choose Google My Business Locations.'
        case 'Medium':
          return 'Choose Medium blogs and publications you want to connect.'
        case 'Shopify':
          return 'Choose Shopify blogs you want to connect.'
        case 'Webflow':
          return 'Choose Webflow sites you want to connect.'
        case 'Youtube':
          return 'Choose Youtube channels you want to connect.'
      }
      return ''
    },

    firstSectionName() {
      switch (this.platformName) {
        case 'Instagram':
          return 'Business Profiles'
        case 'Facebook':
          return 'Pages'
        case 'Pinterest':
          return 'Boards'
        case 'Linkedin':
          return 'Profiles'
        case 'Tumblr':
          return 'Blogs'
        case 'Gmb':
          return 'Locations'
        case 'Medium':
          return 'Blogs'
        case 'Shopify':
          return 'Blogs'
        case 'Youtube':
          return 'Channels'
        case 'Webflow':
          return 'Sites'
      }
      return ''
    },

    sectionFirstItems() {
      switch (this.platformName) {
        case 'Instagram':
          return this.getPlatformsConnectionState.profiles
        case 'Facebook':
          return this.getPlatformsConnectionState.pages
        case 'Pinterest':
          return this.getPlatformsConnectionState.boards
        case 'Linkedin':
          return [this.getPlatformsConnectionState.profile]
        case 'Tumblr':
          return this.getPlatformsConnectionState.blogs
        case 'Gmb':
          return this.getPlatformsConnectionState.locations
        case 'Medium':
          return this.getPlatformsConnectionState.blogs
        case 'Shopify':
          return this.getPlatformsConnectionState.blogs
        case 'Webflow':
          return this.getPlatformsConnectionState.blogs
        case 'Youtube':
          return this.getPlatformsConnectionState.channels
      }
      return []
    },

    firstSectionItemsExists() {
      return this.sectionFirstItems && this.sectionFirstItems.length
    },

    nullFirstSectionText() {
      switch (this.platformName) {
        case 'Instagram':
          return 'No Instagram business profile found.'
        case 'Facebook':
          return 'No Facebook page found.'
        case 'Pinterest':
          return 'No Pinterest boards found.'
        case 'Linkedin':
        case 'Tumblr':
          return 'No ' + this.platformName + ' blogs found.'
        case 'Gmb':
          return 'No location found.'
        case 'Medium':
          return 'No Medium blogs found.'
        case 'Shopify':
          return 'No Shopify blogs found.'
        case 'Youtube':
          return 'No Youtube channels found.'
      }
      return ''
    },

    secondSectionName() {
      switch (this.platformName) {
        case 'Facebook':
          return 'Groups'
        case 'Linkedin':
          return 'Pages'
        case 'Tumblr':
          return 'Profiles'
        case 'Medium':
          return 'Publications'
      }
      return ''
    },

    sectionSecondItems() {
      switch (this.platformName) {
        case 'Facebook':
          return this.getPlatformsConnectionState.groups
        case 'Linkedin':
          return this.getPlatformsConnectionState.pages
        case 'Tumblr':
          return this.getPlatformsConnectionState.profiles
        case 'Medium':
          return this.getPlatformsConnectionState.publications
      }
      return []
    },

    secondSectionItemsExists() {
      return this.sectionSecondItems && this.sectionSecondItems.length
    },

    nullSecondSectionText() {
      switch (this.platformName) {
        case 'Facebook':
          return 'No Facebook groups found'
        case 'Linkedin':
          return 'No Linkedin pages found'
        case 'Tumblr':
          return 'No Tumblr profile found'
        case 'Medium':
          return 'No medium publications found'
      }
      return ''
    },
  },

  watch: {
    selectAll(status) {
      console.debug('Watch::selectAll', status)
      this.selectedPlatform.firstItems = []
      this.selectedPlatform.secondItems = []
      if (
        this.getPlatformsConnectionState &&
        this.getPlatformsConnectionState.process &&
        status
      ) {
        let account = ''
        const stateObject = this
        if (this.sectionFirstItems) {
          this.sectionFirstItems.forEach(function (el, index) {
            account = stateObject.accountCheckbox(el)
            if (
              stateObject.selectedPlatform.firstItems.includes(account) ===
              false
            ) {
              stateObject.selectedPlatform.firstItems.push(account)
            }
          })
        }
        if (this.sectionSecondItems) {
          this.sectionSecondItems.forEach(function (el, index) {
            account = stateObject.accountCheckbox(el)
            if (
              stateObject.selectedPlatform.secondItems.includes(account) ===
              false
            ) {
              stateObject.selectedPlatform.secondItems.push(account)
            }
          })
        }
      }
    },

    getPlatformsConnectionState() {
      let account = ''
      const stateObject = this

      this.selectedPlatform = {
        firstItems: [],
        secondItems: [],
      }

      if (this.sectionFirstItems) {
        this.sectionFirstItems.forEach(function (el, index) {
          if (el.added) {
            account = stateObject.accountCheckbox(el)
            if (
              stateObject.selectedPlatform.firstItems.includes(account) ===
              false
            ) {
              stateObject.selectedPlatform.firstItems.push(account)
            }
          }
        })
      }

      if (this.sectionSecondItems) {
        this.sectionSecondItems.forEach(function (el, index) {
          if (el.added) {
            account = stateObject.accountCheckbox(el)
            if (
              stateObject.selectedPlatform.secondItems.includes(account) ===
              false
            ) {
              stateObject.selectedPlatform.secondItems.push(account)
            }
          }
        })
      }
    },
  },

  methods: {
    sanitizeUrl,
    /* eslint-disable no-prototype-builtins */
    hideModal() {
      this.$bvModal.hide('save_platforms_modal')
    },

    imageLink(item) {
      return platformImage(item)
    },

    accountCheckbox(item) {
      return item.platform_identifier
        ? item.platform_identifier
        : item.id
        ? item.id
        : item.instagram_id
        ? item.instagram_id
        : item.name
        ? item.name
        : ''
    },

    accountName(item) {
      if (item.hasOwnProperty('platform_name')) return item.platform_name

      if (
        item.hasOwnProperty('locationName') &&
        item.hasOwnProperty('address')
      ) {
        const name = item.locationName + ' - ' + item.address.locality
        return item.address.postalCode
          ? name + ' - ' + item.address.postalCode
          : name
      }

      if (item.hasOwnProperty('name')) {
        let name = item.name
        if (item.location && item.location.city) {
          name += ' (' + item.location.city
          if (item.location.country) name += ', ' + item.location.country
          return name + ')'
        } else if (item.location_name) {
          name += ' (' + item.location_name + ' )'
        }
        return name
      }
      if (item.hasOwnProperty('firstName')) {
        return item.firstName + ' ' + item.lastName
      }
      if (item.hasOwnProperty('first_name')) {
        return item.first_name + ' ' + item.last_name
      }
      if (item.hasOwnProperty('url')) return item.url
      if (item.hasOwnProperty('title')) return item.title
      return ''
    },

    accountPublishAs(item) {
      if (item.hasOwnProperty('posted_as')) return item.posted_as

      return []
    },

    sectionIdentifier(key, item) {
      return key + this.accountCheckbox(item)
    },

    async savePlatforms() {
      // Check if user select any account
      if (
        this.selectedPlatform.firstItems.length === 0 &&
        this.selectedPlatform.secondItems.length === 0
      ) {
        this.alertMessage('Please select some account(s) first.', 'error')
        return false
      }

      const data = JSON.parse(JSON.stringify(this.getPlatformsConnectionState))

      // Instagram Case
      if (this.platformName === 'Instagram') {
        const selectedAccounts = data.profiles.filter((value) => {
          return this.selectedPlatform.firstItems.includes(value.instagram_id)
        })
        console.log('connecting IG --> ', selectedAccounts)

        const response = await this.$store.dispatch(
          'processSaveIGBusinessProfile',
          {
            social_accounts: selectedAccounts,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          }
        )
        this.$bvModal.hide('save_platforms_modal')

        if (response && response.data) {
          if (response.data.status === true) {
            if (response.data.limitExceed) {
              this.showUpgradeModal()
            } else {
              this.alertMessage(SAVE_PLATFORMS_SUCCESS, 'success')

              if (
                !this.getActiveWorkspace.onboarding_steps.connect_social_account
                  .status
              ) {
                await this.onboardingStepsCompleted('connect_social_account')
              }
            }

            this.$store.dispatch('setPlatforms', {
              items: response.data.accounts,
              type: 'instagram',
              all_item_setter: instagram.SET_ALL_ACCOUNTS,
              item_setter: instagram.SET_ACCOUNTS,
            })
            this.Plan()
            if (this.getMobileDevices.length === 0) {
              this.$bvModal.show('instagram-connect-modal')
            }
          } else {
            if (response.data.limitExceed) {
              this.showUpgradeModal()
            } else if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage(SAVE_PLATFORMS_ERROR, 'error')
            }
          }
        }

        console.log('saved business .. -> ', response)
      } else {
        data.selectedPlatform = this.selectedPlatform
        data.workspace_id = this.getWorkspaces.activeWorkspace._id
        const response = await this.$store.dispatch('processSavePlatforms', {
          connectionState: data,
        })
        this.$bvModal.hide('save_platforms_modal')
        console.log('Data: ', response.data)
        if (response && response.data) {
          if (response.data.status === true) {
            if (response.data.limitExceed) {
              this.showUpgradeModal()
            } else {
              this.alertMessage(SAVE_PLATFORMS_SUCCESS, 'success')
              if (
                !this.getActiveWorkspace.onboarding_steps.connect_social_account
                  .status
              ) {
                await this.onboardingStepsCompleted('connect_social_account')
              }
            }

            if (blogIntegrationsNames.includes(data.process)) {
              if (data.process === 'tumblr') {
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items.accounts,
                  type: 'tumblr_profiles',
                  all_item_setter: tumblr.SET_ALL_ACCOUNTS,
                  item_setter: tumblr.SET_ACCOUNTS,
                })
                this.$store.commit(blog.SET_BLOGS, {
                  items: response.data.items.blogs,
                  type: data.process,
                })
                this.Plan()
                return false
              }

              if (data.process === 'webflow') {
                this.$store.commit(blog.SET_BLOGS, {
                  items: response.data.items.blogs,
                  type: data.process,
                })
                this.Plan()
                return false
              }

              this.$store.commit(blog.SET_BLOGS, {
                items: response.data.items,
                type: data.process,
              })
              this.Plan()
              return false
            }

            switch (data.process) {
              case 'Facebook':
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items,
                  type: 'facebook',
                  all_item_setter: facebook.SET_ALL_ACCOUNTS,
                  item_setter: facebook.SET_ACCOUNTS,
                })

                if (
                  this.groupsWithoutAppInstall(response.data.items).length > 0
                ) {
                  console.debug(
                    'showAppGroupModal',
                    this.groupsWithoutAppInstall(response.data.items)
                  )
                  EventBus.$emit('showAppGroupModal', {
                    groups: this.groupsWithoutAppInstall(response.data.items),
                  })
                }

                break
              case 'Pinterest':
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items,
                  type: 'pinterest',
                  all_item_setter: pinterest.SET_ALL_ACCOUNTS,
                  item_setter: pinterest.SET_ACCOUNTS,
                })
                break
              case 'Linkedin':
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items,
                  type: 'linkedin',
                  all_item_setter: linkedin.SET_ALL_ACCOUNTS,
                  item_setter: linkedin.SET_ACCOUNTS,
                })
                break
              case 'Gmb':
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items,
                  type: 'gmb',
                  all_item_setter: gmb.SET_ALL_ACCOUNTS,
                  item_setter: gmb.SET_ACCOUNTS,
                })
                break
              case 'Youtube':
                this.$store.dispatch('setPlatforms', {
                  items: response.data.items,
                  type: 'youtube',
                  all_item_setter: youtube.SET_ALL_ACCOUNTS,
                  item_setter: youtube.SET_ACCOUNTS,
                })
                break
            }
            this.Plan()
          } else {
            if (response.data.limitExceed) {
              this.showUpgradeModal()
              return false
            }

            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
              return false
            }
            this.alertMessage(SAVE_PLATFORMS_ERROR, 'error')
          }
        }
      }

      // let data = JSON.parse(JSON.stringify(this.getPlatformsConnectionState))
      // data.selectedPlatform = this.selectedPlatform
      // data.workspace_id = this.getWorkspaces.activeWorkspace._id
      //
      // if (data.process === 'Tumblr') {
      //   data.process = this.$route.name === 'blogs' ? 'TumblrBlog' : 'TumblrAccount'
      // }
      //
      // let response = await this.$store.dispatch('processSavePlatforms', data)
      // this.$bvModal.hide('save_platforms_modal')
      // if (response && response.data) {
      //   if (response.data.status === true) {
      //     if (response.data.limitExceed) {
      //       this.showUpgradeModal()
      //     } else {
      //       this.alertMessage(SAVE_PLATFORMS_SUCCESS, 'success')
      //     }
      //
      //     switch (data.process) {
      //       case 'Facebook':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items,
      //           type: 'facebook',
      //           'all_item_setter': facebook.SET_ALL_ACCOUNTS,
      //           'item_setter': facebook.SET_ACCOUNTS
      //         })
      //         break
      //       case 'Pinterest':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items,
      //           type: 'pinterest',
      //           'all_item_setter': pinterest.SET_ALL_ACCOUNTS,
      //           'item_setter': pinterest.SET_ACCOUNTS
      //         })
      //         break
      //       case 'Linkedin':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items,
      //           type: 'linkedin',
      //           'all_item_setter': linkedin.SET_ALL_ACCOUNTS,
      //           'item_setter': linkedin.SET_ACCOUNTS
      //         })
      //         break
      //       case 'Gmb':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items,
      //           type: 'gmb',
      //           'all_item_setter': gmb.SET_ALL_ACCOUNTS,
      //           'item_setter': gmb.SET_ACCOUNTS
      //         })
      //         break
      //       case 'TumblrAccount':
      //       case 'TumblrBlog':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items.accounts,
      //           type: 'tumblr_profiles',
      //           'all_item_setter': tumblr.SET_ALL_ACCOUNTS,
      //           'item_setter': tumblr.SET_ACCOUNTS
      //         })
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items.blogs,
      //           type: 'tumblr_blogs',
      //           'all_item_setter': blog.SET_ALL_TUMBLR_BLOGS,
      //           'item_setter': blog.SET_TUMBLR_BLOGS
      //         })
      //         break
      //       case 'Medium':
      //         this.$store.dispatch('setPlatforms', {
      //           items: response.data.items,
      //           type: 'medium',
      //           'all_item_setter': blog.SET_ALL_MEDIUM_BLOGS,
      //           'item_setter': blog.SET_MEDIUM_BLOGS
      //         })
      //         break
      //     }
      //     this.Plan()
      //   } else {
      //     if (response.data.limitExceed) {
      //       this.showUpgradeModal()
      //     } else if (response.data.message) {
      //       this.alertMessage(response.data.message, 'error')
      //     } else {
      //       this.alertMessage(SAVE_PLATFORMS_ERROR, 'error')
      //     }
      //   }
      // }
    },

    showAppInstalledOption(item) {
      return this.secondSectionName === 'Groups' && !item.app_installed
    },

    groupsWithoutAppInstall(accounts) {
      return accounts.filter((account) => {
        return account.type === 'Group' && !account.app_installed
      })
    },
  },
}
</script>

<template>
  <b-modal
    id="save_platforms_modal"
    modal-class="add_social_account_modal"
    hide-footer
    hide-header
  >
    <div class=" ">
      <div class="modal_head d-flex align-items-center">
        <h4>Connect {{ platformName }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="hideModal"
          >&times;</button
        >
      </div>

      <div class="modal_body">
        <div class="social_top_heading mt-5">
          <p>{{ platformConnectionTitle }}</p>
        </div>

        <div v-if="isFacebookConnection" class="input_field">
          <div class="warning_box text-center">
            <i class="far fa-question-circle"></i> Not all of your pages or
            groups are showing in the list? You can follow this guide here to
            allow the permissions.
            <p
              ><a
                href="https://docs.contentstudio.io/article/377-how-to-connect-facebook-pages"
                target="_blank" rel="noopener"
                class="insta-link"
                >Follow this guide to allow the permissions</a
              ></p
            >
          </div>
        </div>

        <div v-if="isInstagramConnection" class="input_field">
          <div class="compose_warning_box text-center w-full">
            <i class="far fa-question-circle"> </i>
            If all of your Instagram business accounts are not showing below,
            please follow
            <a
              href="https://docs.contentstudio.io/article/729-getting-started-with-instagram-publishing"
              target="_blank" rel="noopener"
              class="insta-link"
            >
              this guide </a
            >.
          </div>
        </div>

        <div v-if="firstSectionItemsExists" class="top_option check_alignment">
          <div class="field_group d-flex">
            <label>Select All</label>
            <div class="checkbox_container">
              <label for="check_all">
                <input
                  id="check_all"
                  v-model="selectAll"
                  type="checkbox"
                  name="option2"
                  :value="selectAll"
                />
                <span class="check"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="content clear">
          <div class="section">
            <div v-if="firstSectionName" class="heading">
              <h3>{{ firstSectionName }}</h3>
            </div>

            <div v-if="firstSectionItemsExists" class="items">
              <div
                v-for="(item, key) in sectionFirstItems"
                :key="key"
                class="option_row d-flex align-items-center"
              >
                <div class="row_left">
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        v-if="platformName === 'Gmb'"
                        class="img rounded_image"
                        :style="{
                          'background-image': 'url(/img/google-business.png)',
                        }"
                      ></div>
                      <div
                        v-else
                        class="img rounded_image"
                        :style="{
                          'background-image': 'url(' + imageLink(item) + ')',
                        }"
                      ></div>
                    </div>
                    <div class="text_block">
                      <p class="text">{{ accountName(item) }}</p>
                    </div>
                  </div>
                </div>

                <div class="row_right checkbox-pad">
                  <div class="field_group">
                    <div class="checkbox_container">
                      <label :for="sectionIdentifier('first_item_', item)">
                        <input
                          :id="sectionIdentifier('first_item_', item)"
                          v-model="selectedPlatform.firstItems"
                          :value="accountCheckbox(item)"
                          type="checkbox"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="t_item">
              <div class="option_row d-flex align-items-center">
                <p>{{ nullFirstSectionText }}</p>
              </div>
            </div>
          </div>

          <div class="section">
            <div v-if="secondSectionName" class="heading">
              <h3>{{ secondSectionName }}</h3>
            </div>

            <div v-if="isFacebookConnection" class="input_field">
              <div class="warning_box text-center">
                <i class="far fa-question-circle"></i> You need to be an admin
                for any Facebook Group you would like to add to ContentStudio.
                You also need to have ContentStudio added as an app to your
                Group on facebook.com.
                <p
                  ><a
                    href="https://docs.contentstudio.io/article/378-how-to-connect-facebook-groups"
                    target="_blank" rel="noopener"
                    class="insta-link"
                    >Learn More Here</a
                  ></p
                >
              </div>
            </div>

            <div v-if="secondSectionItemsExists" class="items mt-3">
              <div
                v-for="(item, key) in sectionSecondItems"
                :key="key"
                class="option_row d-flex align-items-center"
              >
                <div class="row_left">
                  <div class="profile_picture">
                    <div class="picture_block picture_block_background">
                      <div
                        class="img rounded_image"
                        :style="{
                          'background-image': 'url(' + imageLink(item) + ')',
                        }"
                      ></div>
                    </div>
                    <div class="text_block">
                      <p class="text">
                        {{ accountName(item) }}
                      </p>

                      <template v-if="showAppInstalledOption(item)">
                        <p class="mt-1">
                          <a
                            :href="sanitizeUrl(`https://www.facebook.com/groups/${item.id}/edit/?launch_popover=app_detail&app_id=157148231359191`)"
                            target="_blank" rel="noopener"
                            >Add ContentStudio application to group</a
                          >
                          <i
                            v-tooltip="{
                              content:
                                'In order to publish content to your group <strong>' +
                                accountName(item) +
                                '</strong> you must authorize ContentStudio application. Click on Enable Group link to authorize permission',
                              classes: 'PlatformEnableTooltip',
                            }"
                            class="far fa-question-circle cursor_pointer ml-2"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="row_right checkbox-pad d-flex align_center">
                  <div class="field_group">
                    <div class="checkbox_container">
                      <label :for="sectionIdentifier('second_item_', item)">
                        <input
                          :id="sectionIdentifier('second_item_', item)"
                          v-model="selectedPlatform.secondItems"
                          :value="accountCheckbox(item)"
                          type="checkbox"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="t_item">
              <div class="option_row d-flex align-items-center">
                <p>{{ nullSecondSectionText }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="field_group">
          <button
            class="btn btn-studio-theme-space"
            :disabled="getSavePlatformsLoader"
            @click.prevent="savePlatforms"
          >
            <span>Save</span>
            <clip-loader
              v-if="getSavePlatformsLoader"
              :color="'#ffffff'"
              :size="'16px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.app-installation {
  opacity: 0.3;
  cursor: not-allowed;
}
.PlatformEnableTooltip {
  max-width: 400px;
  .tooltip-inner {
    max-width: 400px;
  }
}
</style>
