<template>
  <b-modal
    id="external-actions-modal"
    centered
    header-class="cs-modal-header"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title-class="cs-modal-title"
    @hidden="onModalHidden"
  >
    <template v-slot:modal-title>
      {{modalTitle}}
    </template>

    <div class="px-2 pb-4 pt-7">

      <div
        class="flex flex-col mb-6 relative"
      >
        <label for="comment-action">Comment
          <span v-if="isRequired.comment" class="text-status-failed">
            *
          </span>
        </label>
        <textarea
          id="comment-action"
          :value="data.comment"
          class="w-full rounded-md border text-gray-900 text-sm focus-within:outline-blue py-2.5 px-3"
          :class="{ '!outline-red !focus-within:outline-red': errors.comment }"
          rows="4"
          placeholder="Write your comment here..."
          @input="(e) => onChange('comment', e.target.value)"
        >
        </textarea>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

        <div class="flex flex-col">
          <label for="full-name-action">Full Name
          <span v-if="isRequired.name" class="text-status-failed">
            *
          </span>
          </label>
          <div
            class=" focus-within:outline-blue mb-6 rounded-lg"
            :class="{ '!outline-red !focus-within:outline-red': errors.name }"
          >
            <CstInputFields
              id="full-name-action"
              :value="data.name"
              class="w-full"
              type="text"
              placeholder="Full Name"
              @change="(value) => onChange('name', value)"
            >
            </CstInputFields>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="email-action">Email
            <span v-if="isRequired.email" class="text-status-failed">
              *
            </span>
          </label>
          <div
            class=" focus-within:outline-blue mb-6 rounded-lg"
            :class="{ '!outline-red !focus-within:outline-red': errors.email }"
          >
            <CstInputFields
              id="email-action"
              :value="data.email"
              class="w-full"
              type="email"
              placeholder="Email"
              @change="(value) => onChange('email', value)"
            >
            </CstInputFields>
          </div>
        </div>
    </div>

    <CstAlert type="info" class="mt-6 text-left">
      We need your Name and Email to show with your feedback about the post. This information is saved, so you won't need to re-enter it, but you can update it while submitting new feedback.
    </CstAlert>
      <!--    Footer    -->
      <div class="flex justify-end mt-3.5">
        <CstButton
          variant="text"
          size="large"
          text="Cancel"
          class="mr-3 w-28"
          @click="$bvModal.hide('external-actions-modal')"
        />
        <CstButton
          :variant="submitButton.type"
          size="large"
          :text="submitButton.title"
          class="text-nowrap"
          @click="onSubmit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent, onMounted, reactive, computed, inject } from 'vue'
import CstInputFields from '@ui/Input/CstInputFields'
import CstButton from '@ui/Button/CstButton'
import CstAlert from '@ui/Alert/CstAlert'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'ExternalActionsModal',

  components: {
    CstButton,
    CstAlert,
    CstInputFields
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch } = useStore()
    const token = computed(() => props.token)

    const DATA_TYPE = {
      COMMENT: 'comment',
      APPROVE: 'approve',
      REJECT: 'reject',
    }

    const TYPE_WISE_DETAILS = {
      [DATA_TYPE.COMMENT]: {
        title: 'Comment Post',
        submitButton:{
          type: 'primary',
          title: 'Comment'
        },
        isRequired:{
          comment: true,
          name: true,
          email: true
        }
      },
      [DATA_TYPE.APPROVE]: {
        title: 'Approve Post',
        submitButton:{
          type: 'success',
          title: 'Approve'
        },
        isRequired:{
          comment: false,
          name: true,
          email: true
        }
      },
      [DATA_TYPE.REJECT]: {
        title: 'Reject Post',
        submitButton:{
          type: 'danger',
          title: 'Reject'
        },
        isRequired:{
          comment: true,
          name: true,
          email: true
        }
      },
    }

    const data = reactive({
      comment: '',
      type: 'comment',
      plans: [],
      name: '',
      email: '',
      token: '',
    })

    const errors = reactive({
      comment: false,
      name: false,
      email: false,
    })

    onMounted(() => {
      console.log('mounted')
      EventBus.$on(
        'external-actions::show',
        ({ type = 'comment', plans = [] }) => {
          data.type = type
          data.plans = plans
          getExternalEmailandName()
          $bvModal.show('external-actions-modal')
        }
      )
    })

    const modalTitle = computed(() => {
      const baseTitle = TYPE_WISE_DETAILS[data.type].title.split(' ')[0] // Get just the action word (Approve/Reject/Comment)
      if (data.plans.length > 1) {
        return `Bulk ${baseTitle} (${data.plans.length}) posts`
      }
      return TYPE_WISE_DETAILS[data.type].title
    })

    const submitButton = computed(() => {
      const button = TYPE_WISE_DETAILS[data.type].submitButton
      if (data.plans.length > 1) {
        return {
          ...button,
          title: `${button.title} All`
        }
      }
      return button
    })

    const isRequired = computed(() => TYPE_WISE_DETAILS[data.type].isRequired )


    const onModalHidden = () => {
      data.name = data.email = data.comment = ''
      data.plans = []
      errors.comment = errors.name = errors.email = false
    }

    const onSubmit = async () => {
      console.log('submit')
      let validationFailed = false;

      // validate
      if (!data.name.trim() && isRequired.value.name) {
        errors.name = true
      }

      if (!data.email.trim() && isRequired.value.email) {
        validationFailed = true
        errors.email = true
      }

      if ( !data.comment.trim() && isRequired.value.comment) {
        validationFailed = true
        errors.comment = true
      }

      if(validationFailed) {
        dispatch('toastNotification', {
          message: 'Please enter required fields.',
          type: 'error',
        })
        return
      }

      saveExternalEmailandName()

      if (data.type === 'comment') {
        await addComment()
      } else {
        await externalAction()
      }
    }

    const saveExternalEmailandName = () => {
        localStorage.setItem('external_email', data.email)
        localStorage.setItem('external_name', data.name)
    }
    const getExternalEmailandName = () => {
        data.email = localStorage.getItem('external_email') || ''
        data.name = localStorage.getItem('external_name') || ''
    }

    const externalAction = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.APPROVE_REJECT_PLAN, {
          token: token.value,
          plans: data.plans,
          action: {
            name: data.name,
            email: data.email,
            comment: data.comment,
            type: data.type,
          },
        })
        dispatch('toastNotification', {
          message: `Post ${data.type === 'approve' ? 'approved' : 'rejected'} successfully`,
          type: 'success',
        })

        // Emit event to trigger plan refetch
        if(data.plans.length) {
          EventBus.$emit('external-actions::complete')
        }

        $bvModal.hide('external-actions-modal')
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const addComment = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.ADD_COMMENT, {
          token: token.value,
          plans: data.plans,
          comment: {
            name: data.name,
            email: data.email,
            comment: data.comment,
          },
        })
        dispatch('toastNotification', {
          message: 'Comment added successfully',
          type: 'success',
        })
        // Emit event to trigger plan refetch
        if(data.plans.length) {
          EventBus.$emit('external-actions::complete')
        }
        $bvModal.hide('external-actions-modal')
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const onChange = (type, value) => {
      switch (type) {
        case 'name':
          errors.name = false
          data.name = value
          break
        case 'email':
          errors.email = false
          data.email = value
          break
        case 'comment':
          errors.comment = false
          data.comment = value
          break
        default:
          break;
      }
    }

    return {
      onModalHidden,
      onSubmit,
      onChange,
      data,
      errors,
      modalTitle,
      submitButton,
      isRequired,
    }
  },
})
</script>

<style scoped></style>
