// src/composables/useDevices.js
import { ref , inject } from 'vue'
import proxy from '@common/lib/http-common'
import useToast from '@common/composables/useToast'
import { getUserSessionsURL, terminateSessionURL, terminateAllSessionsURL } from '@src/modules/setting/config/api-utils'
import { baseUrl } from '@src/config/api-utils'
import { useStore } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import { socket } from '@src/modules/inbox/store/constants'

// Error message constants
const LOGOUT_DEVICE_SUCCESS = 'Device logged out successfully'
const FETCH_DEVICES_ERROR = 'Failed to fetch devices.'
const LOGOUT_DEVICE_ERROR = 'Unable to log out from this device. Please try again later.'
const LOGOUT_ALL_DEVICES_ERROR = 'Unable to log out from all device(s). Please try again later'
const UNKNOWN_ERROR = 'An unknown error occurred.'

export function useDevices() {
  const root = inject('root')
  const { $bvModal } = root
  const { alertMessage } = useToast()
  const store = useStore()
  const devices = ref([])
  const isLoading = ref(false)
  const logoutLoading = ref({})
  const isLoggingOutAll = ref(false)

  const fetchDevices = async () => {
    try {
      isLoading.value = true
      const response = await proxy.get(getUserSessionsURL)
      if (response.data.status && response.data.data?.sessions) {
        devices.value = response.data.data.sessions
        return true
      } else {
        const message = response.data.message || FETCH_DEVICES_ERROR
        alertMessage(message, 'error')
        return false
      }
    } catch (error) {
      alertMessage(UNKNOWN_ERROR, 'error')
      return false
    } finally {
      isLoading.value = false
    }
  }

  const logoutDevice = async (token, isCurrentDevice = false) => {
    try {
      logoutLoading.value[token] = true
      const response = await proxy.post(`${terminateSessionURL}/${token}`)
      if (response.data.status) {
        const message = response.data.message || LOGOUT_DEVICE_SUCCESS
        alertMessage(message, 'success')

        if (isCurrentDevice) {
          // Use Vuex store mutations for logout
          store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
          store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
          store.commit('SET_PLAN', null)
          // Force redirect to login
          window.location.href = '/login'
        } else {
          // Remove the device from local state for other devices
          devices.value = devices.value.filter(device => device.token !== token)
        }
        $bvModal.hide('logout-device-confirmation-modal')

        return true
      } else {
        const message = response.data.message || LOGOUT_DEVICE_ERROR
        alertMessage(message, 'error')
        return false
      }
    } catch (error) {
      alertMessage(UNKNOWN_ERROR, 'error')
      return false
    } finally {
      logoutLoading.value[token] = false
    }
  }

  const logoutAllDevices = async () => {
    try {
      isLoggingOutAll.value = true
      const response = await proxy.post(terminateAllSessionsURL)
      if (response.data.status) {
        const message = response.data.message || LOGOUT_DEVICE_SUCCESS
        alertMessage(message, 'success')
        // Keep only the current device in the list
        devices.value = devices.value.filter(device => device.is_current)
        $bvModal.hide('logout-all-devices-confirmation-modal')
        return true
      } else {
        const message = response.data.message || LOGOUT_ALL_DEVICES_ERROR
        alertMessage(message, 'error')
        return false
      }

    } catch (error) {
      alertMessage(UNKNOWN_ERROR, 'error')
      return false
    } finally {
      isLoggingOutAll.value = false
    }
  }

  return {
    devices,
    isLoading,
    logoutLoading,
    isLoggingOutAll,
    fetchDevices,
    logoutDevice,
    logoutAllDevices
  }
}
