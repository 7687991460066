import {computed, inject, ref} from 'vue'
import isEmpty from 'is-empty'
import { useStore } from '@state/base'
import FacebookRoundedImage from '@assets/img/integration/facebook-rounded.svg'
import TwitterRoundedImage from '@assets/img/integration/twitter-x-rounded.svg'
import LinkedInRoundedImage from '@assets/img/integration/linkedin-rounded.svg'
import InstagramRoundedImage from '@assets/img/integration/instagram-rounded.svg'
import PinterestRoundedImage from '@assets/img/integration/pinterest-rounded.svg'
import YouTubeRoundedImage from '@assets/img/integration/youtube-rounded.png'
import GMBRoundedImage from '@assets/img/integration/gmb-rounded.svg'
import TumblrRoundedImage from '@assets/img/integration/tumblr-rounded.svg'
import TikTokRoundedImage from '@assets/img/integration/tiktok-rounded.svg'
import ThreadsRoundedImage from '@assets/img/integration/threads-rounded.svg'
import WordpressRoundedImage from '@assets/img/integration/wordpress.svg'
import ShopifyRoundedImage from '@assets/img/integration/shopify.svg'
import WebflowRoundedImage from '@assets/img/integration/webflow.svg'
import BlueskyRoundedImage from '@assets/img/integration/bluesky-rounded.svg'
import useDateFormat from "@common/composables/useDateFormat";
import FacebookIcon from '@assets/img/integration/facebook-icon.svg'
import LinkedInIcon from '@assets/img/integration/linkedin-icon.svg'
import InstagramIcon from '@assets/img/integration/instagram-icon.svg'
import PinterestIcon from '@assets/img/integration/pinterest-icon.svg'
import YouTubeIcon from '@assets/img/integration/youtube.png'
import GMBIcon from '@assets/img/integration/gmb-icon.svg'
import TumblrIcon from '@assets/img/integration/tumblr-icon.svg'
import TikTokIcon from '@assets/img/integration/tiktok-icon.svg'
import ThreadsIcon from '@assets/img/integration/threads-icon.svg'
import BlueskyIcon from '@assets/img/integration/bluesky-icon.svg'
import MediumIcon from '@assets/img/integration/medium.svg'
import proxy from '@common/lib/http-common'
import { fetchComposerToolkitViewStatus } from '@src/config/api-utils'
import {swalAttributes} from "@common/constants/common-attributes";

const clone = require('rfdc/default')

const isComposerModalOpen = ref(false)
const isDraftComposer = ref(false)
const draftPlanId = ref('')
const showNewChat = ref(false)

export function useComposerHelper() {
  const root = inject('root')
  const { getters, dispatch } = useStore()
  const {momentWrapper} = useDateFormat()

  const SUPPORTED_PLATFORMS = [
    {
      name: 'facebook',
      label: 'Facebook',
      types: ['Pages'],
      accounts: [],
    },
    {
      name: 'instagram',
      label: 'Instagram',
      types: ['Business'],
      accounts: [],
    },
    {
      name: 'threads',
      label: 'Threads',
      types: ['Profiles'],
      accounts: [],
    },
    { name: 'twitter', label: 'X (Twitter)', types: ['Profiles'], accounts: [] },
    {
      name: 'linkedin',
      label: 'LinkedIn',
      types: ['Profiles', 'Pages'],
      accounts: [],
    },
    {
      name: 'pinterest',
      label: 'Pinterest',
      types: ['Profiles', 'Public Boards'],
      accounts: [],
    },
    { name: 'gmb', label: 'GMB', types: ['Verified Locations'], accounts: [] },
    { name: 'youtube', label: 'YouTube', types: ['Channels'], accounts: [] },
    {
      name: 'tumblr',
      label: 'Tumblr',
      types: ['Personal', 'Business'],
      accounts: [],
    },
    {
      name: 'tiktok',
      label: 'TikTok',
      types: ['Personal', 'Business'],
      accounts: [],
    },
    {
      name: 'bluesky',
      label: 'Bluesky',
      types: ['Profiles'],
      accounts: [],
    }
  ]

  // For facebook templates in composer editor box
  const FACEBOOK_BACKGROUNDS = {
    ranked: [
      {
        preset_id: '',
        category: 'popular',
        type: 'solid',
        thumbnail: '',
        image: '',
        background_color: 'white',
        background_description: ''
      },
      {
        preset_id: '106018623298955',
        category: 'popular',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(198, 0, 255)',
        background_description: 'Solid purple, background'
      },
      {
        preset_id: '1903718606535395',
        category: '',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(226, 1, 59)',
        background_description: 'Solid red, background'
      },
      {
        preset_id: '1881421442117417',
        category: '',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(17, 17, 17)',
        background_description: 'Solid black, background'
      },
      {
        preset_id: '249307305544279',
        category: '',
        type: 'gradient',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(45deg, rgb(255, 0, 71), rgb(44, 52, 199))',
        background_description: 'Gradient, red blue, background'
      },
      {
        preset_id: '1777259169190672',
        category: '',
        type: 'gradient',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(45deg, rgb(93, 63, 218), rgb(252, 54, 253))',
        background_description: 'Gradient, purple magenta, background'
      },
      {
        preset_id: '303063890126415',
        category: '',
        type: 'image',
        color: '#ffffff',
        thumbnail: '303063890126415_thumbnail.jpg',
        image: '303063890126415_image.jpg',
        background_color: '',
        background_description: 'Yellow, orange and pink gradient, background image'
      },
      {
        preset_id: '122708641613922',
        category: '',
        type: 'gradient',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(45deg, rgb(93, 99, 116), rgb(22, 24, 29))',
        background_description: 'Gradient, dark grey black, background'
      },
      {
        preset_id: '319468561816672',
        category: '',
        type: 'image',
        color: '#ffffff',
        thumbnail: '319468561816672_thumbnail.jpg',
        image: '319468561816672_image.jpg',
        background_color: '',
        background_description: 'Dark blue illustration, background image'
      },
    ],
    popular: [
      {
        preset_id: '106018623298955',
        category: 'popular',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(198, 0, 255)',
        background_description: 'Solid purple, background'
      },
      {
        preset_id: '365653833956649',
        category: 'popular',
        type: 'image',
        color: '#000',
        thumbnail: '365653833956649_thumbnail.jpg',
        image: '365653833956649_image.jpg',
        background_color: '',
        background_description: 'Pink tropical plants, background image'
      },
      {
        preset_id: '618093735238824',
        category: 'popular',
        type: 'image',
        color: '#ffffff',
        thumbnail: '618093735238824_thumbnail.jpg',
        image: '618093735238824_image.jpg',
        background_color: '',
        background_description: 'Brown illustration, background image'
      },
      {
        preset_id: '191761991491375',
        category: 'popular',
        type: 'image',
        color: '#ffffff',
        thumbnail: '191761991491375_thumbnail.jpg',
        image: '191761991491375_image.jpg',
        background_color: '',
        background_description: '3D hearts, background image'
      },
      {
        preset_id: '2193627793985415',
        category: 'popular',
        type: 'image',
        color: '#000',
        thumbnail: '2193627793985415_thumbnail.jpg',
        image: '2193627793985415_image.jpg',
        background_color: '',
        background_description: '3D heart eyes emojis, background image'
      },
      {
        preset_id: '200521337465306',
        category: 'popular',
        type: 'image',
        color: '#ffffff',
        thumbnail: '200521337465306_thumbnail.jpg',
        image: '200521337465306_image.jpg',
        background_color: '',
        background_description: '3D flame emojis, background image'
      },
      {
        preset_id: '1821844087883360',
        category: 'popular',
        type: 'image',
        color: '#000',
        thumbnail: '1821844087883360_thumbnail.jpg',
        image: '1821844087883360_image.jpg',
        background_color: '',
        background_description: 'Yellow illustration, background image'
      },
      {
        preset_id: '177465482945164',
        category: 'popular',
        type: 'image',
        color: '#000',
        thumbnail: '177465482945164_thumbnail.jpg',
        image: '177465482945164_image.jpg',
        background_color: '',
        background_description: 'Light purple 3D cube pattern, background image'
      },
      {
        preset_id: '160419724814650',
        category: 'popular',
        type: 'image',
        color: '#ffffff',
        thumbnail: '160419724814650_thumbnail.jpg',
        image: '160419724814650_image.jpg',
        background_color: '',
        background_description: 'Pink illustration, background image'
      },
      {
        preset_id: '255989551804163',
        category: 'popular',
        type: 'image',
        color: '#000',
        thumbnail: '255989551804163_thumbnail.jpg',
        image: '255989551804163_image.jpg',
        background_color: '',
        background_description: 'Pink illustration, background image'
      },
    ],
    new: [
      {
        preset_id: '248623902401250',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '248623902401250_thumbnail.jpg',
        image: '248623902401250_image.jpg',
        background_color: '',
        background_description: '3D smiling emoji background, background image'
      },
      {
        preset_id: '240401816771706',
        category: 'new',
        type: 'image',
        color: '#ffffff',
        thumbnail: '240401816771706_thumbnail.jpg',
        image: '240401816771706_image.jpg',
        background_color: '',
        background_description: '3D rose emojis, background image'
      },
      {
        preset_id: '1868855943417360',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '1868855943417360_thumbnail.jpg',
        image: '1868855943417360_image.jpg',
        background_color: '',
        background_description: '3D crying laughter emoji, background image'
      },
      {
        preset_id: '618093735238824',
        category: 'new',
        type: 'image',
        color: '#ffffff',
        thumbnail: '618093735238824_thumbnail.jpg',
        image: '618093735238824_image.jpg',
        background_color: '',
        background_description: 'Brown illustration, background image'
      },
      {
        preset_id: '191761991491375',
        category: 'new',
        type: 'image',
        color: '#ffffff',
        thumbnail: '191761991491375_thumbnail.jpg',
        image: '191761991491375_image.jpg',
        background_color: '',
        background_description: '3D hearts, background image'
      },
      {
        preset_id: '2193627793985415',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '2193627793985415_thumbnail.jpg',
        image: '2193627793985415_image.jpg',
        background_color: '',
        background_description: '3D heart eyes emojis, background image'
      },
      {
        preset_id: '200521337465306',
        category: 'new',
        type: 'image',
        color: '#ffffff',
        thumbnail: '200521337465306_thumbnail.jpg',
        image: '200521337465306_image.jpg',
        background_color: '',
        background_description: '3D flame emojis, background image'
      },
      {
        preset_id: '1792915444087912',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '1792915444087912_thumbnail.jpg',
        image: '1792915444087912_image.jpg',
        background_color: '',
        background_description: 'Pink illustration, background image'
      },
      {
        preset_id: '1654916007940525',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '1654916007940525_thumbnail.jpg',
        image: '1654916007940525_image.jpg',
        background_color: '',
        background_description: 'Light grey illustration, background image'
      },
      {
        preset_id: '1679248482160767',
        category: 'new',
        type: 'image',
        color: '#000',
        thumbnail: '1679248482160767_thumbnail.jpg',
        image: '1679248482160767_image.jpg',
        background_color: '',
        background_description: 'Light blue illustration, background image'
      }
    ],
    more: [
      {
        preset_id: '518948401838663',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '518948401838663_thumbnail.jpg',
        image: '518948401838663_image.jpg',
        background_color: '',
        background_description: 'Pink heart pattern on pink background, background image'
      },
      {
        preset_id: '423339708139719',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '423339708139719_thumbnail.jpg',
        image: '423339708139719_image.jpg',
        background_color: '',
        background_description: 'Pink illustration, background image'
      },
      {
        preset_id: '204187940028597',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(243, 83, 105)',
        background_description: 'Solid red, background'
      },
      {
        preset_id: '621731364695726',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(243, 84, 106)',
        background_description: 'Solid red, background'
      },
      {
        preset_id: '518596398537417',
        category: 'more',
        type: 'image',
        thumbnail: '518596398537417_thumbnail.jpg',
        image: '518596398537417_image.jpg',
        background_color: '',
        background_description: 'Red illustration, background image'
      },
      {
        preset_id: '134273813910336',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '134273813910336_thumbnail.jpg',
        image: '134273813910336_image.jpg',
        background_color: '',
        background_description: 'Red illustration, background image'
      },
      {
        preset_id: '217321755510854',
        category: 'more',
        type: 'image',
        thumbnail: '217321755510854_thumbnail.jpg',
        image: '217321755510854_image.jpg',
        background_color: '',
        background_description: 'Pink and purple hearts on a pink background, background image'
      },
      {
        preset_id: '323371698179784',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '323371698179784_thumbnail.jpg',
        image: '323371698179784_image.jpg',
        background_color: '',
        background_description: 'Red illustration, background image'
      },
      {
        preset_id: '901751159967576',
        category: 'more',
        type: 'gradient',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(rgb(199, 119, 87), rgb(251, 114, 75))',
        background_description: 'Gradient, dark orange red, background'
      },
      {
        preset_id: '552118025129095',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '552118025129095_thumbnail.jpg',
        image: '552118025129095_image.jpg',
        background_color: '',
        background_description: 'Brown illustration, background image'
      },
      {
        preset_id: '263789377694911',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '263789377694911_thumbnail.jpg',
        image: '263789377694911_image.jpg',
        background_color: '',
        background_description: 'Red illustration, background image'
      },
      {
        preset_id: '606643333067842',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '606643333067842_thumbnail.jpg',
        image: '606643333067842_image.jpg',
        background_color: '',
        background_description: 'Light orange illustration, background image'
      },
      {
        preset_id: '458988134561491',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '458988134561491_thumbnail.jpg',
        image: '458988134561491_image.jpg',
        background_color: '',
        background_description: 'Dark orange illustration, background image'
      },
      {
        preset_id: '548109108916650',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '548109108916650_thumbnail.jpg',
        image: '548109108916650_image.jpg',
        background_color: '',
        background_description: 'Red illustration, background image'
      },
      {
        preset_id: '175493843120364',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '175493843120364_thumbnail.jpg',
        image: '175493843120364_image.jpg',
        background_color: '',
        background_description: 'Pink and yellow gradient, background image'
      },
      {
        preset_id: '338976169966519',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '338976169966519_thumbnail.jpg',
        image: '338976169966519_image.jpg',
        background_color: '',
        background_description: 'Beige illustration, background image'
      },
      {
        preset_id: '206513879997925',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '206513879997925_thumbnail.jpg',
        image: '206513879997925_image.jpg',
        background_color: '',
        background_description: 'Beige illustration, background image'
      },
      {
        preset_id: '168373304017982',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '168373304017982_thumbnail.jpg',
        image: '168373304017982_image.jpg',
        background_color: '',
        background_description: 'Beige illustration, background image'
      },
      {
        preset_id: '1271157196337260',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(255, 99, 35)',
        background_description: 'Solid red, background'
      },
      {
        preset_id: '174496469882866',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '174496469882866_thumbnail.jpg',
        image: '174496469882866_image.jpg',
        background_color: '',
        background_description: 'Yellow illustration, background image'
      },
      {
        preset_id: '862667370603267',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '862667370603267_thumbnail.jpg',
        image: '862667370603267_image.jpg',
        background_color: '',
        background_description: 'Yellow illustration, background image'
      },
      {
        preset_id: '127541261450947',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '127541261450947_thumbnail.jpg',
        image: '127541261450947_image.jpg',
        background_color: '',
        background_description: 'Green illustration, background image'
      },
      {
        preset_id: '218067308976029',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '218067308976029_thumbnail.jpg',
        image: '218067308976029_image.jpg',
        background_color: '',
        background_description: 'Light grey illustration, background image'
      },
      {
        preset_id: '688479024672716',
        category: 'more',
        type: 'gradient',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(135deg, rgb(143, 199, 173), rgb(72, 229, 169))',
        background_description: 'Gradient, teal light green, background'
      },
      {
        preset_id: '238863426886624',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '238863426886624_thumbnail.jpg',
        image: '238863426886624_image.jpg',
        background_color: '',
        background_description: 'Light blue illustration, background image'
      },
      {
        preset_id: '301029513638534',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(22, 83, 72)',
        background_description: 'Solid teal, background'
      },
      {
        preset_id: '154977255088164',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(38, 146, 127)',
        background_description: 'Solid teal, background'
      },
      {
        preset_id: '1941912679424590',
        category: 'more',
        type: 'gradient',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'linear-gradient(135deg, rgb(119, 125, 136), rgb(79, 87, 102))',
        background_description: 'Gradient, grey dark grey, background'
      },
      {
        preset_id: '396343990807392',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '396343990807392_thumbnail.jpg',
        image: '396343990807392_image.jpg',
        background_color: '',
        background_description: 'Teal illustration, background image'
      },
      {
        preset_id: '143093446467972',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '143093446467972_thumbnail.jpg',
        image: '143093446467972_image.jpg',
        background_color: '',
        background_description: 'Blue clouds on a dark blue background, background image'
      },
      {
        preset_id: '161409924510923',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '161409924510923_thumbnail.jpg',
        image: '161409924510923_image.jpg',
        background_color: '',
        background_description: 'Rocket ship makes a heart in the sky, background image'
      },
      {
        preset_id: '145893972683590',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(36, 55, 98)',
        background_description: 'Solid dark purple, background'
      },
      {
        preset_id: '217761075370932',
        category: 'more',
        type: 'solid',
        color: '#000',
        thumbnail: '',
        image: '',
        background_color: 'rgb(32, 136, 175)',
        background_description: 'Solid blue, background'
      },
      {
        preset_id: '931584293685988',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '931584293685988_thumbnail.jpg',
        image: '931584293685988_image.jpg',
        background_color: '',
        background_description: 'Blue illustration, background image'
      },
      {
        preset_id: '148862695775447',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '148862695775447_thumbnail.jpg',
        image: '148862695775447_image.jpg',
        background_color: '',
        background_description: 'Pink and purple hearts on a purple background, background image'
      },
      {
        preset_id: '100114277230063',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '100114277230063_thumbnail.jpg',
        image: '100114277230063_image.jpg',
        background_color: '',
        background_description: 'Blue illustration, background image'
      },
      {
        preset_id: '558836317844129',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '558836317844129_thumbnail.jpg',
        image: '558836317844129_image.jpg',
        background_color: '',
        background_description: 'Light purple illustration, background image'
      },
      {
        preset_id: '172497526576609',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '172497526576609_thumbnail.jpg',
        image: '172497526576609_image.jpg',
        background_color: '',
        background_description: 'Light purple illustration, background image'
      },
      {
        preset_id: '433967226963128',
        category: 'more',
        type: 'solid',
        color: '#ffffff',
        thumbnail: '',
        image: '',
        background_color: 'rgb(115, 33, 173)',
        background_description: 'Solid purple, background'
      },
      {
        preset_id: '197865920864520',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '197865920864520_thumbnail.jpg',
        image: '197865920864520_image.jpg',
        background_color: '',
        background_description: 'Light purple illustration, background image'
      },
      {
        preset_id: '643122496026756',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '643122496026756_thumbnail.jpg',
        image: '643122496026756_image.jpg',
        background_color: '',
        background_description: 'Pink illustration, background image'
      },
      {
        preset_id: '762009070855346',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '762009070855346_thumbnail.jpg',
        image: '762009070855346_image.jpg',
        background_color: '',
        background_description: 'Light grey illustration, background image'
      },
      {
        preset_id: '228164237768720',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '228164237768720_thumbnail.jpg',
        image: '228164237768720_image.jpg',
        background_color: '',
        background_description: 'Grey heart pattern on a black background, background image'
      },
      {
        preset_id: '146487026137131',
        category: 'more',
        type: 'image',
        color: '#ffffff',
        thumbnail: '146487026137131_thumbnail.jpg',
        image: '146487026137131_image.jpg',
        background_color: '',
        background_description: 'Black illustration, background image'
      },
      {
        preset_id: '221828835275596',
        category: 'more',
        type: 'image',
        color: '#000',
        thumbnail: '221828835275596_thumbnail.jpg',
        image: '221828835275596_image.jpg',
        background_color: '',
        background_description: 'Light grey illustration, background image'
      }
    ]
  }

  const analyticsEnabledTwitterAccounts = computed(() =>
    getters?.getTwitterAccounts?.items.filter(
      (account) => account?.developer_app?.analytics_enabled
    )
  )

  const showTypes = (types) => {
    return types && Array.isArray(types) && types.length > 0
  }

  /**
   * Get social channel icon.
   * @param platform
   * @returns {Object}
   */
  const getSocialIcon = (platform) => {
    switch (platform) {
      case 'facebook':
        return FacebookIcon
      case 'twitter':
        return TwitterRoundedImage
      case 'linkedin':
        return LinkedInIcon
      case 'instagram':
        return InstagramIcon
      case 'pinterest':
        return PinterestIcon
      case 'youtube':
        return YouTubeIcon
      case 'gmb':
        return GMBIcon
      case 'tumblr':
        return TumblrIcon
      case 'tiktok':
        return TikTokIcon
      case 'threads':
        return ThreadsIcon
      case 'medium':
        return MediumIcon
      case 'bluesky':
        return BlueskyIcon
      default:
        return FacebookIcon
    }
  }

  /**
   * Get social channel rounded image.
   * @param platform
   * @returns {Object}
   */
  const getSocialImageRounded = (platform) => {
    switch (platform) {
      case 'facebook':
        return FacebookRoundedImage
      case 'instagram':
        return InstagramRoundedImage
      case 'threads':
        return ThreadsRoundedImage
      case 'twitter':
        return TwitterRoundedImage
      case 'linkedin':
        return LinkedInRoundedImage
      case 'pinterest':
        return PinterestRoundedImage
      case 'youtube':
        return YouTubeRoundedImage
      case 'gmb':
        return GMBRoundedImage
      case 'tiktok':
        return TikTokRoundedImage
      case 'tumblr':
        return TumblrRoundedImage
      case 'medium':
        return MediumIcon
      case 'wordpress':
        return 'https://storage.googleapis.com/lumotive-web-storage/default/wordpress.png'
      case 'shopify':
        return ShopifyRoundedImage
      case 'webflow':
        return WebflowRoundedImage
      case 'bluesky':
        return BlueskyRoundedImage
      default:
        return FacebookRoundedImage
    }
  }

  /**
   * fetches account images if it exists. returns a default profile image in case of no-image/error.
   * @param account object
   * @param type string
   */
  const channelImage = (account, type) => {

    const defaultImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    const gmbImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'

    if (!account) return defaultImage

    const {
      platform_logo: platformLogo,
      image,
      picture,
      profile_picture: profilePicture,
      imageUrl,
      location_name: locationName,
    } = account
    if (platformLogo) return platformLogo

    switch (type) {
      case 'facebook':
        return image || (picture && picture.url) || defaultImage
      case 'twitter':
      case 'linkedin':
      case 'pinterest':
        return (
          (image && image.large && image.large.url) || image || defaultImage
        )
      case 'instagram':
        return image || (profilePicture && profilePicture) || defaultImage
      case 'medium':
        return imageUrl || defaultImage
      case 'gmb':
        return locationName ? gmbImage : defaultImage
      default:
        return defaultImage
    }
  }

  /**
   * Get social channel background color.
   * @param platform
   * @returns {string}
   */
  const getSocialChannelBackgroundColor = (platform) => {
    switch (platform) {
      case 'facebook':
        return 'bg-[#02B2FF]  bg-opacity-5'
      case 'twitter':
        return 'bg-[#00AAFB]  bg-opacity-5'
      case 'linkedin':
        return 'bg-[#02B2FF]  bg-opacity-5'
      case 'instagram':
        return 'bg-[#EE446E]  bg-opacity-5'
      case 'pinterest':
        return 'bg-[#F0577D]  bg-opacity-5'
      case 'youtube':
        return 'bg-[#FFEFF3] bg-opacity-60'
      case 'gmb':
        return 'bg-[#4D8BF3]  bg-opacity-5'
      case 'tumblr':
        return 'bg-[#34526F]  bg-opacity-5'
      case 'tiktok':
        return 'bg-[#010101]  bg-opacity-5'
      case 'threads':
        return 'bg-[#010101]  bg-opacity-5'
      case 'bluesky':
        return 'bg-[#010101]  bg-opacity-5'
      default:
        return 'bg-[#3b5998]  bg-opacity-5'
    }
  }

  /**
   * Format date and time.
   * @param date
   * @param time
   * @param monthFirstFormat
   * @returns {string}
   */
  const formatBestDate = (date, time, monthFirstFormat = false) => {
    if (isEmpty(date) || isEmpty(time)) {
      return ''
    }

    time = time.toString().padStart(2, '0')
    const dateTime = `${date} ${time}`
    return momentWrapper(dateTime).formatDateTime()
  }

  /**
   * Check if date is disable or not.
   * @param date
   * @returns {boolean}
   */
  const checkDateDisable = (date) => {
    // Converting current time to given timezone.
    const timeStart = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: getters.getActiveWorkspace.timezone,
      })
    )
    const timeEnd = new Date(date)

    // Removing 1 day from current time.
    const now = timeStart.setHours(
      timeStart.getHours() - 24,
      timeStart.getMinutes(),
      timeStart.getSeconds()
    )
    const value = timeEnd.setHours(
      timeEnd.getHours(),
      timeEnd.getMinutes(),
      timeEnd.getSeconds()
    )

    return value < now
  }

  const getPlatformName = (platform = {}) => {
    let name = ''
    switch (true) {
      case !!platform.platform_name:
        return platform.platform_name
      case !!platform.location_name:
        name = `${platform.location_name} - ${platform.locality}`
        return platform.postal_code ? `${name} - ${platform.postal_code}` : name
      case !platform.name && !!platform.username:
        return platform.username
      case !!platform.account_name:
        return platform.account_name
      case !!platform.name:
        name = platform.name
        name = name.replace('profile_', '')
        name = name.replace('blog_', '')
        return name
      case !!platform.url:
        return platform.url
      case !!platform.platform_url:
        return platform.platform_url
      case !!platform.display_name:
        return platform.display_name
      default:
        return name
    }
  }

  const getPinterestBoardsByProfileId = (profileId) => {
    return getters.getPinterestBoards.filter((board) => {
      return board.profile_id === profileId
    })
  }

  const getAllPinterestProfiles = () => {
    return getters.getPinterestAccounts.items.filter((item) => {
      return (
        item.type.toLowerCase() === 'profile' &&
        item.pinterest_id !== item.username
      )
    })
  }

  const changeCalendarState = (event, date = '') => {
    switch (event?.target?.className) {
      case 'mx-icon-double-left':
        return clone(
          momentWrapper(date).subtract(1, 'year').formatDateTime()
        )
      case 'mx-icon-left':
        return clone(
            momentWrapper(date).subtract(1, 'month').formatDateTime()
        )
      case 'mx-icon-double-right':
        return clone(momentWrapper(date).add(1, 'year').formatDateTime())
      case 'mx-icon-right':
        return clone(momentWrapper(date).add(1, 'month').formatDateTime())
      default:
        return momentWrapper(date).formatDateTime()
    }
  }

  const tooltipHtml = (content, className = '') => {
    return `<div class="text-sm text-gray-900 ${className}">${content}</div>`
  }

  /**
   * Handles the image error and sets the default image
   * @param event
   */
  const handleImageError = (event) => {
    event.target.src =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
  }

   /**
   * Handles the image error and sets the no image available image
   * @param event
   */
   const handleNoImageError = (event) => {
    event.target.src =
      'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
  }

  const setComposerToolkitInfoViewStatus = async (status) => {
    try {
      await proxy.post(fetchComposerToolkitViewStatus, {
        show: false,
      })
      await dispatch('setComposerToolkitInfoStatus', false)
    } catch (e) {
      await dispatch('toastNotification', {
        message: 'Could not save preference to hide the info popup.',
        type: 'warn',
      })
    }
  }

  const featureFlag = () => {
    // Feature Flag disabled for staging
    if(process.env.VUE_APP_ENVIRONMENT === 'staging') return true

    const email = getters.getProfile.email
    if (email)
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    return false
  }
  const camelToSnake = (camelStr) => {
    // Edge case: Empty string
    if (!camelStr) {
        return "";
    }

    // Convert camelCase to snake_case
    const snakeStr = camelStr
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // Handles transitions like 'camelC' or 'camel123Case'
        .toLowerCase();

    return snakeStr;
}
  const openDraftComposer = async (title, message, cancelTitle, okTitle) => {
    if(isDraftComposer.value) {
      const options = {...swalAttributes(), modalClass: 'sweet-alert-confirmation fade sweet-alert-minimize-composer', okVariant: 'primary', hideHeader: false}
      const res = await root.$bvModal.msgBoxConfirm(
          message,
          {
            title,
            ...options,
            cancelTitle,
            okTitle,
          }
      )
      return res
    }
    return false
  }

  const isPremiumTwitter = (account, ignorePlatform = false) => {
    return (
      (account?.channel_type?.toLowerCase() === 'twitter' ||
        ignorePlatform) &&
      account?.subscription_type?.toLowerCase() === 'premium'
    )
  }

  const isShortLink = (link) => {
    console.debug('Method:isShortLink', link)

    let check = false

    if (link) {
      if (
        link.includes('http://cstu.co') ||
        link.includes('http://cstu.io') ||
        link.includes('https://cstu.io') ||
        link.includes('https://cstu-shortener-stage.cstuinternal.com')
      ) {
        check = 'cstu'
      } else if (link.includes('contentstudio.page.link')) {
        check = 'google'
      } else if (checkBitlyUrl(link)) {
        check = 'bitly'
      } else if (checkReplugUrl(link)) {
        check = 'replug'
      }
    }

    return check
  }

  const checkBitlyUrl =(url) => {
    let status = false
    const domianOption = getters.getBitlyAccount.domain_options
    // if (domianOption) {
    domianOption.forEach(function (element) {
      if (url.includes(element)) {
        status = true
        return false
      }
    })
    // }
    return status
  }

  const checkReplugUrl = (url) => {
    let status = false
    // eslint-disable-next-line camelcase
    const domian_option = getters.getReplug.item.domain_options
    // eslint-disable-next-line camelcase
    if (domian_option) {
      domian_option.forEach(function (element) {
        if (url.includes(element)) {
          status = true
          return false
        }
      })
    }
    return status
  }


  return {
    SUPPORTED_PLATFORMS,
    FACEBOOK_BACKGROUNDS,
    isComposerModalOpen,
    isDraftComposer,
    draftPlanId,
    analyticsEnabledTwitterAccounts,
    openDraftComposer,
    getSocialIcon,
    channelImage,
    getSocialImageRounded,
    getSocialChannelBackgroundColor,
    formatBestDate,
    checkDateDisable,
    showTypes,
    getPlatformName,
    changeCalendarState,
    tooltipHtml,
    handleImageError,
    handleNoImageError,
    getPinterestBoardsByProfileId,
    getAllPinterestProfiles,
    setComposerToolkitInfoViewStatus,
    featureFlag,
    camelToSnake,
    isPremiumTwitter,
    showNewChat,

    // Link Shortener Helpers
    isShortLink,
  }
}
