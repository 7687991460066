  <template>
    <div
      class="relative w-full min-w-[30rem] max-w-[30rem] flex flex-col bg-[#F4F7FA] h-full"
      :style="{
        'margin-right': isGenerating || aiImages.length > 0 ? '0rem' : '-30rem',
      }"
    >
      <div class="flex-1 overflow-y-auto px-4">
        <template v-if="aiImages.length > 0 && !isGenerating && completed">
          <div class="flex justify-between w-full items-center my-2">
            <label for="all" class="m-0 mr-2 text-sm">Generated Results</label>
            <div v-if="getAIImages.length" class="flex items-center cursor-pointer mr-[0.875rem]">
              <CstSimpleCheckbox
                :value="selectAll"
                reverse
                @change="handleSelectAll"
              >
                <template v-slot:label>Select All</template>
              </CstSimpleCheckbox>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center gap-2 mt-2 mb-4 overflow-y-auto">
            <div v-if="!getAIImages.length" class="flex flex-col items-center justify-center py-16 px-4 gap-y-2">
              <!-- images were corrupted -->
              <p class="text-sm text-center">
                Images could not be generated due to some error. Update the prompt and try again
              </p>
            </div>
            <ViewImage
              v-for="(image, idx) in getAIImages"
              :key="`ai_image_${idx}`"
              :image="image"
              @add-to-editor="addToEditor"
              @preview="handlePreview"
            />
          </div>
        </template>
        <template v-else>
          <div class="h-full px-2 mt-4">
            <AiGenerationLoader
              :loading="isGenerating"
              text="images"
              :loading-completed="loadingCompleted"
            />
          </div>
        </template>
      </div>

      <div class="flex justify-end px-5 py-4 w-full border-t border-gray-200">
        <button
          v-if="aiImages.length > 0"
          v-b-tooltip="{
            content: 'Choose an image',
            disabled: selectedImageLength === 0,
          }"
          class="btn btn-studio-theme-space flex w-100 justify-center"
          :disabled="mediaUploading || isGenerating || selectedImageLength === 0"
          @click="addToEditor"
        >
          <i class="icon-add-new-member-cs mr-2"></i>
          <span>
            Add to Editor
            {{ selectedImageLength ? `${selectedImageLength} / ${aiImages.length}` : '' }}
          </span>
          <clip-loader
            v-if="mediaUploading"
            :color="'#9da6ac'"
            :size="'13px'"
            class="spinner ml-2"
            variant="info"
          />
        </button>
      </div>


    <VueEasyLightbox
    :visible="visible"
    :imgs="base64Images"
    :index="currentIndex"
    @hide="visible = false"
  ></VueEasyLightbox>

    </div>

  </template>

  <script setup>
  import { computed, ref, defineProps, watch } from 'vue'

  // components
  import VueEasyLightbox from 'vue-easy-lightbox'
  import ViewImage from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/ViewImage.vue'
  import AiGenerationLoader from '@src/components/common/AiGenerationLoader.vue'
  import CstSimpleCheckbox from '@/src/components/UI/CheckBox/CstSimpleCheckbox.vue'

  const props = defineProps({
    aiImages: {
      type: Array,
      default: () => [],
    },
    isGenerating: {
      type: Boolean,
      default: false,
    },
    uploadImagesFromLink: {
      type: Function,
      default: () => {},
    },
    loadingCompleted: {
      type: Function,
      default: () => {},
    },
    completed: {
      type: Boolean,
      default: false,
    },
    mediaUploading: {
      type: Boolean,
      default: false,
    },
  })

  const selectAll = ref(false)
  const visible = ref(false)
  const currentIndex = ref(0)


  // watch
  // watch ai images change and set select all to false
  watch(
    () => props.aiImages,
    (value) => {
      // check if all images are selected and set select all to true
      if (!value.length) return

      if (value && value.every((item) => item.select)) {
        selectAll.value = true
      } else {
        selectAll.value = false
      }
    }
  )
  // computed
  const selectedImageLength = computed(
    () => props.aiImages.filter((item) => item.select).length
  )


  // methods
  const handleSelectAll = () => {
    if (props.aiImages.length > 0) {
      props.aiImages.forEach((item) => {
        item.select = !selectAll.value
      })
      selectAll.value = !selectAll.value
    }
  }

  const addToEditor = (image = null) => {
    // If image is passed, upload that image only instead of uploading all the selected images
    if (image?.filename) {
      const temp  = {
        name: image.filename,
        data: image.base64,
      }
      props.uploadImagesFromLink([temp])
      return
    }

    const selectedImages = props.aiImages
      .filter((item) => item.select)
      .map((item) => ({
        name: item.filename,
        data: item.base64,
      }))

    if (selectedImages.length === 0) {
      return
    }

    props.uploadImagesFromLink(selectedImages)
  }

  const getAIImages = computed(() => {
    return props.aiImages.filter((item) => item.base64)
  })

  const base64Images = computed(() => {
    return getAIImages.value.map((item) => item.base64 ? `data:image/png;base64,${item.base64.trim()}` : '')
  })

  // preview image
  const handlePreview = (image) => {
    if (!image || !image.filename) return

    const index = props.aiImages.findIndex((item) => item.filename === image.filename)
    if (index !== -1) {
      currentIndex.value = index
      visible.value = true
    }
  }
  </script>
