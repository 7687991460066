<script setup>
import { computed, reactive, defineProps, ref } from 'vue'
import CstButton from '@src/components/UI/Button/CstButton.vue'
import UserMavenIcon from '@assets/img/integration/usermaven_icon.svg'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import useUsermavenAnalytics from '@/src/modules/analytics/views/web-analytics/usermaven/composables/useUsermavenAnalytics.js'
import linkIcon from '@/src/assets/img/icons/analytic/link-chain.svg'
import UserMavenxCSIcon from '@/src/assets/img/icons/analytic/usermaven-x-cst.svg'
import ArrowIcon from '@/src/assets/img/icons/analytic/horizontal-arrow.svg'
import PlayIcon from '@/src/assets/img/icons/analytic/play-icon.svg'

const { appData } = useWhiteLabelApplication()
const { DEFAULT_LINK, API_ACTIONS, makeApiCall, processUsermavenURL } =
  useUsermavenAnalytics()

defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
})

const usermavenUrl = ref('')
const isValidURL = ref(false)
const isConnecting = ref(false)

const state = reactive({
  screenHeight: window?.innerHeight,
})

/**
 * @description computes the modal class according to the screenHeight
 * @type {ComputedRef<string>}
 */
const modalContentClass = computed(() =>
  state.screenHeight >= 1112 ? 'w-[80%] xl:w-[50%]' : 'w-[80%] xl:w-[50%]'
)

const connectUsermaven = async () => {
  const url = processUsermavenURL(usermavenUrl.value)
  if (url) {
    isConnecting.value = true
    await makeApiCall(url, API_ACTIONS.CONNECT)
    isConnecting.value = false
  } else {
    isValidURL.value = true
  }
}

const redirectToDemo = () => {
  window.open(DEFAULT_LINK, '_blank', 'noopener, noreferrer')
}

const redirectToWebsite = () => {
  window.open('https://usermaven.com/website-analytics', '_blank', 'noopener, noreferrer')
}

function toggleContent() {
  const content1 = document.getElementById('UM_UI_MAIN');
  const content2 = document.getElementById('UM_HTC');

  if (!content2.classList.contains('hidden')) {
    // Slide content 2 out, content 1 in
    content2.classList.remove('animate-slideIn');
    content2.classList.add('animate-slideOut');

    setTimeout(() => {
      content2.classList.add('hidden', 'translate-x-full', 'opacity-0');
      content1.classList.remove('hidden', 'translate-x-full', 'opacity-0','animate-slideOut');
      content1.classList.add('animate-slideIn');
    }, 200); // Match animation duration
  } else {
    // Slide content 1 out, content 2 in
    content1.classList.remove('animate-slideIn');
    content1.classList.add('animate-slideOut');

    setTimeout(() => {
      content1.classList.add('hidden', 'translate-x-full', 'opacity-0');
      content2.classList.remove('hidden', 'translate-x-full', 'opacity-0','animate-slideOut');
      content2.classList.add('animate-slideIn');
    }, 200); // Match animation duration
  }
}


</script>

<template>
  <div
    id="Backdrop"
    class="
      web-analytics-backdrop
      bg-black-900 bg-opacity-10
      absolute
      h-full
      z-[1000]
      backdrop-blur-[2px]
      shadow-sm
      rounded-md
    "
  >
    <div class="w-full h-full flex justify-center items-center">
      <div
        id="onboarding-card"
        class="
          card
          rounded-[1rem]
          py-10
          px-10
          gap-4
          flex flex-col
          max-w-[56.667rem]
          overflow-hidden
        "
        :class="modalContentClass"
      >
        <div id="UM_UI_MAIN" class="">
          <div id="hero" class="flex flex-col gap-4 items-center">
            <img :src="UserMavenxCSIcon" alt="usermaven and cs icon" />
            <p class="text-center text-[#3A4557] font-bold text-lg"
              >360° website analytics powered by Usermaven</p
            >
            <span class="w-[75%] text-center text-[#595C5F] text-sm"
              >See how your website and social media work together, Track
              visitors, clicks, traffic, sources, top pages, conversations, and
              more-all within your {{ appData.businessName }} workspace.</span
            >
            <button
              class="gradient-button antialiased hover:shadow-lg transition-all"
              @click="redirectToDemo"
            >
              View live demo
            </button>
          </div>
          <div
            id="connection-section"
            class="flex flex-col gap-4 rounded-[1rem] bg-[#F8F8F8] p-8 my-5"
          >
            <p class="text-center text-[#3A4557] text-base font-bold">
              Integrate Usermaven Dashboard
            </p>
            <div class="flex justify-center my-3">
              <button
                v-tooltip="'Open the Usermaven website'"
                class="get-started items-center border-0 hover:shadow-lg"
                @click="redirectToWebsite"
              >
                <img :src="UserMavenIcon" alt="usermaven icon" class="w-4 h-4" />
                <span class="text-white text-sm leading-none"
                  >Get started with Usermaven</span
                >
              </button>
            </div>

            <div class="">
              <p class="text-center text-[#3A4557] text-sm mb-3">
                Usermaven configured? Connect it with {{appData.businessName}} now!
              </p>
              <div class="flex flex-col lg:flex-row gap-4 items-center">
                <div
                  class="
                    w-full
                    flex flex-1
                    items-center
                    h-[40px]
                    gap-3
                    border
                    rounded-md
                    border-[#DEE2E6]
                    bg-white
                    py-2
                    px-4
                  "
                >
                  <img :src="linkIcon" alt="link icon" />
                  <input
                    v-model="usermavenUrl"
                    type="text"
                    class="
                      bg-transparent
                      flex-1 flex-shrink
                      border-0
                      outline-none
                    "
                    placeholder="Enter Usermaven Shareable Link"
                     @keydown.enter="connectUsermaven"
                  />
                </div>
                <CstButton
                  variant="primary"
                  class="!h-[40px] !px-[2.25rem] !py-3 !rounded-md group"
                  :disabled="usermavenUrl.trim() === '' || isConnecting"
                  @click="connectUsermaven"
                >
                  <template v-if="isConnecting">
                    <clip-loader
                      class="spinner"
                      :color="'#e0dfdf'"
                      :size="'15px'"
                    ></clip-loader>
                  </template>
                  <template v-else>
                    <p class="leading-4 font-medium text-light-cs">Connect</p>
                    <div class="ml-3 group-hover:translate-x-2 transition-all">
                      <img :src="ArrowIcon" alt="arrow icon" />
                    </div>
                  </template>
                </CstButton>
              </div>
              <label v-if="isValidURL" class="text-xs text-cs-red" for="">
                Please enter a valid usermaven URL
              </label>
            </div>
          </div>
          <div id="divider" class="flex gap-6 items-center">
            <div class="flex-1">
              <hr />
            </div>
            <span>
              <a class="flex gap-3 items-center cursor-pointer group" @click="toggleContent">
                <img :src="PlayIcon" alt="play icon" />
                <span class="text-sm text-[#595959] group-hover:text-cs-primary transition-all leading-none">
                  How to Connect?
                </span>
              </a>
            </span>
            <div class="flex-1">
              <hr />
            </div>
          </div>
        </div>
        <div id="UM_HTC" class="hidden" >
          <div class="flex flex-col gap-3">
            <div class="flex justify-center items-center relative">
              <div class=" absolute left-0 cursor-pointer" @click="toggleContent">
                <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="39.64" height="36" rx="9.38" fill="#3A4557" fill-opacity="0.05"/>
                  <path d="M22 12L14 18L22 24" stroke="#3A4557" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                  <line x1="14" y1="18" x2="28" y2="18" stroke="#3A4557" stroke-width="1" stroke-linecap="round"/>
                </svg>

              </div>
              <div class="flex flex-col justify-center items-center">
                <img :src="UserMavenxCSIcon" alt="usermaven and cs icon" class="h-[50px]" />
              </div>
              <a
                href="https://docs.contentstudio.io/article/1045-web-analytics-usermaven"
                target="_blank" rel="noopener"
                class="absolute right-0  my-1"
              >Learn More</a>
            </div>
            <div>
              <iframe
                width="100%"
                height="460"
                src="https://www.youtube.com/embed/kv5AzD_E-KA?si=4nG_QdLY0OdAJ1ch"
                title="Introducing Usermaven analytics in ContentStudio | Simplify website tracking"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                style="border: none;"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.web-analytics-backdrop {
  width: 100%;
}

.card {
  border-radius: 15px;
  border: 1px solid #e9eff6;
  background: #fff;
}

.gradient-button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  /* Rounded corners for the button */
  z-index: 1;
  background: linear-gradient(45deg, #ff6ec4, #7873f5);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 4px;
    /* Same border-radius for the gradient border */
    background: linear-gradient(45deg, #ff6ec4, #7873f5);
    padding: 1.75px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.get-started {
  display: inline-flex;
  height: 40px;
  padding: 15.2px 26.177px 15.2px 26.177px;
  justify-content: center;
  align-items: center;
  gap: 8.444px;
  flex-shrink: 0;
  border-radius: 4.754px;
  background: linear-gradient(221deg, #334158 27.41%, #000d20 96.29%);
}
</style>
