<template>
  <div>
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="
          state.activeInboxData.inbox_details.posted_by.image
            ? state.activeInboxData.inbox_details.posted_by.image
            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
        "
        alt=""
        @error="
          $event.target.src = fallbackImagePreview(
            commonMethod
              .getPostedByName(
                state.activeInboxData.inbox_details.posted_by
                  ? state.activeInboxData.inbox_details.posted_by
                  : '',
              )[0]
              .toUpperCase(),
            '63A3F2',
            'white',
          )
        "
      />
      <div class="px-2 font-bold">
        {{ state.activeInboxData.inbox_details.posted_by.name }}
        <span
          v-if="state.activeInboxData.inbox_details.posted_by.headline"
          class="block font-normal text-xs text-gray-800"
        >
          {{ state.activeInboxData.inbox_details.posted_by.headline }}
        </span>
        <span class="block font-normal text-xs text-gray-800">
          <span v-if="state.activeInboxData.created_at">{{
              momentWrapper(state.activeInboxData.created_at).formatDateTime()
            }}</span>
          <span v-else
          >{{
              momentWrapper().formatDateTime()
            }}</span
          >
        </span>
      </div>
    </div>
    <div v-if="getDescription" class="items-center px-3 py-2">
      <HtmlRenderer
          tag="span"
          class="whitespace-pre-wrap select-none"
          :html-content="getDescription"
      />
      <span
        v-if="getDescription.length > state.char && state.load"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        Show More</span
      >
      <span
        v-if="getDescription.length > state.char && !state.load"
        class="text-blue-300 cursor-pointer ml-1"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <!-- used inline style where tailwind base classes are missing -->
    <template
      v-if="
        state.activeInboxData.element_details.post_attachment !== null &&
        state.activeInboxData.element_details.post_attachment.length > 0 &&
        state.activeInboxData.element_details.post_attachment[0] !== null
      "
    >
      <div
        v-if="
          state.activeInboxData.element_details.post_attachment[0]['type'] ===
          'images'
        "
        class="flex flex-wrap relative overflow-hidden"
      >
        <template
          v-for="(image, index) in state.activeInboxData.element_details
            .post_attachment"
          :key="index"
        >
          <div
            v-if="index <= 4"
            class="flex-img bg-cover bg-no-repeat bg-center h-auto"
            :class="{
              'img-five':
                state.activeInboxData.element_details.post_attachment.length >=
                  5 && index > 1,
            }"
            :style="{ 'background-image': 'url(' + image.url + ')' }"
          >
            <span
              v-if="
                state.activeInboxData.element_details.post_attachment.length >
                  5 && index === 4
              "
              class="block w-full h-full"
              style="background-color: rgba(0, 0, 0, 0.4)"
            ></span>
            <img
              class="max-w-full max-h-full w-full opacity-0"
              :src="image.url"
              alt=""
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
          <div
            v-else
            class="absolute p-2 text-3xl font-semibold text-white"
            style="transform: translate(-50%, -50%); right: 9%; bottom: 13%"
            >+{{
              state.activeInboxData.element_details.post_attachment.length - 5
            }}</div
          >
        </template>
      </div>
      <template
        v-if="
          state.activeInboxData.element_details.post_attachment.length === 1 &&
          state.activeInboxData.element_details.post_attachment[0]['type'] !==
            'images'
        "
      >
        <div
          v-if="
            state.activeInboxData.element_details.post_attachment[0]['type'] ===
            'article'
          "
          class="p-1"
        >
          <div class="link_bg">
            <div
              v-if="
                state.activeInboxData.element_details.post_attachment[0].url
              "
              class="flex-img bg-cover bg-no-repeat bg-center h-auto"
            >
              <img
                class="h-full w-full object-cover"
                :src="
                  state.activeInboxData.element_details.post_attachment[0].url
                "
                alt=""
                @error="
                  (event) => {
                    event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  }
                "
              />
            </div>
            <div class="content mt-0.5 px-2">
              <a
                target="_blank" rel="noopener"
                :href="
                  sanitizeUrl(state.activeInboxData.element_details.post_attachment[0]
                    .source)
                "
                class="font-bold text-lg !text-black-900"
                >{{
                  state.activeInboxData.element_details.post_attachment[0].title
                }}
              </a>
              <p class="text-sm mt-1">{{
                state.activeInboxData.element_details.post_attachment[0]
                  .description
              }}</p></div
            >
          </div>
        </div>
        <div
          v-if="
            state.activeInboxData.element_details.post_attachment[0]['type'] ===
              'videos' &&
            checkForValidVideo(
              state.activeInboxData.element_details.post_attachment[0].url,
            )
          "
          class="_video"
        >
          <video controls>
            <source
              :src="
                state.activeInboxData.element_details.post_attachment[0].url
              "
              type="video/mp4"
            />
            <source
              :src="
                state.activeInboxData.element_details.post_attachment[0].url
              "
              type="video/ogg"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { parseDescriptionHtml } from '@common/lib/helper'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import useDateFormat from "@common/composables/useDateFormat";
import {sanitizeUrl} from "@braintree/sanitize-url";
import HtmlRenderer from "@common/components/htmlRenderer.vue";
export default {
  name: 'LinkedinPostContent',
  components: {HtmlRenderer},
  methods: {sanitizeUrl},
  props: {
    inboxData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { momentWrapper } = useDateFormat()
    console.log('LINKEDINPOSTCONTENT', props.inboxData)
    const state = reactive({
      activeInboxData: computed(() => props.inboxData),
      load: true,
      char: 377,
    })

    watch(
      () => state.activeInboxData.element_details.element_id,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          state.load = true
        }
      }
    )

    const commonMethod = commonMethods
    const getDescription = computed(() => {
      let msg = state.activeInboxData.element_details.post_message
      msg = parseDescriptionHtml(msg, 5000, true, true)
      if (msg.length > state.char && state.load) {
        msg = msg.substr(0, state.char).concat('...')
        return msg
      }
      return msg
    })
    const changeLoadMore = (val) => {
      state.load = val
    }
    const checkForValidVideo = (url) => {
      // function checks if the video is of valid .mp4 format
      return url != null && url.includes('mp4')
    }

    return {
      state,
      getDescription,
      commonMethod,
      changeLoadMore,
      checkForValidVideo,
      momentWrapper
    }
  },
}
</script>

<style lang="less" scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
.img-three {
  flex: 1 1 calc(29.33% + 10px) !important;
}
.img-five {
  flex: 1 0 calc(18.33% - 10px);
  border: 1px solid white;
}
.link_bg {
  background: #f7f8f9;
  border-radius: 5px;
}

.load-more-comments {
  text-decoration: underline;
  color: #385898;
  font-size: 13px;

  &:hover {
    cursor: pointer;
  }
}
</style>
