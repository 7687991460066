import { mapGetters, mapActions, mapMutations } from 'vuex'
import { fetchStorageLinkUrl } from '@src/modules/publish/config/api-utils'

import { composer } from '@src/modules/composer/store/composer-mutation-type'
import {
  blogPosting,
  blogPostingTypes,
} from '@src/modules/publish/store/states/mutation-types'
const he = require('he')

export const blogPostMixin = {
  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getBlogEditorSelector',
      'getBlogOptions',
      'getSecondaryBlogSelection',
      'getBlogSelectionEdit',
      'getBlogSelection',
      'getPublishSelection',
      'getTasks',
      'getPublishLoaders',
    ]),
  },
  methods: {
    ...mapMutations(['RESET_COMMENTS_LIST', 'RESET_TASK_LIST']),
    ...mapActions([
      'setPublishSource',
      'setInitializeArticleBlogPostLoader',
      'setDraftCancelAction',
    ]),

    getPrimarySecondaryBlogSelection(type) {
      const primaryBlog = {
        selection: this.getBlogSelection.primaryBlog,
      }

      if (type !== 'Automation') {
        primaryBlog.time = this.getBlogSelection.primaryTime
      }

      const secondaryBlog = []

      this.getBlogSelection.secondaryBlog.forEach(function (element, index) {
        const blog = {
          selection: element.selection,
        }

        if (type !== 'Automation') {
          blog.time = element.time
        }
        if (type === 'BlogPost') {
          blog.id = element.id
          blog.status = element.status
        }
        secondaryBlog.push(blog)
      })

      return {
        primary_blog: primaryBlog,
        secondary_blog: secondaryBlog,
      }
    },

    setSecondaryIdsSelection(ids) {
      console.debug('Method:setSecondaryIdsSelection', ids)
      if (
        this.getSecondaryBlogSelection &&
        this.getSecondaryBlogSelection.length > 0
      ) {
        this.getSecondaryBlogSelection.forEach(function (element, index) {
          element.id = ids && ids[index] ? ids[index] : null
        })
      }
    },

    initializeBlogPost(post, type) {
      console.debug('Method::initializeBlogPost', post)
      this.RESET_TASK_LIST()
      this.setSidebarStatus('task')
      this.RESET_COMMENTS_LIST()
      this.getPublishSelection.labels = []
      this.getPublishSelection.members = []
      this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, type)
      this.setDraftCancelAction(false)

      let source = ''
      let title = ''
      let image = ''
      let link = ''
      let description = ''
      if (type === 'Discovery Article') {
        source = post._source.id
        title = post._source.title
        image = post._source.image
        link = post._source.url
        description = post._source.description
      } else if (type === 'Discovery Video') {
        source = post.id
        title = post.snippet.title
        if (post.snippet.thumbnails) {
          if (post.snippet.thumbnails.maxres) {
            image = post.snippet.thumbnails.maxres.url
          } else if (post.snippet.thumbnails.high) {
            image = post.snippet.thumbnails.high.url
          } else if (post.snippet.thumbnails.default) {
            image = post.snippet.thumbnails.default.url
          }
        }
        description = post.snippet.description + post.player.embedHtml
      } else if (type === 'Discovery Facebook') {
        source = post.post_id
        title = post.name
        if (post.full_picture) {
          image = post.full_picture
        }
        link = post.link
        description =
          post.message +
          '<iframe style="width: 470px;height: 330px;" src="' +
          post.link +
          '" frameborder="0" allowfullscreen></iframe><br></div>'
      }

      if (link) {
        description +=
          '<div><p>This content was originally published <a target="_blank" rel="noopener"  href="' +
          link +
          '">here</a>.</p></div>'
      }

      this.setPublishSource(source)
      this.$store.commit(blogPosting.SET_BLOG_POSTING_TITLE, he.decode(title))
      this.$store.commit(blogPosting.SET_BLOG_POSTING_LINK, link)
      // this.getBlogEditorSelector.setData(description)
      this.$store.commit(blogPosting.SET_BLOG_POSTING_HTML, `${description}`)
      // console.debug('Method::initializeBlogPost', description)
      if (!image || image.indexOf('%') === -1) {
        this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, {
          source: 'Doka',
          link: image,
        })
        return false
      }

      this.$store.commit(blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER, true)
      this.postRequest(
        fetchStorageLinkUrl,
        { link: image, workspace_id: this.getActiveWorkspace._id },
        (response) => {
          if (response.data.status === true) {
            this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, {
              source: 'Doka',
              link: response.data.link,
            })
          }
          this.$store.commit(
            blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
            false
          )
        },
        (response) => {
          console.debug('Exception in initializeBlogPost', response)
          this.$store.commit(
            blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
            false
          )
        }
      )

      // this.processBlogPost(true)
    },

    blogPostAutoSave() {
      console.debug('Method:blogPostAutoSave')
      this.$store.commit(composer.SET_BLOG_AUTO_SAVE_INTERVAL, null)
      if (
        this.getBlogPostingDetails.type === 'Composer Article' &&
        this.getPublishSelection.plan_id &&
        this.getPublishSelection.status === 'draft' &&
        !this.getPublishLoaders.processBlogPost &&
        !this.getPublishLoaders.processSavePublication
      ) {
        this.processBlogPost(true)
      }
    },
  },
}
