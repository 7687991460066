<template>
  <div class="tweet-grid-item">
    <div class="box_inner tweet-grid-item__box_inner">
      <div class="content_block tweet-grid-item__box_inner__content_block">
        <div class="tweet-grid-item__box_inner__content_block__height">
          <div class="tweet-grid-item__box_inner__content_block__user">
            <div
              class="tweet-grid-item__box_inner__content_block__user__profile"
            >
              <div
                class="tweet-grid-item__box_inner__content_block__user__profile__picture"
                :style="getTweetUserImage(tweet.user)"
              ></div>
              <div
                class="tweet-grid-item__box_inner__content_block__user__profile__text"
              >
                <a
                  target="_blank" rel="noopener"
                  class="text"
                  :href="sanitizeUrl(getTweetRedirectLink(tweet))"
                  >{{ limitTextLength(tweet.user.name, 15) }}</a
                >
                <p v-if="tweet.created_at" class="sub_text">{{
                  $filters.relative(tweet.created_at)
                }}</p>
              </div>
            </div>
          </div>

          <!--<div class="image_block" v-if="tweet.entities.media && tweet.entities.media[0]">-->
          <!--<div class="img" v-lazy:background-image="tweet.entities.media[0].media_url"></div>-->
          <!--</div>-->
          <p
            class="tweet-grid-item__box_inner__content_block__description"
            v-html="
              twitterText(
                tweet.text,
                tweet.entities.hashtags,
                tweet.entities.urls,
                tweet.entities.user_mentions,
              )
            "
          ></p>
        </div>

        <div class="tweet-grid-item__box_inner__content_block__social_stats">
          <div
            class="tweet-grid-item__box_inner__content_block__social_stats__inner"
          >
            <div
              v-tooltip.top="'Retweets'"
              class="tweet-grid-item__box_inner__content_block__social_stats__inner__item"
            >
              <div class="name">
                <p> Retweet </p>
              </div>
              <div class="value">
                <h3>{{ intToString(tweet.retweet_count) }}</h3>
              </div>
            </div>
            <div
              v-tooltip.top="'Favorite'"
              class="tweet-grid-item__box_inner__content_block__social_stats__inner__item"
            >
              <div class="name">
                <p> Favorite </p>
              </div>
              <div class="value">
                <h3>{{ intToString(tweet.favorite_count) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="tweet-grid-item__box_inner__content_block__action_buttons">
          <b-dropdown
            id="dropdown-favorite-articles"
            ref="dropdown_favorite"
            variant="studio-icon-theme"
            class="studio-icon-theme-dropdown mr-3"
            no-caret
            block
          >
            <template v-slot:button-content>
              <i
                v-tooltip="'Share to social media'"
                class="icon-Share_icon"
                @click="initializeTwitterPostCreation(tweet)"
              ></i>
            </template>
          </b-dropdown>
          <b-dropdown
            id="dropdown-favorite-articles"
            ref="dropdown_favorite"
            variant="studio-icon-theme"
            class="studio-icon-theme-dropdown mr-3"
            no-caret
            block
          >
            <template v-slot:button-content>
              <i
                v-tooltip="'Retweet'"
                class="cs-refresh"
                @click.prevent="twitterRetweetModal(tweet)"
              ></i>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sanitizeUrl} from "@braintree/sanitize-url";

export default {
  props: {
    tweet: {}
  },
  methods: {
    sanitizeUrl,
    twitterRetweetModal (post) {
      console.debug('Method:initializeTwitterRetweet')

      let username = ''
      if (post.user.screen_name) {
        username = post.user.screen_name
      }

      this.$store.dispatch('setTwitterRetweetSelection', {
        retweetId: post.id_str,
        message: post.text,
        username,
        comment: ''
      })
      this.$bvModal.show('retweetDialog')
    }
  }
}
</script>
