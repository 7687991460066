<template>
  <div
    class="modal-router social_share_modal social_share_only top_left_design_box"
  >
    <div
      class="modal-dialog"
      :class="[closeStatus ? 'animated fadeInDown' : 'animated fadeOutUp']"
    >
      <div class="modal-content">
        <ModelLayout
          :status="closeStatus"
          type="Social"
          :is-c-s-v="isCSV"
        ></ModelLayout>
        <div class="modal_body" style="min-height: 300px">
          <div class="modal_inner_content basic_form" style="overflow: visible">
            <!--<p class="saved">Saved a few seconds ago</p>-->
            <div class="d-flex align-items-stretch">
              <div class="content" style="padding-right: 20px">
                <div class="content_inner">
                  <ContentCategorySelection
                    v-if="!getPublishLoaders.initializeArticlePostCreation"
                    :module="'publish'"
                  ></ContentCategorySelection>
                  <ContentCategoryNote
                    v-if="
                      !getPublishLoaders.initializeArticlePostCreation &&
                      isGlobalContentCategory
                    "
                  ></ContentCategoryNote>
                  <AccountSelection
                    v-if="
                      !getPublishLoaders.initializeArticlePostCreation &&
                      !isGlobalContentCategory
                    "
                    :show-label="false"
                  ></AccountSelection>
                  <clip-loader
                    v-if="getPublishLoaders.initializeArticlePostCreation"
                    color="#4165ed"
                    :size="'16px'"
                  ></clip-loader>

                  <div
                    v-if="getAccountSelection.instagram.length > 0"
                    class="input_field"
                  >
                    <div class="compose_warning_box text-center">
                      <p>
                        <a
                          href="https://docs.contentstudio.io/article/487-publishing-scheduling-to-instagram-using-contentstudio"
                          target="_blank" rel="noopener"
                          class="insta-link"
                        >
                          <i class="far fa-question-circle"></i> Click for the
                          guide to Instagram publishing</a
                        >
                      </p>
                    </div>
                  </div>

                  <ToggleSelection
                    v-if="
                      !getPublishLoaders.initializeArticlePostCreation &&
                      !isGlobalContentCategory
                    "
                  ></ToggleSelection>

                  <SingleBox
                    :class="{
                      hide:
                        !getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation,
                    }"
                  ></SingleBox>
                  <FacebookBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.facebook.length === 0,
                    }"
                  ></FacebookBox>
                  <TwitterBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.twitter.length === 0,
                    }"
                  ></TwitterBox>
                  <LinkedinBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.linkedin.length === 0,
                    }"
                  ></LinkedinBox>
                  <PinterestBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.pinterest.length === 0,
                    }"
                  ></PinterestBox>
                  <TumblrBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.tumblr.length === 0,
                    }"
                  ></TumblrBox>
                  <InstagramBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.instagram.length === 0,
                    }"
                  ></InstagramBox>
                  <ZapierWarnings
                    v-if="
                      instagramSelection.no_access_accounts.length > 0 ||
                      (instagramSelection.all_access_accounts.length > 0 &&
                        zapierAttachmentError(getCommonSharingDetails)
                          .multiple_images)
                    "
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getCommonBoxOptions.globalCategoryStatus ||
                        (getAccountSelection.instagram.length === 0 &&
                          !isGlobalContentCategorySelected) ||
                        (isGlobalContentCategorySelected &&
                          !getCommonBoxOptions.globalCategoryChannels
                            .instagram),
                    }"
                    :multiple_images="
                      zapierAttachmentError(getInstagramSharingDetails)
                        .multiple_images
                    "
                    :aspect_ratio="false"
                    :no_access_accounts="instagramSelection.no_access_accounts"
                    :all_access_accounts="
                      instagramSelection.all_access_accounts
                    "
                  ></ZapierWarnings>

                  <GmbBox
                    :class="{
                      hide:
                        getCommonBoxOptions.status ||
                        getPublishLoaders.initializeArticlePostCreation ||
                        getAccountSelection.gmb.length === 0,
                    }"
                  ></GmbBox>

                  <ErrorSection
                    :errors="socialPostContentErrors()"
                    :warnings="socialPostContentWarnings()"
                  ></ErrorSection>

                  <ZapierWarnings
                    v-if="
                      instagramSelection.no_access_accounts.length > 0 ||
                      (instagramSelection.all_access_accounts.length > 0 &&
                        zapierAttachmentError(getCommonSharingDetails)
                          .multiple_images)
                    "
                    :class="{
                      hide:
                        !getCommonBoxOptions.status ||
                        getCommonBoxOptions.globalCategoryStatus,
                    }"
                    :multiple_images="
                      zapierAttachmentError(getCommonSharingDetails)
                        .multiple_images
                    "
                    :aspect_ratio="false"
                    :no_access_accounts="instagramSelection.no_access_accounts"
                    :all_access_accounts="
                      instagramSelection.all_access_accounts
                    "
                  ></ZapierWarnings>

                  <template
                    v-if="!getPublishLoaders.initializeArticlePostCreation"
                  >
                    <hashtag-suggessions></hashtag-suggessions>

                    <div
                      v-if="getPublishSelection.status !== 'published'"
                      class="post_share_option"
                    >
                      <GmbOptions
                        v-if="getAccountSelection.gmb.length"
                      ></GmbOptions>
                      <div
                        v-if="getPublishSelection.queueStatus"
                        class="post_footer text-right clear"
                      >
                        <div class="warning_box pull-left"
                          >This post is queued and scheduled on
                          <strong>{{
                            getWorkspaceTimeZoneTime(
                              getPublishSelection.planExecutionTime['date'],
                              'MMM DD, hh:mm a',
                            )
                          }}</strong>
                          <i
                            class="icon-edit-cs"
                            @click.prevent="editQueuePost"
                          ></i>
                        </div>
                        <button
                          class="btn btn-studio-theme-space"
                          :disabled="
                            getPublishLoaders.processSocialShare ||
                            sharingPendingProcessStatus ||
                            getPublishLoaders.processSavePublication
                          "
                          @click.prevent="processSocialShare"
                        >
                          <span>Update</span>
                          <clip-loader
                            v-if="getPublishLoaders.processSocialShare"
                            :color="'#ffffff'"
                            :size="'12px'"
                          ></clip-loader>
                        </button>
                      </div>
                      <PublishingTimeOptions
                        :is-instagram-selected="
                          instagramSelection.no_access_accounts.length > 0 ||
                          instagramSelection.all_access_accounts.length > 0
                        "
                        :no_access_accounts="
                          instagramSelection.no_access_accounts
                        "
                        :all_access_accounts="
                          instagramSelection.all_access_accounts
                        "
                        :zapier-errors="
                          zapierAttachmentError(getCommonSharingDetails)
                        "
                        type="Social"
                      >
                      </PublishingTimeOptions>
                    </div>

                    <div v-else class="post_share_option">
                      <div class="post_footer">
                        <div
                          class="d-inline-block success_box warning_box_left"
                        >
                          <p
                            >Published on
                            {{
                              getWorkspaceTimeZoneTime(
                                getPublishSelection.planExecutionTime['date'],
                                `MMMDD, hh:mm
                                                        a`,
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <ModelSidebar
                class="social_post_side_filter_model social_share_model_sidebar"
                type="Social"
                :is-c-s-v="isCSV"
              ></ModelSidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { draftConfirmationTypes } from '@common/store/mutation-types'
import { commonMethods } from '@common/store/common-methods'
import ZapierWarnings from '@common/components/zapier/ZapierWarnings'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import ModelLayout from '../posting/ModelLayout'
import ModelSidebar from '../posting/ModelSidebar'
import AccountSelection from '../posting/social/AccountSelection'
import ToggleSelection from '../posting/social/ToggleSelection'
import SingleBox from '../posting/social/boxes/SingleBox'
import FacebookBox from '../posting/social/boxes/FacebookBox'
import TwitterBox from '../posting/social/boxes/TwitterBox'
import PinterestBox from '../posting/social/boxes/PinterestBox'
import LinkedinBox from '../posting/social/boxes/LinkedinBox'
import TumblrBox from '../posting/social/boxes/TumblrBox'
import PublishingTimeOptions from '../posting/PublishingTimeOptions'
import GmbOptions from '../posting/social/GmbOptions'
import InstagramBox from '../posting/social/boxes/InstagramBox'
import GmbBox from '../posting/social/boxes/GmbBox'
import ContentCategorySelection from '../posting/social/ContentCategorySelection'
import ContentCategoryNote from '../posting/social/ContentCategoryNote'
import {
  commonTypes,
  loadersTypes,
  publish,
  publishTimeTypes,
  sharingTypes,
  social,
} from '../../store/states/mutation-types'

export default {
  components: {
    ErrorSection,
    GmbOptions,
    InstagramBox,
    GmbBox,
    PublishingTimeOptions,
    TumblrBox,
    LinkedinBox,
    PinterestBox,
    TwitterBox,
    FacebookBox,
    SingleBox,
    ToggleSelection,
    AccountSelection,
    ModelSidebar,
    ModelLayout,
    ContentCategorySelection,
    ContentCategoryNote,
    ZapierWarnings,
  },
  props: ['isCSV'],
  data() {
    return {
      closeStatus: true,
      clickaway: false,
      instagramSelection: {
        no_access_accounts: [],
        all_access_accounts: [],
      },
    }
  },
  created() {
    // this.$root.$on('closeShareModal', () => {
    //     this.closeShareModal()
    // })
    document.querySelector('body').classList.add('overflow_hidden')
    this.initializePublishSection('Social')
  },
  async mounted() {
    // body overflow issue for multiple model...
    $('.modal').on('hidden.bs.modal', function (e) {
      if ($('.modal:visible').length) {
        $('body').addClass('modal-open')
      }
    })
    await this.resetSocialShareStates()
    this.initiateSocialShare()
  },
  computed: {
    ...mapGetters([
      'getSharingCursorPosition',
      'getCommonBoxOptions',
      'getPublishLoaders',
      'getPublishSelection',
      'getAccountSelection',
      'getDraftCancelAction',
      'getTwitterVariations',
      'getTwitterVariations',
      'getDraftClickAway',
      'getTwitterVariationsIndex',
      'getInstagramAccounts',
      'getCommonSharingDetails',
      'getInstagramSharingDetails',
    ]),
    checkValidRoute() {
      return (
        this.$route.name !== 'saveBulkCsvAutomation' &&
        this.$router.currentRoute.name !== 'calender_plans' &&
        this.$router.currentRoute.name !== 'list_plans' &&
        this.$router.currentRoute.name !== 'feed_view' &&
        !this.$route.name.includes('feed_item_social_share')
      )
    },
  },
  beforeRouteLeave(to, from, next) {
    const confirmResult = this.isSocialConfirmBox()
    console.log(confirmResult)
    if (!confirmResult) {
      document.querySelector('body').classList.remove('overflow_hidden')
      next()
    } else {
      next(false)
    }
  },
  methods: {
    ...mapActions(['setDraftCancelAction']),

    resetSocialShareStates() {
      // resetting the values
      this.$store.commit(
        loadersTypes.SET_INITIALIZE_ARTICLE_POST_CREATION_LOADER,
        true
      ) // show the loader
      this.$store.commit(social.RESET_ACCOUNT_SELECTION) // reset all accounts
      this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true) // set customization option to true
      this.setDefaultSharingDetails() // set the default sharing details
      this.$store.commit('RESET_TASK_LIST') // reset all tasks
      this.$store.commit('RESET_COMMENTS_LIST') // reset all comments
      this.$store.commit(draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION, false)
      this.$store.commit(publish.RESET_SOCIAL_SHARE_SELECTION) // reset social share selection
      this.$store.commit(sharingTypes.RESET_SHARING_TAGS_SUGGESTION_ITEMS) // empty the hashtags list
      this.$store.commit(publishTimeTypes.SET_PUBLISHING_TIME_TYPE, 'queued') // set publishing time to queued
      this.$store.commit('setBlogSharingStage', 'content') // set the content and preview field enabled.
      this.$store.commit('setSidebarStatus', 'preview')
      this.$store.commit('setPublishPlanId', null)
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS) // reset the twitter variations
      this.$store.commit(
        loadersTypes.SET_INITIALIZE_ARTICLE_POST_CREATION_LOADER,
        false
      )
      return true
    },
    async initiateSocialShare() {
      const payload = {
        post_id: this.$route.params.post_id,
        type_of: 'social',
      }
      const res = await this.$store.dispatch('shareFeederPost', payload)
      if (res.data.data.post) {
        const item = {
          _id: res.data.data.post.id,
          post_id: res.data.data.post.id,
          url: res.data.data.post.url,
          title: res.data.data.post.title,
          image: res.data.data.post.image,
        }
        console.log(item)
        this.processPost(item)
        /* setTimeout(async () => {
                                     await this.processPost(item)
                                     console.log('process post', this.$store.getters.getCommonSharingDetails)
                                   }, 3000) */
      }
      console.log(this.$route.params, res)
    },
    closeShareModal() {
      if (!this.getDraftClickAway) {
        this.$store.commit(draftConfirmationTypes.SET_DRAFT_CLICKAWAY, true)
        console.log('closeAllModal --> click away go back to previous route...')
        this.$router.go(-1)
        document.querySelector('body').classList.remove('overflow_hidden')
      }

      // this.closeStatus= false
      //  setTimeout(() => {
      //   document.querySelector('body').classList.remove('overflow_hidden')
      //   this.$router.go(-1)
      // }, 300)
    },
    isSocialConfirmBox(event) {
      console.debug(event, 'modal::showmodal::showmodal::showmodal::show')
      if (this.checkValidRoute) {
        console.log('isSocialConfirmBox -> valid route')
        if (!this.getPublishSelection.plan_id && !this.getDraftCancelAction) {
          console.log('isSocialConfirmBox -> plan id and draft not set')
          let validForSave = false

          const selection = this.getAccountSelection
          if (
            selection.facebook.length > 0 ||
            selection.twitter.length > 0 ||
            selection.pinterest.length > 0 ||
            selection.linkedin.length > 0 ||
            selection.tumblr.length > 0 ||
            selection.instagram.length > 0 ||
            selection.gmb.length > 0
          ) {
            validForSave = true
          } else if (this.socialShareDraftValidation(false)) {
            validForSave = true
          }

          console.log('isSocialConfirmBox -> is valid for save', validForSave)
          // here we also check about editor value
          if (validForSave) {
            if (event) event.preventDefault()
            console.log('isSocialConfirmBox -> valid for save and returning...')
            this.$store.commit(
              draftConfirmationTypes.SET_DRAFT_CANCEL_ACTION,
              false
            )
            this.$bvModal.show('socialConfirmationDialog')
            return true
          } else {
            console.log('isSocialConfirmBox -> valid set to false')
            return false
          }
        }
      }
      return false
    },
  },

  watch: {
    'getAccountSelection.instagram': async function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.instagramSelection = {
          no_access_accounts: [],
          all_access_accounts: [],
        }
      } else {
        this.instagramSelection =
          commonMethods.zapierLinkedInstagramAccountExist(this)
      }
    },
  },
}
</script>
