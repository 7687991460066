<template>
  <div>
    <template v-if="loader">
      <clip-loader
        class="spinner mt-4 mb-3"
        :color="'#6d76ff'"
        :size="'20px'"
      ></clip-loader>
    </template>
    <template v-else>
      <div v-if="!(social.length > 0)" class="text-center px-3 py-3">
        You have not connected any any social account yet.
      </div>
      <b-table
        v-else
        class="onboarding-team__table mt-1"
        thead-tr-class="onboarding-team__table-header"
        tbody-tr-class="onboarding-team__table-body"
        table-class="text-left"
        responsive
        borderless
        :items="social"
        :fields="socialFields"
      >
        <template v-slot:cell(actions)="data">
          <button
              class="btn onboarding-team__trash"
              @click="removeWSAccount(data.item)"
            ><i class="fas fa-trash"></i
          ></button>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'SocialTab',
  props: ['workspace_id'],
  data () {
    return {
      socialFields: [
        { key: 'name' },
        { key: 'platform' },
        { key: 'type' },
        { key: 'actions', label: 'Action' }
      ],
      loader: false,
      social: []
    }
  },
  created () {
    // When Account get removed we listen for event
    EventBus.$on('refetchSocialAccounts', (obj) => {
      this.fetchWSSocialAccounts()
    })
  },
  mounted () {
    this.fetchWSSocialAccounts()
  },
  methods: {
    ...mapActions(['fetchWorkspaceSocialAccounts', 'removeSocialIntegration']),
    async fetchWSSocialAccounts () {
      this.loader = true
      const socialAccounts = await this.fetchWorkspaceSocialAccounts({
        workspace_id: this.workspace_id
      })

      const channels = [
        {
          name: 'facebook',
          key: 'facebook_id'
        },
        {
          name: 'twitter',
          key: 'twitter_id'
        },
        {
          name: 'linkedin',
          key: 'linkedin_id'
        },
        {
          name: 'pinterest',
          key: 'board_id'
        },
        {
          name: 'tumblr',
          key: 'platform_identifier'
        },
        {
          name: 'instagram',
          key: 'instagram_id'
        },
        {
          name: 'gmb',
          key: 'name'
        },
        {
          name: 'youtube',
          key: 'platform_identifier'
        },
        {
          name: 'tiktok',
          key: 'platform_identifier'
        },
        {
          name: 'threads',
          key: 'platform_identifier'
        },
        {
          name: 'bluesky',
          key: 'platform_identifier'
        }
      ]

      const container = []
      for (let x = 0; x < channels.length; x++) {
        const channel = channels[x]
        const socialData =
          channel.name === 'instagram'
            ? socialAccounts[channel.name].accounts
            : socialAccounts[channel.name]

        for (let y = 0; y < socialData.length; y++) {
          const social = socialData[y]

          let name = social.name
          let platformId = social[channel.key]
          if (
            channel.name === 'pinterest' &&
            social.type.toLowerCase() === 'profile'
          ) {
            name = social.first_name + ' ' + social.last_name
            platformId = social.pinterest_id
          }

          if (['tumblr', 'tiktok','youtube', 'threads', 'bluesky'].includes(channel.name)) {
            name =
                social.platform_name[0].toUpperCase() +
                social.platform_name.substr(1).toLowerCase()
          }
          if (channel.name === 'instagram') {
            social.type = 'Profile'
          }

          const payload = {
            ...social,
            platform_id: platformId,
            platform:
              channel.name[0].toUpperCase() +
              channel.name.substr(1).toLowerCase(),
            name,
            type: social.type
              ? social.type[0].toUpperCase() +
                social.type.substr(1).toLowerCase()
              : social.type
          }

          container.push(payload)
        }
      }
      this.social = container

      this.loader = false
    },

    async removeWSAccount (account) {
      console.debug('Method::removeAccount - Social', account)
      const payload = {
        platform: account.platform_id,
        type: account.platform.toLowerCase(),
        workspace_id: this.workspace_id,
        context: this
      }
      const context = this
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to remove a social account.',
          {
            title: 'Remove Social Account',
            ...swalAttributes()
          }
        )
        .then((value) => {
          if (value) {
            context.removeSocialIntegration(payload)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    },

    accountIdentifiar (account, type) {
      let key = ''
      switch (type) {
        case 'pinterest':
          key = account.board_id ? 'board_id' : 'pinterest_id'
          break
        case 'tumblr':
        case 'youtube':
          key = 'platform_identifier'
          break
        case 'gmb':
          key = 'name'
          break
        default:
          key = type.toLowerCase() + '_id'
      }
      return account[key]
    }
  }
}
</script>
