<template>
  <div class="auth_parent_container">
    <div v-if="verifying_information_loader" class="flex flex-col h-full items-center justify-center">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div v-else class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent v-if="!isWhiteLabelDomain" />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Already have an account?<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <div v-if="isWhiteLabelDomain" class="px-[5rem] py-[1rem] 2xl:py-[2rem]">
                <LogoComponent />
              </div>
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Join {{appData.businessName}}</h2>
              <p class="text-md text-[#757A8A] mt-2">Please enter your basic details.</p>
            </div>

            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 pt-12">
                <CstFloatingLabelInput
                    id="memberfirstName"
                    v-model="first_name"
                    class="!h-[3.3rem] mb-2.5"
                    type="text"
                    label="First Name"
                    :maxlength="18"
                    show-icon-left
                >
                  <template v-slot:icon>
                    <i class="icon-User"></i>
                  </template>
                </CstFloatingLabelInput>

                <CstFloatingLabelInput
                    id="memberlastName"
                    v-model="last_name"
                    class="!h-[3.3rem] mb-2.5"
                    type="text"
                    label="Last Name"
                    :maxlength="18"
                    show-icon-left
                >
                  <template v-slot:icon>
                    <i class="icon-User"></i>
                  </template>
                </CstFloatingLabelInput>

                <div>
                  <CstFloatingLabelInput
                    id="member_password"
                    v-model="password"
                    class="!h-[3.3rem] mb-2.5"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    show-icon-left
                    show-icon-right
                  >
                    <template v-slot:icon>
                      <i class="icon-Password"></i>
                    </template>
                    <template v-slot:icon-right>
                      <i
                          class="cursor-pointer"
                          :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                          @click="showPassword = !showPassword"
                      ></i>
                    </template>
                  </CstFloatingLabelInput>
                  <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                    <p class="text-xs">
                      {{ password_strength_message }}
                    </p>
                    <div class="flex gap-1">
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'danger', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                    </div>
                  </div>
                </div>

                <CstFloatingLabelInput
                    id="member_confirm_password"
                    v-model="confirmPassword"
                    class="!h-[3.3rem] mb-2.5"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    label="Confirm Password"
                    show-icon-left
                    show-icon-right
                    @keyup.enter="validateAndJoinOrganization"
                >
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="showConfirmPassword = !showConfirmPassword"
                    ></i>
                  </template>
                </CstFloatingLabelInput>
              </div>
            </div>

            <div class="auth-form__button-field mt-7">
              <button
                  :class="{ disabled: organization_join_loader }"
                  :disabled="organization_join_loader"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="validateAndJoinOrganization"
              >
                <span class="w-full">Join Now <img
                    v-if="organization_join_loader"
                    style="width: 20px; margin-left: 8px"
                    src="@/src/modules/account/assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import http from '@common/lib/http-common'
import {
  joinOrganizationAndSetPasswordURL,
  validateJoinTokenURL,
} from '@src/modules/setting/config/api-utils'
import SignupSideComponent from '@src/components/authentication/SignupSideComponent.vue'
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import LogoComponent from "@modules/account/components/LogoComponent.vue";

export default {
  components: {
    SignupSideComponent,
    CstFloatingLabelInput
  },
  computed: {
    ...mapGetters(['getSettingLoaders']),
  },
  created() {
    console.log(this.$route.query.token)
    this.validateJoiningToken()
  },
  setup(){
    const {isWhiteLabelDomain, appData} = useWhiteLabelApplication()

    return {
      isWhiteLabelDomain,
      appData
    }
  },
  data() {
    return {
      size: '14px',
      loaderColor: '#ffffff',
      confirmPassword: '',
      password: '',
      first_name: '',
      last_name: '',
      organization_join_loader: false,
      verifying_information_loader: true,
      showPassword: false,
      showConfirmPassword: false,
      password_strength_message: '',
      password_state: ''
    }
  },
  methods: {
    ...mapActions([]),
    validateJoiningToken() {
      http
        .post(validateJoinTokenURL, { token: this.$route.query.token })
        .then((res) => {
          if (res.data.status) {
            this.verifying_information_loader = false
          } else {
            this.verifying_information_loader = false
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
            this.$router.push({ name: 'login' })
          }
        })
        .catch()
    },
    validateAndJoinOrganization() {
      if (!this.first_name) {
        this.$store.dispatch('toastNotification', {
          message: 'Please enter your first name.',
          type: 'error',
        })
        return
      }
      if (!this.password) {
        this.$store.dispatch('toastNotification', {
          message: 'Please enter the password.',
          type: 'error',
        })
        return
      }
      if (!this.confirmPassword) {
        this.$store.dispatch('toastNotification', {
          message: 'Please enter the confirm password.',
          type: 'error',
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$store.dispatch('toastNotification', {
          message: 'Your password and confirm password does not match',
          type: 'error',
        })
        return
      }
      this.organization_join_loader = true
      http
        .post(joinOrganizationAndSetPasswordURL, {
          token: this.$route.query.token,
          password: this.password,
          confirm_password: this.confirmPassword,
          first_name: this.first_name,
          last_name: this.last_name
        })
        .then((res) => {
          if (res.data.status) {
            this.$store.dispatch('toastNotification', {
              message:
                'Your account has been created successfully, you can now login to your account.',
            })
            this.$router.push({ name: 'login' })
          } else {
            this.$store.dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
          }
          this.organization_join_loader = false
        })
        .catch((err) => {
          this.organization_join_loader = false
        })
    },
  },
  watch: {
    'password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is satisfactory';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
}
</script>
