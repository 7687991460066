<template>
  <div>
    <b-modal
      :id="modalId"
      dialog-class="flex fixed max-w-full my-0 top-0 bottom-0 left-0 right-0"
      content-class="bg-transparent"
      body-class="flex items-center justify-center relative"
      footer-class="bg-white flex items-center justify-between"
      hide-header

      @shown="handleModalShown"
      @hidden="handleModalHidden"
    >
      <div
        v-tooltip="'Close'"
        class="absolute top-5 right-5 cursor-pointer text-4xl text-gray-600 hover:text-white"
        @click="$bvModal.hide(modalId)"
      >
        <i class="fa fa-times"></i>
      </div>

      <div class="flex w-100 items-center justify-between">
        <div class="w-28">
          <div
            v-if="!disableLeft"
            class="inline-flex items-center justify-center space-x-2 text-center text-white cursor-pointer bg-gray-900 bg-opacity-95 hover:bg-opacity-100 px-3 py-2 rounded-xl"
            @click="previousImage"
          >
              <i class="fa fa-chevron-left text-2xl"></i>
              <span class="font-medium text-sm select-none">Previous</span>
          </div>
        </div>

        <video
          v-if="isVideo()"
          ref="videoPlayer"
          class="current-media"
          controls
        >
          <source
            :src="videoPlayableLink"
            :type="
              mediaType === 'video/quicktime' || mediaType === 'video/x-msvideo'
                ? 'video/mp4'
                : mediaType
            "
          />
          Your browser does not support the video tag.
        </video>
        <img
          v-else
          class="current-media object-contain"
          :src="mediaLink"
          alt="media-asset"
        />
        <div class="w-28">
          <div
            v-if="!disableRight"
            class="float-right inline-flex items-center justify-center space-x-2 text-center text-white cursor-pointer bg-gray-900 bg-opacity-95 hover:bg-opacity-100 px-3 py-2 rounded-xl"
            @click="nextImage"
          >
              <span class="font-medium text-sm select-none">Next</span>
              <i class="fa fa-chevron-right text-2xl"></i>
          </div>

          <div class="clear-both"></div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="flex flex-col space-y-2">
          <h4 v-if="mediaName">
            Name:&nbsp;<span class="font-normal">{{ mediaName }}</span>
          </h4>
          <div class="flex flex-row space-x-4">
            <h4 v-if="mediaType"
              >Type:&nbsp;<span class="font-normal">{{ mediaType }}</span>
            </h4>
            <h4 v-if="mediaSize"
              >Size:&nbsp;<span class="font-normal">{{ mediaSize }}</span>
            </h4>
            <h4 v-if="mediaDimension"
              >Dimension:&nbsp;<span class="font-normal">{{
                mediaDimension
              }}</span>
            </h4>
            <h4 v-if="mediaDuration"
              >Duration:<span class="font-normal">{{ mediaDuration }}</span>
            </h4>
            <h4 v-if="mediaVideoCodec"
              >Video Codec:&nbsp;<span class="font-normal">{{
                mediaVideoCodec
              }}</span>
            </h4>
            <h4 v-if="mediaAudioCodec"
              >Audio Codec:&nbsp;<span class="font-normal">{{
                mediaAudioCodec
              }}</span>
            </h4>
            <h4 v-if="mediaAudioHertz"
              >Audio Hertz:&nbsp;<span class="font-normal">{{
                mediaAudioHertz
              }}</span>
            </h4>
          </div>
        </div>
        <div class="flex space-x-3">
          <div
          v-b-tooltip="'Download media'"
          class="flex cursor-pointer border-1 border-solid w-10 h-10 rounded-xl bg-blue-400 border-blue-700 font-normal text-white items-center justify-center"
          @click="downloadMedia"
        >
          <i v-if="!isDownloading" class="fa fa-download fa-1x" />
          <clip-loader
            v-else
            :color="'#ffffff'"
            :size="'16px'"
            class="spinner"
            variant="info"
          ></clip-loader>
        </div>
          <div
            v-b-tooltip="'Compose post'"
            class="flex cursor-pointer border-1 border-solid w-10 h-10 rounded-xl border-blue-700 bg-blue-400 font-normal text-white items-center justify-center"
            @click="composePost"
          >
            <i v-if="!shareLoader" class="fa fa-share-alt text-base" />
            <clip-loader
              v-else
              :color="'#fffff'"
              :size="'16px'"
              class="spinner ml-2.5 mr-1"
              variant="info"
            ></clip-loader>
          </div>
              <div
                v-b-tooltip="isCopied ? 'Copied!' : 'Copy media link to clipboard'"
                class="flex cursor-pointer border-1 border-solid w-10 h-10 rounded-xl items-center justify-center"
                :class="isCopied ? '!bg-green-400 border-green-500' : 'bg-gray-400 border-gray-500'"
                @click="copyMediaLink(currentMedia.link)"
              >
                <i
                  class="fa fa-1x"
                  :class="[
                    isCopied ? 'fa-check text-white' : 'fa-link text-gray-900',
                  ]"
                />
              </div>
          <div
            v-b-tooltip="'Remove'"
            class="flex cursor-pointer border-1 border-solid w-10 h-10 rounded-xl bg-red-600 border-red-600 font-normal text-white items-center justify-center"
            @click="deleteMedia"
            ><i class="fa fa-trash fa-1x"
          /></div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, computed, ref, nextTick } from 'vue'

export default defineComponent({
  props: {
    currentMedia: {
      type: Object,
      default: () => {},
    },
    disableLeft: {
      type: Boolean,
      default: false,
    },
    disableRight: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: 'preview-media-asset-modal',
    },
  },
  setup(props, { emit }) {
    const shareLoader = ref(false)
    const videoPlayer = ref(null) // template ref
    const isCopied = ref(false)
    const isDownloading = ref(false)

    /**
     * Getter for media link
     * @type {ComputedRef<*>}
     */
    const mediaLink = computed(() => {
      const { currentMedia } = props;

      if(currentMedia?.extension === 'pdf'){
        return currentMedia?.thumbnails?.small
      }
      return currentMedia?.link
    })

    /**
     * Getter for video playable link
     * @type {ComputedRef<*>}
     */
    const videoPlayableLink = computed(() => {
      return props.currentMedia?.converted_video
    })

    /**
     * Getter for media name
     * @type {ComputedRef<*>}
     */
    const mediaName = computed(() => {
      return props.currentMedia?.name
    })

    /**
     * Getter for media type
     * @type {ComputedRef<*>}
     */
    const mediaType = computed(() => {
      return props.currentMedia?.mime_type
    })

    /**
     * Getter for media size
     * @type {ComputedRef<*>}
     */
    const mediaSize = computed(() => {
      return bytesConversion(props.currentMedia?.size)
    })

    /**
     * Getter for media dimension
     * @type {ComputedRef<unknown>}
     */
    const mediaDimension = computed(() => {
      if (
        isVideo(mediaType) &&
        props.currentMedia?.width &&
        props.currentMedia?.height
      ) {
        return `${props.currentMedia?.width} x ${props.currentMedia?.height}`
      } else if (props.currentMedia?.w && props.currentMedia?.h) {
        return `${props.currentMedia?.w} x ${props.currentMedia?.h}`
      }
      return null
    })

    /**
     * Getter for media duration
     * @type {ComputedRef<unknown>}
     */
    const mediaDuration = computed(() => {
      if (isVideo(mediaType) && props.currentMedia?.duration) {
        return props.currentMedia.duration
      }
    })

    /**
     * Getter for media video codec
     * @type {ComputedRef<unknown>}
     */
    const mediaVideoCodec = computed(() => {
      if (isVideo(mediaType) && props.currentMedia?.video_codec) {
        return props.currentMedia.video_codec
      }
    })

    /**
     * Getter for media audio codec
     * @type {ComputedRef<unknown>}
     */
    const mediaAudioCodec = computed(() => {
      if (isVideo(mediaType) && props.currentMedia?.audio_codec) {
        return props.currentMedia.audio_codec
      }
    })

    /**
     * Getter for media audio hertz
     * @type {ComputedRef<unknown>}
     */
    const mediaAudioHertz = computed(() => {
      if (isVideo(mediaType) && props.currentMedia?.audio_hertz) {
        return props.currentMedia.audio_hertz
      }
    })

    /**
     * Checks if the media loaded is a video
     * @returns {boolean|*}
     */
    const isVideo = () => {
      if (mediaType?.value) {
        return mediaType.value.includes('video/')
      } else {
        return false
      }
    }

    /**
     * Takes the active media to compose new post
     */
    const composePost = () => {
      console.log('METHOD::composePost -> ')
      shareLoader.value = true
      emit('share', props.currentMedia)
    }

    /**
     * Copies media url link to clipboard
     * @param str
     * @returns {Promise<never>|HTMLPreElement|HTMLTextAreaElement|HTMLElement}
     */
    // In the setup function, add a new ref for tracking copy state
    const copyMediaLink = async (str) => {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()

      try {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(str)
          document.body.removeChild(el)
          // Set copied state to true
          isCopied.value = true
          // Reset after 3 seconds
          setTimeout(() => {
            isCopied.value = false
          }, 3000)
          return true
        }
      } catch (err) {
        console.error('Failed to copy:', err)
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The Clipboard API is not available.')
    }

    /**
     * Removes the current active media in preview
     */
    const deleteMedia = () => {
      console.log(
        'METHOD::deleteMedia -> ',
        props.currentMedia._id,
        props.currentMedia.link
      )
      emit('delete', props.currentMedia._id, props.currentMedia.link)
    }

    /**
     * Handles bytes conversion
     * @param bytes
     * @returns {string}
     */
    const bytesConversion = (bytes) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      const value = bytes / Math.pow(1024, i)
      return Math.round(value * 100) / 100 + ' ' + sizes[i]
    }

    /**
     * Handles arrow keys left/right operation to navigated
     * @param e
     */
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 37:
          previousImage()
          break
        case 39:
          nextImage()
          break
      }
    }

    /**
     * Emits when the modal is shown
     */
    const handleModalShown = () => {
      window.addEventListener('keydown', handleKeyDown, null)
    }

    /**
     * Emits when the modal is hidden
     */
    const handleModalHidden = () => {
      window.removeEventListener('keydown', handleKeyDown)
      shareLoader.value = false
    }

    /**
     * onClick event for next media. If the media is of video type loads the new url in the video player.
     * @returns {Promise<void>}
     */
    const nextImage = async () => {
      emit('next-image')
      await nextTick() // waiting for the next DOM update flush
      // eslint-disable-next-line no-unused-expressions
      videoPlayer.value?.load()
    }

    /**
     * onClick event for previous media. If the media is of video type loads the new url in the video player.
     * @returns {Promise<void>}
     */
    const previousImage = async () => {
      emit('previous-image')
      await nextTick() // waiting for the next DOM update flush
      // eslint-disable-next-line no-unused-expressions
      videoPlayer.value?.load()
    }

    const downloadMedia = async () => {
      try {
        if (!props.currentMedia?.link) return
        isDownloading.value = true
        const response = await fetch(props.currentMedia.link)
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = props.currentMedia.name || 'download'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('Download failed:', error)
      } finally {
        isDownloading.value = false
      }
    }

    return {
      shareLoader,
      mediaLink,
      videoPlayableLink,
      mediaName,
      mediaType,
      mediaSize,
      mediaDimension,
      mediaDuration,
      mediaVideoCodec,
      mediaAudioCodec,
      mediaAudioHertz,
      videoPlayer,
      isVideo,
      composePost,
      copyMediaLink,
      deleteMedia,
      bytesConversion,
      handleModalShown,
      handleModalHidden,
      nextImage,
      previousImage,
      isCopied,
      isDownloading,
      downloadMedia,
    }
  },
})
</script>

<style lang="scss">
.current-media {
  max-width: 65%;
  max-height: 85vh;
  box-shadow: 1px 1px 15px #000000;
}
</style>
