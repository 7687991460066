<template>
  <div
    v-if="notice"
    ref="noticeArea"
    class="notice_area"
    :class="setBannerForCardExpiry()"
  >
    <template v-if="is_for === 'credit_card_expire'">
      <h5>
        <i class="icon-plan-cs"></i>

        <span v-if="has_expired" style="margin-left: 10px">
          Your credit card used with us ending with
          {{ getBillingDetails?.payment_information?.last_four_digits }} has
          expired on {{ getBillingDetails?.payment_information?.expiry_date }}.

          <a class="btn btn-studio-theme" :href="sanitizeUrl(getBillingDetails?.update_url)"
            >Update Now
          </a>
        </span>

        <span v-else style="margin-left: 10px">
          Your credit card used with us ending with
          {{ getBillingDetails?.payment_information?.last_four_digits }} will
          expire on {{ getBillingDetails.payment_information.expiry_date }}.

          <a class="btn btn-studio-theme" :href="sanitizeUrl(getBillingDetails?.update_url)"
            >Update Now
          </a>
        </span>
      </h5>

      <div class="sp-banner-close">
        <button @click="notice = false">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </template>

    <template v-else-if="is_for === 'release'">
      <h5>
        <span>
          <span style="color: #e53b3b"> Important Announcement! </span>
          <b>ContentStudio 3.0</b> is going to be released <b>Today</b> between
          <b>00:00 to 02:00 (Pacific time)</b>.
        </span>
      </h5>

      <div class="sp-banner-close">
        <button @click="notice = false">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import usePlatform from "@common/composables/usePlatform";
import { sanitizeUrl } from '@braintree/sanitize-url'

export default {
  name: 'NoticeBanner',
  props: ['is_for', 'has_expired', 'no_access_accounts'],
  setup(){
    const {topBannersHeight} = usePlatform()

    return {
      topBannersHeight
    }
  },
  data() {
    return {
      notice: true,
    }
  },
  computed: {
    ...mapGetters(['getBillingDetails']),
  },
  watch: {
    notice(val) {
      // if val return
      if (!val) {
        this.topBannersHeight -= this.$refs.noticeArea.clientHeight;
        document.documentElement.style.setProperty('--calender-height', `${270 + this.topBannersHeight}px`);
      }
    }
  },
  mounted() {
    const noticeHeight = this.$refs.noticeArea.clientHeight; // Get notice area height once
    this.topBannersHeight += noticeHeight;
    document.documentElement.style.setProperty('--calender-height', `${270 + this.topBannersHeight}px`); // Set initial calendar height
  },
  beforeUnmount() {
    // reset the --calendar-height to 210px
    this.topBannersHeight -= this.$refs.noticeArea.clientHeight;
    document.documentElement.style.setProperty('--calender-height', `${270 + this.topBannersHeight}px`);
  },
  methods: {
    sanitizeUrl,
    setBannerForCardExpiry() {
      if (this.is_for === 'credit_card_expire') {
        if (this.has_expired) {
          return 'notice_card_expired'
        } else {
          return 'notice_card_about_to_expire'
        }
      }
      return ''
    },
  },
}
</script>

<style scoped lang="scss">
.notice_card_about_to_expire {
  background: #fff3d3 !important;
  span {
    cursor: default !important;
  }
  a {
    background: #ffbb02;
    color: white !important;
    font-weight: bold;
    margin-left: 1rem;
    &:hover {
      background: #ffc11b;
    }
  }
}

.notice_card_expired {
  background: #e52f01 !important;
  span {
    color: white !important;
    cursor: default !important;
  }
  a {
    background: #cc2a01;
    color: white !important;
    font-weight: bold;
    margin-left: 1rem;
    &:hover {
      background: #b22501;
    }
  }
}
.notice_area {
  padding: 0.7rem 0;
  position: relative;
  z-index: 0;
  box-shadow: 0 5px 12px rgba(34, 36, 38, 0.2);
  background: #fff;
  font-family: inherit;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  h5 {
    font-size: 1rem;
    color: #1e2261;
    display: flex;
    align-items: center;
    cursor: default;
    a {
      color: #2d2d2d;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      width: 17px;
      margin: 0 0.5rem;
    }
  }
  span {
    color: #2d2d2d;
    cursor: pointer;

    i {
      font-size: 1.4rem;
      opacity: 1;
      font-weight: 100;
    }
  }
}
.notice_hide {
  display: none !important;
}

.sp-banner-close {
  margin-left: 1rem;
  position: absolute;
  right: 1rem;
  button {
    background: none;
    border: 0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #475059;
    cursor: pointer;

    &:hover {
      color: #000000;
    }
  }
}
</style>
