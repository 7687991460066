<template>
  <div
    id="analyticSocialPreview"
    class="modal fade normal_modal analyticSocialPreview analytic-post-preview"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Post Preview</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div
          class="modal_body basic_form clear grid-flex p-4"
        >
          <div class="preview_left col-9">
            <div class="post-preview-header">
              <h2 class="title">Post</h2>
              <a
                class="btn"
                :href="sanitizeUrl(getPreviewLink(social_network, post))"
                target="_blank" rel="noopener"
              >
                <i class="far fa-external-link-square-alt"></i>
              </a>
            </div>

            <div class="social_preview_no_tab analytic_social_preview">
              <div
                v-if="social_network === 'facebook'"
                class="twitter_post_preview min-h-[30rem]"
              >
                <FacebookPublishedPostPreview
                  container-id="fb-post-container-facebook-analytics"
                  :post-link="post.permalink"
                />
              </div>
              <div
                v-if="social_network === 'instagram'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img
                        v-if="post.profile_picture_url"
                        :src="post.profile_picture_url"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <div class="text_block">
                      <p class="text head_text"
                        >{{ post.name }}
                        <span class="ml-1"
                          >@{{ post.username }} <br />{{
                            formatDate(post.post_created_at)
                          }}</span
                        ></p
                      >
                    </div>
                  </div>

                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('instagram', post)">
                        <p class="desc">
                          <HtmlRenderer
                            v-if="!readMoreActivated"
                            tag="span"
                            :html-content="
                              isHashtag(isLink(post.caption).slice(0, 250)) +
                              '...'
                            "
                          />
                          <HtmlRenderer
                          v-else
                            tag="span"
                            :html-content="isHashtag(isLink(post.caption))"
                          />
                          <a
                            v-if="isHashtag(isLink(post.caption)).length >= 250"
                            class="ml-2"
                            href="javascript:"
                            @click.prevent="toggleReadMore"
                            >{{
                              readMoreActivated ? 'Read Less' : 'Read More'
                            }}</a
                          >
                        </p>
                      </template>
                    </div>
                    <div
                      v-if="post.media_url"
                      class="preview_images flex justify-center"
                    >
                      <Carousel
                      v-if="shouldShowCarousel"
                      id="instagram-competitor-preview-carousel"
                      :mouse-drag="true"
                      :loop="true"
                      :navigation-next-label="navigationNext"
                      :navigation-prev-label="navigationPrev"
                      :navigation-enabled="true"
                      :per-page="1"
                      :scroll-per-page="true"
                      pagination-active-color="#005fd0"
                      pagination-color="rgba(58, 69, 87, 0.2)"
                      :pagination-padding="7"
                      class="w-[90%] h-auto "
                    >
                      <template v-for="(mediaUrl, index) in post.media_url" :key="`insta-carousel_img_preview_${index}`">
                        <Slide
                        class="flex w-full h-full justify-center items-center cursor-move !visible"
                      >
                        <video
                          v-if="
                            mediaUrl.includes('//video') ||
                            mediaUrl.includes('.mp4')
                          "
                          class="w-full max-w-[500px] h-[500px]"
                          controls

                        >
                          <source :src="mediaUrl" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <img
                          v-else
                          :src="mediaUrl"
                          alt="Preview of instagram post"
                          class="max-h-[30rem] h-full"
                          loading="lazy"
                          @error="
                          $event.target.src =
                            'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
                            "
                        />
                      </Slide>
                      </template>
                      </Carousel>
                      <video
                        v-else-if="['VIDEO','REELS'].includes(post.media_type)"
                        :key="post.video_url"
                        class="video_preview_image"
                        controls
                      >
                        <source
                          :src="post.video_url[0]"
                        />
                        <source
                          :src="post.video_url[0]"
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <img
                        v-else-if="post.media_type === 'IMAGE'"
                        :src="post.media_url[0]"
                        :alt="`Preview of the post`"
                      />

                    </div>
                  </div>
                </div>
              </div>
              <!-- preview for the twitter -->
              <div
                v-if="social_network === 'twitter'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img :src="post.profile_image_url" alt="" />
                    </div>
                    <div class="text_block">
                      <p class="text head_text"
                        >{{ post.name
                        }}<span class="tag">@{{ post.screen_name }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('twitter', post)">
                        <HtmlRenderer
                            tag="p"
                            class="desc"
                            :html-content="isHashtag(isLink(post.tweet_text))"
                          />
                      </template>
                    </div>
                    <div v-if="post.media_url" class="preview_images"
                      ><img
                        :src="getResizedImageURL(post.media_url, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>

              <!-- preview for the linkedin -->
              <div
                v-if="social_network === 'linkedin'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="d-flex flex-row pb-3">
                    <div class="picture_block">
                      <img
                        v-if="post.linkedin_id"
                        :src="getActiveCompanyPage(post.linkedin_id)"
                        alt=""
                        @error="
                          $event.target.src = fallbackImagePreview(
                            getPostName('linkedin', post)
                              ? getPostName('linkedin', post)[0]
                              : '#',
                          )
                        "
                      />
                      <img
                        v-else
                        src="../../../../assets/img/profile_default.svg"
                        alt=""
                      />
                    </div>
                    <div class="text_block"
                      ><p class="text head_text"
                        >{{ getPostName('linkedin', post) }}
                      </p>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('linkedin', post)"
                        >
                        <HtmlRenderer
                            tag="p"
                            class="desc"
                            :html-content="isHashtag(isLink(post.title))"
                          />
                      </template>
                    </div>
                    <div v-if="post.image" class="preview_images">
                      <img
                        :src="getResizedImageURL(post.image, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>

              <!-- preview for the pinterest -->
              <div
                v-if="social_network === 'pinterest'"
                class="twitter_post_preview"
              >
                <div class="profile_picture">
                  <div class="picture_block">
                    <img
                      v-if="post.board_id"
                      :src="getActiveBoardImage(post.board_id)"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../../../assets/img/profile_default.svg"
                      alt=""
                    />
                  </div>
                  <div class="text_block"
                    ><p class="text head_text">{{ post.board_name }} </p>
                    <div class="preview_desc">
                      <template v-if="isPostTextAvailable('pinterest', post)">
                        <HtmlRenderer
                          tag="p"
                          class="desc"
                          :html-content="isHashtag(isLink(post.pin_text))"
                        />
                      </template>
                    </div>
                    <div v-if="post.image" class="preview_images"
                      ><img
                        :src="getResizedImageURL(post.image, 570, 0)"
                        alt=""
                        class="single_image"
                        @error="
                          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        "
                    /></div>
                  </div>
                </div>
              </div>

              <!-- preview for the tiktok -->
              <div v-if="social_network === 'tiktok'" :class="mediaCardClass">
                <iframe :src="post?.embed_link" :style="{ border: 'none' }" />
              </div>

              <!-- preview for the youtube -->
              <div v-if="social_network === 'youtube'" :class="mediaCardClass">
                <iframe
                  :src="post?.iframe_embed_url"
                  :style="{ border: 'none' }"
                />
              </div>
            </div>
          </div>
          <div class="preview_right col-3">
            <div class="post-preview-header">
              <h2 class="title">Statistics</h2>
            </div>
            <div class="right_content clear grid-flex">
              <div
                v-for="(stats, key) in engagement_list"
                :key="key"
                class="analytic_p_box col-12"
              >
                <div class="box_inner d-flex align-items-center">
                  <div
                    class="icon d-flex align-items-center justify-content-center"
                  >
                    <img
                      :src="require('../../assets/imgs/post/' + stats[2])"
                      alt=""
                    />
                  </div>
                  <div class="content">
                    <p>{{ stats[0] }}</p>
                    <h3>{{ $filters.numberToCommas(stats[1]) }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import useDateFormat from "@common/composables/useDateFormat";
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { EventBus } from '@common/lib/event-bus'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import {sanitizeUrl} from "@braintree/sanitize-url";
import analyticsUtilsMixin from './analyticsUtilsMixin'
import FacebookPublishedPostPreview from '@/src/modules/analytics_v3/components/FacebookPublishedPostPreview.vue';

export default {
  components: {
    Carousel,
    Slide,
    FacebookPublishedPostPreview,
    HtmlRenderer
  },
  mixins: [analyticsUtilsMixin],
  setup(){
    const {momentWrapper} = useDateFormat()
    return {
      momentWrapper
    }
  },
  data() {
    return {
      post: {},
      engagement_list: [],
      social_network: 'facebook',
      readMoreActivated: false,
      carouselKey: 0,
    }
  },
  computed: {
    ...mapGetters([]),
    mediaCardClass() {
      return 'rounded-xl border !border-[#E9EFF6] flex justify-center p-5 h-[28rem] xl:h-[42rem] lg:h-[30rem] media-card'
    },
    navigationNext() {
      return `<i class="fas fa-chevron-right"></i>`
    },
    navigationPrev() {
      return `<i class="fas fa-chevron-left"></i>`
    },
    shouldShowCarousel() {
      return this.post.media_type === 'CAROUSEL_ALBUM' || (this.post?.video_url?.length > 1);
    },
  },
  created() {
    EventBus.$on('facebook-analytics-preview', (text) => {
      this.social_network = 'facebook'
      this.post = text

      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        [
          'Impressions',
          this.post.post_impressions || 0,
          'controlling-card.svg',
        ],
        ['Post Clicks', this.post.post_clicks || 0, 'click-like.svg'],
        ['Reactions', this.post.total || 0, 'smiling.svg'],
        ['Comments', this.post.comments || 0, 'comment.svg'],
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('instagram-posts-analytics-preview', (text) => {
      this.social_network = 'instagram'
      this.post = text

      this.engagement_list = [
        ['Total Engagement', this.post.engagement || 0, 'statistics.svg'],
        ['Saves', this.post.saved || 0, 'books.svg'],
        ['Reactions', this.post.like_count || 0, 'smiling.svg'],
        ['Comments', this.post.comments_count || 0, 'comment.svg'],
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('instagram-stories-analytics-preview', (text) => {
      this.social_network = 'instagram'
      this.post = text

      this.engagement_list = [
        ['Impressions', this.post.impressions || 0, 'statistics.svg'],
        ['Reach', this.post.reach || 0, 'smiling.svg'],
        ['Exits', this.post.exits || 0, 'books.svg'],
        ['Replies', this.post.replies || 0, 'comment.svg'],
      ]

      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })

    EventBus.$on('twitter-analytics-preview', (text) => {
      this.social_network = 'twitter'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Retweets', this.post.retweet_count || 0, 'comment.svg'],
        ['Favorites', this.post.favorite_count || 0, 'click-like.svg'],
      ]
      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })
    EventBus.$on('linkedin-analytics-preview', (text) => {
      this.social_network = 'linkedin'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Reactions', this.post.favorites || 0, 'smiling.svg'],
        ['Comments', this.post.comments || 0, 'comment.svg'],
      ]
      $('#analyticSocialPreview').modal('show')
      console.log(text)
    })
    EventBus.$on('pinterest-analytics-preview', (text) => {
      console.log(text)
      this.social_network = 'pinterest'
      this.post = text
      this.engagement_list = [
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Repins Count', this.post.repins_count || 0, 'books.svg'],
        ['Pins Click', this.post.pin_click || 0, 'click-like.svg'],
      ]
      $('#analyticSocialPreview').modal('show')
    })

    EventBus.$on('tiktok-analytics-preview', (post) => {
      console.log(post)
      this.social_network = 'tiktok'
      this.post = post
      this.engagement_list = [
        ['Total Followers', this.post.total_follower_count || 0, 'smiling.svg'],
        ['Total Views', this.post.views_count || 0, 'controlling-card.svg'],
        ['Total Engagement', this.post.total_engagement || 0, 'statistics.svg'],
        ['Total Likes', this.post.likes_count || 0, 'click-like.svg'],
        ['Total Comments', this.post.comments_count || 0, 'comment.svg'],
        ['Total Shares', this.post.shares_count || 0, 'books.svg'],
      ]
      $('#analyticSocialPreview').modal('show')
    })

    EventBus.$on('youtube-analytics-preview', (post) => {
      console.log(post)
      this.social_network = 'youtube'
      this.post = post
      this.engagement_list = [
        [
          'Subscribers Change',
          this.post?.subscribers_gained || 0,
          'smiling.svg',
        ],
        ['Views', this.post?.views || 0, 'controlling-card.svg'],
        ['Engagements', this.post?.engagement_rate || 0, 'statistics.svg'],
        ['Likes', this.post?.like || 0, 'click-like.svg'],
        ['Comments', this.post?.comment || 0, 'comment.svg'],
        ['Shares', this.post?.share || 0, 'books.svg'],
      ]
      $('#analyticSocialPreview').modal('show')
    })
  },
  mounted() {},
  beforeUnmount() {
    EventBus.$off('twitter-analytics-preview', () => {})
    EventBus.$off('facebook-analytics-preview', () => {})
    EventBus.$off('linkedin-analytics-preview', () => {})
    EventBus.$off('pinterest-analytics-preview', () => {})
    EventBus.$off('pinterest-analytics-preview', () => {})
  },

  methods: {
    sanitizeUrl,
    toggleReadMore() {
      this.readMoreActivated = !this.readMoreActivated
    },
    formatDate(date) {
      return this.momentWrapper(date).formatDateTime()
    },
    reinitializeCarousel() {
      this.carouselKey++ // Add this line
    },
  },
}
</script>
