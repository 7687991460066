import { ref, computed } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import { fetchAiImagesHistory } from '@/src/config/api-utils'


const showHistory = ref(false)
const showCredits = ref(false)
const aiImages = ref([])
const promptDetails = ref({
  prompt: '',
  resolution: '512x512',
  quantity: '3',
  aspectRatio: '1:1',
  style: 'none',
})

const limits = {
  promptLength: 1000,
}
const isGenerating = ref(false)
const completed = ref(false)
const mediaUploading = ref(false)
const oldComposer = ref(false)
const searchPromptRef = ref(null)
const imagesListingRef = ref(null)

// History states
const historyItems = ref({})
const isLoading = ref(false)
const isInitialLoading = ref(true)
const currentPage = ref(1)
const hasMore = ref(true)

const hasHistory = computed(() => Object.keys(historyItems.value).length > 0)

// Aspect ratios configuration
const aspectRatios = [
  { value: '1:1', label: 'Square' },
  { value: '16:9', label: 'Landscape' },
  { value: '4:3', label: 'Standard\nLandscape' },
  { value: '3:4', label: 'Standard\nPortrait' },
  { value: '9:16', label: 'Reel' },
  { value: '5:4', label: 'Standard\nPortrait' },
  { value: '4:5', label: 'Portrait' },
  { value: '3:2', label: 'Photo' },
]

// Image styles configuration
const imageStyles = [
  { value: 'none', label: 'None', image: require('@src/assets/img/composer/ai-image-generation/none.svg') },
  { value: 'abstract', label: 'Abstract', image: require('@src/assets/img/composer/ai-image-generation/abstract.png') },
  { value: 'oil-painting', label: 'Oil Painting', image: require('@src/assets/img/composer/ai-image-generation/oil-painting.png') },
  { value: 'neon-punk', label: 'Neon Punk', image: require('@src/assets/img/composer/ai-image-generation/neon-punk.png') },
  { value: 'app-icon', label: 'App Icon', image: require('@src/assets/img/composer/ai-image-generation/app-icon.png') },
  { value: 'black-white', label: 'B&W', image: require('@src/assets/img/composer/ai-image-generation/black-white.png') },
  { value: 'bokeh', label: 'Bokeh', image: require('@src/assets/img/composer/ai-image-generation/bokeh.png') },
  { value: 'cartoon', label: 'Cartoon', image: require('@src/assets/img/composer/ai-image-generation/cartoon.png') },
  { value: 'cinematic', label: 'Cinematic', image: require('@src/assets/img/composer/ai-image-generation/cinematic.png') },
  { value: 'cyberpunk', label: 'Cyberpunk', image: require('@src/assets/img/composer/ai-image-generation/cyberpunk.png') },
  { value: 'digital-watercolor', label: 'Watercolor', image: require('@src/assets/img/composer/ai-image-generation/digital-watercolor.png') },
  { value: 'film-noir', label: 'Film Noir', image: require('@src/assets/img/composer/ai-image-generation/film-noir.png') },
  { value: 'film-poster', label: 'Film Poster', image: require('@src/assets/img/composer/ai-image-generation/film-poster.png') },
  { value: 'flat-design', label: 'Flat Design', image: require('@src/assets/img/composer/ai-image-generation/flat-design.png') },
  { value: 'futuristic', label: 'Futuristic', image: require('@src/assets/img/composer/ai-image-generation/futuristic.png') },
  { value: 'grunge', label: 'Grunge', image: require('@src/assets/img/composer/ai-image-generation/grunge.png') },
  { value: 'highly-detailed', label: 'Highly Detailed', image: require('@src/assets/img/composer/ai-image-generation/highly-detailed.png') },
  { value: 'isometric', label: 'Isometric', image: require('@src/assets/img/composer/ai-image-generation/isometric.png') },
  { value: 'minimalistic', label: 'Minimalistic', image: require('@src/assets/img/composer/ai-image-generation/minimalistic.png') },
  { value: 'photorealistic', label: 'Photorealistic', image: require('@src/assets/img/composer/ai-image-generation/photorealistic.png') },
  { value: 'pixel-art', label: 'Pixel Art', image: require('@src/assets/img/composer/ai-image-generation/pixel-art.png') },
  { value: 'polaroid', label: 'Polaroid', image: require('@src/assets/img/composer/ai-image-generation/polaroid.png') },
  { value: 'pop-art', label: 'Pop Art', image: require('@src/assets/img/composer/ai-image-generation/pop-art.png') },
  { value: 'retro-80s', label: 'Retro 80s', image: require('@src/assets/img/composer/ai-image-generation/retro-80s.png') },
  { value: 'steampunk', label: 'Steampunk', image: require('@src/assets/img/composer/ai-image-generation/steampunk.png') },
  { value: 'sticker', label: 'Sticker', image: require('@src/assets/img/composer/ai-image-generation/sticker.png') },
  { value: 'super-realistic', label: 'Super Realistic', image: require('@src/assets/img/composer/ai-image-generation/super-realistic.png') },
  { value: 'surrealism', label: 'Surrealism', image: require('@src/assets/img/composer/ai-image-generation/surrealism.png') },
  { value: 'tattoo', label: 'Tattoo', image: require('@src/assets/img/composer/ai-image-generation/tattoo.png') },
  { value: 'unreal-engine', label: 'Unreal Engine', image: require('@src/assets/img/composer/ai-image-generation/unreal-engine.png') },
  { value: 'vaporwave', label: 'Vaporwave', image: require('@src/assets/img/composer/ai-image-generation/vaporwave.png') }
]

const getImageStyleLabel = (value) => {
  const style = imageStyles.find(style => style.value === value)
  return style ? style.label : ''
}

export default function useImageGeneration() {
  const { getters } = useStore()


  /**
   * Calculates preview dimensions for aspect ratio display
   * @param {string} ratio - Aspect ratio in format "width:height"
   * @returns {{width: number, height: number}} Calculated dimensions
   */
  const getPreviewDimensions = (ratio) => {
    const [width, height] = ratio.split(':').map(Number)
    const maxHeight = 32
    const maxWidth = 48

    let finalWidth, finalHeight

    if (width > height) {
      finalWidth = maxWidth
      finalHeight = (height * maxWidth) / width
    } else {
      finalHeight = maxHeight
      finalWidth = (width * maxHeight) / height
    }

    return {
      width: Math.round(finalWidth),
      height: Math.round(finalHeight),
    }
  }

  async function fetchHistory(page = 1) {
    try {
      isLoading.value = true
      const response = await proxy.post(fetchAiImagesHistory, {
        workspace_id: getters.getActiveWorkspace._id,
        type: "ImageGeneration",
        page,
        count: 10
      })

      if (response?.data?.status && response.data.data) {
        if (page === 1) {
          historyItems.value = response.data.data
        } else {
          // append new items to existing history like if it is the same date then append new items in it rather than replace
          // Check if the last item in historyItems is the same date as the first item in response.data.data
          const lastItem = Object.keys(historyItems.value).pop()
          const firstItem = Object.keys(response.data?.data)[0]
          if (lastItem === firstItem) {
            historyItems.value[firstItem].push(...response.data?.data[firstItem])
          } else {
            historyItems.value = {
              ...historyItems.value,
              ...response.data.data,
            }
          }
        }

        // Check if we received a full page of results
        const totalNewItems = Object.values(response.data.data).reduce((sum, items) =>
          sum + (Array.isArray(items) ? items.length : 0), 0)
        hasMore.value = totalNewItems === 10
        currentPage.value = page
      } else {
        console.error('Invalid response format:', response)
      }
    } catch (error) {
      console.error('Error fetching AI image history:', error)
    } finally {
      isLoading.value = false
      if (page === 1) {
        isInitialLoading.value = false
      }
    }
  }

  function loadMore() {
    if (!isLoading.value && hasMore.value) {
      return fetchHistory(currentPage.value + 1)
    }
  }

  const toggleHistory = () => {
    showHistory.value = !showHistory.value
  }

  const toggleCredits = () => {
    showCredits.value = !showCredits.value
  }


  // Set the prompt details and images from the history
  const setPromptDetails = (prompt, images) => {
    promptDetails.value = {
      prompt: prompt?.text,
      quantity: prompt?.count?.toString(),
      aspectRatio: prompt?.aspect_ratio,
      style: prompt?.style
    }
    aiImages.value = images || []

    // set image loading state
    completed.value = true
    isGenerating.value = false

    // close the history
    showHistory.value = false
  }
  return {
    // History state
    historyItems,
    hasHistory,
    isLoading,
    isInitialLoading,
    hasMore,
    currentPage,
    showHistory,
    fetchHistory,
    loadMore,
    toggleHistory,

    // Credits state
    showCredits,
    toggleCredits,

    // Image generation configuration
    aspectRatios,
    imageStyles,
    getPreviewDimensions,

    // Prompt details
    promptDetails,
    aiImages,

    // Set prompt details
    setPromptDetails,

    // Image generation configuration
    limits,
    isGenerating,
    completed,
    mediaUploading,
    oldComposer,
    searchPromptRef,
    imagesListingRef,
    getImageStyleLabel
  }
}
