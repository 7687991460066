<template>
  <div class="warning_box_zapier">
    <div class="text_container">
      <div v-if="no_access_accounts.length > 0">
        <div class="header">
          <i class="fas fa-exclamation-triangle"></i>
          <HtmlRenderer
              v-tooltip="{
              content: channelTooltipMultipleHTML(
                'instagram',
                no_access_accounts,
              ),
              classes: 'team_tooltip',
            }"
              tag="span"
              :html-content="getDescriptionForPermissionLinkedAccounts()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapGetters, mapActions } from 'vuex'
import HtmlRenderer from "@common/components/htmlRenderer.vue";

export default {
  props: [
    'multiple_images',
    'aspect_ratio',
    'no_access_accounts',
    'all_access_accounts',
  ],
  components: {HtmlRenderer},
  data() {
    return {}
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    getDescriptionForPermissionLinkedAccounts() {
      if (this.no_access_accounts.length > 0) {
        let postingAccounts =
          this.no_access_accounts.length > 1 // if count > 1
            ? '<u>' + this.no_access_accounts.length + ' accounts</u>' // then with count add 'account' in the underline
            : '<u>' + this.no_access_accounts[0].name + '</u> account' // else only count should be underline
        return (
          'Instagram direct publishing method cannot be used on ' +
          postingAccounts +
          ' because ' +
          'you need to reconnect this account to provide the required permissions.'
        )
      }
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.warning_box_zapier {
  margin-top: 20px;
  border-radius: 0.625rem;
  background: #faf2dc;

  .header {
    color: #8e8c1a;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .unlinked {
    color: #8e8c1a;
    font-size: 0.875rem;
    font-weight: 500;
  }

  ul {
    margin-left: 50px;
  }

  li {
    list-style-type: initial;
  }

  i {
    padding: 7px;
    color: #d9d623;
  }
}

.text_container {
  padding: 0.625rem;
}
</style>
