<script>
import { mapGetters } from 'vuex'
import {
  BLOG_NULL_URL_ERROR,
  BLOG_INVALID_URL_ERROR,
  BLOG_NULL_USERNAME_ERROR,
  BLOG_NULL_PASSWORD_ERROR,
  SAVE_BLOG_ERROR,
  SAVE_BLOG_SUCCESS
} from '@common/constants/messages'
import { isValidURL } from '@common/lib/helper'
import { blog } from '../../../store/states/mutation-types'
export default {
  components: {},

  data () {
    return {
      saving_loader: false,
      validations: {
        url: false,
        invalid_url: false,
        username: false,
        password: false
      },
      messages: {
        url: BLOG_NULL_URL_ERROR,
        invalid_url: BLOG_INVALID_URL_ERROR,
        username: BLOG_NULL_USERNAME_ERROR,
        password: BLOG_NULL_PASSWORD_ERROR
      }
    }
  },
  computed: {
    ...mapGetters(['getBlogDetails', 'getBlogsLoader', 'getWorkspaces']),
    isEmptyURL () {
      return this.validations.url
    },
    isInvalidURL () {
      return !this.validations.url && this.validations.invalid_url
    },
    isEmptyUsername () {
      return this.validations.username
    },
    isEmptyPassword () {
      return this.validations.password
    },
    fetchBlogLoader () {
      return this.getBlogsLoader.fetch_blog
    },
    saveBlogLoader () {
      return this.saving_loader
    },
    isActionDisable () {
      return this.fetchBlogLoader || this.saveBlogLoader
    }
  },
  watch: {
    'getBlogDetails.wordpress.url' (value) {
      if (value && value.length > 0) {
        console.debug('Watch::getBlogDetails.wordpress.url', value)
        this.validations.url = false
        this.validations.invalid_url = false
      }
    },
    'getBlogDetails.wordpress.username' (value) {
      if (value && value.length > 0) {
        console.debug('Watch::getBlogDetails.wordpress.username', value)
        this.validations.username = false
      }
    },
    'getBlogDetails.wordpress.password' (value) {
      if (value && value.length > 0) {
        console.debug('Watch::getBlogDetails.wordpress.password', value)
        this.validations.password = false
      }
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide('save_wordpress_blog_modal')
      return false
    },
    async saveBlog () {
      console.debug('Method:saveBlog')
      const payload = this.getBlogDetails.wordpress
      this.validations.url = this.requiredCheck(payload.url)
      this.validations.invalid_url = !isValidURL(payload.url)
      this.validations.username = this.requiredCheck(payload.username)
      this.validations.password = this.requiredCheck(payload.password)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        if (
          !payload.platform_identifier &&
          this.checkSubscriptionLimits('blogs')
        ) {
          return false
        }
        this.saving_loader = true
        payload.workspace_id = this.getWorkspaces.activeWorkspace._id
        payload.type = 'wordpress'
        const response = await this.$store.dispatch('saveBlog', payload)
        this.saving_loader = false
        console.log('test' + JSON.stringify(response))

        if (response.data.status === true) {
          this.alertMessage(SAVE_BLOG_SUCCESS, 'success')
          if (response.data.warning_message) {
            this.alertMessage(response.data.warning_message, 'warn')
          }
          this.$store.commit(blog.SET_BLOG_DETAILS, null)
          this.$bvModal.hide('save_wordpress_blog_modal')
          let blogs = []
          if (payload.platform_identifier) {
            this.$store.getters.getBlogs.wordpress.items.forEach(function (
              element,
              index
            ) {
              if (element.platform_identifier !== payload.platform_identifier) {
                blogs.push(element)
              }
              if (element.platform_identifier === payload.platform_identifier) {
                blogs.push(response.data.blog)
              }
            })
          }
          if (!payload.platform_identifier) {
            blogs = this.$store.getters.getBlogs.wordpress.items
            blogs.push(response.data.blog)
          }
          this.$store.dispatch('setPlatforms', {
            items: blogs,
            type: 'wordpress',
            all_item_setter: blog.SET_ALL_WORDPRESS_BLOGS,
            item_setter: blog.SET_WORDPRESS_BLOGS
          })
          this.Plan()
          return false
        }
        if (response.data.upgrade) {
          this.showUpgradeModal()
          this.$bvModal.hide('save_wordpress_blog_modal')
          return false
        }
        if (response.data.message) {
          this.alertMessage(response.data.message, 'error')
          return false
        }
        this.alertMessage(SAVE_BLOG_ERROR, 'error')
      }
    }
  }
}
</script>

<template>
  <b-modal
    id="save_wordpress_blog_modal"
    modal-class="add_wordpress_account_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Add your WordPress details</h4>
      <button type="button" class="modal_head__close" @click="hideModal"
        >&times;</button
      >
    </div>

    <div class="modal_body" :class="{ overlay: fetchBlogLoader }">
      <!--<clip-loader color="#4165ed" class="center_loader" :size="'16px'" v-if="fetchBlogLoader"></clip-loader>-->

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <!--<div class="field_group">-->
          <!--<input type="text" placeholder="Enter first name here..." v-model="getTeam.first_name"-->
          <!--:readonly="getTeam.id">-->
          <!--<label class="label-animated" for="">Website Url (http://yourwebsite.com)</label>-->
          <!--</div>-->

          <div class="field_group">
            <input
              id="websiteURL"
              v-model="getBlogDetails.wordpress.url"
              :class="{
                'input-field-error': validations.url || validations.invalid_url,
              }"
              type="url"
              placeholder="Website URL..."
            />
            <span v-if="isEmptyURL" class="input-error">{{
              messages.url
            }}</span>
            <span v-if="isInvalidURL" class="input-error">{{
              messages.invalid_url
            }}</span>
            <label for="websiteURL" class="label-animated"
              >Website Url (http://yourwebsite.com)</label
            >
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <input
              id="wpUsername"
              v-model="getBlogDetails.wordpress.username"
              :class="{ 'input-field-error': validations.username }"
              type="text"
              placeholder="Username..."
            />
            <label for="wpUsername" class="label-animated">Username</label>
            <div class="input_validation">
              <span v-if="isEmptyUsername" class="input-error">{{
                messages.username
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <input
              id="wpPassword"
              v-model="getBlogDetails.wordpress.password"
              :class="{ 'input-field-error': validations.password }"
              type="password"
              placeholder="Password..."
            />
            <label for="wpPassword" class="label-animated">Password</label>
            <div class="input_validation">
              <span v-if="isEmptyPassword" class="input-error">{{
                messages.password
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="field_group">
            <select
              id=""
              v-model="getBlogDetails.wordpress.connection_type"
              class="with_bg"
              name=""
              required
            >
              <!--<option selected="selected" value="">Choose Method for Content Submission aria-hidden="true"></i></option>-->
              <option value="official_plugin"
                >Official WordPress Plugin (Preferred)</option
              >
              <option value="plugin">ContentStudio Plugin (Deprecated)</option>
              <option value="xmlrpc">XML-RPC</option>
              <option value="api">WordPress API</option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>

          <p class="help_text">
            <a
              target="_blank" rel="noopener"
              class="help_link"
              href="https://docs.contentstudio.io/article/382-how-to-connect-wordpress-blog"
            >
              <i class="far fa-question-circle" aria-hidden="true"></i>
              WordPress integration help</a
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="isActionDisable"
              @click.prevent="saveBlog"
            >
              <span>Save</span>
              <clip-loader
                v-if="saveBlogLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
