<script setup>
import OverviewSection from '@src/modules/analytics/views/pinterest/components/OverviewSection.vue'
import PostsSection from '@src/modules/analytics/views/pinterest/components/PostsSection.vue'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar.vue'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent.vue'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading.vue'
import { EventBus } from '@common/lib/event-bus'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { onBeforeUnmount, ref, watch, onMounted, onBeforeMount } from 'vue'
import { useStore } from '@state/base'
import { pinterest } from '@src/modules/integration/store/states/mutation-types'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import isBoolean from 'lodash/isBoolean'

const { getters, dispatch } = useStore()
const mainComponentRef = ref(null)
const pinterestAccounts = ref([])
const selectedAccount = ref(null)
const dateFilter = ref('')
const previousDate = ref('')
const fetchingData = ref(false)
const profileHasBoards = ref(false)
const analyticsChannel = ref('')
const { postsLimit } = usePinterestAnalytics()

watch(selectedAccount, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    setTimeout(() => {
      mainComponentRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 0)
    removeSocketListeners(oldVal)
    bindSocketListeners()
  }
})

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.debug('workspace-changed')
    resetState()

    const pusher = pusherAuthSocketAnalytics()
    analyticsChannel.value = pusher.subscribe(
      'analytics-channel-' + getters.getActiveWorkspace._id
    )
    if (selectedAccount.value?.board_id) {
      bindSocketListeners()
    }
  })
})

onMounted(() => {
  const pusher = pusherAuthSocketAnalytics()
  analyticsChannel.value = pusher.subscribe(
    'analytics-channel-' + getters.getActiveWorkspace._id
  )
  if (selectedAccount.value?.board_id) {
    bindSocketListeners()
  }
  EventBus.$emit('set-top-posts-limit', postsLimit.value)
})

onBeforeUnmount(() => {
  removeSocketListeners(selectedAccount.value?.board_id)
  if (analyticsChannel.value) {
    analyticsChannel.value.unbind(
      'syncing-' +
        getters.getActiveWorkspace._id +
        '-' +
        selectedAccount.value?.board_id
    )
    analyticsChannel.value.unsubscribe(
      'analytics-channel-' + getters.getActiveWorkspace._id
    )
  }
})

const resetState = () => {
  fetchingData.value = false
  profileHasBoards.value = false
  pinterestAccounts.value = []
  selectedAccount.value = []
  dateFilter.value = ''
  previousDate.value = ''
  analyticsChannel.value = ''
}

const getAccounts = (account) => {
  selectedAccount.value = account
}

const setProfileHasBoards = (status) => {
  if (isBoolean(status)) {
    profileHasBoards.value = status
  }
}

const getDate = (date) => {
  dateFilter.value = date
}

const getPreviousDate = (date) => {
  previousDate.value = date
}

const removeSocketListeners = (value) => {
  const event = 'syncing-' + getters.getActiveWorkspace._id + '-' + value
  analyticsChannel.value.unbind(event)
}

const bindSocketListeners = () => {
  const event =
    'syncing-' +
    getters.getActiveWorkspace._id +
    '-' +
    selectedAccount.value?.board_id
  console.log(event)
  analyticsChannel.value.bind(event, (data) => {
    console.log('message data --> ', data)
    setPinterestState(data.state, data.account, data.last_analytics_updated_at)
  })
}

const setPinterestState = (state, checkFor, lastAnalyticsUpdatedAt) => {
  const updatedItems = []
  for (const item in getters.getPinterestBoards) {
    const update = getters.getPinterestBoards[item]
    if (update?.board_id === checkFor) {
      update.state = state
      // eslint-disable-next-line camelcase
      update.last_analytics_updated_at = lastAnalyticsUpdatedAt
    }
    updatedItems.push(update)
  }
  dispatch('setPlatforms', {
    items: updatedItems,
    type: 'pinterest',
    all_item_setter: pinterest.SET_ALL_ACCOUNTS,
    item_setter: pinterest.SET_ACCOUNTS,
  })
}
</script>

<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <AnalyticsFilterBar
      :get-accounts="getAccounts"
      :get-date="getDate"
      :get-previous-date="getPreviousDate"
      :set-profile-has-boards="setProfileHasBoards"
      :style="{
        // eslint-disable-next-line vue/camelcase
        cursor: fetchingData ? 'progress' : '',
        pointerEvents: fetchingData ? 'none' : '',
      }"
      :updating="fetchingData"
      type="pinterest"
    />
    <div
      v-if="
        (getters.getPinterestBoards?.length <= 0 && !fetchingData) ||
        !profileHasBoards
      "
      class="analytics-main-errors"
    >
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-pinterest-account.png"
      />
      <p v-if="getters.getFetchSocialStatus">
        Retrieving Data! Please Wait...
      </p>
      <p v-else>
        <span
          v-if="
            !profileHasBoards &&
            getters?.getPinterestAccounts?.items?.length > 0
          "
        >
          No Board Connected
        </span>
        <span v-else> No Account Connected </span>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{
            path: `/${getters.getActiveWorkspace.slug}/settings/social/pinterest`,
          }"
          >Connect Pinterest Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getters.getPinterestBoards?.length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selectedAccount?.state === 'Added'">
        <AnalyticsLoading
          :name="selectedAccount?.name || selectedAccount?.username"
          :type="selectedAccount?.type.toLowerCase()"
        />
      </template>
      <template v-else>
        <TabsComponent
          v-if="!getters.getFetchSocialStatus"
          :tabs="['#overview', '#pins']"
          type="pinterest"
        >
          <!-- overview tab -->
          <template v-if="selectedAccount" v-slot:overview>
            <OverviewSection />
          </template>

          <!-- pins tab -->
          <template v-slot:pins>
            <PostsSection />
          </template>
        </TabsComponent>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>
