<template>
  <div class="scroll_content">
    <template v-if="getInfluencerLoaders.top_mentioned">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="top_mentioned && top_mentioned.length > 0">
      <div
        v-for="(mention, mentionIndex) in top_mentioned"
        :key="`mention_${mentionIndex}`"
        class="twitter_influencer_box"
      >
        <div v-if="mention.follower_count > 0" class="box_inner">
          <div class="d-flex align-items-center">
            <div class="t_left">
              <div class="d-flex item_row">
                <div class="row_left">
                  <div class="picture_box">
                    <div
                      :style="`background-image: url(${mention.profile_image})`"
                      class="img"
                    ></div>

                    <!--<img :src="mention.profile_image" alt="">-->
                  </div>
                </div>
                <div class="row_right">
                  <div class="top_content">
                    <h3 v-html="instagramUsernameHtml(mention)"></h3>
                    <p v-if="mention.bio" v-html="mention.bio"></p>
                    <a
                      v-if="mention.influencer_domain"
                      :href="sanitizeUrl(mention.influencer_domain)"
                      target="_blank" rel="noopener"
                      >{{ mention.influencer_domain }}</a
                    >
                  </div>
                </div>
              </div>
              <!--d-flex-->
              <div class="item_row d-none">
                <div class="row_left">
                  <!--<template v-if="mention.created_at">-->
                  <!--<p>{{mention.created_at | relative}}</p>-->
                  <!--</template>-->
                </div>
                <!--<div class="row_right" v-if="mention.status && mention.status.text">-->
                <!--<div class="bottom_content">-->
                <!--<p  v-html="twitterText(mention.status.text,mention.status.entities.hashtags,mention.status.entities.urls,mention.status.entities.user_mentions)"></p>-->
                <!--</div>-->
                <!--</div>-->
              </div>
            </div>
            <div class="t_right">
              <p>Followers</p>
              <h3
                v-tooltip="getMentionedFollowersTooltip(mention.follower_count)"
              >
                {{ intToString(mention.follower_count) }}</h3
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <!--<h2>No version history found</h2>-->
            <p>No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {sanitizeUrl} from "@braintree/sanitize-url";

export default {
  components: {},
  props: {
    top_mentioned: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders']),
  },
  methods: {
    sanitizeUrl,
    instagramUsernameHtml(mention) {
      return (
        mention.full_name +
        '<a href="https://instagram.com/' +
        mention.username +
        '" target="_blank" rel="noopener" >@' +
        mention.username +
        '</a>'
      )
    },
    getMentionedFollowersTooltip(value) {
      return value.toLocaleString()
    },
  },
}
</script>
