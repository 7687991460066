<template>
  <div class="article-search-bar-pane__content_types">

    <!-- INFO:: Might needed in future -->

    <!-- <div class="article-search-bar-pane__content_types__buttons">
      <a
        href="javascript:;"
        :class="{ active: $route.meta.is_active === 'content' }"
        @click.prevent="toggleArticleSearchFeed"
      >
        <i class="icon-Content-Feed_icon"></i>
        <span>Content Feed</span>
      </a>
      <a
        href="javascript:;"
        :class="{ active: $route.meta.is_active === 'insights' }"
        @click.prevent="toggleInsightsSearchFeed"
      >
        <i class="icon-Insights_icon"></i>
        <span>Insights</span>
      </a>
    </div> -->

    <!--  <div class="icon_tabs ">
            &lt;!&ndash;indicator_animation&ndash;&gt;
            <ul class="tab_ul tab_ul_2 " data-cy="discovery_content_options">
                <li class="tab_li" :class="{'active': search_content_type === 'insights'}">
                    <a class="tab_a gray_dark" href="javascript:;"
                       :class="{'active': $route.meta.is_active === 'insights'}"
                       @click.prevent="toggleInsightsSearchFeed()">
                        <i class="icon-Insights_icon"></i>
                        <span>Insights</span>
                    </a>
                </li>
                <li class="tab_li" :class="{'active': search_content_type === 'content'}">
                    <a class="tab_a gray_dark" href="javascript:;"
                       :class="{'active': $route.meta.is_active === 'content'}"
                       @click.prevent="toggleArticleSearchFeed()">
                        <i class="icon-Content-Feed_icon"></i>
                        <span>Content Feed</span>
                    </a>
                </li>

            </ul>
        </div>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      search_content_type: 'content'
    }
  },
  methods: {
    toggleArticleSearchFeed () {
      if (this.$route.query.q) {
        return this.$router.push({
          name: 'discover_search_web',
          query: { q: this.$route.query.q }
        })
      }
      if (this.$route.query.tag) {
        return this.$router.push({
          name: 'discover_search_web',
          query: { tag: this.$route.query.tag }
        })
      }
      this.$router.push({
        name: 'discover_search'
      })
    },
    toggleInsightsSearchFeed () {
      if (this.$route.query.q) {
        return this.$router.push({
          name: 'discover_insights_web',
          query: { q: this.$route.query.q }
        })
      }
      if (this.$route.query.tag) {
        return this.$router.push({
          name: 'discover_insights_web',
          query: { tag: this.$route.query.tag }
        })
      }
      this.$router.push({
        name: 'discover_insights'
      })
    }
  }
}
</script>

<style lang="less">
@media all and (max-width: 1600px) {
  .discover-content-types {
    padding-top: 25px !important;
  }
}
</style>
