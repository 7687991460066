import {ref} from "vue";
import {decodeTextURI, parseDescriptionHtml} from "@common/lib/helper";
import usePermissions from "@common/composables/usePermissions";
import {blogPlatformNames, socialPlatformNames} from "@common/lib/integrations";
import {useStore} from "@state/base";
import proxy from "@common/lib/http-common";
import {fetchPlanPreviewURL} from "@src/modules/planner/config/api-utils";
import PublishedIcon from "@assets/img/icons/statuses/published_new.svg"
import PartiallyFailedIcon from "@assets/img/icons/statuses/partially_failed.svg"
import ScheduledIcon from "@assets/img/icons/statuses/scheduled.svg"
import FailedIcon from "@assets/img/icons/statuses/failed.svg"
import DraftIcon from "@assets/img/icons/statuses/draft.svg"
import UnderReviewIcon from "@assets/img/icons/statuses/under_review.svg"
import MissedReviewIcon from "@assets/img/icons/statuses/missed_review.svg"
import InProgressIcon from "@assets/img/icons/statuses/in_progress.svg"
import RejectedIcon from "@assets/img/icons/statuses/rejected.svg"
import {apiUrl} from "@src/config/api-utils";
import {pusherSocketPublish} from "@common/lib/pusher";
import {socialChannelsNameArray} from "@common/constants/common-attributes";
import {UNKNOWN_ERROR} from "@common/constants/messages";
//states
const selectedPlanAccounts = ref(null)
const planId = ref(null)
const isLoading = ref(false)


export default function usePlannerHelper() {
    const store = useStore()
    const { getLoggedUser } = usePermissions()
    function renderClass(item) {

        if(item.partially_failed)
            return {
                icon: 'far fa-exclamation-circle',
                tooltip: 'Partially Failed'
            }
        const render = {}
        switch (item.post_state) {
            case 'published':
                render.icon = 'far fa-check-circle';
                render.tooltip = 'Published';
                break;
            case 'scheduled':
                render.icon = 'far fa-clock';
                render.tooltip = 'Scheduled';
                break;
            case 'failed':
                render.icon = 'far fa-exclamation-circle';
                render.tooltip = 'Failed';
                break;
            case 'rejected':
                render.icon = 'far fa-times-circle';
                render.tooltip = 'Rejected';
                break;
            case 'reviewed':
                render.icon = 'far fa-eye';
                render.tooltip = 'In review';
                break;
            case 'missedReview':
                render.icon = 'far fa-eye-slash';
                render.tooltip = 'Missed review';
                break;
            case 'queued':
                render.icon = 'far fa-clock';
                render.tooltip = 'In Progress';
                break;
            case 'processing':
                render.icon = 'far fa-clock';
                render.tooltip = 'In Progress';
                break;
            default:
                render.icon = 'far fa-folder-plus';
                render.tooltip = 'Draft';
        }
        return render
    }

    function isReplaceablePost(item) {
        return ((item.article_automation_id || item.video_automation_id) && item.status !== 'published' && item.status !== 'failed')
    }

    function isDuplicatablePost(item) {
        return (item.status === 'published' || item.status === 'scheduled')
    }

    function isEditablePost(item) {
        return (item.status !== 'published' || item.blog_reference)
    }

    function canEditThisPost(item) {
        const user = getLoggedUser.value
        if(user?.role !== 'approver') return true
        if(!user?.permissions?.approverCanEditPost) return false
        if(item.post_state !== 'reviewed' || !item.approval) return false
        return !!(item.approval && item.approval?.status === 'pending_approval' &&
            item.approval?.approvers?.find(approver => approver?.user_id === user.user_id)?.status === 'pending')
    }

    function isInProgressPost(item) {
        return item.status === 'queued' || item.status === 'processing'
    }

    function getPostSharingDetails(item) {
        if(item.common_box_status || !item.account_selection)
            return item.common_sharing_details

        const sharingDetails = {};

        for (const platform of socialPlatformNames) {
            if (item.account_selection[platform]?.length) {
                sharingDetails[platform] = item[`${platform}_sharing_details`];
                break; // This will break out of the loop
            }
        }

        return sharingDetails;
    }

    function postText(item){
        if(item.blog_selection)
            return decodeTextURI(item?.post?.title || '')
        if(item.common_box_status || !item.account_selection)
            return decodeTextURI(item?.common_sharing_details?.message || '')
        if(item.account_selection?.facebook?.length)
            return decodeTextURI(item?.facebook_sharing_details?.message || '')
        if(item.account_selection?.instagram?.length)
            return decodeTextURI(item?.instagram_sharing_details?.message || '')
        if(item.account_selection?.twitter?.length)
            return decodeTextURI(item?.twitter_sharing_details?.message || '')
        if(item.account_selection?.linkedin?.length)
            return decodeTextURI(item?.linkedin_sharing_details?.message || '')
        if(item.account_selection?.pinterest?.length)
            return decodeTextURI(item?.pinterest_sharing_details?.message || '')
        if(item.account_selection?.gmb?.length)
            return decodeTextURI(item?.gmb_sharing_details?.message || '')
        if(item.account_selection?.youtube?.length)
            return decodeTextURI(item?.youtube_sharing_details?.message || '')
        if(item.account_selection?.tumblr?.length)
            return decodeTextURI(item?.tumblr_sharing_details?.message || '')
        return ''
    }

    function getPostText(item, limit = 100){
        const text = postText(item)
        return parseDescriptionHtml(text || '', limit)
    }

    function getPostMedia(item) {
        if(item.blog_selection)
            return {type: 'image', url: item.post?.image?.link || ''}
        const sharingDetails = getPostSharingDetails(item)
        if(sharingDetails?.video?.link)
            return {type: 'video', url: sharingDetails?.video?.link, thumbnail: sharingDetails?.video?.thumbnail}
        if(sharingDetails?.image?.length)
            return {type: 'image', url: sharingDetails?.image[0]}

        // linkedin carousel post
        return item?.linkedin_options?.document?.thumbnail || ''
    }

    /**
     * Fetch plan for preview
     * @param plan_id
     * @returns {Promise<*|boolean|null>}
     */
    async function fetchPlan(plan_id) {
        try {
            const response = await proxy.get(`${fetchPlanPreviewURL}?id=${plan_id}&workspace_id=${store.getters.getActiveWorkspace._id}`)
            return response.data.status ? response.data?.plan : false
        } catch (e) {
            console.error(e)
            return null
        }
    }

    /**
     * Fetches the accounts associated with a specific plan.
     *
     * @async
     * @function fetchPlanAccounts
     * @param {string} plan_id - The ID of the plan for which to fetch the associated accounts.
     * @returns {Promise<null>} Returns null if an error occurs during the fetch operation.
     * @throws Will log the error to the console if the HTTP request fails.
     */
    async function fetchPlanAccounts(plan_id) {
        try {
            const response = await proxy.get(`${apiUrl}fetchPlanPosting?id=${plan_id}&workspace_id=${store.getters.getActiveWorkspace._id}`)
            if (response.data.status) {
                planId.value = plan_id
                selectedPlanAccounts.value = response.data?.posting
                    .sort((a, b) => socialChannelsNameArray.indexOf(a.platform_type?.toLowerCase()) - socialChannelsNameArray.indexOf(b.platform_type?.toLowerCase()))
                    .map(item => {
                        item.delete_cs = false
                        return item
                    })
            } else {
                selectedPlanAccounts.value = []

            }
        } catch (e) {
            console.error(e)
            await store.dispatch('toastNotification', {
                type: 'error',
                message: UNKNOWN_ERROR
            })
        }
    }

    async function deletePlanAccounts(postingIds){
        if(!postingIds.length) return store.dispatch('toastNotification',{
            type: 'error',
            message: 'No Accounts available for deletion'
        })
        try {
            isLoading.value = true
            const payload = {
                id: planId.value,
                workspace_id: store.getters.getActiveWorkspace._id,
                posting_ids: postingIds
            }
            const response = await proxy.post(`${apiUrl}removePlanPosting`, payload)
            if(response.data.status) {
                // map the selected post ids to the selectedPlanAccounts and set in progress true
                selectedPlanAccounts.value = selectedPlanAccounts.value.map(item => {
                   // check if the item is in the selected posting ids
                    if(postingIds.some(posting => posting.id === item._id)){
                        item.inProgress = true;
                    }
                    return item
                })



            }
        } catch (e) {
            console.error(e)
        }
    }
    const subscribeAndBindPusherEvents = async () => {
        const channel = pusherSocketPublish.subscribe(`post_${planId.value}_${store.getters.getActiveWorkspace._id}`);
        channel.bind('data', (data) => {
            selectedPlanAccounts.value = selectedPlanAccounts.value.map(account => {
                if (account._id === data?.posting_id) {
                    account.inProgress = false;
                    account.isDeleted = data?.status;
                    account.isDeletedFailed = !data?.status;
                    account.deleted_message = data?.message;
                }
                return account;
            });
            //filter out the deleted accounts
            store.dispatch('toastNotification', {
                type: data?.status ? 'success' : 'error',
                message: data?.title

            })
            isLoading.value = false
        });
    }

    // Unsubscribe from pusher events
    const unsubscribePusherEvents = () => {
        pusherSocketPublish.unsubscribe(`post_${planId.value}_${store.getters.getActiveWorkspace._id}`);
    }

    function getPostStateImage(item){
        if (item) {
            if (item?.partially_failed) {
                return PartiallyFailedIcon
            }
            switch (item?.post_state) {
                case "published":
                    return PublishedIcon
                case "scheduled":
                    return ScheduledIcon
                case "failed":
                    return FailedIcon
                case "draft":
                    return DraftIcon
                case "reviewed":
                    return UnderReviewIcon
                case "missedReview":
                    return MissedReviewIcon
                case "queued":
                case "processing":
                    return InProgressIcon
                case "rejected":
                    return RejectedIcon
                default:
                    return DraftIcon
            }
        }
    }

    function getCalanderPostText(message , limit = 100){
        return parseDescriptionHtml(decodeTextURI(message) || '', limit)
    }

    function itemCalanderPlatformsCount(item){
        let count = 0
        // eslint-disable-next-line camelcase
        if (item.blog_selection) {
            const blogPlatforms = blogPlatformNames()
            blogPlatforms.forEach(function (platform) {
                if (
                    item.blog_selection[platform] &&
                    item.blog_selection[platform].website
                ) {
                    count += 1
                }
            })
        }

        if (item.account_selection) {
            count = item.account_selection_count
        }
        return count
    }

    function postCalenderHeadAttachment(item) {
        return item.image;
    }

    /**
     * Generate unique id for each note popover
     * @param itemId
     * @returns {string}
     */
    function generateUniqueId(itemId) {
        return `${itemId}_${Date.now().toString(36)}_note_popover`
    }

    /**
     * Getting the note title and description
     * @returns {string|string|*}
     */
    function getNoteBlockTitle(item) {
        const title = item.note_title ? item.note_title.charAt(0).toUpperCase() + item.note_title.slice(1) : '';
        const description = item.description || '';
        return {
            title,
            description
        }
    }

    function getLighterColor(hexColor) {
        // Convert hex to RGB
        let r = parseInt(hexColor.slice(1, 3), 16) / 255;
        let g = parseInt(hexColor.slice(3, 5), 16) / 255;
        let b = parseInt(hexColor.slice(5, 7), 16) / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        // Adjust lightness to create lighter version (0.95 creates very light shade)
        // You can adjust this value between 0.85 and 0.95 for desired lightness
        l = 0.95;

        // Convert back to RGB
        let c = (1 - Math.abs(2 * l - 1)) * s;
        let x = c * (1 - Math.abs((h * 6) % 2 - 1));
        let m = l - c/2;

        let [r1, g1, b1] = [0, 0, 0];
        if (0 <= h && h < 1/6) {
            [r1, g1, b1] = [c, x, 0];
        } else if (1/6 <= h && h < 2/6) {
            [r1, g1, b1] = [x, c, 0];
        } else if (2/6 <= h && h < 3/6) {
            [r1, g1, b1] = [0, c, x];
        } else if (3/6 <= h && h < 4/6) {
            [r1, g1, b1] = [0, x, c];
        } else if (4/6 <= h && h < 5/6) {
            [r1, g1, b1] = [x, 0, c];
        } else {
            [r1, g1, b1] = [c, 0, x];
        }

        r = Math.round((r1 + m) * 255).toString(16).padStart(2, '0');
        g = Math.round((g1 + m) * 255).toString(16).padStart(2, '0');
        b = Math.round((b1 + m) * 255).toString(16).padStart(2, '0');

        return `#${r}${g}${b}`;
    }

    return {
        subscribeAndBindPusherEvents,
        unsubscribePusherEvents,
        selectedPlanAccounts,
        isLoading,
        fetchPlanAccounts,
        deletePlanAccounts,
        planId,
        renderClass,
        isReplaceablePost,
        isDuplicatablePost,
        canEditThisPost,
        isEditablePost,
        getPostText,
        getPostMedia,
        isInProgressPost,
        fetchPlan,
        getPostStateImage,
        getCalanderPostText,
        itemCalanderPlatformsCount,
        postCalenderHeadAttachment,
        generateUniqueId,
        getNoteBlockTitle,
        getLighterColor
    }
}