<template>
  <div class="bg-white p-5 rounded-md w-full relative">
    <div class="overflow-hidden">
      <div class="grid grid-cols-2 gap-28 mb-6">
        <div class="flex gap-3 items-center justify-start">
          <p class="text-base font-weight-500 select-none capitalize"
            >Top Performing Posts</p
          >
          <v-menu
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                Review the most engaging posts of competitors, published during
                the selected time period.
              </p>
            </template>
          </v-menu>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex gap-3 items-center justify-center">
            <p class="text-base font-weight-500 select-none capitalize"
              >Least Performing Posts</p
            >
            <v-menu
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Review the least engaging posts of competitors, published
                  during the selected time period.
                </p>
              </template>
            </v-menu>
          </div>

          <div
            v-if="!isModal && data.length"
            v-tooltip="{
              content: 'Maximize',
              theme: 'light',
            }"
            class="cursor-pointer w-10 h-10 flex justify-center items-center bg-gray-50 hover:bg-gray-100 active:bg-gray-300 rounded-full"
            @click="$bvModal.show(`top-and-least-performance-modal`)"
          >
            <img
              src="@src/assets/img/full_screen.svg"
              alt="fullScreen"
              class="w-5 h-5"
            />
          </div>
          <div
            v-if="isModal"
            v-tooltip="{
              content: 'Minimize',
              theme: 'light',
            }"
            class="cursor-pointer w-10 h-10 flex justify-center items-center bg-gray-50 hover:bg-gray-100 active:bg-gray-300 rounded-full"
            @click="$bvModal.hide(`top-and-least-performance-modal`)"
          >
            <i class="fa fa-times fa-lg" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <SkeletonBox class="!w-full !h-[30rem]"></SkeletonBox>
      </div>
      <div v-else-if="isAllDataFetched" class="analytics-no-data-found">
        <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
        <p>Data is being fetched.</p>
      </div>
      <div v-else-if="!data.length" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <template v-else>
        <div
          v-for="(competitor, key) in data"
          :key="`competitor_performance_${key}`"
          class="grid grid-cols-2 gap-28 rounded !p-2 mb-4 color-border group grid-hover mx-1"
        >
          <!--        top performance-->
          <div>
            <div
              class="relative flex justify-start items-center w-full gap-2 mb-3 select-none"
            >
              <img
                :src="competitor.image"
                alt=""
                class="w-11 h-11 rounded-full border"
                @error="
                  $event.target.src =
                    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                "
              />
              <p
                v-tooltip="{
                  content: `${competitor.name}`,
                  theme: 'light',
                }"
                class="max-w-[8rem] truncate xl:max-w-[15rem]"
                >{{ competitor.name }}</p
              >
              <p
                v-if="competitor.least_5_posts.length"
                class="hidden group-hover:block text-sm absolute left-1/2 opacity-90 text-gray-800"
              >
                Top Performing Posts
              </p>
            </div>
            <div
              v-if="competitor.top_5_posts.length"
              :key="`top_posts_data_${key}`"
              class="w-full mt-2"
            >
              <CompetitorTopLeastPosts
                posts-type="topPerforming"
                :posts-data="competitor.top_5_posts"
                :followers="
                  platformName === 'facebook'
                    ? competitor.followers_count
                    : competitor.followersCount
                "
                :platform-name="platformName"
                @preview-performance-post="
                  (value) => createPreviewPostData(value, key, 'topPosts')
                "
              />
            </div>
            <div v-else class="w-full flex justify-center items-center">
              <p>No top performing posts found.</p>
            </div>
          </div>

          <!--        least performance-->
          <div>
            <div class="relative mb-3 h-[2.8rem]">
              <p
                v-if="competitor.least_5_posts.length"
                class="hidden group-hover:block text-sm absolute left-1/4 top-[30%] opacity-90 text-gray-800"
              >
                Least Performing Posts
              </p>
            </div>
            <div v-if="competitor.least_5_posts.length">
              <CompetitorTopLeastPosts
                posts-type="leastPerforming"
                :posts-data="competitor.least_5_posts"
                :followers="
                  platformName === 'facebook'
                    ? competitor.followers_count
                    : competitor.followersCount
                "
                :platform-name="platformName"
                @preview-performance-post="
                  (value) => createPreviewPostData(value, key, 'leastPosts')
                "
              />
            </div>
            <div v-else class="w-full flex justify-center items-center">
              <p>No least performing posts found.</p>
            </div>
          </div>
        </div>
      </template>
      <b-modal
        :id="`top-and-least-performance-modal`"
        centered
        hide-footer
        hide-header
        body-class="!overflow-y-auto"
        content-class="mt-2 !rounded-none h-screen"
        dialog-class="cst-modal"
        @hide="commonMethods.toggleWidgets(false)"
        @shown="commonMethods.toggleWidgets(true)"
      >
        <TopAndLeastPerformingPosts
          :is-modal="true"
          :data="data"
          :platform-name="platformName"
        />
      </b-modal>

      <PerformancePostPreviewModal
        :preview-post-data="state.previewPostData"
        :is-modal="isModal"
        :type="state.postPreviewModalType"
      >
        <template v-slot:modal-title>
          <h4>Post Preview</h4>
        </template>
        <template v-slot:sidebar-title>
          <p class="font-weight-500 mb-[1rem]">Statistics</p>
        </template>
        <template v-if="state.previewPostData" v-slot:sidebar-content>
          <template v-if="platformName === 'instagram'">
            <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/engagement.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Total Engagement</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData[0],
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/rate.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Engagement Rate</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      getEngagementRate(
                        state.previewPostData.followers,
                        state.previewPostData.postData[0],
                      )
                    }}
                    %</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/thumbsUp.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Likes</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData[8],
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/comments.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Comments</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData[9],
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/followers.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Followers</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(state.previewPostData.followers).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  v-if="
                    state.previewPostData.postData[4] === 'IMAGE' ||
                    state.previewPostData.postData[4] === 'CAROUSEL_ALBUM'
                  "
                  src="@src/assets/img/icons/analytic/type_image.svg"
                  alt=""
                  class="w-5"
                />
                <img
                  v-else
                  src="@src/assets/img/icons/analytic/type_video.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Type</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      getMediaTypeName(
                        platformName,
                        state.previewPostData.postData[4],
                        state.previewPostData.postData[11],
                      )
                    }}</span
                  >
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="platformName === 'facebook'">
            <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/engagement.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Total Engagement</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData.post_engagement,
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/rate.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Engagement Rate</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      getEngagementRate(
                        state.previewPostData.followers,
                        state.previewPostData.postData.post_engagement,
                      )
                    }}
                    %</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/thumbsUp.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Reactions</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData.total_post_reactions,
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/comments.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Comments</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData.comments,
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class=" border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <i class="fa fa-share w-5 text-[#9da2a6] flex items-center"></i>
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Shares</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(
                        state.previewPostData.postData.shares,
                      ).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class="border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/followers.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Followers</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      parseInt(state.previewPostData.followers).toLocaleString()
                    }}</span
                  >
                </div>
              </div>
              <div
                class="border !border-2 !border-gray-500 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group"
              >
                <img
                  src="@src/assets/img/icons/analytic/type_image.svg"
                  alt=""
                  class="w-5"
                />
                <div class="flex flex-col ml-5">
                  <span class="text-gray-800 group-hover:text-black-900"
                    >Type</span
                  >
                  <span
                    class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
                    >{{
                      getMediaTypeName(
                        platformName,
                        state.previewPostData.postData.media_type,
                      )
                    }}</span
                  >
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="state.previewPostData" v-slot:post-preview>
          <template v-if="platformName === 'instagram'">
            <div class="flex justify-between items-center pb-3">
              <div class="flex justify-start items-center">
                <img
                  :src="state.previewPostData.image"
                  alt=""
                  class="w-10 h-10 rounded-full"
                  @error="
                    $event.target.src =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <div
                  class="flex flex-col ml-3 font-weight-500 text-black-900 select-none"
                >
                  <p>{{ state.previewPostData.name }}</p>
                  <span
                    v-if="
                      state.previewPostData.postData &&
                      state.previewPostData.postData[7]
                    "
                    class="text-gray-800 text-xs"
                    >{{
                      $filters.publishedOnlyDateWithWeekdayAndMonth(
                        getWorkspaceTimeZoneTime(
                          state.previewPostData.postData[7],
                          'YYYY-MM-DD HH:mm:ss',
                        ),
                      )
                    }}</span
                  >
                </div>
              </div>
              <a
                v-tooltip="{
                  content: 'View post on instagram',
                }"
                :href="sanitizeUrl(state.previewPostData.postData[6])"
                target="_blank" rel="noopener"
                class="far fa-external-link-square-alt fa-lg"
              ></a>
            </div>
            <p
              class="text-sm mt-3 whitespace-pre-line mb-0.5"
              v-html="getDescription"
            >
            </p>
            <div
              v-if="
                state.previewPostData.postData[4] === 'VIDEO' &&
                state.previewPostData.postData[5] === ''
              "
              class="bg-[#faf2dc] flex justify-center items-center rounded-lg gap-2 p-2 my-3"
            >
              <i class="far fa-exclamation-circle text-[#2f8ae0]"></i>
              <p class="text-sm text-[#b57e00]"
                >The video cannot be viewed or downloaded due to copyright
                issues. However, you can watch the video using the
                <a
                  v-tooltip="{
                    content: 'View post on instagram',
                  }"
                  :href="sanitizeUrl(state.previewPostData.postData[6])"
                  target="_blank" rel="noopener"
                >
                  live link</a
                >.</p
              >
            </div>
            <div
              class="rounded-xl border !border-[#E9EFF6] flex justify-center p-5 min-h-[23rem] media-card"
            >
              <video
                v-if="
                  state.previewPostData.postData[4] === 'VIDEO' &&
                  (state.previewPostData.postData[5].includes('video') ||
                    state.previewPostData.postData[5].includes('.mp4'))
                "
                class="w-full max-w-[500px] h-[500px]"
                controls
              >
                <source
                  :src="state.previewPostData.postData[5]"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <Carousel
                v-else-if="
                  state.previewPostData.postData[4] === 'CAROUSEL_ALBUM'
                "
                id="instagram-competitor-preview-carousel"
                :mouse-drag="true"
                :loop="true"
                :navigation-next-label="navigationNext"
                :navigation-prev-label="navigationPrev"
                :navigation-enabled="true"
                :per-page="1"
                :scroll-per-page="true"
                pagination-active-color="#005fd0"
                pagination-color="rgba(58, 69, 87, 0.2)"
                :pagination-padding="7"
                class="w-[90%] h-auto"
              >
                <Slide
                  v-for="(mediaUrl, key) in getCarouselImageLinks(
                    state.previewPostData.postData[5],
                  )"
                  :key="`carousel_img_preview_${key}`"
                  class="flex w-full h-full justify-center items-center"
                  :class="{
                    'cursor-move': state.previewPostData.postData[5].length > 0,
                  }"
                >
                  <video
                    v-if="
                      mediaUrl.includes('//video') || mediaUrl.includes('.mp4')
                    "
                    class="w-full max-w-[500px] h-[500px]"
                    controls
                  >
                    <source :src="mediaUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <img
                    v-else
                    :src="mediaUrl"
                    alt=""
                    class="max-h-[30rem] h-full"
                    loading="lazy"
                  />
                </Slide>
              </Carousel>
              <img
                v-else
                :src="
                  state.previewPostData.postData[4] === 'VIDEO' &&
                  !state.previewPostData.postData[5]
                    ? getResizedImageURL(
                        getMediaLink(state.previewPostData.postData[6]),
                        570,
                        0,
                      )
                    : state.previewPostData.postData[5]
                "
                alt=""
                class="w-full"
                @error="
                  $event.target.src =
                    'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
                "
              />
            </div>
          </template>
          <template v-else-if="platformName === 'facebook'">
              <FacebookPublishedPostPreview
                :container-id="`fb-post-container-${state.previewPostData.postData.id}`"
                class="flex justify-center"
                :post-link="state.previewPostData?.postData?.permalink"
                width="350"
              />
          </template>
        </template>
      </PerformancePostPreviewModal>
    </div>
  </div>
</template>

<script setup>
// libraries
import { computed, inject, reactive } from 'vue'

// components
import CompetitorTopLeastPosts from '@src/modules/analytics_v3/components/CompetitorTopLeastPosts.vue'
import PerformancePostPreviewModal from '@src/modules/analytics_v3/components/PerformancePostPreviewModal.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

// helpers
import { commonMethods } from '@common/store/common-methods'
import { parseDescriptionHtml } from '@common/lib/helper'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import FacebookPublishedPostPreview from '@src/modules/analytics_v3/components/FacebookPublishedPostPreview.vue'
import {sanitizeUrl} from "@braintree/sanitize-url";

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
  isAllDataFetched: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  platformName: {
    type: String,
    default: 'instagram',
  },
})

const root = inject('root')
const { $bvModal } = root
const { getMediaTypeName, getEngagementRate, getMediaLink } =
  useCompetitorHelper()

const state = reactive({
  previewPostData: {},
  postPreviewModalType: 'preview-performance-post-modal',
})

/**
 * @description returns html parsed text
 * @type {ComputedRef<string|*>}
 */
const getDescription = computed(() => {
  let caption = state.previewPostData?.postData[3] || ''
  caption = parseDescriptionHtml(caption, 5000, true, true)
  return caption
})

const createPreviewPostData = (postIndex, competitorIndex, postType) => {
  switch (props.platformName) {
    case 'instagram':
      state.previewPostData = {
        name: props.data[competitorIndex].name,
        followers: props.data[competitorIndex].followersCount,
        image: props.data[competitorIndex].image,
        businessAccountId: props.data[competitorIndex].business_account_id,
        instagramAccountId: props.data[competitorIndex].instagram_account_id,
      }
      break
    case 'facebook':
      state.previewPostData = {
        name: props.data[competitorIndex].name,
        followers: props.data[competitorIndex].followers_count,
        image: props.data[competitorIndex].image,
      }
      break
    default:
      break
  }

  switch (postType) {
    case 'topPosts':
      state.previewPostData = {
        ...state.previewPostData,
        postData: props.data[competitorIndex].top_5_posts[postIndex],
      }
      break

    case 'leastPosts':
      state.previewPostData = {
        ...state.previewPostData,
        postData: props.data[competitorIndex].least_5_posts[postIndex],
      }
      break
    default:
      break
  }
  $bvModal.show(
    `${
      props.isModal
        ? `${state.postPreviewModalType}-double`
        : state.postPreviewModalType
    }`
  )
}

/**
 * @description returns array of image links when the post type is carousel
 * @param allUrlString
 * @returns {*}
 */
const getCarouselImageLinks = (allUrlString) => {
  return allUrlString.split(',')
}

const navigationNext = computed(() => {
  return `<i class="fas fa-chevron-right"></i>`
})
const navigationPrev = computed(() => {
  return `<i class="fas fa-chevron-left"></i>`
})
</script>

<style scoped lang="scss">
.grid-hover:hover {
  border-color: #409aff;
  box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  transition: all 0.4s ease;
}
</style>
