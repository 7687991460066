<template>
  <div
      class="analytic_component analytics-report-wrapper"
      style="background: #515558"
  >
    <div class="page_view">
      <div class="subpage">
        <ReportHeader
          :report-name="'Group'"
          :current="1"
          :total="totalPages"
        ></ReportHeader>
        <div>
          <AnalyticsSummaryBlock
              class="p-0 mx-0"
              :heading="'All Profiles Performance Summary'"
              :subtitle="'Key profile performance metrics from the selected time period.'"
          >
            <div class="row">
              <template v-for="(value, key) in overview_values" :key="`overview_values_${key}`">
                <div class="col-6 col-sm-3">
                  <SummaryCard
                      :title="value"
                      :total="metrics.overview.current[key]"
                      :reach="Math.abs(getReach(key, metrics.overview)) + '%'"
                      :reach-up="getReachUp(key, metrics.overview)"
                  ></SummaryCard>
                </div>
              </template>
            </div>
          </AnalyticsSummaryBlock>

          <div class="analytics-section-header">
            <h2>Engagement / Posts Published </h2>
            <p>See how your audience interacted with your posts.</p>
          </div>
          <ColumnCrossHairChart
              v-if="metrics.posts_engagements.data.show_data > 0"
              :categories="metrics.posts_engagements.buckets"
              :series="getPostEngagementSeries()"
          />
          <div class="row mt-4">
            <template v-for="(value, key) in engagement_rollup_values" :key="`engagement_rollup_values_${key}`">
              <div class="col-6 col-sm-3">
                <SummaryCard
                    :title="value"
                    :total="metrics.engagement_rollup.current[key]"
                    :reach="
                    Math.abs(getReach(key, metrics.engagement_rollup)) + '%'
                  "
                    :reach-up="getReachUp(key, metrics.engagement_rollup)"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(accounts, accountsKey) in splicedTopPosts" :key="`accounts_${accountsKey}`">
      <div class="page_view">
        <div class="subpage card_activity_top_post">
          <ReportHeader
              :report-name="'Group'"
              :current="2 + accountsKey"
              :total="totalPages"
              @increment-page="incrementTotalPageNumber"
          ></ReportHeader>
          <h2>Top Posts By Engagement</h2>
          <p
          >Review your top posts published during the selected time period,
            based on the story’s lifetime performance.</p
          >
          <AnalyticsTopPosts
              :top_posts="{ overall: accounts }"
              :type="'report'"
              :account-select-flags="accountSelectFlags"
          ></AnalyticsTopPosts>
        </div>
      </div>
    </template>

    <template v-for="(accounts, key) in spliced_accounts" :key="`spliced_accounts_${key}`" >
      <div class="page_view">
        <div class="subpage card_activity_top_post">
          <ReportHeader
              :report-name="'Group'"
              :current="pageCount + key"
              :total="totalPages"
              @increment-page="incrementTotalPageNumber"
          ></ReportHeader>
          <h2>Social Profiles Performance</h2>
          <p>Key metrics of the Social Profiles in the selected time period.</p>
          <AnalyticsAccountPerformance
              :account-performance="metrics.account_performance.overall"
              :accounts="accounts"
              :account-select-flags="accountSelectFlags"
              :type="'report'"
          ></AnalyticsAccountPerformance>
        </div>
      </div>
    </template>
    <template v-for="(flagChunk, index) in splitAccountFlags" :key="`analytics_engagement_${index}`">
      <div class="page_view">
        <div class="subpage card_activity_top_post analytics-engagement">
          <ReportHeader
              :report-name="'Group'"
              :current="pageCount + spliced_accounts.length + index"
              :total="totalPages"
              @increment-page="incrementTotalPageNumber"
          ></ReportHeader>
          <h2>Overall Platforms Engagement</h2>
          <p>View engagement metrics across all platforms.</p>
          <AnalyticsEngagement
              :account-performance="metrics.account_performance"
              :account-select-flags="flagChunk"
          ></AnalyticsEngagement>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader'
import AnalyticsTopPosts from '@src/modules/analytics/views/overview/components/AnalyticsTopPosts'
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import AnalyticsAccountPerformance from '@src/modules/analytics/views/overview/components/AnalyticsAccountPerformance'
import AnalyticsEngagement from '@src/modules/analytics/views/overview/components/AnalyticsEngagement'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'

export default {
  components: {
    ReportHeader,
    AnalyticsTopPosts,
    AnalyticsSummaryBlock,
    AnalyticsAccountPerformance,
    AnalyticsEngagement,
    SummaryCard,
    ColumnCrossHairChart
  },
  props: {
    metrics: {
      type: Object,
      default: () => {
      }
    },
    accountSelectFlags: {
      type: Object,
      default: () => {
        return {
          facebook: false,
          instagram: false,
          linkedin: false,
          tiktok: false,
          twitter: false,
          pinterest: false,
          youtube: false
        }
      }
    }
  },
  data() {
    return {
      total: 0,
      overview_values: {
        total_posts: 'Total Posts',
        reactions: 'Reactions',
        comments: 'Comments',
        reposts: 'Reposts'
      },
      engagement_rollup_values: {
        total_posts: 'Total Posts',
        posts_per_day: 'Posts Per Day',
        total_engagement: 'Total Engagement',
        engagement_per_day: 'Engagement Per Day'
      },
      spliced_accounts: [],
      splicedTopPosts: [],
      currentPage: 1,
      pageCount: 2,

    }
  },
  computed: {
    splitAccountFlags() {
      const selectedAccounts = Object.entries(this.accountSelectFlags)
        .filter(([_, isSelected]) => isSelected)
        .map(([platform]) => platform);

      const chunks = [];
      const size = 4;

      for (let i = 0; i < selectedAccounts.length; i += size) {
        const chunk = selectedAccounts.slice(i, i + size);
        const chunkFlags = {};
        Object.keys(this.accountSelectFlags).forEach(platform => {
          chunkFlags[platform] = chunk.includes(platform);
        });
        chunks.push(chunkFlags);
      }

      return chunks;
    },
    totalPages() {
      // Base page (1) + Top posts pages + Account performance pages + Analytics engagement pages
      return 1 + this.splicedTopPosts.length + this.spliced_accounts.length + this.splitAccountFlags.length;
    }
  },
  mounted() {
    if (this.metrics?.accounts?.length) {
      const cloneAccounts = this.metrics.accounts
      const size = 12
      for (let i = 0; i < cloneAccounts.length; i += size) {
        this.spliced_accounts.push(cloneAccounts.slice(i, i + size));
      }
    }

    if (this.metrics.top_posts?.overall?.length) {
      this.metrics.top_posts.overall.splice(this.metrics.topPostsLimit)
      const topPostsPageLimit = 5
      while (this.metrics.top_posts.overall.length > 0) {
        this.pageCount += 1
        this.splicedTopPosts.push(this.metrics.top_posts.overall.splice(0, topPostsPageLimit))
      }
    }

    // Set initial total
    this.total = this.totalPages;
  },
  methods: {
    incrementTotalPageNumber() {
      this.total += 1
    },
    getReach(type, section) {
      const previous =
          section.previous[type] === 0 || !section.previous[type]
              ? section.current[type]
              : section.previous[type]
      const value = (
          ((section.current[type] - previous) / previous) *
          100
      ).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReachUp(type, section) {
      return this.getReach(type, section) > 0
    },
    getPostEngagementSeries() {
      return [
        {
          name: 'Comments',
          data: this.metrics.posts_engagements.data.comments,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Reposts',
          data: this.metrics.posts_engagements.data.reposts,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Reactions',
          data: this.metrics.posts_engagements.data.reactions,
          color: '#e02f5f',
          type: 'column'
        },
        {
          name: 'Posts Published',
          data: this.metrics.posts_engagements.data.post_count,
          color: '#6173ff',
          type: 'spline'
        }
      ]
    }
  }
}
</script>
