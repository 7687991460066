<template>
  <div
    class="modal-router modal article_preview_modal right side_slide_right w_1150 full_height article-preview-dialog"
  >
    <div
      v-click-away="closeModal"
      class="modal-dialog"
      :class="[closeStatus ? 'animated fadeInRight' : 'animated fadeOutRight']"
    >
      <div class="modal-content article-preview-dialog__content">
        <div class="modal-body article-preview-dialog__content__body">
          <div class="article-preview-dialog__content__body__inner">
            <template v-if="loading">
              <div
                class="article-preview-dialog__content__body__inner__loading"
              >
                <beat-loader />
              </div>
            </template>
            <template v-else>
              <div class="article-preview-dialog__content__body__inner__header">
                <div
                  class="article-preview-dialog__content__body__inner__header__icon_actions"
                >
                  <div
                    v-if="$route.query.module === 'search'"
                    v-tooltip="'View Top Influencers'"
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="influencersWebPreview"
                  >
                    <i class="icon-User"></i>
                  </div>

                  <!-- share buttons -->
                  <b-dropdown
                    id="dropdown-share-articles"
                    variant="studio-icon-theme"
                    class="studio-icon-theme-dropdown mr-2"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <i
                        v-tooltip="'Share this post'"
                        class="icon-Share_icon"
                      ></i>
                    </template>
                    <template v-if="$route.query.module === 'search'">
                      <b-dropdown-item
                        variant="studio-icon-theme"
                        @click.prevent="
                          shareSearchFromDialog('social_share_modal')
                        "
                        ><i class="far fa-share"></i> Social Media
                      </b-dropdown-item>
                      <b-dropdown-item
                        variant="studio-icon-theme"
                        @click.prevent="
                          shareSearchFromDialog('blog_share_modal')
                        "
                        ><i class="far fa-rss"></i> Blog Post
                      </b-dropdown-item>
                    </template>
                    <template v-else>
                      <b-dropdown-item
                        variant="studio-icon-theme"
                        @click.prevent="
                          shareToSocial(post.record_id ?? post.id)
                        "
                        ><i class="far fa-share"></i> Social Media
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                  <template v-if="isFeedsModule">
                    <template v-if="getArticlePreviewHasStarred">
                      <div
                        v-if="!getArticlePreviewStarred"
                        v-tooltip.top="'Starred'"
                        class="btn-studio-theme-transparent-icon mr-2"
                        @click="starredPreviewAction('starred')"
                      >
                        <i class="icon-Favorite_icon"></i>
                      </div>
                      <div
                        v-else
                        v-tooltip.top="'Unstarred'"
                        class="btn-studio-theme-transparent-icon mr-2"
                        @click="starredPreviewAction('unstarred')"
                      >
                        <i class="icon-Favorite_icon is-favorite"></i>
                      </div>
                    </template>
                  </template>
                  <template v-if="isSearchModule">
                    <template v-if="getArticlePreviewHasFavorited">
                      <b-dropdown
                        id="dropdown-favorite-articles"
                        ref="dropdown_favorite"
                        variant="studio-icon-theme"
                        class="studio-icon-theme-dropdown mr-2"
                        :class="{ article_preview_fev_ico: post.is_favorite }"
                        no-caret
                        block
                      >
                        <template v-if="post.is_favorite" v-slot:button-content>
                          <i
                            v-tooltip="'Unfavorite'"
                            class="icon-Favorite_icon is-favorite"
                            @click.prevent="
                              unfavoriteArticlePreviewPostItemEventFire(post.id)
                            "
                          ></i>
                          <span
                            :class="{ ' active': post.is_active }"
                            class="icon_animate"
                          ></span>
                        </template>
                        <template v-else v-slot:button-content>
                          <i
                            v-tooltip="'Favorite'"
                            class="icon-Favorite_icon"
                            :class="{ 'animated tada': post.is_active }"
                          ></i>
                        </template>
                        <BDropdownFavoriteArticle
                          v-show="!post.is_favorite"
                          :post_id="post.id"
                          :post-index="index"
                        />
                      </b-dropdown>
                    </template>
                  </template>

                  <div
                    v-tooltip.top="'Add to Pocket'"
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="pocketPreviewAction"
                  >
                    <i class="icon-pocket"></i>
                  </div>
                  <div
                    v-tooltip.top="getArticlePreviewArchiveText"
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="archivePreviewAction"
                  >
                    <i class="icon-Archive_icon"></i>
                  </div>
                  <div class="btn-separator-line"> </div>
                  <div
                    v-tooltip.top="'Open this link'"
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="openExternalPageLink"
                  >
                    <i class="icon-link_icon"></i>
                  </div>
                </div>
                <div
                  class="article-preview-dialog__content__body__inner__header__close_action"
                >
                  <div class="btn-studio-theme-grey-icon" @click="closeModal">
                    <i class="fa fa-times"></i>
                  </div>
                </div>
              </div>

              <div class="article-preview-dialog__content__body__inner__main">
                <div
                  class="article-preview-dialog__content__body__inner__main__about_domain"
                >
                  <div
                    class="article-preview-dialog__content__body__inner__main__about_domain__left_section"
                  >
                    <p
                      >{{ getArticlePreviewDomainURL }} -
                      {{ $filters.relative(getArticlePreviewPostDate) }} on
                      {{ getArticlePreviewPostDate }}</p
                    >
                  </div>
                  <div
                    class="article-preview-dialog__content__body__inner__main__about_domain__right_section"
                  >
                    <div
                      v-if="getArticlePreviewTopics"
                      class="article-preview-dialog__content__body__inner__main__about_domain__right_section__tags"
                    >
                      <template v-for="topic in getArticlePreviewTopics">
                        <div v-if="topic.label" :key="topic.label">
                          <span
                            v-for="finalized_topic in checkIfMultipleTopics(
                              topic.label
                            )"
                            :key="finalized_topic"
                            class="tag"
                          >
                            {{ finalized_topic }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  class="article-preview-dialog__content__body__inner__main__title"
                >
                  <h2 v-html="getArticlePreviewTitle" />
                </div>

                <div
                  v-if="isSearchModule"
                  class="article-preview-dialog__content__body__inner__main__engagement"
                >
                  <div class="article-preview-engagement-block">
                    <div
                      class="article-preview-engagement-block-social popover-social-statistics"
                    >
                      <SharedCountTooltip :post="getSharedCountTooltipData" />
                      <span class="value-block">{{
                        intToString(
                          $filters.numberToCommas(getArticlePreviewTotalShares)
                        )
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-if="getArticlePreviewVelocity > 5"
                    class="article-preview-engagement-block"
                  >
                    <span class="text-block">Trending Score</span>
                    <span class="value-block">{{
                      getArticlePreviewVelocity
                    }}</span>
                  </div>
                  <div
                    v-if="getArticlePreviewSentiment"
                    class="article-preview-engagement-block"
                  >
                    <span class="text-block">Sentiment</span>
                    <span class="value-block"
                      ><i
                        v-tooltip.top="getArticleTooltip"
                        aria-hidden="true"
                        :class="getArticlePreviewSentiment"
                      ></i
                    ></span>
                  </div>
                </div>

                <div
                  class="article-preview-dialog__content__body__inner__main__image"
                >
                  <img
                    :src="backgroundArticlePreviewImageURL(post, 800)"
                    alt=""
                    class="h-[400px] w-full object-cover rounded-lg"
                    @error="
                      $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-400.png`
                    "
                  />
                  <!--                  <div-->
                  <!--                    class="article-preview-image-block"-->
                  <!--                    :style="{-->
                  <!--                      'background-image':-->
                  <!--                        'url(' +-->
                  <!--                        backgroundArticlePreviewImageURL(post, 800) +-->
                  <!--                        '), url(' +-->
                  <!--                        backgroundArticlePreviewImageDefaultURL() +-->
                  <!--                        ')',-->
                  <!--                    }"-->
                  <!--                  >-->
                  <!--                  </div>-->
                </div>
                <div
                  class="article-preview-dialog__content__body__inner__main__content"
                >
                  <div
                    v-if="getArticlePreviewDescription"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getArticlePreviewDescription"
                  >
                  </div>
                  <div
                    v-else
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                  >
                    <p>No description available for this article.</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--</transition>-->
  </div>
</template>

<script>
import http from '@common/lib/http-common'
import { feederPostURI } from '@src/modules/discovery/config/api-utils'
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import SharedCountTooltip from '@src/modules/discovery/components/common/snippets/SharedCountTooltip'
import BDropdownFavoriteArticle from '@src/modules/discovery/components/folders/BDropdownFavoriteArticle'
import { feederItemMixin } from '@src/modules/discovery/components/feeder/mixins/feeder-item-mixin'
import useDateFormat from "@common/composables/useDateFormat";
export default {
  components: {
    BDropdownFavoriteArticle,
    SharedCountTooltip,
  },
  mixins: [feederItemMixin],
  setup(){
    const {momentWrapper} = useDateFormat()
    return {momentWrapper}
  },
  data() {
    return {
      loading: true,
      post: {},
      closeStatus: true,
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
    isFeedsModule() {
      return this.$route.query.module === 'feeds'
    },
    isSearchModule() {
      return this.$route.query.module === 'search'
    },
    getArticlePreviewTitle() {
      return this.post.title
    },
    getArticlePreviewDomainURL() {
      switch (this.$route.query.module) {
        case 'feeds':
          return this.post.domain_netloc
        case 'search':
          return this.post.domain
      }
      return ''
    },
    getArticlePreviewPostDate() {
      return this.momentWrapper(this.post.post_date).formatDate()
    },
    getArticlePreviewDescription() {
      return this.post.description
    },
    getArticlePreviewTotalShares() {
      switch (this.$route.query.module) {
        case 'feeds':
          return this.post.total_stats || 0
        case 'search':
          return (
            (this.post.facebook_shares || 0) +
            (this.post.pins || 0) +
            (this.post.twitter_shares || 0) +
            (this.post.reddit || 0)
          )
      }
      return 0
    },
    getArticlePreviewVelocity() {
      return this.post.velocity || 0
    },
    getArticlePreviewSentiment() {
      if (this.post.sentiment && this.post.sentiment.length > 0) {
        const sentimentLabel = this.post.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'smile far fa-smile-o'
          case 'negative':
            return 'sad far fa-meh-o'
          case 'neutral':
            return 'far fa-meh normal'
          default:
            return 'normal far fa-frown-o'
        }
      }
      return 'normal far fa-frown-o'
    },
    getArticleTooltip() {
      if (this.post.sentiment && this.post.sentiment.length > 0) {
        const sentimentLabel = this.post.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'Positive'
          case 'negative':
            return 'Negative'
          case 'neutral':
            return 'Neutral'
          default:
            return 'Normal'
        }
      }
      return 'Normal'
    },

    getArticlePreviewTopics() {
      return this.post.topics
    },
    getArticlePreviewStarred() {
      return this.post.is_starred
    },
    getArticlePreviewHasStarred() {
      return Object.hasOwn(this.post, 'is_starred')
    },
    getArticlePreviewHasFavorited() {
      return Object.hasOwn(this.post, 'is_favorite')
    },
    getArticlePreviewArchiveText() {
      console.log(this.$route.params)
      return this.$route.params.content_type &&
        this.$route.params.content_type === 'archived'
        ? 'Unarchive'
        : 'Archive'
    },
    getSharedCountTooltipData() {
      return {
        _source: this.post,
      }
    },
  },
  watch: {},
  created() {
    console.log('load preview item')
    this.loadArticlePreview()
    document.querySelector('body').classList.add('overflow_hidden')
  },
  mounted() {
    console.log('load preview item')
    EventBus.$on('favorite-post-item-preview', () => {
      this.post.is_favorite = true
      this.post.is_active = true
    })
  },
  beforeUnmount() {
    EventBus.$off('favorite-post-item-preview')
  },
  methods: {
    closeModal() {
      this.closeStatus = false
      setTimeout(() => {
        document.querySelector('body').classList.remove('overflow_hidden')
        this.$router.go(-1)
      }, 300)
    },
    async loadArticlePreview() {
      this.loading = true
      console.log(this.$route.query.module, this.loading)
      if (this.$route.query.module === 'feeds') {
        await http
          .post(feederPostURI + 'preview/get', {
            workspace_id: this.getActiveWorkspace._id,
            record_id: this.$route.params.post_id,
            state: this.$route.query.module,
          })
          .then((res) => {
            if (res.data.status) {
              this.post = res.data.post
            }
          })
        this.loading = false
      }
      if (this.$route.query.module === 'search') {
        const response = this.$store.dispatch(
          'fetchArticlePreviewById',
          this.$route.params.post_id
        )
        console.log('article preview response', response)
        await response
          .then((res) => {
            if (res.data.status) {
              this.post = res.data.post._source
            }
          })
          .catch()
          .finally()
        this.loading = false
      }
    },
    starredPreviewAction(state) {
      this.post.is_starred = state === 'starred'
      EventBus.$emit('feeder_starred_action', {
        record_id: this.post.record_id ?? this.post.id,
        index: 0,
        state,
      })
      if (
        this.$route.params.content_type &&
        this.$route.params.content_type === 'starred'
      ) {
        this.$router.go(-1)
      }
    },
    archivePreviewAction() {
      switch (this.$route.query.module) {
        case 'search':
          // NOTE: we have to cater the use case for the topics and other sections later on.
          EventBus.$emit('archive-post-item', {
            record_id: this.post.record_id ?? this.post.id,
            state: 'archive',
          })
          break
        case 'feeds':
          //  for the feeds we are checking the content type as content type can be of archived, starred etc.
          if (
            this.$route.params.content_type &&
            this.$route.params.content_type === 'archived'
          ) {
            EventBus.$emit('feeder_unarchive_action', {
              record_id: this.post.record_id ?? this.post.id,
              index: 0,
              domain_uuid: this.post.domain_uuid,
              state: 'unarchive',
            })
          } else {
            EventBus.$emit('feeder_archive_action', {
              record_id: this.post.record_id ?? this.post.id,
              index: 0,
              domain_uuid: this.post.domain_uuid,
              state: 'archive',
            })
          }
          break
      }

      document.querySelector('body').classList.remove('overflow_hidden')
      this.$router.go(-1)
    },
    pocketPreviewAction() {
      const url = this.savePocketContent(this.post.url)
      window.open(url, '_blank', 'noopener, noreferrer')
    },
    openExternalPageLink() {
      window.open(this.post.url, '_blank', 'noopener, noreferrer')
    },
    unfavoriteArticlePreviewPostItemEventFire(postId) {
      EventBus.$emit('unfavorite-post-item', { postId })
      this.post.is_favorite = false
      this.post.is_active = false
    },
    shareSearchFromDialog(type = 'social_share_modal') {
      this.$router.go(-1)
      document.querySelector('body').classList.remove('overflow_hidden')
      this.initializeArticlePostCreation(
        this.post.record_id ?? this.post.id,
        type
      )
    },
    influencersWebPreview() {
      const routeName =
        this.$route.query.module === 'search' ? 'discover_search_web' : ''
      const payload = {
        name: `${routeName}_item_influencers`,
        params: { post_id: this.post.record_id ?? this.post.id },
        query: { module: 'search', ...this.$route.query },
      }
      console.log(payload)
      this.$router.push(payload)
    },
  },
}
</script>

<style>
.favorite_icon_orange {
  color: #ff9300 !important;
}
</style>
