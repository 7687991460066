<template>
  <div :class="isRoot ? '' : 'sub_comment moveLeft clear'">
    <div class="picture_block picture_block_background">
      <div
        v-if="tweet.posted_by.image"
        class="img"
        :style="'background: url(' + tweet.posted_by.image + ')'"
      ></div>
      <div
        v-else
        class="img"
        style="
          background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
        "
      ></div>
    </div>
    <div class="text_block">
      <div v-if="isParent || !isRoot">
        <p class="text head_text_custom"
          >{{ tweet.posted_by.name }}<br />
          <span class="tag" style="margin-left: 0px"
            >@{{ tweet.posted_by.user_name }}</span
          >
          <span class="tag">
            . {{ commonMethods.timesAgo(tweet.created_time) }}</span
          >
        </p>
        <div class="preview_desc">
          <HtmlRenderer
            tag="p"
            class="desc"
            :html-content="stringToHtmlTwitter(tweet.tweet_message)"
          />
        </div>
        <div class="option_block option_block_bottom d-flex align-items-left">
        </div>
      </div>
      <div v-else>
        <p class="text head_text_custom"
          >{{ tweet.posted_by.name
          }}<span class="tag">@{{ tweet.posted_by.user_name }}</span
          ><span class="tag">
            . {{ commonMethods.timesAgo(tweet.created_time) }}</span
          >
          <template v-if="!isParent && isRoot && repliesCount > 0">
            <span class="linkable" @click="shouldShowReplies"
              >({{ repliesCount }})
              <span
                v-html="showReplies ? 'View Replies' : 'Hide Replies'"
              ></span
            ></span>
          </template>
        </p>
        <div class="preview_desc">
          <p class="desc" v-html="stringToHtmlTwitter(tweet.tweet_message)"></p>
        </div>
        <div class="option_block option_block_bottom">
          <span
            class="span_item"
            @click="
              replyCommentTwitter(
                tweet.posted_by.user_name,
                tweet.mentioned_user,
                tweet.tweet_id,
                false,
                rootIndex,
              )
            "
          >
            <i class="fas fa-reply"></i> <span>Reply</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import HtmlRenderer from "@common/components/htmlRenderer.vue";

export default {
  components: {HtmlRenderer},
  props: [
    'isParent',
    'isRoot',
    'rootIndex',
    'subIndex',
    'post',
    'tweet',
    'repliesCount',
    'screenName',
    'setCanShowReplies',
    'replyCommentTwitter'
  ],
  data () {
    return {
      commonMethods,
      showReplies: true
    }
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  methods: {
    ...mapActions([]),
    shouldShowReplies () {
      this.setCanShowReplies(this.showReplies, this.tweet, this.rootIndex)
      this.showReplies = !this.showReplies
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.moveLeft {
  padding-left: 45px;
}

.head_text_custom {
  padding-right: 9px;
  color: #14171a;
  font-weight: 500;

  .tag {
    color: #657786;
    font-weight: normal;
    margin-left: 8px;
  }

  span {
    font-size: 0.9em;
  }
}

.linkable {
  color: #00aced;
  text-decoration: underline;
  cursor: pointer;
  opacity: 1;
  float: right;
}

.social_preview_no_tab .twitter_post_preview .profile_picture .text_block {
  width: calc(100% - 35px);
}
</style>
