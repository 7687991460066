<template>
  <div>
    <b-modal
      id="add_pinterest_modal"
      class="modish-modal"
      hide-footer
      hide-header
      centered
    >
      <div class="mt-0 mb-4">
        <div class="modal-content">
          <div class="modal_head d-flex align-items-center">
            <div class="modish-modal__header">
              <a
                href="https://docs.contentstudio.io/article/833-how-to-connect-pinterest-boards-accounts-with-contentstudio"
                target="_blank" rel="noopener"
              >
                <h4>Add Pinterest</h4>
                <p
                  >Posting to Pinterest will be done via Zapier
                  <i class="far fa-info-circle ml-2"></i>
                </p>
              </a>
            </div>
            <button
              type="button"
              class="modal_head__close"
              data-dismiss="modal"
              @click="$bvModal.hide('add_pinterest_modal')"
            >
              &times;
            </button>
          </div>

          <div class="modal_body">
            <div class="modish-modal__form simple-form">
              <div class="modish-modal__upload">
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  :disabled="uploadingImage"
                  @change="onFileChange($event)"
                />
                <label for="imageUpload">
                  <i class="far fa-camera mr-1"></i>
                  {{ uploadingImage ? 'Uploading' : 'Upload' }}
                </label>
                <div
                  class="modish-modal__preview"
                  :class="{ 'modish-modal__preview--empty': image === null }"
                >
                  <img v-if="account_image" :src="account_image" />
                </div>
              </div>

              <div class="simple-form__text-field pl-0">
                <input
                  v-model="account_name"
                  type="text"
                  placeholder="Pinterest Account Name"
                />
              </div>

              <!--  ADD Account  -->
              <div v-if="!shiftform" class="modish-modal__form-buttons">
                <button
                  class="btn btn-studio-theme-space btn-size-large w-100 justify_center"
                  :disabled="creatingANewBoard"
                  @click="createProfileAndBoards"
                >
                  <span>Connect A Pinterest Profile?</span>
                  <clip-loader
                    v-if="creatingANewBoard"
                    :color="'#ffffff'"
                    :size="'16px'"
                  ></clip-loader>
                </button>
                <span class="modish-modal__form-breaker">or</span>
                <button
                  class="btn btn-studio-theme-transparent btn-size-large w-100 justify_center"
                  @click="shiftform = !shiftform"
                >
                  <span>Add An Existing Board To ContentStudio</span>
                </button>
              </div>

              <!--  ADD Board  -->
              <div v-else class="modish-modal__form-boards">
                <!-- <a href="https://www.nutt.net/how-do-i-get-pinterest-board-id/" target="_blank" rel="noopener" >
                      You can find the board ID here.
                      <i
                          v-tooltip.top="'Since this is a manual integration of Pinterest, users are expected to provide the Board Ids using the link provided.'"
                          class="far fa-info-circle ml-2">
                      </i>
                  </a> -->
                <div
                  v-for="(board, index) in board_details"
                  :key="index"
                  class="d-flex"
                >
                  <div class="simple-form__text-field pl-0 mr-3">
                    <input
                      v-model="board.board_name"
                      type="text"
                      placeholder="Board Name"
                    />
                  </div>
                  <!-- temporarily we are using board_id field for board url -->
                  <div class="simple-form__text-field pl-0 mr-3">
                    <input
                      v-model="board.board_id"
                      type="text"
                      placeholder="Board Url"
                    />
                  </div>

                  <button
                    class="btn btn-studio-theme-grey-space delete-btn justify_center"
                    :disabled="board_details.length === 1"
                    @click="removeBoard(index)"
                  >
                    <span><i class="far fa-trash-alt"></i></span>
                  </button>
                </div>

                <button
                  class="add-another-btn btn btn-studio-theme-transparent btn-size-small mb-4 w-100"
                  @click="addNewBoard"
                >
                  Add Another Existing Board
                </button>

                <button
                  class="btn btn-studio-theme-space btn-size-large w-100 justify_center"
                  :disabled="creatingANewBoard"
                  @click="createProfileAndBoards"
                >
                  <span>Connect A Profile and Pinterest Boards?</span>
                  <clip-loader
                    v-if="creatingANewBoard"
                    :color="'#ffffff'"
                    :size="'16px'"
                  ></clip-loader>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";
import { pinterest } from '../../store/states/mutation-types'

export default {
  name: 'AddPinterestModal',
  data () {
    return {
      account_name: null,
      image: null,
      account_image:
        'https://storage.googleapis.com/lumotive-web-storage/publish/sharing/5fa4f1a1c4b76e310620ed42/JURvn0U5FmfOWPjGJ3ij.png',
      shiftform: false,
      board_details: [
        {
          board_id: null,
          board_name: null
        }
      ],
      uploadingImage: false,
      creatingANewBoard: false
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'add_pinterest_modal') {
        this.account_name = null
        this.account_image =
          'https://storage.googleapis.com/lumotive-web-storage/publish/sharing/5fa4f1a1c4b76e310620ed42/JURvn0U5FmfOWPjGJ3ij.png'
        this.board_details = [
          {
            board_id: null,
            board_name: null
          }
        ]
        this.shiftform = false
        this.image = null
        this.uploadingImage = false
        this.creatingANewBoard = false
      }
    })
  },
  methods: {
    async onFileChange (e) {
      // const file = e.target.files[0]
      this.image = e.target.files[0]
      if (!this.uploadingImage) {
        this.uploadingImage = true
        this.account_image = require('../../../../assets/img/upload-file-feature-contact-form.gif')

        const resp = await this.uploadImage(e)
        if (resp && resp.status && resp.media.length > 0) {
          this.account_image = resp.media[0].link
          this.alertMessage('Account Image Uploaded Successfully', 'success')
        } else if (resp && !resp.status && resp.storageFull) {
          this.image = null
          this.account_image = null
          this.$store.commit('setMediaStorageLimit', resp.usedStorage)
          this.$bvModal.show('media-storage-limits-exceeded-modal')
        } else {
          this.image = null
          this.account_image = null

          this.alertMessage('Account Image Failed to Upload', 'error')
        }

        this.uploadingImage = false
      }
    },
    addNewBoard () {
      this.board_details.push({
        board_id: null,
        board_name: null
      })
    },
    removeBoard (index) {
      this.board_details.splice(index, 1)
    },
    isValidated () {
      if (this.account_name === null || this.account_name.trim().length === 0) {
        this.alertMessage('Account Name Is Required', 'error')
        return false
      }

      let isBoardValid = true

      // if it's board's form
      if (this.shiftform) {
        for (let i = 0; i < this.board_details.length; i++) {
          // temporarily we are using board_id field for board url
          if (
            this.board_details[i].board_id === null ||
            this.board_details[i].board_id.trim().length === 0
          ) {
            this.alertMessage('Board Url Is Required', 'error')
            isBoardValid = false
            break
          }
        }
      }

      return isBoardValid
    },
    async createProfileAndBoards () {
      const payload = {
        account_name: this.account_name,
        account_image: this.account_image,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        board_details: this.board_details
      }

      if (this.uploadingImage) {
        this.alertMessage('Uploading Image Please Wait.', 'error')
        return
      }

      if (this.isValidated()) {
        if (!this.creatingANewBoard) {
          this.creatingANewBoard = true
          const response = await this.$store.dispatch(
            'createProfileAndBoardsManually',
            payload
          )
          if (response.data.status) {
            this.$store.dispatch('setPlatforms', {
              items: response.data.accounts,
              type: 'pinterest',
              all_item_setter: pinterest.SET_ALL_ACCOUNTS,
              item_setter: pinterest.SET_ACCOUNTS
            })
          }
          this.creatingANewBoard = false

          this.$bvModal.hide('add_pinterest_modal')
        }
      }
    }
  }
}
</script>

<style scoped></style>
