<template>
  <div :id="type + '-section'" class="ps_box">
    <div class="box_heading">
      <h2>{{ title }}</h2>
    </div>
    <div class="box_content">
      <clip-loader
          v-if="fetchingLoader"
          class="spinner mt-5 mb-3"
          :color="'#6d76ff'"
          :size="'20px'"
      ></clip-loader>
      <div v-else class="col-7 integration_box_list">
        <div>
          <p class="mt-[14px]"
          >Due to the latest updates on the X (Twitter) API, full access to the services related to X (Twitter) are only
            available for users that provide their consumer keys.
            <a
                href="https://docs.contentstudio.io/article/966-how-to-connect-with-a-twitter-custom-app"
                target="_blank" rel="noopener"
                class="text-[#0068E5]"
            >Learn how to create and connect using your app.</a
            >
          </p>
          <template v-if="state.customApps.length">
            <p
                class="text-[16px] font-semibold text-[#3C4549] my-[16px] select-none"
            >Connected Apps</p
            >
            <div class="w-full flex flex-wrap gap-3">
              <div
                  v-for="customApp in state.customApps"
                  :key="`custom_app_${customApp._id}`"
                  class="w-[280px] rounded-[8px] p-[12px] h-[48px] flex items-center justify-between border border-[#757A8A]"
              >
                <p v-tooltip="customApp.app_name" class="select-none text-[#3C4549] text-[16px] truncate">
                  {{ customApp.app_name }}
                </p>
                <img
                    v-tooltip="'Delete App'"
                    src="@src/assets/img/integration/delete_red_light.svg"
                    class="cursor-pointer w-5 h-5"
                    alt=""
                    @click="
                    deleteCustomApp(customApp._id, customApp.app_name, customApp.platform_type)
                  "
                />
              </div>
            </div>
            <hr class="text-[#D2D5DF] w-full mb-0 mr-0 ml-0 mt-6"/>
          </template>
          <a
              v-tooltip="!customAppAccess?.allowed ? customAppAccess.error?.message : ''"
              class="btn btn-studio-theme-space mt-6"
              :class="{ 'btn-studio-theme-transparent-disabled': !customAppAccess?.allowed }"
              @click="$bvModal.show('add-twitter-account-modal')"
          >
            <span>Add New Custom App</span>
          </a>
        </div>
      </div>
    </div>
    <TwitterIntegrationModal />
  </div>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// libraries
import {inject, onMounted, reactive} from 'vue'
import useIntegrationComposable from '@src/modules/integration/Composables/useIntegrationComposable'
import TwitterIntegrationModal from '@src/modules/integration/components/dialogs/TwitterIntegrationModal.vue'
import {useStore} from '@state/base'
import proxy from '@common/lib/http-common'
import {integrationsBaseURL} from '@src/config/api-utils'
import {UNKNOWN_ERROR} from '@common/constants/messages'
import {swalAttributes} from "@common/constants/common-attributes";
import {useFeatures} from "@modules/billing/composables/useFeatures";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: 'Custom Apps',
  },
})

const root = inject('root')
const { $bvModal } = root
const {getters, dispatch} = useStore()
const {canAccess} = useFeatures()
const customAppAccess = canAccess('twitter_custom_app')
const {getAuthorizationUrl} = useIntegrationComposable()

// destructure the necessary variables and functions from the useIntegrationComposable hook to fetch and display Twitter custom apps.
const {fetchTwitterCustomApps, fetchingLoader, twitterCustomApps} =
    useIntegrationComposable()

const state = reactive({
  fetchCustomAppsLoader: false,
  customApps: twitterCustomApps,
})

onMounted(async () => {
  await fetchTwitterCustomApps()
})

/**
 * @description Connects a Twitter app to ContentStudio by redirecting to the app URL with the necessary query parameters
 * @param {Object} twitterAppData - The Twitter app data containing the app ID, URL, and platform type
 * @param process
 */
const connectTwitterApp = async (twitterAppData = {}, process = 'connect') => {
  // Define an object containing details of the connection process
  const details = {
    process,
    app_id: twitterAppData._id,
    follow: false,
  }

  const baseUrl = window.location.href.split('#')[0];
  details.callback_url = `${baseUrl}#twitter`;

  await getAuthorizationUrl({
    platform: 'twitter',
    connectionDetails: details,
  })
}

/**
 * @description Deletes the Twitter custom app with the specified ID
 * @param {string} id - The ID of the custom app to delete
 * @param appName - The name of the custom app to delete
 * @param {string} platform_type - The platform type of the custom app (default: 'twitter')
 */
const deleteCustomApp = async (id = '', appName = '', platform_type = 'twitter') => {
  const res = await $bvModal.msgBoxConfirm(
      `Are you sure you want to delete custom app "${appName}"?`,
      {
        title: 'Delete Custom App',
        ...swalAttributes(),
      }
  )

  if(!res) return

  // payload for the API request
  const payload = {
    id,
    platform_type,
    workspace_id: getters.getActiveWorkspace._id,
  }
  try {
    // Send a DELETE API request to delete the custom app
    const {data} = await proxy.delete(
        `${integrationsBaseURL}developerApp/deleteApp`,
        {data: payload}
    )

    // Display a success message and refresh the list of custom apps if the delete operation was successful (i.e. status = true)
    if (data.status) {
      await showToastNotification('Custom app deleted successfully.', 'success')

      // Refresh the list of custom apps
      await fetchTwitterCustomApps()
    } else {
      // Display an error message if the delete operation failed
      await showToastNotification(data.message, 'error')
    }
  } catch (error) {
    // Display an error message if the API request failed
    const errorMessage =
        error?.response?.data?.message || error?.message || UNKNOWN_ERROR
    await showToastNotification(errorMessage, 'error')
  }
}

/**
 * Dispatches a toast notification with the given message and type.
 *
 * @param {string} message - The message to display in the toast notification.
 * @param {string} type - The type of toast notification (e.g. 'success', 'error', 'warning', etc.).
 */
const showToastNotification = async (message, type) => {
  await dispatch('toastNotification', {
    message,
    type,
  })
}
</script>
