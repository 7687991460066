<template>
  <component :is="element" />
</template>

<script setup>
import { h, computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
  htmlContent: {
    type: String,
    required: true
  },
  tag: {
    type: String,
    default: 'div',
    validator: (value) => {
      // List of allowed HTML tags
      const allowedTags = [
        'div', 'p', 'span', 'article', 'section',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'pre', 'code', 'blockquote', 'strong',
      ]
      return allowedTags.includes(value)
    }
  }
})

const emit = defineEmits(['click'])

const element = computed(() => ({
  render() {
    return h(props.tag, {
      innerHTML: props.htmlContent,
      onclick: () => {
        emit('click')
      }
    })
  }
}))
</script>

<style scoped>
:deep(button) {
  cursor: pointer;
}
</style>
