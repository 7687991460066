<template>
  <!-- The Modal -->

  <b-modal
    id="article_preview_modal"
    ref="article_preview_modal"
    modal-class="article_preview_modal right side_slide_right w_1150 full_height article-preview-dialog"
    hide-footer
    hide-header
  >
    <div class="modal-content article-preview-dialog__content">
      <div class="modal-body article-preview-dialog__content__body">
        <div class="article-preview-dialog__content__body__inner">
          <template v-if="!isShowArticlePreview">
            <div class="article-preview-dialog__content__body__inner__loading">
              <beat-loader />
            </div>
          </template>
          <template v-else>
            <div class="article-preview-dialog__content__body__inner__header">
              <div
                class="article-preview-dialog__content__body__inner__header__icon_actions"
              >
                <template v-if="sectionName !== 'composerSection'">
                  <div
                    class="btn-studio-theme-transparent-icon mr-2"
                    @click="showTwitterPreview(getContentPreview._source.id)"
                  >
                    <i class="icon-User"></i>
                  </div>

                  <!-- share buttons -->
                  <b-dropdown
                    id="dropdown-share-articles"
                    variant="studio-icon-theme"
                    class="studio-icon-theme-dropdown mr-2"
                    dropright
                    no-caret
                  >
                    <template v-slot:button-content>
                      <i :title="'Share this post'" class="icon-Share_icon"></i>
                    </template>
                    <b-dropdown-item
                      variant="studio-icon-theme"
                      @click.prevent="
                        shareSearchFromDialog('social_share_modal')
                      "
                      ><i class="far fa-share share-ico-align"></i> Social Media
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="studio-icon-theme"
                      @click.prevent="shareSearchFromDialog('blog_share_modal')"
                      ><i class="far fa-rss"></i> Blog Post
                    </b-dropdown-item>
                  </b-dropdown>

                    <template v-if="getTopicPreviewHasFavorited">
                      <b-dropdown
                        id="dropdown-favorite-articles"
                        ref="dropdown_favorite"
                        variant="studio-icon-theme"
                        class="studio-icon-theme-dropdown mr-2"
                        no-caret
                        block
                      >
                        <template
                          v-if="getContentPreview._source.is_favorite"
                          v-slot:button-content
                        >
                          <i
                            v-tooltip="'Unfavorite'"
                            class="icon-Favorite_icon is-favorite"
                            @click.prevent="
                              unfavoriteArticlePreviewPostItemEventFire(
                                getContentPreview._source.id,
                              )
                            "
                          ></i>
                          <span
                            :class="{
                              ' active': getContentPreview._source.is_active,
                            }"
                            class="icon_animate"
                          ></span>
                        </template>
                        <template v-else v-slot:button-content>
                          <i
                            v-tooltip="'Favorite'"
                            class="icon-Favorite_icon"
                            :class="{
                              'animated tada':
                                getContentPreview._source.is_active,
                            }"
                          ></i>
                        </template>
                        <BDropdownFavoriteArticle
                          v-show="!getContentPreview._source.is_favorite"
                          :post_id="getContentPreview._source.id"
                          :post-index="getContentPreview.index_id"
                        />
                      </b-dropdown>
                    </template>

                  <div
                    class="btn-studio-theme-transparent-icon mr-2"
                    title="Add to Pocket"
                    @click="pocketPreviewAction"
                  >
                    <i class="icon-pocket"></i>
                  </div>
                  <template v-if="$route.name === 'discover_topics_archived'">
                    <div
                      class="btn-studio-theme-transparent-icon mr-2"
                      title="Unarchive post"
                      @click="
                        unarchivePostItemEventFire(getContentPreview._source.id)
                      "
                    >
                      <i class="icon-Archive_icon"></i>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="btn-studio-theme-transparent-icon mr-2"
                      title="Archive post"
                      @click="
                        archivePostItemEventFire(getContentPreview._source.id)
                      "
                    >
                      <i class="icon-Archive_icon"></i>
                    </div>
                  </template>

                  <div class="btn-separator-line"> </div>
                </template>

                <div
                  class="btn-studio-theme-transparent-icon mr-2"
                  title="Open this link"
                  @click="openExternalPageLink"
                >
                  <i class="icon-link_icon"></i>
                </div>
              </div>
              <div
                v-if="sectionName === 'composerSection'"
                class="article-preview-dialog__content__body__inner__header__add_full_article_to_editor"
              >
                <button
                  class="btn btn-studio-theme-space mr-2"
                  @click="addTextToEditor('full')"
                >
                  <span>Add full article to editor</span>
                </button>
              </div>
              <div
                class="article-preview-dialog__content__body__inner__header__close_action"
              >
                <div
                  class="btn-studio-theme-grey-icon"
                  @click="$bvModal.hide('article_preview_modal')"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>

            <div class="article-preview-dialog__content__body__inner__main">
              <div
                class="article-preview-dialog__content__body__inner__main__about_domain"
              >
                <div
                  class="article-preview-dialog__content__body__inner__main__about_domain__left_section"
                >
                  <p
                    >{{ getContentPreview._source.domain }} -
                    {{ $filters.relative(getContentPreview._source.post_date) }} on
                    {{
                      $filters.dateWithYear(getContentPreview._source.post_date)
                    }}</p
                  >
                </div>
                <div
                  class="article-preview-dialog__content__body__inner__main__about_domain__right_section"
                >
                  <div
                    v-if="getContentPreview._source.topics"
                    class="article-preview-dialog__content__body__inner__main__about_domain__right_section__tags"
                  >
                    <template
                      v-for="topic in getContentPreview._source.topics"
                    >
                        <template v-if="topic.label">
                      <span
                              v-for="finalized_topic in checkIfMultipleTopics(
                          topic.label,
                        )"
                              class="tag"
                      >
                        {{ finalized_topic }}
                      </span>
                        </template>
                    </template>
                  </div>
                </div>
              </div>
              <div
                class="article-preview-dialog__content__body__inner__main__title"
              >
                <h2 v-html="getContentPreview._source.title" />
              </div>

              <div
                class="article-preview-dialog__content__body__inner__main__engagement"
              >
                <div class="article-preview-engagement-block">
                  <div
                    class="article-preview-engagement-block-social popover-social-statistics"
                  >
                    <SharedCountTooltip
                      :post="{ _source: getContentPreview._source }"
                    />
                    <span class="value-block">{{
                      intToString(getTopicPreviewTotalShares)
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="
                    getContentPreview._source.velocity &&
                    getContentPreview._source.velocity > 5
                  "
                  class="article-preview-engagement-block"
                >
                  <span class="text-block">Trending Score</span>
                  <span class="value-block">{{
                    $filters.numberToCommas(getContentPreview._source.velocity)
                  }}</span>
                </div>
                <div
                  v-if="getTopicPreviewSentiment"
                  class="article-preview-engagement-block"
                >
                  <span class="text-block">Sentiment</span>
                  <span class="value-block"
                    ><i
                      v-tooltip.top="getTopicSentimentTooltip"
                      aria-hidden="true"
                      :class="getTopicPreviewSentiment"
                    ></i
                  ></span>
                </div>
              </div>

              <div
                class="article-preview-dialog__content__body__inner__main__image"
              >
                <div
                  class="article-preview-image-block"
                  :style="{
                    'background-image':
                      'url(' +
                      backgroundArticlePreviewImageURL(
                        getContentPreview._source,
                        800,
                      ) +
                      '), url(' +
                      backgroundArticlePreviewImageDefaultURL() +
                      ')',
                  }"
                >
                </div>
              </div>

              <template v-if="sectionName === 'composerSection'">
                <div
                  id="article_wrapper"
                  class="article-preview-dialog__content__body__inner__main__content"
                >
                  <!--                         @click.left="closeMenu"-->
                  <!--                         v-click-away="closeMenu"-->
                  <!--                         @contextmenu.prevent="openMenu($event)"-->
                  <div
                    v-if="getContentPreview._source.description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.description"
                  >
                  </div>
                  <div
                    v-else-if="getContentPreview._source.short_description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.short_description"
                  >
                  </div>
                  <div
                    v-else
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                  >
                    <p>No description available for this article.</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="article-preview-dialog__content__body__inner__main__content"
                >
                  <div
                    v-if="getContentPreview._source.description"
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                    v-html="getContentPreview._source.description"
                  >
                  </div>
                  <div
                    v-else
                    class="article-preview-dialog__content__body__inner__main__content__inner"
                  >
                    <p>No description available for this article.</p>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { POSTS_REPORT_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import FavoriteDropdown from '../../folders/FavoriteDropdown'
import { reportPostURL } from '../../../config/api-utils'
import SharedCountTooltip from '../snippets/SharedCountTooltip'
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'

export default {
  components: {
    FavoriteDropdown,
    SharedCountTooltip,
    BDropdownFavoriteArticle
  },
  props: ['sectionName'],
  data () {
    return {
      index: null,
      showDropdown: false,
      text: '',
      origin: {}
    }
  },
  computed: {
    ...mapGetters([
      'getContentPreview',
      'getSearchLoader',
      'getBlogEditorSelector'
    ]),
    isShowArticlePreview () {
      return !!(
        !this.getSearchLoader.content.previewArticle &&
        this.getContentPreview._source
      )
    },
    getTopicPreviewTotalShares () {
      const post = this.getContentPreview._source
      return (
        (post.facebook_shares || 0) +
        (post.pins || 0) +
        (post.twitter_shares || 0) +
        (post.reddit || 0)
      )
    },
    getTopicPreviewSentiment () {
      if (
        this.getContentPreview._source.sentiment &&
        this.getContentPreview._source.sentiment.length > 0
      ) {
        const sentimentLabel = this.getContentPreview._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'smile far fa-smile-o'
          case 'negative':
            return 'sad far fa-meh-o'
          case 'neutral':
            return 'far fa-meh normal'
          default:
            return 'normal far fa-frown-o'
        }
      }
      return 'normal far fa-frown-o'
    },

    getTopicSentimentTooltip () {
      if (
        this.getContentPreview._source.sentiment &&
        this.getContentPreview._source.sentiment.length > 0
      ) {
        const sentimentLabel = this.getContentPreview._source.sentiment[0].label
        switch (sentimentLabel) {
          case 'positive':
            return 'Positive'
          case 'negative':
            return 'Negative'
          case 'neutral':
            return 'Neutral'
          default:
            return 'Normal'
        }
      }
      return 'Normal'
    },
    getTopicPreviewHasFavorited () {
      return this.getContentPreview._source.hasOwnProperty('is_favorite')
    }
  },
  mounted () {
    EventBus.$on('favorite-post-item-preview', () => {
      this.getContentPreview._source.is_favorite = true
      this.getContentPreview._source.is_active = true
    })
  },
  beforeUnmount () {
    EventBus.$off('favorite-post-item-preview')
  },

  methods: {
    ...mapActions(['archive']),
    pocketPreviewAction () {
      const url = this.savePocketContent(this.getContentPreview._source.url)
      window.open(url, '_blank', 'noopener, noreferrer')
    },
    openExternalPageLink () {
      window.open(
        this.fetchUtmBasedLinks(
          this.getContentPreview._source.url,
          this.fetchActiveUtm
        ).common,
        '_blank', 'noopener, noreferrer'
      )
    },

    archivePostItemEventFire (postId) {
      this.$bvModal.hide('article_preview_modal')
      EventBus.$emit('archive-post-item', { postId })
    },
    unarchivePostItemEventFire (postId) {
      this.$bvModal.hide('article_preview_modal')
      EventBus.$emit('unarchive-post-item', { postId })
    },

    shareSearchFromDialog (type = 'social_share_modal') {
      this.$bvModal.hide('article_preview_modal')
      this.initializeArticlePostCreation(
        this.getContentPreview._source.id,
        type
      )
    },
    unfavoriteArticlePreviewPostItemEventFire (postId) {
      EventBus.$emit('unfavorite-post-item', { postId })
      this.getContentPreview._source.is_favorite = false
      this.getContentPreview._source.is_active = false
    },

    // old methods.

    reportPost (postId, elasticId, websiteURL, link) {
      console.debug('Method:reportPostMixin')

      const data = {
        elastic_id: elasticId,
        post_id: postId,
        website_url: websiteURL,
        link
      }

      this.postRequest(
        reportPostURL,
        data,
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(POSTS_REPORT_ERROR, 'error')
          }
        },
        (response) => {
          console.debug('Exception in reportPostMixin ', response)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
    showTwitterPreview (id) {
      const thiss = this
      // $('#article_preview_modal').modal('hide')
      this.$bvModal.hide('article_preview_modal')
      setTimeout(function () {
        thiss.showSharers(id, true)
      }, 500)
    },
    closeMenu () {
      this.showDropdown = false
    },
    openMenu (e) {
      this.text = ''
      // get selected text
      this.showDropdown = false
      if (window.getSelection) {
        this.text = window.getSelection().toString()
        if (this.text.trim() !== '') {
          this.showDropdown = true
        }
      }
      // set dropdown position
      const parent = $('#article_wrapper')
      this.origin = {
        left: e.pageX - parent.offset().left,
        top: e.pageY - parent.offset().top
      }
    },
    addTextToEditor (type) {
      // this.checkEditorRange()
      // add text by "selected" or "full" content type
      // selected by mouse cursor
      if (type === 'selected') {
        if (this.text.trim() !== '') {
          this.setCKEditorHtml(this.text, true)

          // this.getBlogEditorSelector.insertHtml(this.text)
        }
        // full by click on "add to editor" button
      } else if (type === 'full') {
        if (this.getContentPreview._source.description.length) {
          this.setCKEditorHtml(this.getContentPreview._source.description, true)
          // this.getBlogEditorSelector.insertHtml(this.getContentPreview._source.description)
        } else if (this.getContentPreview._source.short_description.length) {
          // this.getBlogEditorSelector.insertHtml(this.getContentPreview._source.description)
          this.setCKEditorHtml(
            this.getContentPreview._source.short_description,
            true
          )
        }
      }
    }
  }
}
</script>

<style>
.article_preview_modal
  .modal-dialog
  .modal-content
  .modal_left
  .article_detail_left
  .btn_block
  .btn
  .favorite_icon_orange {
  color: #ff9300;
}
</style>
