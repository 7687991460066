<template>
  <div class="flex">
    <div
      class="bg-[#F2F3F8] mr-4 w-9 h-9 rounded-full flex items-center justify-center"
    >
      <img src="@src/assets/img/chat_bot/cyrus-icon-blue.svg" alt="robot" class="w-[20px] h-[20px]" />
    </div>
    <div
      class="p-4 bg-[#F2F3F8] rounded-e-2xl self-end max-w-[75%] mr-auto mb-4 relative"
      style="border-radius: 0 16px 16px 16px"
    >
      <HtmlRenderer
        tag="span"
        class="text-sm text-left ai-chat-message"
        :html-content="formatText(message.content)"
      />
      <div class="flex justify-between mt-3 items-center">
        <span class="text-xs text-[#595C5F]"
          >{{ calculateTextLength.wordCount }} words /
          {{ calculateTextLength.charCount }} characters</span
        >
        <div class="flex justify-end items-center">
          <img
              v-if="isComposerModalOpen || props.type === 'blog'"
              v-tooltip="{ content: 'Replace the content in the editor' }"
              src="@src/assets/img/chat_bot/replace-content.svg"
              width="18"
              height="18"
              alt="external-link"
              class="ml-3 cursor-pointer active:opacity-50"
              @click.stop="replaceInEditor(message.content)"
          />
          <img
              v-if="isComposerModalOpen || props.type === 'blog'"
              v-tooltip="{ content: 'Add to Editor' }"
              src="@src/assets/img/chat_bot/add-text.svg"
              width="18"
              height="18"
              alt="external-link"
              class="ml-3 cursor-pointer active:opacity-50"
              @click="addToEditor(message.content)"
          />
          <img
            v-tooltip="{ content: 'Copy' }"
            src="@src/assets/img/chat_bot/copy.svg"
            class="ml-3 cursor-pointer active:opacity-50"
            width="18"
            height="18"
            alt="copy"
            @click="copyPrompt(message.content)"
          />
          <div class="group relative">
            <img
              src="@src/assets/img/chat_bot/more-vertical.svg"
              width="18"
              height="18"
              alt="more-vertical.svg"
              class="ml-3 cursor-pointer active:opacity-50"
            />
            <!--  DROPDOWN-->
            <div
              class="w-[160px] flex-col p-4 absolute -right-3 rounded top-7 z-10 bg-white hidden group-hover:!flex"
              style="
                filter: drop-shadow(0px 3px 20px rgba(201, 207, 224, 0.4));
                border-radius: 10px;
              "
            >
              <img
                src="@src/assets/img/chat_bot/top-edge.svg"
                alt="arrow"
                class="absolute -top-2 -right-2"
              />
              <div class="cursor-pointer hover:font-bold" @click="props.deleteMessage(message._id)">
                <img
                  src="@src/assets/img/chat_bot/trash-2.svg"
                  alt="external-link"
                  width="18"
                  height="18"
                />
                <span class="text-[#D65151] text-sm ml-3">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { computed, onMounted, reactive, ref, defineProps, defineEmits } from 'vue'
import {useAiChatFormatter} from "@src/modules/AI-tools/composables/chat";
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import {useStore} from "@state/base";
import {useRoute} from "vue-router";
import {EventBus} from "@common/lib/event-bus";
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";

const {isComposerModalOpen} = useComposerHelper()
const { formatText } = useAiChatFormatter()
const props = defineProps({
  message: {
    type: Object,
    default: () => {},
  },
  deleteMessage:{
    type: Function,
    default: () => {},
  },
  type: {
    type: String,
    default: 'modal',
  },
})
const store = useStore()
const route = useRoute()
const calculateTextLength = computed(() => {
  if (props.message?.content) {
    const words = props.message.content.split(' ')
    const wordCount = words.length
    const charCount = props.message.content.length
    return { wordCount, charCount }
  }
  return { wordCount: 0, charCount: 0 }
})
const copyPrompt = (prompt) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(prompt)
  }
}
const addToEditor = (prompt) => {
  let htmlText = prompt
  if(props.type === 'blog'){
    htmlText = formatText(prompt)
  }

  // below event is for blog editor implement for composer also
  EventBus.$emit('insert-chat-content', htmlText)
}

const replaceInEditor = (prompt) => {
  let htmlText = prompt
  if(props.type === 'blog'){
    htmlText = formatText(prompt)
  }
  // below event is for blog editor implement for composer also
  EventBus.$emit('replace-chat-content', htmlText)
}
</script>

<style scoped>

</style>
