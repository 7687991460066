<script setup>
import { defineProps, ref } from 'vue'
import PerformancePostPreviewModal from '@src/modules/analytics_v3/components/PerformancePostPreviewModal.vue'
import TiktokPublishedPostPreview from '@src/modules/analytics/views/tiktok/components/TiktokPublishedPostPreview.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import {sanitizeUrl} from "@braintree/sanitize-url";

const { getSocialImageRounded } = useComposerHelper()

defineProps({
  selectedPost: {
    type: Object,
    default: () => ({}),
  },
  selectedAccount: {
    type: Object,
    default: () => ({}),
  },
})

const isLoading = ref(true)

const handleModalShow = () => {
  isLoading.value = true
}
const handleModalHidden = () => {
  isLoading.value = true
}
</script>

<template>
  <PerformancePostPreviewModal
    :preview-post-data="selectedPost"
    :is-modal="false"
    :type="'post-details-modal'"
    @show="handleModalShow"
    @hidden="handleModalHidden"
  >
    <template v-slot:modal-title>
      <h4>Post Preview</h4>
    </template>
    <template v-slot:sidebar-title>
      <p class="font-weight-500 mb-[1rem]">Statistics</p>
    </template>
    <template v-slot:sidebar-content>
      <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/eye-icon.svg"
            alt="Eye Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Views
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost.views }}
            </span>
          </div>
        </div>
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/point-select.svg"
            alt="Pointer Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Total Engagements
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost.engagement }}
            </span>
          </div>
        </div>
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/graph-up.svg"
            alt="Graph Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Engagement Rate
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost.engagementRate }}%
            </span>
          </div>
        </div>
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/heart-icon.svg"
            alt="Like Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Likes
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost.likes }}
            </span>
          </div>
        </div>
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/comment-icon.svg"
            alt="Comments Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Comments
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost.comments }}
            </span>
          </div>
        </div>
        <div
          v-if="selectedPost?.followers_count"
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/followers-icon.svg"
            alt="Followers Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">
              Followers
            </span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              {{ selectedPost?.followers_count }}
            </span>
          </div>
        </div>
        <div
          class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
        >
          <img
            src="@src/assets/img/icons/analytic/image-icon.svg"
            alt="Image Icon"
            class="w-6 h-6 my-auto"
          />
          <div class="flex flex-col ml-5">
            <span class="text-gray-800 group-hover:text-black-900">Type</span>
            <span
              class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500"
            >
              Video
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:post-preview>
      <div class="flex justify-center gap-4">
        <TiktokPublishedPostPreview
          v-if="selectedPost.embedHtml"
          :tiktok-embed-html="selectedPost?.embedHtml"
        />
        <a :href="sanitizeUrl(selectedPost.link)" target="_blank" rel="noopener"  class="mt-[18px]">
          <img
            v-tooltip="{
              content: 'View post on ' + selectedAccount.account_type,
            }"
            :src="getSocialImageRounded(selectedAccount.account_type)"
            alt="Platform Logo"
            class="h-8 w-8"
          />
        </a>
      </div>
    </template>
  </PerformancePostPreviewModal>
</template>
