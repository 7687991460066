<template>
  <div>
    <div
      v-for="(post, index) in getPosts"
      :key="index"
      class="topic_content_box _article"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
    >
      <div
        v-if="post._source"
        class="box_inner"
        :draggable="$route.name === 'composerBlog'"
        @dragstart="dragstartHandler($event, post)"
      >
        <div class="d-flex">
          <div class="image_block">
            <div
              v-lazy:background-image="backgroundImageURL(post._source)"
              class="img"
            ></div>
          </div>
          <div class="content_block">
            <div class="content_block_inner">
              <h3
                :title="post._source.title"
                @click.prevent="handleArticlePreview(post._source.id,index)"
                >{{ limitTextLength(post._source.title, 65) }}</h3
              >
              <div class="detail_box d-flex align-items-center">
                <a class="domain_name" href="javascript:;">{{
                  trimURL(post._source.domain_url)
                }}</a>
              </div>
              <div class="detail_box d-flex align-items-center">
                <p class="date">{{ $filters.relative(post._source.post_date) }}</p>
                <p class="author_name">
                  <template v-if="post._source.author">
                    By
                    <HtmlRenderer
                      tag="span"
                      :html-content="post._source.author" />
                    <a
                      v-for="(account, accountsIndex) in post._source.twitter_accounts"
                      target="_blank" rel="noopener"
                      :href="sanitizeUrl(getTwitterLink(account))"
                      ><template v-if="post._source.twitter_accounts && accountsIndex < 1">{{ account }}</template></a
                    >
                  </template>
                  <template
                    v-else-if="
                      post._source.twitter_accounts &&
                      post._source.twitter_accounts.length > 0
                    "
                  >
                    By
                    <a
                      v-for="(account, accIndex) in post._source.twitter_accounts"
                      target="_blank" rel="noopener"
                      :href="sanitizeUrl(getTwitterLink(account))"
                      ><template v-if="post._source.twitter_accounts && accIndex < 1">{{ account }}</template></a
                    >
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer clear">
          <div v-tooltip.top="'Engagement'" class="social_detail">
            <i class="icon-engagement-ico"></i>
            <span v-if="post._source" class="state_txt">{{
              intToString(calculateEngagements(post._source))
            }}</span>
            <span v-else class="state_txt">0</span>
          </div>
          <div v-tooltip.top="'Sentiment'" class="social_detail">
            <template
              v-if="post._source.sentiment && post._source.sentiment.length > 0"
            >
              <i
                v-if="post._source.sentiment[0].label === 'negative'"
                class="cs-sad-face sad"
              ></i>
              <i
                v-if="
                  post._source.sentiment[0].label === 'positive' &&
                  post._source.sentiment[0].probability >= 0.65
                "
                class="cs-happy-face smile"
              ></i>
              <i
                v-else-if="
                  post._source.sentiment[0].label === 'positive' &&
                  post._source.sentiment[0].probability < 0.65
                "
                class="cs-normal-face normal"
              ></i>
              <i
                v-if="post._source.sentiment[0].label === 'neutral'"
                class="cs-normal-face normal"
              ></i>
            </template>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_article_block"
          contenteditable="false"
        >
          <div class="top_option no_flickr">
            <div class="right_block">
              <i
                class="handler fa fa-hand-paper-o editor_tooltip"
                title="Move"
              ></i>
              <i
                class="edit_box fa fa-check-circle editor_tooltip green"
                title="Merge"
              ></i>
              <i
                class="remove_box fa fa-times-circle editor_tooltip red"
                title="Remove"
              ></i>
            </div>
          </div>
          <div class="editor_dragable_box_inner clear">
            <!--<br>-->
            <h2>{{ limitTextLength(post._source.title, 0) }}</h2>
            <p
              ><img
                style="
                  width: 225px;
                  float: left;
                  margin: 5px 0.625rem;
                "
                :src="backgroundImageURL(post._source)"
                alt=""
            /></p>
            <p v-if="post._source.description">{{
              limitTextLength(removeHtmlTags(post._source.description), 200)
            }}</p>
            <a
              style="text-decoration: underline"
              target="_blank" rel="noopener"
              :href="sanitizeUrl(post._source.url)"
              >Read more</a
            >
            <br />
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { removeHtmlTags } from '@common/lib/helper'
import {sanitizeUrl} from "@braintree/sanitize-url";
import HtmlRenderer from "@common/components/htmlRenderer.vue";

export default {
  components: {HtmlRenderer},
  computed: {
    ...mapGetters(['getPosts'])
  },
  created () {},
  methods: {
    sanitizeUrl,
    ...mapActions(['articlePreview']),
    handleArticlePreview(source,index){
      this.articlePreview({ id: source, index, appContainer: this })
    },
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      console.log('dragstartHandler', post)
      if (post && post._source.title) {
        ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'article',
            heading: post._source.title,
            media: this.backgroundImageURL(post._source),
            p: this.truncateString(
              removeHtmlTags(post._source.description),
              400
            ),
            link: post._source.url
          })
        )
      }
    }
  }
}
</script>
